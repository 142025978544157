import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { LastSelectionType, PermissionLevels, User, UserPermissionSet, UserProfile } from '@/common/models'
import { userPermissionValidatorService, userService } from '@/common/services'
import { cloneDeep } from 'lodash'

export const useCurrentUserStore = defineStore('currentUser', () => {
    const currentUser = ref<User | null>(null)
    const permissionSets = ref<UserPermissionSet[]>([])

    const userPermissionSet = computed(() => {
        const userSet: UserPermissionSet[] = permissionSets.value ?? []
            userSet.forEach(p => {
                const permissionLevels: PermissionLevels[] = []
                p.permissions.forEach(per => {
                    permissionLevels.push(userPermissionValidatorService.getPermissionLevel(per))
                })
                p.permissionLevels = permissionLevels
            })

            return userSet
    })
    const currentUserProfile = computed(() => cloneDeep(currentUser.value?.profile) || ({} as UserProfile))
    const lastSelectedLender = computed(() => currentUserProfile.value?.lastSelectedFilters?.lender ?? '')
    const userDefaultLender = computed(() => {
        const defaultLenderSetting = currentUserProfile.value?.preferences?.loan?.defaultLender ?? ""
        return defaultLenderSetting === LastSelectionType.Lender ? lastSelectedLender.value : defaultLenderSetting
    })
    const lastSelectedPartner = computed(() => currentUserProfile.value?.lastSelectedFilters?.partner ?? '')
    const userDefaultPartner = computed(() => {
        const defaultPartnerSetting = currentUserProfile.value?.preferences?.loan?.defaultPartner ?? ""
        return defaultPartnerSetting === LastSelectionType.Partner ? lastSelectedPartner.value : defaultPartnerSetting
    })
    const useAlternatingRowColors = computed(() => currentUserProfile.value?.preferences?.grids?.alternateRowColors)
    
    async function getCurrentUser() {
        const userProfile = await userService.getCurrentUserProfile()
        const permissionSetsFromApi = await userService.getFlattenedUserPermissions(userProfile.id)
        currentUser.value = userProfile
        permissionSets.value = permissionSetsFromApi
    }

    async function updateCurrentUser(user: User) {
        let userRecord = cloneDeep(currentUser.value || {}) as User
        userRecord = Object.assign({}, userRecord, cloneDeep(user))
        const currentId = userRecord.id
        //Since the local user doesn't have an ID with which we can post
        userRecord = currentId ? await userService.setUserProfile(userRecord) : cloneDeep(userRecord)
        userRecord.id = currentId
        currentUser.value = userRecord
    }

    async function updateUserProfile(user: User) {
        const response = await userService.setUserProfile(user)
        currentUser.value = response
    }

    return { 
        currentUser, 
        permissionSets,
        userPermissionSet, 
        currentUserProfile, 
        lastSelectedLender,
        userDefaultLender,
        lastSelectedPartner,
        userDefaultPartner,
        useAlternatingRowColors,
        
        getCurrentUser,
        updateCurrentUser, 
        updateUserProfile 
    }
})