<template>
    <div :id="`${id}-group`" :class="`field ${size} form-group`">
        <base-overridable :label="label" :jsonPathLabel="jsonPathLabel" :value="vm" :id="id" :auditChecks="auditChecks" :validationRules="validationRules" :helpText="helpText"
                :validationFieldName="label" :immediateValidation="immediateValidation" :errorMessage="errorMessage" @undoOverride="undoOverride">
            <template v-slot:input="{ errors }">
                <input-select
                    v-if="referenceType"
                    :id="`${id}`"
                    v-model="vm.value"
                    :referenceType="referenceType"
                    :disabled="!vm.isOverridden"
                    :referenceSource="referenceSource"
                    :showEmptyOption="showEmptyOption"
                    :class="getErrorMessage(errors[0]) ? 'error' : ''"
                    :jsonPath="jsonPath"
                    :multiple="multiple"
                    @change="change"
                />
                <input-select
                    v-else
                    :id="`${id}`"
                    v-model="vm.value"
                    :disabled="!vm.isOverridden"
                    :source="source"
                    :showEmptyOption="showEmptyOption"
                    :class="getErrorMessage(errors[0]) ? 'error' : ''"
                    :jsonPath="jsonPath"
                    :multiple="multiple"
                    @change="change"
                />
            </template>
        </base-overridable>
    </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, PropType, toRef } from 'vue'
import { MultiSelectItem } from './multi-select-item'
import { Overridable, ReferenceSource } from '../models'
import BaseOverridable from './base-overridable.vue'
import { useAuditChecks, useValidation, validationProps } from '@/common/composables/audit-check-composable'

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'change', event: any): void
    (e: 'undoOverride', event: any): void
}>()

const props = defineProps({
    id: { type: String, required: true },
    value: { type: Object as PropType<Overridable<string>>, required: true },
    size: { type: String as PropType<string|null> },
    label: { type: String },
    helpText: { type: String },
    referenceType: { type: String, default: '' },
    referenceSource: { type: String as PropType<ReferenceSource>, default: ReferenceSource.loan },
    source: { type: [Array, Function] as PropType<MultiSelectItem[] | ((searchTerm?: string) => Promise<MultiSelectItem[]>)>, default: null },
    showEmptyOption: { type: Boolean, default: true },
    multiple: { type: Boolean },
    jsonPath: { type: String, default: '' },
    ...validationProps
})

const { auditChecks, jsonPathLabel } = useAuditChecks(props)
const { getErrorMessage } = useValidation(props)

const vm = toRef(props, 'value')
//#endregion

function undoOverride(args: any) {
    emit('undoOverride', args)
}

function change(args: any){
    emit('change', args)
}
</script>