export const ClickOutside = {
    bind: function (el: any, binding, vnode: any) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                if(vnode.context[binding.expression])
                    vnode.context[binding.expression](event); //vue 2 style
                else
                    binding.value(event, el) //vue 3 style
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el: any) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
  };
