import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { InterfaceDetail, LoanLock, LosIntegrationSetting, Partner, Products, RegCheckSuiteOptions } from '@/common/models'
import { adminDataService } from '@/common/services'
import { cloneDeep } from 'lodash'
import { usePageActionsStore } from './page-actions'

export const usePartnerStore = defineStore('partners', () => {
    const pageActions = usePageActionsStore()
    const partner = ref<Partner | null>(null)

    const initializedPartner = computed(() => {
        const initializedPartner = cloneDeep(partner.value || {} as Partner)
        initializedPartner.loanLock ??= {} as LoanLock
        initializedPartner.products ??= {} as Products
        initializedPartner.interfaceDetail ??= {} as InterfaceDetail
        initializedPartner.interfaceDetail.losIntegrationSettings ??= {} as LosIntegrationSetting
        initializedPartner.interfaceDetail.integrationLinks ??= []
        initializedPartner.regCheckSuiteOptions ??= { enableOfacExtendedSearch: false, enabledTests: [] } as RegCheckSuiteOptions
        initializedPartner.regCheckSuiteOptions.enabledTests ??= []
        initializedPartner.preparedBy ??= []
        initializedPartner.docPrepAttorneys ??= []
        initializedPartner.trustees ??= []
        return initializedPartner
    })

    const partnerClosingConditions = computed(() => {
        const conditionsToTransform = initializedPartner.value.closingConditions as Map<string, string[]> || ({} as Map<string, string[]>)
        return Object
            .entries(conditionsToTransform)
            .map(([key, value]) => [key, value]) as Array<[string, string[]]>
    }) 

    async function getPartner(partnerCode: string) {
        try {
            pageActions.setBusyPage(true)
            partner.value = await adminDataService.getPartner(partnerCode)
        } finally {
            pageActions.setBusyPage(false)
        }
    }

    async function updatePartnerData(updatedPartner: Partner) {
        try {
            await pageActions.updateSaveLoading(true)
            let partnerRecord = cloneDeep(partner.value || {}) as Partner
            partnerRecord = Object.assign({}, partnerRecord, cloneDeep(updatedPartner))
            partner.value = await adminDataService.postPartner(partnerRecord)
        } finally {
            await pageActions.updateSaveLoading(false)
        }
    }

    return { partner, initializedPartner, partnerClosingConditions, getPartner, updatePartnerData }
})