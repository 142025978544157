 <template>
    <div class="regcheck-summary">
        <div v-if="showHeader" class="regcheck-header">
            <span class="logo" />
            <custom-button v-if="complianceErrors.length == 0" icon="Preview" label="View PDF" @click="$emit('preview')" />
        </div>
        <div v-for="suite in complianceResults" :key="suite.displayName">
            <blurb small v-if="showSuite(suite)" :type="getVariant(suite.result)">
                <template v-slot:shortMessage>{{ suite.displayName }}: {{ getTestResultDisplayName(suite.result) }}</template>
                <template v-slot:longMessage>
                    <div v-if="showTestResults(suite)">
                        <div v-for="test in suite.testResults" :key="test.displayName">
                            <ul v-if="showTest(test)">
                                <li :class="test.result">
                                    <span>
                                        {{ test.displayName }}:
                                        {{ getTestResultDisplayName(test.result) }}
                                        <ul v-if="showResultText(test)" class="result-details">
                                            <li v-if="test.resultText">{{ test.resultText }}</li>
                                            <template v-if="test.subTestErrors">
                                                <li v-for="(error, index) in test.subTestErrors" :key="index">{{ error }}</li>
                                            </template>
                                        </ul>
                                    </span>
                                </li>
                            </ul>
                    </div>
                    </div>
                    <ul v-if="hasErrors(suite)" class="result-details">
                        <li v-for="(error, index) in suite.errorMessages" :key="index" class="missing-data">{{ error }}</li>
                    </ul>
                </template>
            </blurb>
        </div>
        <blurb small v-if="complianceErrors && complianceErrors.length > 0" type="Error">
            <template v-slot:shortMessage>One or more errors occurred while running RegCheck:</template>
            <template v-slot:longMessage>
                <ul>
                    <li v-for="(error, index) in complianceErrors" :key="index" class="Error">
                        {{ error }}
                    </li>
                </ul>
            </template>
        </blurb>
    </div>
</template>
<script setup lang="ts">
import { ComplianceSuiteTestResult, ComplianceTestResult, TestResult } from '@/common/models'
import { defineProps, PropType } from "vue"

//#region DEFINE VARIABLES
defineProps({
    showHeader: {type: Boolean, default: true },
    complianceResults: {
        type: Array as PropType<Array<ComplianceTestResult>>,
        default() {
            return [] as ComplianceTestResult[]
        }
    },
    complianceErrors: {
        type: Array as PropType<Array<string>>,
        default() {
            return [] as string[]
        }
    }
})
//#endregion

function getTestResultDisplayName(testResult: TestResult) {
    switch (testResult) {
        case TestResult.Pass:
            return 'Pass'
        case TestResult.Fail:
            return 'Fail'
        case TestResult.Warning:
            return 'Warning'
        case TestResult.NotApplicable:
            return 'N/A'
        case TestResult.DataNeeded:
            return 'Missing Data'
    }
}

function getVariant(testResult: string) {
    switch (testResult) {
        case TestResult.Pass:
            return 'Success'
        case TestResult.Warning:
            return 'Warning'
        case TestResult.Fail:
        case TestResult.DataNeeded:
            return 'Error'
    }
}

function showSuite(suite: ComplianceTestResult) {
    //For now, we don't want to diplay N/A suites as they likely don't apply to this loan at all
    return suite.result != TestResult.NotApplicable
}

function showTestResults(suite: ComplianceTestResult) {
    return suite.result != TestResult.Pass && suite.result != TestResult.NotApplicable && suite.result != TestResult.DataNeeded
}

function showTest(test: ComplianceSuiteTestResult) {
    //Only display the N/A test if it will display a reason why it is N/A
    return test.result != TestResult.NotApplicable || test.resultText || test.subTestErrors?.length > 0
}

function showResultText(test: ComplianceSuiteTestResult) {
    return (
        (test.resultText || test.subTestErrors?.length > 0) &&
        (test.result == TestResult.Warning || test.result == TestResult.Fail || test.result == TestResult.NotApplicable)
    )
}

function hasErrors(suite: ComplianceTestResult) {
    return suite.errorMessages && suite.errorMessages.length > 0
}

</script>
<style lang="scss" scoped>
.regcheck-summary {
    padding-bottom: 1rem;
    padding-left: .25rem;// allows a little room for warning icon to budge to the left

    .regcheck-header {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        margin-bottom: 0.5rem;

        .logo {
            display: inline-block;
            background-image: url('~@../../../public/img/RegCheck.svg');
            background-repeat: no-repeat;
            background-size: 10rem;
            width: 10rem;
            height: 2.5rem;
            margin-left: -0.625rem;
        }

        button {
            margin-top: -0.125rem;
        }
    }

    .preview-container {
        margin-bottom: 1rem;
    }

    .blurb {
        margin-bottom: 0.5rem;

        :deep(.bi-exclamation-triangle-fill) {
            margin-left:-1px; // center-aligns with other icons
            margin-top: 1px;
        }
    }

    ul {
        padding-inline-start: 1.75rem;

        li {
            &::marker {
                font-size: 2rem;
                line-height: 21px;
            }
            &.Pass {
                color: $green;
            }

            &.Warning {
                color: $yellow-warning;
            }

            &.Fail,
            &.DataNeeded,
            &.Error {
                color: $red;
            }

            &.NotApplicable {
                color: $dark-gray;
                span {
                    color: $dark-gray;
                }
            }

            span,
            span li {
                color: $near-black;
            }

            span {
                position: relative;
                top: -5px;
                left: -6px;
            }
        }

        &.result-details {
            padding-inline-start: 0;
            margin: 0.25rem 0 0 0;
            font-size: 95%;
            font-style: italic;
            list-style-type: none;

            li:not(:first-child) {
                padding-top: 0.4rem;
            }
        }
    }

    .missing-data {
        font-style: italic;
        font-size: 95%;
        margin: 2px 0;
    }
}
</style>