import { axiosMcdService } from './index'

class McdService {

    async getMcdXml(id: string): Promise<Blob> {
        const response = await axiosMcdService.get(`${id}/xml`, {
            responseType: 'blob',
        })
        return response.data
    }
}

export const mcdService = new McdService()