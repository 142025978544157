import { AdminInvestor, Contact, InvestorAddress, InvestorContact } from "@/common/models"
import { cloneDeep } from "lodash"

export interface AdminInvestorViewModel extends AdminInvestor {
    systemContacts: Contact[]
    clientContacts: Contact[]
}

class AdminInvestorViewModelConverter {
    convertToViewModel(model: AdminInvestor): AdminInvestorViewModel {
        const investor = cloneDeep(model) as AdminInvestorViewModel
        
        investor.primaryAddress = investor.primaryAddress ?? ({} as InvestorAddress)
        investor.contacts = investor.contacts ?? ([] as Contact[])
        investor.contactIds = investor.contactIds ?? ([] as string[])
        investor.defaultContacts = investor.defaultContacts ?? ([] as InvestorContact[])

        // Seperate the system and client contacts
        investor.systemContacts = investor.contacts.filter(c => c.clientCode == null)
        investor.clientContacts = investor.contacts.filter(c => c.clientCode != null)

        return investor
    }

    convertToModel(viewModel: AdminInvestorViewModel): AdminInvestor {
        function groupAllContacts(systemContacts: Contact[], clientContacts: Contact[]): Contact[] {
            return systemContacts.concat(clientContacts);
        }

        return Object({
            ...viewModel,
            contacts: groupAllContacts(viewModel.systemContacts, viewModel.clientContacts)
        })
    }
}

export const adminInvestorViewModelConverter = new AdminInvestorViewModelConverter()