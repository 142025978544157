<template>
    <li :class="item.name ? '' : 'no-category'" v-if="visible">
        <a href="#" @click.prevent="toggleExpanded" v-if="!item.routeName && item.name">
            <custom-icon :icon="isExpanded ? 'TreeItemOpen' : 'TreeItemClosed'" v-if="item.options.length > 0" />
            {{ item.name }}
            <slot />
        </a>
        <template v-else>
            <router-link
                class="d-flex align-items-center"
                :class="activeRoute ? 'router-link-exact-active' : ''"
                v-if="item.routeName"
                :to="{ name: item.routeName, params: item.params }"
            >
                {{ item.name }}
                <custom-icon v-show="displayAuditCheckErrors && auditCheckResults.length" :icon="`${auditCheckLevel}Circle`" />
            </router-link>
        </template>
        <tree-menu class="child-item" v-if="item.options.length > 0 && isExpanded" :items="item.options"></tree-menu>
    </li>
</template>

<script setup lang="ts">
import { LoanAuditChecks } from "@/common/models"
import { AuditCheckResult } from "@/propel/models"
import { computed, defineProps, PropType, ref, watch } from "vue"
import { TreeMenuDataItem } from "./tree-menu-data-item"
import { cloneDeep } from "lodash"
import { Route } from "vue-router"
import { useRoute } from "vue-router/composables"
import { useDisplayAuditCheckErrors } from '@/common/composables/audit-check-composable'
import TreeMenu from "@/common/components/navigation/tree-menu/tree-menu.vue"
import { useAuditCheckStore, useSideNavStore } from "@/common/store"

//#region DEFINE VARIABLES
const store = useAuditCheckStore()
const props = defineProps({
    item: { type: Object as PropType<TreeMenuDataItem>, required: true },
})

const route = useRoute()

const { displayAuditCheckErrors } = useDisplayAuditCheckErrors()
const sideNavStore = useSideNavStore()

const isExpanded = ref(true)
const activeRoute = ref(false)
const auditCheckLevel = ref("")
const auditCheckResults = ref([] as AuditCheckResult[])
//#endregion

//#region WATCH
watch(() => route, (value: Route) => setSelectedItemForCurrentRoute(value), { immediate: true, deep: true })
watch(() => store.loanAuditChecks,(value: LoanAuditChecks) => setAuditChecks(value), { immediate: true })
//#endregion

//#region COMPUTED
const visible = computed<boolean>(() => props.item?.visible ?? true)
//#endregion

//#region INITIALIZE
initialize()

function initialize(){
    isExpanded.value = props.item?.isExpanded ?? true
}
// #endregion


function toggleExpanded() {
    isExpanded.value = !isExpanded.value
}

function setSelectedItemForCurrentRoute(route: Route) {
    const routeName = route.name 
    const routeNameMatches = (props.item?.dependentRoute === routeName || props.item?.routeName === routeName)
    
    activeRoute.value = routeNameMatches && paramsMatch(props.item?.params, route)

    if (activeRoute.value)
        sideNavStore.selectedSideNavMenuItem = props.item
}

function setAuditChecks(value: LoanAuditChecks) {
    if (!props.item?.auditCheckKeys) return

    auditCheckResults.value.splice(0)

    for (const wildCardKey of props.item.auditCheckKeys) {
        const matchingKeys = Object.keys(value).filter((x) => x.startsWith(wildCardKey))
        if (matchingKeys.length > 0) {
            for (const key of matchingKeys) {
                if (value[key]) {
                    const storeAuditChecksForKey = cloneDeep(value[key])
                    auditCheckResults.value.push(...storeAuditChecksForKey)
                }
            }
        }
    }

    if (auditCheckResults.value.length)
        auditCheckLevel.value = auditCheckResults.value.some((x) => x.level === "Error") ? "Error" : "Warning"
}

function paramsMatch(params: any, route: Route): boolean {
    let paramsMatch = true // defaulting to always true so only routes that need param matching get affected

    // matching on index to facilitate building menu items with indexes
    if (params && params.index !== undefined) 
        paramsMatch = params.index == route.params.index

    return paramsMatch
}
</script>

<style lang="scss" scoped>
ul {
    li {
        a {
            display: flex;
            align-items: center;
            gap: 0.25rem;

            text-decoration: none;
            text-transform: uppercase;
            color: $bluish-off-white;
            font-weight: bold;
            padding: 0.1875rem 1rem;
            letter-spacing: 0.5px;

            &:hover {
                background: lighten($dark-blue, 10%);
                color: $bluish-off-white;
            }
        }

        ul li a {
            color: $bluish-off-white;
            font-weight: normal;
            text-transform: none;
            padding-left: 2rem;
        }
        &.no-category {
            ul li a {
                padding-left: 0.9375rem;
            }
        }

        .custom-icon {
            font-size: 0.5rem;
            line-height: 1.125rem; // to match li text

            &.tree-item-closed,
            &.tree-item-open {
                height: 0.75rem;
                color: $off-white;
                margin-left: -0.25rem;
            }

            &.error-circle {
                color: #ff4949;
                align-items: flex-start;
            }

            &.warning-circle {
                color: #ffd634;
                align-items: flex-start;
            }
        }
    }

    .router-link-exact-active {
        background: darken($layout-nav-bg, 10%);
        color: lighten($orange, 15%);
        font-weight: bold;
    }
}
</style>