import { Borrower } from '@/common/models'
import { Route } from "vue-router"
import { useClientStore, useLoanStore, usePartnerStore } from '@/common/store'

class BrowserTabService {

    generateTabTitle (to: Route, from: Route) {
        const basePath = to.matched[0].path

        if (basePath === "/setup") {
            if (to?.meta?.title && !to?.meta?.itemInfo) {
                document.title = to?.meta?.title
            }      
        }

        if (basePath === "/admin") {
            if (to?.meta?.title && !to?.meta?.itemInfo) {
                document.title = `Admin: ${to?.meta?.title}`; 
            } else if(to?.meta?.title && to?.meta?.itemInfo && from.fullPath !== "/admin") {
                const view = this.adminItemNameSelector(to?.meta?.getter)
                document.title = this.createAdminItemEditPageTab(to?.meta?.title, view)
            }
        }

        if (basePath === "/system") {
            if (to?.meta?.title && !to?.meta?.itemInfo) {
                document.title = `System: ${to?.meta?.title}`; 
            } 
        }

        if (basePath === "/loans" || basePath === "/loans/:id") {
            if (to?.meta?.title && !to?.meta?.itemInfo) {
                document.title = to?.meta?.title;
            } 
            else if(to?.meta?.title && to?.meta?.itemInfo && from.fullPath !== "/loans") {
                const store = useLoanStore()
                document.title = this.createLoanItemTabTitle(to?.meta?.title, store.borrowers, store.loanNumber)
            }
        }

        if (basePath === "/delivery-grid" || basePath === "/order-grid") {
            if (to?.meta?.title && !to?.meta?.itemInfo) {
                document.title = to?.meta?.title;
            } 
        }

        if (basePath === "/unauthorized") {
            document.title = "Unauthorized"
        }
    }

    adminItemNameSelector(getter: string) {
        if (getter === "client"){
            const clientStore = useClientStore()
            return clientStore.initializedClient.name
        } 
        else if (getter === "partner") {
            const partnerStore = usePartnerStore()
            return partnerStore.initializedPartner.name
        }    
    }

    createLoanItemTabTitle (pageTitle: string, borrowers: Borrower[], loanNumber: string) {
        const primaryBorrower = borrowers[0];
        let tabText = pageTitle;

        if (loanNumber) {
            tabText = `(${loanNumber}): ` + tabText;
        }

        if (primaryBorrower) {
            tabText = primaryBorrower.lastName + " " + tabText;
        }

        return tabText;
    }

    createFormsItemTabTitle (sectionTitle: string, itemName: string){
        let tabText = '';

        if (sectionTitle) {
            tabText = sectionTitle;
        }
        
        if (itemName) {
            tabText = tabText + ": " + itemName;
        }

        return tabText;
    }

    createAdminItemEditPageTab (pageTitle: string, itemName: string | undefined) {
        let tabText = 'Admin: ';

        if (itemName) {
            tabText = tabText + itemName;
        }
        
        if (pageTitle) {
            tabText = tabText + " - " + pageTitle;
        }

        return tabText;
    }
}

export const browserTabService = new BrowserTabService()