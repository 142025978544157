<template>
    <div>
        <content-block-list
            id="auditChecks"
            ref="auditCheckList"
            :listItems.sync="auditChecks"
            deletable
            copyable
            addLabelText="Add Audit Check"
            :onAdd="addNewAuditCheck"
            :addItemDataSource="addItemOptions()"
            :onCopy="(x) => (x.id = null)"
            @editListItem="showEditModal"
            @itemDeleted="deleteAuditCheck"
        >
            <template v-slot:secondaryActions="slotParams">
                <custom-icon :icon="`${slotParams.item.level}Circle`" />
            </template>
            <template v-slot:view="slotParams">
                <div class="standard-columns">
                    <div class="l wrap">
                        {{ slotParams.item.message }}
                        <div class="subtitle">{{ slotParams.item.description }}</div>
                    </div>
                    <rule-condition-content-block-column :conditions="slotParams.item.conditions" />
                </div>
            </template>
            <template v-slot:deleteModal> Are you sure you want to delete this audit check? </template>
        </content-block-list>

        <b-modal
            id="edit-audit-modal"
            title="Edit Audit Check"
            size="xl"
            @ok="saveAuditCheck()"
            @cancel="auditCheckList.cancelEditMode(selectedRule)"
            @close="auditCheckList.cancelEditMode(selectedRule)"
            ok-title="Save"
            cancel-title="Cancel"
            :ok-disabled="rulesAreOpen"
            modal-class="testable"
            v-model="showAuditModal"
        >
            <div class="testable-area-wrap">
                <b-tabs class="tabs-fixed">
                    <b-tab :active="editingExistingItem()">
                        <template v-slot:title>Configuration</template>
                        <h1>Message</h1>
                        <div class="field-section">
                            <fg-select
                                id="auditLevel"
                                class="audit-check-form-group"
                                :source="getAuditLevel"
                                :searchable="false"
                                :multiple="false"
                                :showEmptyOption="false"
                                v-model="selectedRule.level"
                                placeholder="Level"
                                :disabled="readOnly"
                            />
                            <fg-textarea
                                id="message"
                                :rows="5"
                                class="audit-check-form-group"
                                v-model="selectedRule.message"
                                :disabled="readOnly"
                                :placeholder="`Enter the ${selectedRule?.level?.toLowerCase()} message`"
                            />
                        </div>
                        <rule-criteria-editor
                            :key="selectedRule.id"
                            :conditions="selectedRule.conditions"
                            :field="field"
                            :defaultRule="selectedRule"
                            :saveNewRules="true"
                            @openItemsChanged="openItemsChanged"
                        />
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>Details</template>
                        <div class="field-section">
                            <fg-textarea id="description" label="Description" :rows="6" v-model="selectedRule.description" :disabled="readOnly" />
                            <fg-textarea id="notes" :rows="6" label="Notes" v-model="selectedRule.notes" :disabled="readOnly" />
                        </div>
                    </b-tab>
                </b-tabs>
                <aside>
                    <summary-sidebar>
                        <h2>Audit Check Testing</h2>
                        <test-container :conditions="selectedRule.conditions" conditionsType="AuditCheck" :field="field" />
                        <template v-slot:footer v-if="selectedRule && selectedRule.audit && selectedRule.audit.created">
                            <summary-sidebar-audit-info :audit="selectedRule.audit" />
                        </template>
                    </summary-sidebar>
                </aside>
            </div>
        </b-modal>
    </div>
</template>

<script setup lang="ts">
import { defineProps, ref, PropType, toRef } from 'vue'
import { messageService } from '@/common/services'
import { metadataService } from '@/propel/services'
import { AuditCheck, Criteria, FieldDetail, ItemsChangedEventArgs } from '@/common/models'
import { MultiSelectItem } from '@/common/components/multi-select-item'

//#region DEFINE VARIABLES
const props = defineProps({
    auditChecks: { type: Array as PropType<AuditCheck[]>, required: true },
    field: { type: Object as PropType<FieldDetail>, required: true },
    fieldText: { type: String, required: true },
    readOnly: { type: Boolean }
})

const selectedRule = ref<AuditCheck>({} as AuditCheck)
const rulesAreOpen = ref(false)
const showAuditModal = ref(false)
const auditCheckList = ref()
const auditChecks = toRef(props, 'auditChecks')
//#endregion

async function saveAuditCheck() {
    const auditCheckResponse = await metadataService.postFieldAuditCheck(props.field.id, selectedRule.value)
    selectedRule.value.id = selectedRule.value.id ?? auditCheckResponse.id
    messageService.showSaveSuccess(selectedRule.value.description)
}

async function deleteAuditCheck(item: AuditCheck) {
    if (item?.id) {
        await metadataService.deleteAuditCheck(props.field.id, item.id)
    }
}

function showEditModal(item: AuditCheck) {
    selectedRule.value = item
    showAuditModal.value = true
}

function addNewAuditCheck(selectedValue: any) {
    const isRequiredAuditcheck = selectedValue === 'required'
    return {
        id: null,
        fieldId: props.field.id,
        fieldName: props.field.name,
        description: '',
        level: isRequiredAuditcheck ? 'Error' : 'Warning',
        message: isRequiredAuditcheck ? `${props.field.displayName} is required` : '',
        notes: '',
        conditions: {
            activationDate: null,
            expirationDate: null,
            packageTypes: [] as string[],
            ruleIds: [] as string[],
            criteriaItems: isRequiredAuditcheck
                ? [
                        {
                            fieldName: props.field.name,
                            operatorType: 'Empty',
                            compareValues: [] as string[],
                            compareExpression: '',
                        }
                    ]
                : ([] as Criteria[])
        }
    } as AuditCheck
}

function addItemOptions(): MultiSelectItem[] {
    return [
        { value: 'new', text: 'Start empty' }, 
        { value: 'required', text: 'Start with a required field check' } 
    ]
}

function getAuditLevel() {
    return [
        { value: 'Warning', text: 'Warning' },
        { value: 'Error', text: 'Error' }
    ]
}

function editingExistingItem(): boolean {
    return (selectedRule.value?.id ? selectedRule.value.id : 0) !== 0
}

function openItemsChanged(e: ItemsChangedEventArgs) {
    rulesAreOpen.value = e.openItemsCount > 0
}
</script>
