<template>
    <validation-observer v-slot="{ invalid }">
        <b-modal
            size="lg"
            id="user-profile-modal"
            title="My Profile"
            @ok="save()"
            @cancel="cancel()"
            @close="cancel()"
            :ok-disabled="invalid"
            ok-title="Save"
            :scrollable="false"
        >
            <div class="user-profile-settings">
                <b-tabs class="tabs-fixed">
                    <b-tab>
                        <template v-slot:title>Contact Info</template>
                        <div class="field-section">
                            <fg-label id="my-profile-email" label="Email" size="">{{ currentUser.emailAddress }}</fg-label>
                            <fg-text id="my-profile-name" label="Name" size="m" v-model="currentUser.name" v-if="hasUserId" />

                            <div class="field-group" v-if="hasUserId && currentUser.profile">
                                <div class="form-group field ms">
                                    <label for="my-profile-phone">Phone</label>
                                    <div class="input-group">
                                        <input-pattern-mask pattern="phone" id="my-profile-phone" v-model="currentUser.profile.phone" />
                                        <input-text class="extension" placeholder="ext" id="my-profile-phoneExt" v-model="currentUser.profile.phoneExt" />
                                    </div>
                                </div>
                                <fg-input-pattern-mask pattern="phone" id="my-profile-fax" label="Fax" size="s" v-model="currentUser.profile.fax" />
                            </div>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>Preferences</template>
                        <h1 class="top-header">Grids</h1>
                        <div class="field-section">
                            <fg-checkbox
                                id="user-pref-alternate-grid"
                                v-model="currentUser.profile.preferences.grids.alternateRowColors"
                                label="Use alternating row colors"
                            />
                            <fg-radio-button
                                id="grid-click-anywhere"
                                :options="[
                                    { value: 'Anywhere', text: 'Click anywhere on a grid item to open it' },
                                    { value: 'Link', text: 'Display a specific link on each row to open the item' }
                                ]"
                                v-model="currentUser.profile.preferences.grids.rowSelectionType"
                                name="grid-click-anywhere"
                                label="Grid selection behavior:"
                            />
                        </div>

                        <h1>Loan Data</h1>
                        <div class="field-section">
                            <fg-select
                                v-if="displayPartnerDropdown"
                                id="user-pref-default-partner"
                                size="l"
                                v-model="currentUser.profile.preferences.loan.defaultPartner"
                                label="Default Partner"
                                :source="partners"
                                :stickyItems="partnerStickyItems"
                                :showEmptyOption="false"
                                :showDescriptionAfterSelect="true"
                                @change="updateLenderOptions"
                                optionDisplayType="SplitWithValue"
                            />
                            <fg-select
                                id="user-pref-default-lender"
                                size="l"
                                v-model="currentUser.profile.preferences.loan.defaultLender"
                                label="Default Lender"
                                :source="lenders"
                                :stickyItems="lenderStickyItems"
                                :showEmptyOption="false"
                                :showDescriptionAfterSelect="true"
                                optionDisplayType="SplitWithValue"
                                useLocalSearch
                            />

                            <fg-checkbox
                                id="user-pref-loan-autosave"
                                v-model="currentUser.profile.preferences.loan.autoSave"
                                label="Use auto-save by default when editing loans"
                            />
                        </div>

                        <h1>Default Landing Page</h1>
                        <div class="field-section">
                            <fg-select
                                id="user-pref-default-landing-page"
                                size="l"
                                v-model="currentUser.profile.preferences.defaultLandingPage"
                                label="Default Landing Page"
                                :source="landingPageOptions"
                                :showEmptyOption="false"
                                :showDescriptionAfterSelect="true"
                            />
                        </div>

                        <h1>Date/Time Format</h1>
                        <div class="field-section">
                            <div class="field-group">
                                <fg-select
                                    id="dateFormat"
                                    label="Dates"
                                    :source="dateFormatValues"
                                    v-model="currentUser.profile.preferences.dateFormat"
                                    :showEmptyOption="false"
                                />
                                <fg-text
                                    id="customDateFormat"
                                    label="Date Format"
                                    size="m"
                                    v-if="useCustomDateFormat"
                                    v-model="customDateFormat"
                                    validationRules="required"
                                    immediateValidation
                                />
                            </div>
                            <div class="field-group">
                                <fg-select
                                    id="timeFormat"
                                    label="Times"
                                    :source="timeFormatValues"
                                    v-model="currentUser.profile.preferences.timeFormat"
                                    :showEmptyOption="false"
                                />
                                <fg-text
                                    id="customTimeFormat"
                                    label="Time Format"
                                    size="m"
                                    v-if="useCustomTimeFormat"
                                    v-model="customTimeFormat"
                                    validationRules="required"
                                    immediateValidation
                                />
                            </div>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>Password</template>
                        <p>
                            <blurb>
                                <template v-slot:longMessage>
                                    To change your password, click the button below and Propel will send you an email with a link to a page where you can enter a
                                    new one.</template
                                >
                            </blurb>
                        </p>
                        <custom-button @click="resetPassword" :disabled="!hasUserId" label="Change Password" />
                    </b-tab>
                </b-tabs>
            </div>
        </b-modal>
    </validation-observer>
</template>

<script setup lang="ts">
import { dateTimeFilter } from '@/common/filters'
import { User, LastSelectionType, DefaultLandingPages, UserPermissionSet } from '@/common/models'
import { adminDataService, messageService, userPreferenceService, userService } from '@/common/services'
import { cloneDeep } from 'lodash'
import { MultiSelectItem } from './multi-select-item'
import { ref, computed } from 'vue'
import { config } from '@/config'
import { useCurrentUserStore } from '@/common/store'
//#region DEFINE VARIABLES
const currentUser = ref({} as User)
const dateFormats: string[] = [
    'MMM D, YYYY',
    'MMM DD, YYYY',
    'MM/DD/YYYY',
    'M/D/YY',
    'MMM/DD/YYYY',
    'DD/MMM/YYYY', 
    'Custom'
]
const timeFormats: string[] = [
    'h:mm a',
    'h:mm A',
    'H:mm',
    'Custom'
]
const dateFormatValues = ref<MultiSelectItem[]>([])
const timeFormatValues = ref<MultiSelectItem[]>([])
const customDateFormat = ref('')
const customTimeFormat = ref('')
const lenders = ref<MultiSelectItem[]>([])
const partners = ref<MultiSelectItem[]>([])
const landingPageOptions = ref<MultiSelectItem[]>([])
const lenderStickyItems = [
    { text: 'No Default', value: '' },
    { text: 'Last Selected Lender', value: LastSelectionType.Lender }
]
const partnerStickyItems = [
    { text: 'No Default', value: '' },
    { text: 'Last Selected Partner', value: LastSelectionType.Partner }
]
const regcheckPageOptions = [DefaultLandingPages.Loans, DefaultLandingPages.LenderSettings, DefaultLandingPages.SystemSettings]
const displayPartnerDropdown = ref(false)
const store = useCurrentUserStore()
//#endregion

//#region COMPUTED
const hasUserId = computed(() => currentUser.value?.id)
const useCustomDateFormat = computed(() => currentUser.value?.profile?.preferences?.dateFormat === 'Custom')
const useCustomTimeFormat = computed(() => currentUser.value?.profile?.preferences?.timeFormat === 'Custom')
//#endregion

//#region INITIALIZE
initializeModel()

async function initializeModel() {
    //Creating a clone of the store getter here,
    //since even updating the getter seems to be updating the getters in other components where it's used
    currentUser.value = cloneDeep(store.currentUser as User)
    currentUser.value.profile.preferences.loan.defaultPartner = currentUser.value?.profile?.preferences?.loan?.defaultPartner || ''
    currentUser.value.profile.preferences.loan.defaultLender = currentUser.value?.profile?.preferences?.loan?.defaultLender || ''
    currentUser.value.profile.preferences.loan.autoSave = userPreferenceService.getAutoSavePreference() //ensure default value if the user pref is null
    currentUser.value.profile.preferences.dateFormat = currentUser.value?.profile?.preferences?.dateFormat || 'MMM D, YYYY'
    currentUser.value.profile.preferences.timeFormat = currentUser.value?.profile?.preferences?.timeFormat || 'h:mm a'

    //getting the landing pages before instantiating the default landing page to select the first valid landing page option (typically loans grid)
    await getlandingPageOptions()
    currentUser.value.profile.preferences.defaultLandingPage = currentUser.value?.profile?.preferences?.defaultLandingPage || (landingPageOptions.value[0]?.value ?? DefaultLandingPages.Loans)

    refreshDateTimeFormats()
    getPartnerOptions()
    getLenderOptions()
}
//#endregion

async function resetPassword() {
    messageService.showLoading('Sending password reset email...')
    await userService.resetPassword(currentUser.value.emailAddress)
    messageService.showSuccess('A password reset link has been sent to your email address.', 'Reset Link Sent')
}

async function getlandingPageOptions(){
    const flattenedUserPermissions = await userService.getFlattenedUserPermissions(hasUserId.value)
    const userHasSystemAdminAccess = flattenedUserPermissions[0]?.permissions?.some(p => p === 'system-admin')
    const orderOnlyAccess = permissionExistsInFlattenedPermissions(['orders:read', 'orders:write'], flattenedUserPermissions)
    const userHasFormsAccess = permissionExistsInFlattenedPermissions(['metadata:read', 'metadata:write', 'forms:read', 'forms:write'], flattenedUserPermissions)
    const userHasFieldsAccess = permissionExistsInFlattenedPermissions(['metadata:read', 'metadata:write', 'fields:read', 'fields:write'], flattenedUserPermissions)
    const userHasLenderSettingsAccess = userCanAccessLenderSettings(flattenedUserPermissions)
    const userHasSystemSettingsAccess = permissionExistsInFlattenedPermissions(['system:read', 'system:write'], flattenedUserPermissions)
    //visible if user does not have “orders-only” access
    if (orderOnlyAccess){
        landingPageOptions.value.push({text: 'Orders', value: DefaultLandingPages.Orders})
    } else {
        landingPageOptions.value.push({text: 'Loans', value: DefaultLandingPages.Loans})
        landingPageOptions.value.push({text: 'Orders', value: DefaultLandingPages.Orders})

        if (userHasSystemAdminAccess || userHasFormsAccess) {
            landingPageOptions.value.push({text: 'Form Library', value: DefaultLandingPages.FormLibrary})
        }

        if (userHasSystemAdminAccess || userHasFieldsAccess) {
            landingPageOptions.value.push({text: 'Fields', value: DefaultLandingPages.Fields})
        }

        if (userHasSystemAdminAccess || userHasLenderSettingsAccess) {
            landingPageOptions.value.push({text: 'Lender Settings', value: DefaultLandingPages.LenderSettings})
        }

        if (userHasSystemAdminAccess || userHasSystemSettingsAccess) {
            landingPageOptions.value.push({text: 'System Settings', value: DefaultLandingPages.SystemSettings})
        }
    }

    if (config.app.regCheckOnly) {
        landingPageOptions.value = landingPageOptions.value.filter(i => regcheckPageOptions.includes(i.value))
    } 
}

function userCanAccessLenderSettings(flattenedUserPermissions: UserPermissionSet[]) : boolean {
    const lenderSettingsPermissions = ['admindata.clients:read', 'admindata.clients:write', 'admindata.clients.settings:read', 'admindata.clients.settings:write']
    return permissionExistsInFlattenedPermissions(lenderSettingsPermissions, flattenedUserPermissions)    
}

function permissionExistsInFlattenedPermissions(permissionList: string[], flattenedUserPermissions: UserPermissionSet[]) : boolean {
    return flattenedUserPermissions[0]?.permissions?.some( p => permissionList.includes(p)) ?? false
}

async function getPartnerOptions() {
    //todo: replace with partner lookup service
    const apiPartners = await adminDataService.getPartners()
    partners.value = apiPartners.map((p) => {
        return { text: p.name, value: p.code }
    })

    if (partners.value.length === 1) {
        currentUser.value.profile.preferences.loan.defaultPartner = partners.value[0].value
    } else {
        displayPartnerDropdown.value = true
    }
}

async function getLenderOptions() {
    const params = new URLSearchParams()
    if (
        currentUser.value.profile.preferences.loan.defaultPartner &&
        currentUser.value.profile.preferences.loan.defaultPartner !== LastSelectionType.Partner
    ) {
        params.append('partnerCode', currentUser.value.profile.preferences.loan.defaultPartner)
        lenders.value = await adminDataService.getClientsLookup(params)
    } else if (currentUser.value.profile.preferences.loan.defaultPartner === LastSelectionType.Partner) {
        lenders.value = [] as MultiSelectItem[]
    } else {
        lenders.value = await adminDataService.getClientsLookup(params)
    }
}

async function updateLenderOptions() {
    const currentLenderSelection = currentUser.value.profile.preferences.loan.defaultLender
    await getLenderOptions()
    const optionInLenders = lenders.value.find((e) => e.value === currentLenderSelection)
    // clears out the default lender if it isnt an option for the selected partner
    // might need to hash out what to do for last selected partner
    if (!optionInLenders) {
        currentUser.value.profile.preferences.loan.defaultLender = ''
    }
}

function refreshDateTimeFormats() {
    dateFormatValues.value = getDateFormats()
    timeFormatValues.value = getTimeFormats()
}

function getDateFormats(): MultiSelectItem[] {
    const date = new Date()
    const values: MultiSelectItem[] = []
    const userFormat = currentUser.value.profile.preferences.dateFormat
    if (userFormat && !dateFormats.includes(userFormat)) {
        values.push({ value: userFormat, text: dateTimeFilter(date, userFormat) })
    }

    dateFormats.forEach((format) => {
        const dateFormatValue = format !== 'Custom' ? dateTimeFilter(date, format) : 'Custom'
        values.push({ value: format, text: dateFormatValue })
    })
    return values
}

function getTimeFormats() {
    const date = new Date()
    const values: MultiSelectItem[] = []
    const userFormat = currentUser.value.profile.preferences.timeFormat
    if (userFormat && !timeFormats.includes(userFormat)) {
        values.push({ value: userFormat, text: dateTimeFilter(date, userFormat) })
    }

    timeFormats.forEach((format) => {
        const timeFormatValue = format !== 'Custom' ? dateTimeFilter(date, format) : 'Custom'
        values.push({ value: format, text: timeFormatValue })
    })
    return values
}

async function save() {
    const userDateFormat = currentUser.value.profile.preferences.dateFormat
    const userTimeFormat = currentUser.value.profile.preferences.timeFormat
    currentUser.value.profile.preferences.dateFormat = useCustomDateFormat.value ? customDateFormat.value : userDateFormat
    currentUser.value.profile.preferences.timeFormat = useCustomTimeFormat.value ? customTimeFormat.value : userTimeFormat
    await store.updateCurrentUser(currentUser.value)
    await initializeModel()
}

function cancel() {
    initializeModel()
}
</script>

<style lang="scss" scoped>
.user-profile-settings {
    :deep(.tab-content) {
        height: 370px;
        overflow: auto;
    }
}
</style>