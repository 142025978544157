import { AuditCheckResult } from ".";

export interface JsonPathTestResult extends AuditCheckResult {
    errorType: JsonPathErrorType | null
    routeLocation: string
}

export enum JsonPathErrorType {
    FormInputMissing = 'FormInputMissing',
    AuditCheckKeyMissing = 'AuditCheckKeyMissing'
}