<template>
    <div class="wrap l">
        <template v-if="conditions && (conditions.criteriaItems?.length > 0 || conditions.packageTypes?.length > 0 || conditions.ruleIds?.length > 0)">
            <div class="subtitle">When:</div>
            <div v-for="p in conditions.packageTypes" :key="p">Package = {{ p }}</div>
            <div v-for="rule in ruleDisplays(conditions.ruleIds)" :key="rule">
                {{ rule }}
            </div>
            <div v-for="criteria in criteriaDisplays(conditions.criteriaItems)" :key="criteria">
                {{ criteria }}
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { MultiSelectItem } from "@/common/components/multi-select-item";
import { Condition, Criteria, EmptyOperatorTypes, OperatorType, ReferenceSource, ReferenceType, Rule } from "@/common/models";
import { referenceService } from "@/common/services";
import { defineProps, PropType, ref } from "vue"
import { metadataService } from "../services";

//#region DEFINE VARIABLES
defineProps({
    conditions: { type: Object as PropType<Condition>, required: true },
})

const allRules = ref([] as Rule[])
const allOperators = ref([] as MultiSelectItem[])
//#endregion

//#region INITIALIZE
initialize()

async function initialize(){
    allOperators.value = await getOperators()
    allRules.value = await metadataService.getAllRules()
}
//#endregion

async function getOperators(): Promise<MultiSelectItem[]> {
    return await referenceService.getMultiSelectItems("criteria-operator-types" as ReferenceType, ReferenceSource.metadata)
}

function ruleDisplays(ruleIds: string[]): string[] {
    return ruleIds?.map((x) => getRuleDisplay(x) ?? "").filter((x) => x !== "") // some conditions have references to non-existent rules
}

function criteriaDisplays(criteria: Criteria[]): string[] {
    return criteria?.map((x) => `${x.fieldName} ${getOperatorValueLabel(x.operatorType)} ${getCriteriaItemValueLabel(x)}`)
}

function getCriteriaItemValueLabel(criteriaItem: Criteria) {
    return canAssignCriteriaValueCriteria(criteriaItem)
        ? criteriaItem.compareExpression
            ? getExpressionLabel(criteriaItem)
            : criteriaItem.compareValues?.join(", ")
        : ""
}

function canAssignCriteriaValueCriteria(criteria: Criteria) {
    return !EmptyOperatorTypes.includes(criteria.operatorType as OperatorType)
}

function getExpressionLabel(criteriaItem: Criteria) {
    if (criteriaItem.compareExpression.includes("\r\n") || criteriaItem.compareExpression.includes("<")) return "[ Expression ]"
    else return criteriaItem.compareExpression
}

function getRuleDisplay(id: string): string | undefined {
    return allRules.value.find((x) => x.id === id)?.description
}

// TODO: There's a display helper for this now
function getOperatorValueLabel(operatorValue) {
    return allOperators.value.find((x) => x.value === operatorValue)?.text
}
</script>
