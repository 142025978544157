<template>
    <!-- 
    Styles in _aside.scss are used to show/hide content so we can 
    force an unexpandable collapsed summary at smaller screen widths.
-->
    <div :class="['summary-sidebar', internalState.toLowerCase(), { 'can-toggle': $slots.collapsed }]">
        <div class="summary-header" v-if="$slots.header">
            <div class="header-content">
                <slot name="header" />
            </div>
            <custom-icon icon="chevron-up" class="toggle-icon collapse" @click="toggleState" />
        </div>
        <div class="summary-content expanded">
            <slot />
        </div>
        <div class="summary-content collapsed" v-if="$slots.collapsed">
            <slot name="collapsed" />
        </div>
        <custom-icon icon="chevron-down" class="toggle-icon expand" @click="toggleState" v-if="displayToggleIcon" />
        <div class="summary-footer" v-if="$slots.footer">
            <slot name="footer" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, ref, useSlots, onMounted, PropType } from "vue"
import { SidebarState } from "../models"
import { useLoanStore } from "@/common/store"

//#region DEFINE VARIABLES
const store = useLoanStore()
const emit = defineEmits<{
    (e:"sidebarStateChanged", value: SidebarState)
}>()

const props = defineProps({
    state: { type: String as PropType<SidebarState>, default: SidebarState.Expanded }
})

const slots = useSlots()

const internalState = ref(SidebarState.Expanded)
const displayToggleIcon = ref(true)
//#endregion

//#region INITIALIZE
onMounted(() => {
    if (store.isReleaseOfLien) {
        internalState.value = SidebarState.Collapsed
        displayToggleIcon.value = false
    }
    else
        internalState.value = slots.collapsed ? props.state : SidebarState.Expanded
})


//#endregion
async function toggleState() {
    if (internalState.value === SidebarState.Collapsed)
        internalState.value = SidebarState.Expanded
    else
        internalState.value = SidebarState.Collapsed

    emit("sidebarStateChanged", internalState.value)
}
</script>
