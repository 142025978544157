import { ClientDeliveryOption, FormPlacement } from '@/common/models'

export interface Package {
    packageType: string
    documents: PackageDocument[]
}

export interface PackageDocument {
    formId: string
    title: string
    placement: FormPlacement
    sortOrder: number
    afterFormIds: string[]
    isOptional: boolean
}

export interface PackageType {
    code: string
    name: string
    displayName: string
    defaultFeeMode: DocumentMode
    isDefault: boolean
    requireWatermark: boolean
    packageSpecificAuditChecksOnly: boolean
    isNew: boolean
    deliveryOptions: ClientDeliveryOption[]
}

export enum DocumentMode{
    Closing = "Closing",
    Initial = "Initial",
    ReleaseOfLien = "ReleaseOfLien",
    Modification = "Modification",
    RegCheck = "RegCheck",
}

export interface LoanAuditCheckResult {
    loanId: string
    isValid: boolean
    totalCount: number
    results: AuditCheckResult[]
}

export interface AuditCheckResult {
    fieldId: string
    fieldName: string
    fieldDisplayName: string
    jsonPath: string
    passed: boolean
    level: 'Warning' | 'Error' | 'Success'
    message: string
}

export enum AdminDataSystemTypes{
    ClientCode = "Client Code",
    Partner = "Partner",
    Program = "Program",
    State = "State",
    LenderProfile = "Lender Profile",
    InvestorCode = "Investor Code",
    PackageType = "Package Type"
}