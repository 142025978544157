import { MultiSignerNotary, Name, Overridable } from './index';

export interface Seller {
    ownerType: SellerPropertyOwnerType
    contractDate: Date
    sellers: SellerInfo[]
    notary: MultiSignerNotary
}

export interface SellerInfo {
    id: number
    type: SellerType
    individual: SellerIndividual | null
    corporation: SellerCorporation | null
}

export interface SellerIndividual extends Name {
    address: SellerAddress
    powerOfAttorneyName: string
    powerOfAttorneyTitle: Overridable<string>
    aka: string
    signature: string
    phone: string
}

export interface SellerCorporation {
    name: string
    type: SellerCorporationType
    address: SellerAddress
    signers: CorpSellerSigner[]
    phone: string
    stateOfIncorporation: string
}

export interface CorpSellerSigner {
    id?: number
    name: string
    title: string
}

export interface SellerAddress {
    street: string
    city: string
    state: string
    zip: string
}

export enum SellerType {
    Individual = 'Individual',
    Corporation = 'Corporation'
}

export enum SellerPropertyOwnerType {
    LenderReo = 'LenderReo',
    HudReo = 'HudReo',
    Seller = 'Seller'
}

export enum SellerCorporationType {
    Association = 'Association',
    Bank = 'Bank',
    Corporation = 'Corporation',
    GeneralPartnership = 'GeneralPartnership',
    JointVenture = 'JointVenture',
    LimitedPartnership = 'LimitedPartnership',
    Trust = 'Trust',
    LimitedLiabilityCompany = 'LimitedLiabilityCompany',
    Unknown = 'Unknown'
}
