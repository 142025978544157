import { AppArea, AreaPage, AppAreaName, SingleEntityDisplayType } from './app-area'
import { config } from '@/config'

const LOAN_DATA_AREA_PAGES: AreaPage[] = [
    {
        name: 'Loans',
        options: [],
        routeName: '/loans',
        dependentRoute: 'loan-info',
        readPermission: 'loans',
        visible: true
    }
]

if(!config.app.regCheckOnly) 
{
    LOAN_DATA_AREA_PAGES.push(
        {
            name: 'Orders',
            options: [],
            routeName: '/order-grid',
            dependentRoute: '',
            readPermission: ['loans', 'orders']
        }
    )
    LOAN_DATA_AREA_PAGES.push(
        {
            name: 'Delivery',
            options: [],
            routeName: '/delivery-grid',
            dependentRoute: '',
            readPermission: ['loans']
        }
    )
}

const ADMIN_AREA_PAGES: AreaPage[] = [
    {
        name: 'Partner Settings',
        options: [],
        routeName: '/admin/partners/:code',
        dependentRoute: 'partner-config', //not sure what this is used for
        readPermission: 'admindata.partners', //not sure if this is right
        singleEntityDisplay: true,
        singleEntityDisplayType: SingleEntityDisplayType.Partner
    },
    {
        name: 'Lender Settings',
        options: [],
        routeName: '/admin/lenders/:code',
        dependentRoute: 'account-setup',
        readPermission: 'admindata.clients',
        singleEntityDisplay: true,
        singleEntityDisplayType: SingleEntityDisplayType.Lender
    },
    {
        name: 'Partners',
        options: [],
        routeName: '/admin/partners',
        dependentRoute: 'partner-config',
        readPermission: 'admindata.partners',
        singleEntityDisplay: false,
        singleEntityDisplayType: SingleEntityDisplayType.Partner
    },
    {
        name: 'Lenders',
        options: [],
        routeName: '/admin/lenders',
        dependentRoute: 'account-setup',
        readPermission: 'admindata.clients',
        singleEntityDisplay: false,
        singleEntityDisplayType: SingleEntityDisplayType.Lender
    },
    {
        name: 'Users',
        options: [],
        routeName: '/admin/users',
        dependentRoute: 'user',
        readPermission: 'users'
    }
]

const FORM_SETUP_AREA_PAGES: AreaPage[] = [
    {
        //Rules section with Form Rules, Rule Library, and Sort Groups
        name: 'Forms',
        options: [],
        routeName: '/setup/forms',
        readPermission: 'metadata.forms'
    },
    {
        //Field Setup section with Fields, Field Formats, and Images, JSON Paths
        name: 'Fields',
        options: [],
        routeName: '/setup/fields',
        readPermission: 'metadata.forms'
    },
    {
        name: 'Programs',
        options: [],
        routeName: '/setup/programs',
        readPermission: 'metadata.programs'
    }
]

const SYSTEM_AREA_PAGES: AreaPage[] = [
    {
        // System settings like Packages, warehouse banks, and anything else with system/settings in its route
        name: 'System Settings',
        options: [],
        routeName: '/system/settings',
        readPermission: 'system.setup'
    },
    {
        //Field Setup section with Propel side menu Treasury (Weekly), SOFR, and Prime, regcheck with the rest
        name: 'Market Data',
        options: [],
        routeName: '/system/market-data',
        readPermission: 'system.marketdata.indices'
    },
    {
        // Security, Feature Flags, integrations with interface types, and interface clients
        name: 'IT Setup',
        options: [],
        routeName: '/system/it-setup',
        readPermission: 'system-admin'
    }
]

const MAIN_APP_AREAS: AppArea[] = [
    {
        name: AppAreaName.LoanData,
        icon: 'house-fill',
        areaPages: LOAN_DATA_AREA_PAGES
    },
    {
        name: AppAreaName.Admin,
        icon: 'gear-fill', 
        areaPages: ADMIN_AREA_PAGES
    },
    {
        name: AppAreaName.FormSetup,
        icon: 'file-earmark-word-fill', 
        areaPages: FORM_SETUP_AREA_PAGES
    },
    {
        name: AppAreaName.System,
        icon: 'shield-shaded', 
        areaPages: SYSTEM_AREA_PAGES
    }
]

export { MAIN_APP_AREAS, LOAN_DATA_AREA_PAGES, ADMIN_AREA_PAGES, FORM_SETUP_AREA_PAGES, SYSTEM_AREA_PAGES}