<template>
    <div :class="`field ${size} form-group`">
        <base-overridable :label="label" :jsonPathLabel="jsonPathLabel" :value="vm" :id="id" :auditChecks="auditChecks" :validationRules="validationRules" :helpText="helpText"
                :validationFieldName="label" :immediateValidation="immediateValidation" :errorMessage="errorMessage" @undoOverride="undoOverride">
            <template v-slot:input="{ errors }">
                <input-select 
                    :id="id"
                    :jsonPath="jsonPath" 
                    :referenceSource="source" 
                    :referenceType="type" 
                    :disabled="!vm.isOverridden" 
                    v-model="vm.value"
                    :showEmptyOption="showEmptyOption" 
                    :class="getErrorMessage(errors[0]) ? 'error' : ''" 
                />
            </template>
        </base-overridable>
    </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, PropType, toRef } from 'vue'
import { Overridable } from '../models';
import BaseOverridable from './base-overridable.vue'
import { useAuditChecks, useValidation, validationProps } from '@/common/composables/audit-check-composable'

//#region DEFINE VARIABLES
const emit = defineEmits<{ (e: 'undoOverride', args: any): void }>()

const props = defineProps({
    value: { type: Object as PropType<Overridable<any>>, required: true },
    id: { type: String, required: true },
    label: { type: String },
    helpText: { type: String },
    size: { type: String, default: 'm'},
    jsonPath: { type: String, default: '' },
    type: { type: String, required: true },
    source: { type: String, required: true },
    showEmptyOption: { type: Boolean },
    ...validationProps
})

const { auditChecks, jsonPathLabel } = useAuditChecks(props)
const { getErrorMessage } = useValidation(props)

const vm = toRef(props, 'value')
//#endregion

function undoOverride(args: any){
    emit('undoOverride', args)
}
</script>