import moment from 'moment'
import { useCurrentUserStore } from '../store'
export function dateTimeFilter(value: Date | null, format?: string): string {
    if (!value)
        return ''

    const store = useCurrentUserStore()
    const userDateFormat = store.currentUserProfile?.preferences?.dateFormat || 'MMM D, YYYY'
    const userTimeFormat = store.currentUserProfile?.preferences?.timeFormat || 'h:mm a'
    // ensure moment always parses date as UTC from server before converting to local
    return moment.utc(value).local().format(format || `${userDateFormat} ${userTimeFormat}`)
}

//Assume the input coming into the filter is local and does not need to be converted before formatting
export function localDateTimeFilter(value: Date | null, format?: string): string {
    if (!value)
        return ''

    const store = useCurrentUserStore()
    const userDateFormat = store.currentUserProfile?.preferences?.dateFormat || 'MMM D, YYYY'
    const userTimeFormat = store.currentUserProfile?.preferences?.timeFormat || 'h:mm a'
    return moment.utc(value).format(format || `${userDateFormat} ${userTimeFormat}`)
}

function dateDifferenceDays(auditDate: Date, currentDate: Date) {
    const timeDiff = currentDate.getTime() - auditDate.getTime()
    return timeDiff / (1000 * 3600 * 24)
}

export function dateTimeDiffFilter(value: Date | null | undefined): string {
    if (!value) return ""
    
    const store = useCurrentUserStore()
    const daysDiff = dateDifferenceDays(new Date(dateTimeFilter(value, 'MM/DD/YYYY')), new Date(dateTimeFilter(new Date(), 'MM/DD/YYYY')))
    const userDateFormat = store.currentUserProfile?.preferences?.dateFormat || 'MMM D, YYYY'
    const userTimeFormat = store.currentUserProfile?.preferences?.timeFormat || 'h:mm a'
    switch (daysDiff) {
        case 0:
            return `Today at ${dateTimeFilter(value, userTimeFormat)}`
        case 1:
            return `Yesterday at ${dateTimeFilter(value, userTimeFormat)}`
        default:
            return `${dateTimeFilter(value, userDateFormat + ' ' + userTimeFormat)}`
    }
}