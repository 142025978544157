import Cookies from 'js-cookie'
import { AccessTokenRequest, AccessTokenResponse, GrantType } from '@/common/models'
import { config } from '@/config'
import { axiosAuthService, sessionService } from './index'

class AuthService {
    private readonly cookieName = 'docwebToken3'
    private cookieService = Cookies.withConverter({
        read: (value) => {
            return JSON.parse(value)
        },
        write: (value) => value
    })

    private get authDetails(): AuthDetails | undefined {
        return this.cookieService.get(this.cookieName) as unknown as AuthDetails | undefined
    }

    private set authDetails(value: AuthDetails | undefined) {
        if (value) {
            this.cookieService.set(this.cookieName, JSON.stringify(value), {secure: true})
        }
    }

    hasAccessToken(): boolean {
        return this.authDetails?.accessToken !== undefined
    }

    async getAccessToken(): Promise<string | undefined> {
        let details = this.authDetails
        // previously we have had issues where a token is a minute from expiring
        // and would expire while generating documents. Now we are saying if the token
        // is within two minutes of expiring, then generate a new token before making
        // the request
        const expirationTime = new Date()
        expirationTime.setMinutes(expirationTime.getMinutes() + 2)
        if (!details?.expires || new Date(details.expires) <= expirationTime) {
            const newDetails = await this.refreshAccessTokenInternal()
            this.authDetails = newDetails
            details = newDetails
            await sessionService.updateUserSession()
        }
        return details?.accessToken?.token
    }

    private getTokenPromise = {} as Promise<AuthDetails | undefined>
    private promiseIsExecuting = false

    async refreshAccessTokenInternal(): Promise<AuthDetails | undefined> {
        if (!this.promiseIsExecuting) {
            this.promiseIsExecuting = true

            this.getTokenPromise = new Promise<AuthDetails | undefined>(resolve => {
                setTimeout(async () => {
                    const details = await this.refreshAccessToken()
                    resolve(details)
                    this.promiseIsExecuting = false
                }, 0)
            });
        }

        return this.getTokenPromise
    }

    async forceRefreshAccessToken() {
        this.authDetails = await this.refreshAccessToken()
        sessionService.updateUserSession()
    }

    async refreshAccessToken(): Promise<AuthDetails | undefined> {
        const details = this.authDetails
        if (details?.refreshToken) {
            const request = {
                grant_type: GrantType.RefreshToken,
                refresh_token: details?.refreshToken
            } as AccessTokenRequest
            return await this.requestAccessToken(request)
        }
    }

    async exchangeAuthCode(authCode: string) {
        const request = {
            grant_type: GrantType.AuthorizationCode,
            client_id: config.auth.clientId,
            redirect_uri: config.auth.redirectUri,
            authorization_code: authCode
        } as AccessTokenRequest
        this.authDetails = await this.requestAccessToken(request)
        await sessionService.updateUserSession()
    }

    async requestAccessToken(request: AccessTokenRequest): Promise<AuthDetails | undefined> {
        try {
            const result = await axiosAuthService.post('/login/tokens', request)
            return await this.createAuthToken(result.data as AccessTokenResponse)
        }
        catch {
            this.logout()
        }
    }

    async createAuthToken(response: AccessTokenResponse) {
        if (response.access_token) {
            const currentDate = new Date()
            return {
                accessToken: {
                    token: response.access_token,
                    scopes: [response.scope],
                    expiration: response.expires_in
                },
                refreshToken: response.refresh_token,
                expires: new Date(currentDate.getTime() + ((response.expires_in - config.app.timeoutWarning) * 1000))
            } as AuthDetails
        }
    }

    clearExistingAuth() {        
        sessionService.clearLastActiveTime()
        Cookies.remove(this.cookieName)
    }
   
    logout(redirectState = '') {
        this.clearExistingAuth()
        location.href = `${config.auth.loginUrl}?client_id=${config.auth.clientId}&redirect_uri=${encodeURIComponent(config.auth.redirectUri)}&scope=${config.auth.scope}&state=${encodeURIComponent(redirectState)}`
    }
}

export interface AuthDetails {
    accessToken: AccessToken
    refreshToken: string
    expires: Date
}

export interface AccessToken {
    token: string
    scopes: string[]
    expiration: number
}

export const authService = new AuthService()
