import { axiosDocGenService } from '@/common/services'
import {
    PackageRequest,
    PackageResponse,
    PreviewPackageRequest
} from '@/propel/models'
import { DocumentTestDataParams, DocumentTestResult, ResponseType } from '@/common/models'

class DocGenService {
    async generatePackage(request: PackageRequest): Promise<PackageResponse> {
        request.originatingSystem = 'Propel'
        const response = await axiosDocGenService.post('/packages', request)
        return response.data
    }

    async generatePreviewPackage(request: PreviewPackageRequest): Promise<PackageResponse>{
        const response = await axiosDocGenService.post("/packages/preview", request);
        return response.data;
    }

    async getDocumentTestResults(params: DocumentTestDataParams): Promise<DocumentTestResult> {
        const result = {} as DocumentTestResult
        try{
            const response = await axiosDocGenService.post('/test-packages', params)
            if(response.data.success) {
                result.type = ResponseType.Success
                result.fileUri = response.data.package.pdfUri
            }
            else {
                result.type = ResponseType.Error
                result.fileUri = response.data.message
            }
        }
        catch (error: any) {
            result.type = ResponseType.Error
            result.fileUri = JSON.stringify(error.response?.data?.errors ?? error.response?.data ?? error)
        }
        return result
    }

    async getUcdXml(id: string): Promise<Blob> {
        const response = await axiosDocGenService.get(`/ucd/xml?loanId=${id}`, {
            responseType: 'blob',
        })
        return response.data
    }

    getAttachmentUploadUri(clientCode: string): string {
        return axiosDocGenService.getUri(`/packages/attachment/${clientCode}`)
    }
}

export const docGenService = new DocGenService()