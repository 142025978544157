<template>
    <div class="field form-group m wrap">
        <div class="label-container">
            <label>Description</label>
        </div>
        <div class="base-input-container">
            <div class="input-group">
                <vue-autosuggest
                    v-model="query"
                    :suggestions="autoCompleteSuggestions"
                    :input-props="{
                        id: 'autosuggest__input',
                        class: 'autosuggest__input'
                    }"
                    @input="onInputChange"
                    @selected="(suggestion) => selectHandler(suggestion)"
                >
                    <template v-slot:default="{ suggestion }">
                        <div class="dropdown-item">
                            <span>{{ suggestion.item }}</span>
                        </div>
                    </template>
                </vue-autosuggest>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { AutoCompleteSuggestions } from '@/propel/models'
import { MaintenanceFee } from '@/common/models'
import { PropType, ref, toRef, defineProps } from 'vue'

//#region DEFINE VARIABLES
const props = defineProps({
    maintenanceFee: {type: Object as PropType<MaintenanceFee>, required: true },
})

const vm = toRef(props, 'maintenanceFee')
const autoCompleteSuggestions = ref([] as AutoCompleteSuggestions[])
const query = ref("")
//#endregion

//#region INITIALIZE
initialize()

async function initialize(){
    query.value = vm.value.description || ''
    loadSuggestions()
}
//#endregion
function selectHandler(suggestion) {
    vm.value.description = suggestion.item
}

function onInputChange(text) {
    query.value = text
    vm.value.description = query.value

    let filteredData = getAutoCompleteSuggestions()
    filteredData = filteredData.filter((item) => `${item}`.toLowerCase().includes(text.toLowerCase()))

    autoCompleteSuggestions.value = [
        {
            data: filteredData
        }
    ]
}

function loadSuggestions() {
    autoCompleteSuggestions.value = [
        {
            data: getAutoCompleteSuggestions()
        }
    ]
}

function getAutoCompleteSuggestions(): string[] {
    return ['Return Check Fee', 'Stop Payment Fee', 'Annual Fee', 'Non-Sufficient Funds', 'Over Limit Fee', 'Termination Fee']
}
</script>

<style lang="scss" scoped>
.input-group {
    > div {
        width: 100%;
    }
}
</style>