<template>
    <div :id="`${id}-group`" :class="`field ${size} form-group`">
        <base-overridable :label="label" :jsonPathLabel="jsonPathLabel" :value="vm" :id="id" :auditChecks="auditChecks" :validationRules="validationRules" :helpText="helpText"
                :validationFieldName="label" :immediateValidation="immediateValidation" :errorMessage="errorMessage" @undoOverride="undoOverride">
            <template v-slot:input="{ errors }">
                <date-picker :id="`${id}`" v-model="vm.value" :restricted="!vm.isOverridden" :jsonPath="jsonPath" :class="getErrorMessage(errors[0]) ? 'error' : ''"/>
            </template>
        </base-overridable>
    </div>
</template>

<script setup lang="ts">
import { PropType, defineProps, defineEmits, toRef } from 'vue'
import BaseOverridable from './base-overridable.vue'
import { Overridable } from '../models'
import { useAuditChecks, useValidation, validationProps } from '@/common/composables/audit-check-composable'

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'undoOverride', args: any) 
}>()

const props = defineProps({
    value: { type: Object as PropType<Overridable<any>>, required: true },
    id: { type: String, required: true },
    size: { type: String as PropType<string|null>, default: 's' },
    label: { type: String },
    helpText: { type: String },
    jsonPath: { type: String, default: '' },
    ...validationProps
})

const { auditChecks, jsonPathLabel } = useAuditChecks(props)
const { getErrorMessage } = useValidation(props)

const vm = toRef(props, 'value')
//#endregion

function undoOverride(args: any){
    emit('undoOverride', args)
}
</script>