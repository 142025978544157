export interface FeatureFlag {
    key: string
    displayName: string
    availableLevels: AvailableLevels[]
    enabledAt: FeatureFlagEnabledSettings
}

export interface FeatureFlagEnabledSettings {
    system: boolean
    partners: string[]
    clients: string[]
    users: string[]
}

export interface FeatureFlagUpdate {
    key: string
    displayName: string
    availableLevels: string[]
    isEnabledAtSystem: boolean
}


export interface FeatureFlagStatus {
    key: string
    isEnabled: boolean
    levelEnabled: FeatureFlagEnabledMethod
}

export enum FeatureFlagEnabledMethod
{
    UnknownFlagName,
    NotEnabled,
    EnabledBySystem,
    EnabledByPartner,
    EnabledByLender,
    EnabledByUser
}

export enum AvailableLevels
{
    Client = "Client",
    User = "User"
}
   