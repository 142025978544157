import { auditCheckService, jsonPathTestingService } from "@/propel/services"
import { ComputedRef, ExtractPropTypes, computed } from 'vue'
import { AuditCheckResult } from "@/propel/models"
import { useRoute } from "vue-router/composables"
import { useAuditCheckStore } from "@/common/store"

export function useAuditChecks(props: Readonly<ExtractPropTypes<JsonPathAndProperty>>) {
    //#region COMPUTED
    const route = useRoute()
    const store = useAuditCheckStore()
    const storeAuditChecks = computed(() => store.loanAuditChecks)
    const auditChecks = computed(() => {
        if (props.jsonPath) {
            if(store.debugModeEnabled) {
                const allJsonPaths = store.loanJsonPathTests
                const results = (props.jsonProperty ? jsonPathTestingService.getIndividualJsonPathTestResults(allJsonPaths, props.jsonPath, formatJsonProperty()) : allJsonPaths[props.jsonPath]) || []
                return results.filter(r => !r.routeLocation || r.routeLocation == route.name)
            } else {
                return (props.jsonProperty ? auditCheckService.getIndividualAuditChecks(storeAuditChecks.value, props.jsonPath, formatJsonProperty()) : storeAuditChecks.value[props.jsonPath]) || []
            }
        }

        return []
    })

    const jsonPathValue = computed(() => props.jsonProperty ? `${props.jsonPath}${formatJsonProperty()}` : props.jsonPath)

    const jsonPathLabel = computed(() => {
        if(store.debugModeEnabled && props.jsonPath) {
            return `{${jsonPathValue.value}}`
        }

        return ''
    })


    function formatJsonProperty(): string {
        let formattedProp = ''
        if (props.jsonProperty) {
            //if the jsonProperty does not start with '.', add it to the beginning of the string
            formattedProp = props.jsonProperty[0] === '.' ? props.jsonProperty : "." + props.jsonProperty
        }

        return formattedProp
    }

    const { auditCheckLevelClass } = useAuditCheckLevelClass(auditChecks)
    //#endregion

    return {
        auditCheckLevelClass,
        jsonPathValue,
        jsonPathLabel,
        formatJsonProperty,
        auditChecks
    }
}

export function useAuditCheckLevelClass(auditChecks: ComputedRef<AuditCheckResult[]>) {
    const { displayAuditCheckErrors } = useDisplayAuditCheckErrors()
    const store = useAuditCheckStore()
    const auditCheckLevelClass = computed(() => {
        if (store.debugModeEnabled) {
            return jsonPathTestingService.getJsonPathClass(displayAuditCheckErrors.value, auditChecks.value)
        } else {
            return auditCheckService.getAuditCheckLevelClass(displayAuditCheckErrors.value, auditChecks.value)
        }
    })

    return {
        auditCheckLevelClass
    }
}

export function useDisplayAuditCheckErrors() {
    const store = useAuditCheckStore()
    const displayAuditCheckErrors = computed(() => store.displayAuditCheckErrors || store.debugModeEnabled)
    return {
        displayAuditCheckErrors
    }
}

export function useValidation(props) {
    function getErrorMessage(validationError: string) {
        return props.errorMessage || validationError
    }

    return {
        getErrorMessage
    }
}

export const validationProps = {
    validationRules: { type: String, default: '' },
    validationFieldName: { type: String, default: '' },
    immediateValidation: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' }
}

interface JsonPathAndProperty {
    jsonPath: string | undefined 
    jsonProperty: string | undefined
}