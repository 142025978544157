export interface Reference {
    key: string
    displayValue: string
}

export interface StateSummary {
    abbreviation: string 
    name: string 
}

export enum ReferenceSource {
    admin = "admin",
    loan = "loan",
    metadata = "metadata"
}

export const enum ReferenceType {
    //loan
    accountTypes = "account-types",
    adjustmentItemPaidByTypes = "adjustment-item-paid-by-types",
    adjustmentItemTypes = "adjustment-item-types",
    adverseActionFormTypes = "adverse-action-form-types",
    aggregatePaidByTypes = "aggregate-paid-by-types",
    aggregatePaymentFrequencyTypes = "aggregate-payment-frequency-types",
    aggregateTypes = "aggregate-types",
    amortizationScheduleTypes = "amortization-schedule-types",
    amortizationTypes = "amortization-types",
    appraisalDocumentTypes= "appraisal-document-types",
    assetTypes = "asset-types",
    automatedSystemTypes = "automated-system-types",
    bankruptcyTypes = "bankruptcy-types",
    bonusEligibiltyTypes = "bonus-eligibility-types",
    borrowerDemographicCollectionMethods = "borrower-demographic-collection-methods",
    borrowerLiabilityTypes = "borrower-liability-types",
    borrowerTypes = "borrower-types",
    brokerFeePaidByTypes = "broker-fee-paid-by-types",
    brokerPaidWhenTypes = "broker-paid-when-types",
    cashToCloseItemPaymentTypes = "cash-to-close-item-payment-types",
    cashToCloseItemTypes = "cash-to-close-item-types",
    citizenshipTypes = "citizenship-types",
    clientDeliveryOptions = "client-delivery-options",
    closingAdjustmentTypes = "closing-adjustment-types",
    cocTypes = "change-of-circumstance-reason-types", 
    constructionClosingTypes = "construction-closing-types",
    contractorTypes = "contractor-types",
    disbursementOfLoanProceedsTypes = "disbursement-of-loan-proceeds-types",
    disclosureSectionTypes = "disclosure-section-types",
    disclosureSubsectionTypes = "disclosure-subsection-types",
    documentModeConversionTypes = "document-mode-conversion-types",
    documentModes = "document-modes",
    duplicateLoanActionTypes = "duplicate-loan-action-types",
    escrowItemTypes = "escrow-item-types",
    escrowPaidByTypes = "escrow-paid-by-types",
    estateTypes = "estate-types",
    existingLoanIrrrlTypes = "existing-loan-irrrl-types",
    existingLoanTypes = "existing-loan-types",
    expenseTypes = "expense-types",
    feePaidByTypes = "fee-paid-by-types",
    feePaidToTypes = "fee-paid-to-types",
    feeTypes = "fee-types",
    fhaRiskClass = "fha-risk-classes",
    fundTypes = "fund-types",
    giftSources = "gift-sources",
    giftTypes = "gift-types",
    holdbackTypes = "holdback-types",
    homeownershipProgramFormats = "homeownership-program-formats",
    housingCounselingProgramFormats = "housing-counseling-program-formats",
    housingExpenseTypes = "housing-expense-types",
    incomeSourceTypes = "income-source-types",
    indianCountryOwnershipTypes = "indian-country-ownership-types",
    integratedDisclosureDocumentTypes = "integrated-disclosure-document-types",
    intendedOccupancyTypes = "intended-occupancy-types",
    introductoryTerms = "introductory-terms",
    languageTypes = "language-types",
    lateFeeTypes = "late-fee-types",
    lenderEntityTypes = "lender-entity-types",
    liabilityTypes = "liability-types",
    licenseTypes = "license-types",
    lienPositions = "lien-positions",
    linkResponseTypes = "link-response-types",
    loanPurposes = "loan-purposes",
    loanRepaymentTypes = "loan-repayment-types",
    loanTransactionDetailTypes = "transaction-detail-types",
    loanTypes = "loan-types",
    manufacturedHomeConditions = "manufactured-home-conditions",
    manufacturedHomeCondtion = "manufactured-home-conditions",
    maritalStatuses = "marital-statuses",
    martialStatuses = "marital-statuses",
    militaryServiceTypes = "military-service-types",
    monthlyEscrowRoundingTypes = "monthly-escrow-rounding-types",
    mortgageInsuranceRefundableTypes = "mortgage-insurance-refundable-types",
    mortgageInsuranceRenewalCalculationTypes = "mortgage-insurance-renewal-calculation-types",
    mortgageInsuranceSourceTypes = "mortgage-insurance-source-types",
    mortgageTypes = "mortgage-types",
    notaryDisplayTypes = "notary-display-types",
    notaryLayoutTypes = "notary-layout-types",
    noteEndorsementTypes = "note-endorsement-types",
    occupancyTypes = "property-usage-types",
    pastCreditRecords = "va-past-credit-records",
    paymentFrequencyTypes = "payment-frequency-types",
    payoffLiabilityTypes = "payoff-liability-types",
    perDiemCalculationTypes = "per-diem-calculation-types",
    prepaidItemPaymentTimingTypes = "prepaid-item-payment-timing-types",
    prepaidItemTypes = "prepaid-item-types",
    prepaymentPenaltyBalanceTypes = "prepayment-penalty-tier-balance-types",  
    prepaymentPenaltyOptionTypes = "prepayment-penalty-tier-option-types",
    priorPropertyOccupancyTypes = "prior-property-occupancy-types",
    priorTitleOwnershipTypes = "prior-title-ownership-types",
    propertyStatuses = "property-statuses",
    propertyTypes = "property-types",
    propertyUsageTypes = "property-usage-types",
    prorationItemTypes = "proration-item-types",
    rateLockStatusTypes = "rate-lock-status-types",
    realEstateAgentTypes = "real-estate-agent-types",
    realEstatePropertyMortgageTypes = "real-estate-property-mortgage-types",
    refiComparisionTypes = "refi-comparision-types",
    refinancePrograms = "refinance-programs",
    refinanceTypes = "refinance-types",
    relationshipCodes = "relationship-codes",
    relationshipTypes = "relationship-types",
    releaseTypes = "release-types",
    repaymentTypes = "loan-repayment-types",
    residencyTypes = "residency-types",
    returnResponseTypes = "return-response-types",
    riskClassifications = "risk-classifications",
    section184RiderTypes = "section184-rider-types",
    sectionOfActTypes = "section-of-act-types",
    sellerCorporationTypes = "seller-corporation-types",
    sellerPropertyOwnerTypes = "seller-property-owner-types",
    sellerTypes = "seller-types",
    signingTypes = "signing-types",
    subsectionPaidByTypes = "subsection-paid-by-types",
    subsectionPaymentTimingTypes = "subsection-payment-timing-types",
    taxFormRecordTypes = "tax-form-record-types",
    taxFormRequestorTypes = "tax-form-requestor-types",
    titleOwnerships = "title-ownerships",
    transactionDetailTypes = "transaction-detail-types",
    trustTypes = "trust-types",
    unitDescriptions = "unit-descriptions",
    upfrontRoundingTypes = "upfront-rounding-types",
    urlaLoanPurposes = "urla-loan-purposes",
    vaAutomatedUnderwritingSystems = "va-automated-underwriting-systems",
    vaCertificateOfEligibilityConditionTypes = "va-certificate-of-eligibility-condition-types",
    vaFinalActions = "va-final-actions",
    vaLoanPurposeTypes = "va-loan-purpose-types",
    vaPastCreditRecords = "va-past-credit-records",
    vaRecommendedActions = "va-recommended-actions",
    warrantyDeedTypes = "warranty-deed-types",

    //compliance-loan
    constructionPhaseTypes = "construction-phase-types",
    propertyInspectionTypes = "property-inspection-types",
    projectDesignTypes = "project-design-types",
    propertyAttachmentTypes = "property-attachment-types",

    //admin
    accessCodeTypes = "access-code-types",
    armRoundingTypes = "arm-rounding-types",
    barcodeAlignments = "barcode-alignments",
    barcodeTypes = "barcode-types",
    complianceTestTypes = "compliance-test-types",
    contactTypes = "contact-types",
    dayOfWeeks = "day-of-weeks",
    defaultTaxRequestFormType = "default-tax-request-form-types",
    holidayDateTypes = "holiday-date-types",
    indexTypes = "index-types",
    insurableInterestTypes = "insurable-interest-types",
    legalDescriptionPrintOptions = "legal-description-print-options",
    loanContactTypes = "loan-contact-types",
    loanOfficerSigningOrder = "loan-officer-signing-orders",
    regcheckPrintStyles = "reg-check-print-styles",
    regcheckPrintTimezone = "reg-check-print-timezones",
    ssnDisplayTypes = "ssn-display-types",
    states = "states",
    timeZoneOptions = "time-zone-options",
    helocScreenAndFieldSetupTypes = "heloc-screen-and-field-setup-types",
    drawPeriodMinimumPaymentOptions = "draw-period-minimum-payment-options",
    helocPaymentApplicationOptions = "heloc-payment-application-options",
    webhookAuthenticationTypes = "webhook-authentication-types",
    forwardingTypes = "forwarding-types",
    ftpProtocolTypes = "ftp-protocol-types",

    //metadata
    actionTypes = "action-types",
    criteriaOperatorTypes = "criteria-operator-types",
    documents = "documents",
    eFillableFieldTypes = "e-fillable-field-types",
    eFillableFieldValidationTypes = "e-fillable-field-validation-types",
    fieldFormats = "field-formats",
    fieldPropertyTypes = "field-property-types",
    fieldTypes = "field-types",
    fields = "fields",
    originatorActingAsTypes = "originator-acting-as-types",
    originatorTypes = "originator-types",
    packageTypes = "package-types",
    sectionDisplayTypes = "section-display-types",
    sectionLabelLocationTypes = "section-label-location-types",
    sectionTypes = "section-types",
    signatureBorrowerLabels = "signature-borrower-labels",
    signatureDateLocations = "signature-date-locations",
    signatureDisplayOptions = "signature-display-options",
    signatureLayoutTypes = "signature-layout-types",
    signatureNonBorrowerLabels = "signature-non-borrower-labels",
    signatureSealFormats = "signature-seal-formats",
    signatureSellerLabels = "signature-seller-labels",
    signatureSettlorLabels = "signature-settlor-labels",
    statuses = "statuses",
    systemTypes = "system-types"
}