export interface PackageDetails {
    href: string
    clientCode: string
    loanNumber: string
    orderNumber: string
    packageType: string
    description: string
    links: FileLink[]
    password: string
}

export interface PackageSummary {
    id: string
    href: string
    clientCode: string
    loanNumber: string
    orderNumber: string
    description: string
    notes: string
    createdBy: string
    dateCreated: Date
    hasEditPassword?: boolean
    hasViewPassword?: boolean
    links: FileLink[]
    delivery?: PacakgeDelivery
    pdf?: Pdf
    packageType: string
}

export interface FileLink {
    id: string
    rel: string
    timestamp: string
    href: string
    type: string
    size?: string
    downloadUri: string
}

export enum FileType {
    Pdf = 'Pdf',
    SignedPdf = 'SignedPdf',
    XmlImportData = 'XmlImportData',
    Loan = 'Loan',
    Ucd = 'Ucd',
    SignedZip = 'SignedZip',
    AuditTrail = "AuditTrail"
}

export interface PacakgeDelivery {
    transactionId: string
    status: string
    propelStatus: string
    type: string
}

export interface Pdf {
    isDraft: boolean
    documentCount?: number
    pageCount: number
    hasEditPassword: boolean
    hasViewPassword: boolean
}

export interface Transaction {
    id: string
    description: string
    destination: string
    fileId: string
    status: string
    paperedOut: string
    vendor: Vendor
    recipients: Recipient[]
    history: History[]
}

export interface Vendor {
    type: string
    transactionId: string
    status: string
    options: any
}

export interface Recipient {
    name: string
    emailAddress: string
    history: History[]
    role: RecipientRole
    type: string
}

export interface History {
    timestamp: Date
    event: string
    message: string | null
    isInternalOnly: boolean
}

export enum RecipientRole {
    Unknown = 'Unknown',
    Signer = 'Signer',
    Viewer = 'Viewer',
    Copied = 'Copied'
}