<template>
    <div class="container-scroll">
        <ul>
            <li v-for="item in allItems" :key="item.value">
                {{ item.text }}
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue'
import { metadataService } from '@/propel/services'
import { Reference } from '@/common/models'
import { MultiSelectItem } from '@/common/components/multi-select-item'

//#region DEFINE VARIABLES
const props = defineProps({
    type: { type: String, required: true }
})
const allItems = ref<MultiSelectItem[]>([])
//#endregion

//#region INITIALIZE
initialize()

async function initialize() {
    allItems.value = await getSystemTypeValues()
}

async function getSystemTypeValues(): Promise<any> {
    const values = await metadataService.getSystemTypeValues(props.type)
    return values.map((t: Reference) => ({ value: t.key, text: t.key } as MultiSelectItem))
}
//#endregion
</script>
<style scoped lang="scss" >
.container-scroll {
    padding: 0.5rem;
    background-color: none;
    border: 1px solid $light-gray;
    height: 100px;
    overflow: auto;
}
ul {
    list-style-type: none;
    padding: 0;
}
</style>