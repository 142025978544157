import marketData from './market-data'
import { regCheckSystemRoutes } from '@/regcheck/router/system'
export default [
    {
        path: '/system',
        component: () => import('@/common/views/system/system-area-layout.vue'),
        children: [
            {
                path: 'settings',
                name: 'system-settings',
                redirect: 'settings/packages'
            },
            {
                path: 'settings/packages',
                name: 'system-package-types',
                component: () => import('@/common/views/system/package-types.vue'),
                meta: {
                    title: 'Packages',
                    readPermission: 'system',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/investors',
                name: 'investor-grid',
                component: () => import('@/common/views/system/investors/investor-grid.vue'),
                meta: {
                    title: 'Investors',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/investors/:key',
                name: 'investor-detail',
                component: () => import('@/common/views/system/investors/investor.vue'),
                meta: {
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/warehouse-banks',
                name: 'warehouse-bank-grid',
                component: () => import('@/common/views/system/warehouse-bank/warehouse-bank-grid.vue'),
                meta: {
                    title: 'Warehouse Banks',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/warehouse-banks/:key',
                name: 'warehouse-bank-detail',
                component: () => import('@/common/views/system/warehouse-bank/warehouse-bank.vue'),
                meta: {
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/system-closing-conditions',
                name: 'system-closing-conditions',
                component: () => import ('@/common/views/system/closing-conditions/closing-conditions.vue'),
                meta: {
                    title: 'Closing Conditions',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/credit-bureau',
                name: 'system-config-credit-bureau',
                component: () => import('@/common/views/system/system-config/credit-bureau.vue'),
                meta: {
                    title: 'Credit Agencies',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/ecoa-notices',
                name: 'ecoa-notices',
                component: () => import('@/common/views/system/ecoa-notices/ecoa-notices.vue'),
                meta: {
                    title: 'ECOA Notices',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/loan-review-settings',
                name: 'system-loan-review-settings',
                component: () => import('@/common/views/system/loan-review-settings.vue'),
                meta: {
                    title: 'Loan Review',
                    readPermission: 'system',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/document-defaults',
                name: 'document-defaults-trustees',
                component: () => import('@/common/views/system/system-config/admin-trustees.vue'),
                meta: {
                    title: 'Deed of Trust',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/doc-prep-attorney',
                name: 'document-defaults-doc-prep-attorney',
                component: () => import('@/common/views/system/document-defaults/doc-prep-attorney.vue'),
                meta: {
                    title: 'Doc Prep/Attorney',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/prepared-by',
                name: 'document-defaults-prepared-by',
                component: () => import('@/common/views/system/document-defaults/prepared-by.vue'),
                meta: {
                    title: 'Prepared By',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/documents',
                name: 'document-defaults-cd',
                component: () => import('@/common/views/system/document-defaults/cd.vue'),
                meta: {
                    title: 'CD',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/other',
                name: 'document-defaults-other',
                component: () => import('@/common/views/system/document-defaults/other.vue'),
                meta: {
                    title: 'Other',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/fee-defaults',
                name: 'fee-defaults-le-cd-sections',
                component: () => import('@/common/views/system/fee-defaults/le-cd-sections.vue'),
                meta: {
                    title: 'LE/CD Sections',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/fees',
                name: 'fee-defaults-fees',
                component: () => import('@/common/views/system/fee-defaults/fees.vue'),
                meta: {
                    title: 'Fees',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/prepaids',
                name: 'fee-defaults-prepaids',
                component: () => import('@/common/views/system/fee-defaults/prepaids.vue'),
                meta: {
                    title: 'Prepaids',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/escrows',
                name: 'fee-defaults-escrows',
                component: () => import('@/common/views/system/fee-defaults/escrows.vue'),
                meta: {
                    title: 'Escrows',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/housing-expenses',
                name: 'fee-defaults-housing-expenses',
                component: () => import('@/common/views/system/fee-defaults/housing-expenses.vue'),
                meta: {
                    title: 'Housing Expenses',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            },
            {
                path: 'settings/calculations',
                name: 'system-config-calculations',
                component: () => import('@/common/views/system/system-config/calculations.vue'),
                meta: {
                    title: 'Calculations',
                    readPermission: 'system.setup',
                    writePermission: 'system.setup:write'
                }
            }, 
            {
                path: 'it-setup',
                redirect: 'it-setup/security'
            },
            {
                path: 'it-setup/security',
                name: 'system-config-security',
                component: () => import('@/common/views/system/system-config/security.vue'),
                meta: {
                    title: 'Security',
                    readPermission: 'system-admin',
                    writePermission: 'system-admin'
                }
            },
            {
                path: 'it-setup/integration-clients',
                name: 'system-integration-clients',
                component: () => import('@/common/views/system/integration-clients/integration-clients.vue'),
                meta: {
                    title: 'Integration Clients',
                    readPermission: 'system-admin',
                    writePermission: 'system-admin'
                }
            },
            {
                path: 'it-setup/feature-flags',
                name: 'system-feature-flags',
                component: () => import('@/common/views/system/system-config/feature-flags.vue'),
                meta: {
                    title: 'Feature Flags',
                    readPermission: 'system-admin',
                    writePermission: 'system-admin'
                }
            },
            ...regCheckSystemRoutes,
            ...marketData
        ]
    }  
]