import moment from 'moment'
import { Borrower, Contact, Address, Name, ContactType, Reference, BasicContact } from '@/common/models'
import { startCase, toLower, trim } from 'lodash'
import { MultiSelectItem } from '@/common/components/multi-select-item'
import saveAs from 'file-saver'

export class DateHelper {
    static formatUTCDateForRoute(date: Date | undefined): string {
        return moment.utc(date).format('MM-DD-YYYY')
    }

    static formatDate(date: Date, formatType: string) : string {
        return moment.utc(date).format(formatType)
    }

    static getMomentFromString(date: string, ignoreLocalTime = false) {
        return moment(date).utcOffset(0, ignoreLocalTime)
    }

    //1 = d1 greater, -1 = d2 greater, 0 = dates are same
    static compareDate(d1: Date|string, d2: Date|string): number {
        //If dates are strings, remove any time component before comparing
        if(typeof d1 === 'string') d1 = d1.split('T')[0]
        if(typeof d2 === 'string') d2 = d2.split('T')[0]

        const isSame = moment(d1).isSame(d2, 'day')
        if(isSame) return 0

        return moment(d1).isAfter(d2, 'day') ? 1 : -1
    }
}

export class DisplayHelper {
    static getBorrowerText(borrower: Borrower): string {
        const borrowerText = `${this.getBorrowerOrSellerName(borrower)} ${this.getBorrowerSsn(borrower)} ${borrower.type && borrower.type !== 'Borrower' ? `- ${borrower.type}` : ''}`
        return borrower.isCorp ? borrower.corporation?.name ? borrower.corporation.name : 'New Corporate Borrower' : borrowerText
    }
    
    static getBorrowerOrSellerName(name: Name | undefined, newText = 'New Borrower'): string {
        if (name) {
            const firstName = name.firstName ?? ''
            const middleName = name.middleName ?? ''
            let lastName = name.lastName ?? ''
            const suffixName = name.suffixName ?? ''

            if (suffixName) {
                lastName += ','
            }
    
            if (name.firstName || name.lastName) {
                return trim(this.removeExtraSpaces(`${firstName} ${middleName} ${lastName} ${suffixName}`))
            }else {
                return newText
            }
        }

        return ''
    }

    static removeExtraSpaces(fullName: string) {
        while(fullName.indexOf('  ') > 0) {
            fullName = fullName.replace('  ', ' ')
        }
        return fullName
    }

    private static getBorrowerSsn(borrower: Borrower): string {
        return borrower.ssn && borrower.ssn.length === 9 ? `(${borrower.ssn.substring(5)})` : ''
    }

    static addressSubTitle(address: Address): string {
        let subTitle = 'None'
        if (address) {
            subTitle = address.street || ''
            if (address.unit)
                subTitle += ' #' + address.unit
            const city = address.city || ''
            subTitle += city && subTitle ? `, ${city}` : `${city}`
            const state = address.state || ''
            subTitle += state && subTitle ? `, ${state}` : `${state}`
        }
        return subTitle
    }
    
    //TODO: investigate handling this in the multi select control with a prop for these types of selections and let that control do the lookup in a case insensitive fashion.
    // When working on this todo see the getInsurableInterest method in the lender.ts file
    //hack to deal with issue surrounding the way we store state of incorp and uppercasing all string values
    static getIncorporationState(state: string | undefined) {
        let stateString = ''
        if (state) {
            const stateSplit = state.split(' ')
            if(stateSplit.length > 1) {
                stateString = stateSplit.map(x => this.startCaseForIncorpStateCasing(x)).join(' ')
            } else {
                stateString = startCase(toLower(state))
            }
        }
        return stateString
    }

    private static startCaseForIncorpStateCasing(word: string) {
        let wordToReturn = toLower(word)
        if (wordToReturn === 'us') {
            wordToReturn = 'US'
        } else if (wordToReturn === 'of') {
            wordToReturn = 'of'
        } else {
            wordToReturn = startCase(wordToReturn)
        }

        return wordToReturn
    }

    private static addressTypes = [
        ContactType.Broker, 
        ContactType.Ssa89, 
        ContactType.Trustee, 
        ContactType.NotePay, 
        ContactType.NoticeOfRightToCancel,
        ContactType.Servicer,
        ContactType.ServicerPayment,
        ContactType.DtMortgageReturn,
        ContactType.DocumentsReturn,
        ContactType.FirstPaymentLetter,
        ContactType.MortgageeClause,
        ContactType.TaxServiceProvider,
        ContactType.Investor,
        ContactType.Beneficiary
    ]

    static contactSubTitle(contact: Contact | BasicContact, type: ContactType): string {
        let subTitle = 'None'
        if (contact) {
            subTitle = contact.name || subTitle
            if (contact.address && this.addressTypes.includes(type)) {
                const city = contact.address.city || ''
                subTitle += city && subTitle ? `, ${city}` : `${city}`
                const state = contact.address.state || ''
                subTitle += state && subTitle ? `, ${state}` : `${state}`
            }
        }

        return subTitle
    }

    static getCityStateZip(address: Address) {
        let addressString = ''

        if (address) {
            const city = address.city || ''
            addressString += city && addressString ? `, ${city}` : `${city}`
            const state = address.state || ''
            addressString += state && addressString ? `, ${state}` : `${state}`
            addressString += ` ${address.zip}`
        }

        return addressString
    }

    static getStreetUnit(address: Address) {
        let addressString = ''

        if (address) {
            addressString = address.street || ''
            if (address.unit) addressString += ' #' + address.unit
        }

        return addressString
    }

    
    static getFullAddress(address: Address) {
        let addressString = ''

        if (address) {
            addressString = address.street || ''
            if (address.unit) addressString += ' #' + address.unit
            const city = address.city || ''
            addressString += city && addressString ? `, ${city}` : `${city}`
            const state = address.state || ''
            addressString += state && addressString ? `, ${state}` : `${state}`
        }

        return addressString
    }

    static getDisplayValue(referenceList: Reference[], value: string) {
        return referenceList.find((r: Reference) => r.key === value)?.displayValue
    }
}

export class SortHelper {
    static alphabeticalSort(caseSensitive = true) {
        return (a, b) => {
            return this.sortValues(a.toString(), b.toString(), caseSensitive)
        }
    }

    static alphabeticalFieldSort(field, caseSensitive = true) {
        return (a, b) => {
            return this.sortValues(a[field], b[field], caseSensitive)
        }
    }

    private static sortValues(a, b, caseSensitive: boolean) {
        a = caseSensitive ? a : a.toLowerCase()
        b = caseSensitive ? b : b.toLowerCase()
        if (a < b) return -1
        else if (a > b) return 1
        return 0
    }
}

export class MultiSelectHelper {
    static formatToMultiSelect(items: Reference[]): MultiSelectItem []{
        return items.map(x => ({value: x.key, text: `${x.displayValue}`} ))
    }

    //note: this is used on the loan and order grid page to format the client dropdown response when using optionDisplayType=SplitWithValue
    static removeValuePrefixFromText(items: MultiSelectItem []): MultiSelectItem[]{
        return items.map(x => {
            return {
                value: x.value,
                text: x.text.split(' - ')[1] || x.text
            }
        })
    }
}

export class BlobSaverHelper {
    static SaveBlob(data: any, contentType: string, fileName: string) {
        const blob = new Blob([data] as BlobPart[], {type: `${contentType};charset=utf-8`})
        saveAs(blob, fileName)
    }
}