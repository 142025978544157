import { useAppStore } from '@/common/store'

export interface NotificationDetails {
    title?: string
    text: string
    type: string
    autoHide: boolean
    manualClose: boolean
    error?: ErrorDetails
}

export interface ErrorDetails {
    id: string
    message: string
}

class MessageService {

    // Messages are expected to have options.title set in most cases.
    // A message without a title will display in shortened form,
    // but that should only be used for VERY SHORT info or success messages 
    // (and the close icon isn't styled correcty for shorts).

    showSaveSuccess(itemName: string) {
        if (itemName) {
            this.showSuccess(`Changes to ${itemName} have been saved`)
        }
        else {
            this.showSuccess("Your changes have been saved")
        }
    }

    showSuccess(text: string, title?: string, autoHide = true) {
        const details: NotificationDetails = { text: text, title: title, autoHide: autoHide, manualClose: !autoHide, type: 'Success' }
        this.show(details)
    }

    showInfo(text: string, title?: string, autoHide = true) {
        const details: NotificationDetails = { text: text, title: title, autoHide: autoHide, manualClose: !autoHide, type: 'Info' }
        this.show(details)
    }

    showWarning(text: string, title: string) {
        const details: NotificationDetails = { text: text, title: title, autoHide: false, manualClose: true, type: 'Warning' }
        this.show(details)
    }

    showError(text: string, title: string) {
        const details: NotificationDetails = { text: text, title: title, autoHide: false, manualClose: true, type: 'Error' }
        this.show(details)
    }

    showSystemError(text: string, title: string, errorDetails?: string, errorId?: string) {
        const details: NotificationDetails = {
            text: text, title: title, autoHide: false, manualClose: true, type: 'SystemError', error: errorDetails ? {
                id: errorId,
                message: errorDetails
            } as ErrorDetails : undefined
        }
        this.show(details)
    }

    showLoading(text: string, title?: string) {
        const details: NotificationDetails = { text: text, title: title, autoHide: false, manualClose: false, type: 'Loading' }
        this.show(details)
    }

    private show(details: NotificationDetails) {
        const store = useAppStore()
        store.notificationDetails = details
    }
}

export const messageService = new MessageService()
