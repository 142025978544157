<template>
    <div :id="`${id}-form-group`" :class="`field ${size} form-group custom-control custom-checkbox`">
        <input
            type="checkbox"
            class="custom-control-input"
            :id="`${id}`"
            :disabled="isDisabled"
            :checked="value"
            :jsonpath="jsonPathValue"
            @input="$emit('input', $event.target.checked)"
            @change="$emit('change', $event)"
        />
        <label class="custom-control-label" :for="id">{{ label }}</label>
        <span v-if="jsonPathLabel" class="json-path-label">&nbsp;{{jsonPathLabel}}</span>
        <custom-icon icon="Help" :id="`${id}-help`" v-if="helpText" :title="helpText" />
        <protected-edit-icon :icon="protectedEditIconString" :showProtectedEdit="showProtectedEdit" :allowProtectedEdit="allowProtectedEdit" @click="turnOffProtectedDisabled" />
        <fg-defaultable :currentValue="value" :defaultValue="defaultValue" :isDefaulted="isDefaulted" @change="onDefaultValueChange" @defaultChange="onDefaultChange" :disabled="!showDefaultFieldEdit" />
        <audit-check-icon :id="`${id}-audit-check`" :auditChecks="auditChecks" />
        <p class="post-text" v-if="postText">{{postText}}</p>
    </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import { useFormGroupBase, baseProps } from './form-group-base-composable'

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'input', value: boolean): void 
    (e: 'unlocked'): void
    (e: 'change', event: any): void
}>()

const props = defineProps({
    value: { type: Boolean, default: false},
    id: { type: String, required: true },
    label: { type: String, required: true },
    size: { type: String, default: "" },
    helpText: { type: String },
    postText: { type: String },
    ...baseProps
})
//#endregion

//#region INITIALIZE
const { jsonPathLabel, jsonPathValue, protectedEditIconString, showProtectedEdit, allowProtectedEdit, turnOffProtectedDisabled, showDefaultFieldEdit, onDefaultValueChange,
     onDefaultChange, defaultValue, isDefaulted, auditChecks, isDisabled } = useFormGroupBase(props, emit)
//#endregion

</script>

<style lang="scss" scoped>
.post-text{
    font-size: 0.75rem;
}
.json-path-label {
    font-size: 80%;
    word-break: break-all;
}
</style>