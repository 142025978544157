<template>
    <div :id="`${id}-group`" :class="`field ${size} form-group`">
        <validation-provider :name="label" :rules="validationRules" :immediate="immediateValidation" v-slot="{ errors }">
            <div class="label-container" v-if="label || helpText">
                <label :for="id" v-if="label">{{ label }}</label>
                <span v-if="jsonPathLabel" class="json-path-label">{{jsonPathLabel}}</span>
                <custom-icon icon="Help" :id="`${id}-help`" v-if="helpText" :title="helpText" />
            </div>
            <div class="base-input-container">
                <input-select
                    v-if="referenceType"
                    :id="`${id}`"
                    :value="value"
                    :stickyItems="stickyItems"
                    :referenceType="referenceType"
                    :referenceSource="referenceSource"
                    :multiple="multiple"
                    :searchable="searchable"
                    :useLocalSearch="useLocalSearch"
                    :disabled="isDisabled"
                    :showEmptyOption="showEmptyOption"
                    :placeholder="placeholder"
                    :optionsToFilter="optionsToFilter"
                    :jsonPath="jsonPathValue"
                    :class="getErrorMessage(errors[0]) ? 'error' : ''"
                    @input="$emit('input', $event)"
                    @change="$emit('change', $event)"
                />
                <input-select
                    v-else
                    :id="`${id}`"
                    :value="value"
                    :source="source"
                    :stickyItems="stickyItems"
                    :multiple="multiple"
                    :searchable="searchable"
                    :useLocalSearch="useLocalSearch"
                    :disabled="isDisabled"
                    :showEmptyOption="showEmptyOption"
                    :placeholder="placeholder"
                    :optionsToFilter="optionsToFilter"
                    :showSelectedItemsValueOnDisplay="showSelectedItemsValueOnDisplay"
                    :showDescriptionAfterSelect="showDescriptionAfterSelect"
                    :optionDisplayType="optionDisplayType"
                    :jsonPath="jsonPathValue"
                    :class="getErrorMessage(errors[0]) ? 'error' : ''"
                    @input="$emit('input', $event)"
                    @change="$emit('change', $event)"
                />
                <span class="base-input-icons">
                    <slot name="icon"></slot>
                    <protected-edit-icon :icon="protectedEditIconString" :showProtectedEdit="showProtectedEdit" :allowProtectedEdit="allowProtectedEdit" @click="turnOffProtectedDisabled" />
                    <fg-defaultable
                        :currentValue="value"
                        :defaultValue="defaultValue"
                        :isDefaulted="isDefaulted"
                        @change="onDefaultValueChange"
                        @defaultChange="onDefaultChange"
                        :disabled="!showDefaultFieldEdit"
                    />
                    <custom-icon icon="Error" :id="`${id}-error`" v-if="getErrorMessage(errors[0])" :title="getErrorMessage(errors[0])" />
                    <audit-check-icon :id="`${id}-audit-check`" :auditChecks="auditChecks" />
                </span>
            </div>
        </validation-provider>
    </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, PropType } from 'vue'
import { useFormGroupBase, baseProps } from './form-group-base-composable'
import { MultiSelectItem } from './multi-select-item'
import { ReferenceSource } from '../models'
import { DropdownOptionDisplayType } from './dropdown-option-display'

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'input', value: boolean): void 
    (e: 'change', event: any): void
}>()

const props = defineProps({
    id: { type: String, required: true },
    // TODO: Add the following type to value, and fix all vue warnings it causes. These warnings are caused by initialization issues.
    // "type: [Array, String] as PropType<Array<string>|string>",
    value: { required: true }, 
    label: { type: String },
    helpText: { type: String },
    size: { type: String as PropType<string|null>, default: 's' },
    referenceType: { type: String, default: '' },
    referenceSource: { type: String as PropType<ReferenceSource>, default: ReferenceSource.loan },
    source: { type: [Array, Function] as PropType<Array<MultiSelectItem> | ((searchTerm?: string) => Promise<Array<MultiSelectItem>>)>, default: null },
    stickyItems: { type: Array as PropType<Array<MultiSelectItem>>, default: () => [] },
    optionsToFilter: { type: Array as PropType<Array<string>>, default: () => [] },
    showEmptyOption: { type: Boolean, default: true },
    multiple: { type: Boolean },
    searchable: { type: Boolean },
    useLocalSearch: { type: Boolean },
    showSelectedItemsValueOnDisplay: { type: Boolean },
    showDescriptionAfterSelect: { type: Boolean },
    placeholder: { type: String, default: '' },
    optionDisplayType: { type: String as PropType<DropdownOptionDisplayType>, default: DropdownOptionDisplayType.Text },
    ...baseProps
})
//#endregion

//#region INITIALIZE
const { jsonPathLabel, jsonPathValue, protectedEditIconString, showProtectedEdit, allowProtectedEdit, auditChecks, isDisabled, defaultValue, isDefaulted, showDefaultFieldEdit,
    onDefaultChange, onDefaultValueChange, turnOffProtectedDisabled, getErrorMessage } = useFormGroupBase(props, emit);
//#endregion
</script>

<style lang="scss">
.base-input-container {
    span:first-of-type {
        flex-grow: 1;
    }
}
</style>