import { AuditInfo, Notary } from '../index'

export interface Contact {
    id: string | null
    clientCode: string
    investorCode: string
    types: ContactType[]
    displayName: string
    name: string
    title: string
    firstName: string
    lastName: string
    phone: string
    phoneExt: string
    fax: string
    email: string
    attn: string
    contactName: string
    hours: string
    licenseId: string
    stateLicenseId: string
    taxId: string
    address: ContactAddress
    insurableInterest: string
    warehouseParticipation: number
    //Note: This is only for UI handling, this property is not part of the DB model
    useContactForClientInvestor: boolean
    signerName: string
    signerTitle: string
    secondSignerName: string
    secondSignerTitle: string
    notary: Notary
    entity: string
    entityOtherDescription: string
    stateOfIncorporation: string
    allongeEndorseTo: string
    participantId: string
    sorMailboxId: string
    isInvestorContact: boolean
    recordAndReturnTo: RecordAndReturnTo | null
    fileNumber: string
    hasWireDisbursements: boolean
    pointOfContact: PointOfContact
    audit: AuditInfo
    notes: string
}

export interface ContactAddress {
    street: string
    unit: string
    city: string
    county: string
    state: string
    zip: string
}

export interface ContactSummary {
    id: string | null
    name: string
    firstName: string
    lastName: string
    displayName: string
    email: string
    types: ContactType[]
    street: string
    city: string
    state: string
}

export interface RecordAndReturnTo {
    name: string
    phone: string
    phoneExt: string
    attn: string
    address: ContactAddress
}

export interface PointOfContact {
    firstName: string
    lastName: string
    phone: string
    licenseId: string
    stateLicenseId: string
    email: string
}

export enum ContactType {
    MersSigner = 'MersSigner',
    Branch = 'Branch',
    Broker = 'Broker',
    Builder = 'Builder',
    Closer = 'Closer',
    ClosingInstructions = 'ClosingInstructions',
    DocumentsReturn = 'DocumentsReturn',
    DtMortgageReturn = 'DtMortgageReturn',
    FirstPaymentLetter = 'FirstPaymentLetter',
    Funder = 'Funder',
    LoanOfficer = 'LoanOfficer',
    Investor = 'Investor',
    MortgageeClause = 'MortgageeClause',
    NotePay = 'NotePay',
    NoticeOfRightToCancel = 'NoticeOfRightToCancel',
    Servicer = 'Servicer',
    Ssa89 = 'Ssa89',
    TaxServiceProvider = 'TaxServiceProvider',
    Trustee = 'Trustee',
    Beneficiary = 'Beneficiary',
    PackageNotification = 'PackageNotification',
    WarehouseBank = 'WarehouseBank',
    Assignor = 'Assignor',
    Assignee = 'Assignee',
    TrustAccount = 'TrustAccount',
    ServicerPayment = 'ServicerPayment',
    Processor = 'Processor',
    TaxFormRequester = 'TaxFormRequester',
    TitleCompany = 'TitleCompany',
    SettlementCompany = 'SettlementCompany',
    Contractor = 'Contractor'
}