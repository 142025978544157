<template>
    <div
        :id="`${id}-container`"
        v-bind:class="{ toggled: value, 'toggle-icon': hasIcons }"
        :title="title"
        v-b-tooltip.html.hover
        class="toggle-container"
        @click.prevent="clickToggle()"
    >
        <b-link v-if="hasIcons" :disabled="disabled" class="icon">
            <i :class="`bi-${onIcon}`" v-if="value" />
            <i :class="`bi-${offIcon}`" v-if="!value" />
        </b-link>
        <toggle-button
            :id="id"
            class="toggle-button"
            :value="value"
            :sync="true"
            :labels="label"
            :disabled="disabled"
            :color="backgroundColor ? { checked: backgroundColor, unchecked: backgroundColor } : { checked: '#2d59ab', unchecked: '#78909c' }"
            :width="45"
            :height="21"
            :font-size="fontSize"
        />
        <b-tooltip :target="`${id}-container`" custom-class="tooltip-md" triggers="hover" :delay="tooltipDelay">
            <slot name="tooltip"></slot>
        </b-tooltip>
    </div>
</template>

<script setup lang="ts">
import { computed, defineProps, defineEmits } from 'vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e: 'onToggle')
}>()

const props = defineProps({
    value: { type: Boolean, required: true },
    id: { type: String, required: true },
    title: { type: String },
    onIcon: { type: String },
    offIcon: { type: String },
    onLabel: { type: String },
    offLabel: { type: String },
    backgroundColor: { type: String },
    disabled: { type: Boolean },
    tooltipDelay: { type: Number, default: 750 },
    fontSize: { type: Number },
})
//#endregion

//#region COMPUTED
const hasIcons = computed(() => props.onIcon || props.offIcon)
const label = computed(() => {
    if (props.onLabel && props.offLabel) {
        return { checked: props.onLabel, unchecked: props.offLabel }
    }

    return false
})
//#endregion

async function clickToggle() {
    if (!props.disabled) {
        emit('onToggle')
    }
}
</script>

<style lang="scss" scoped>
.toggle-container {
    position: relative;
    line-height: 0;

    &.toggle-icon {
        a.disabled {
            pointer-events: none;
        }

        &.toggled {
            .icon {
                left: 0.4rem;
            }
        }
        .icon {
            position: absolute;
            z-index: 2;
            left: 1.4rem;
            padding-top: 0.2rem;

            i {
                font-size: 0.875rem;
                color: $white;
            }
        }
    }
}
</style>