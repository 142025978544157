<template>
    <div class="view-section" :class="{ expanded: isExpanded, 'has-header': title || $slots.title }">
        <div class="view-section-header" v-if="title || $slots.title">
            <slot name="title">
                <div class="title" @click.prevent="isExpanded = !isExpanded">
                    <custom-icon :icon="isExpanded ? 'SectionOpen' : 'SectionClosed'" />
                    {{ title }}
                    <custom-icon :icon="auditCheckIcon" v-show="!isExpanded && displayAuditCheckErrors && auditCheckResults.length" />
                </div>
            </slot>
            <div class="sub-title" v-if="subTitle">{{ subTitle }}</div>
        </div>
        <div :id="`${id}-viewSectionBody`" class="view-section-body" v-show="isExpanded" ref="viewSectionBody">
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import { AuditCheckResult } from '@/propel/models'
import { userPermissionValidatorService } from '@/common/services'
import { cloneDeep } from 'lodash'
import { defineProps, ref, watch, onMounted, computed } from 'vue'
import { useDisplayAuditCheckErrors } from '@/common/composables/audit-check-composable'
import { jsonPathTestingService } from '@/propel/services'
import { useAuditCheckStore } from '@/common/store'

//#region DEFINE VARIABLES
const store = useAuditCheckStore()
const props = defineProps({
    id: { type: String, default: '' },
    title: { type: String },
    subTitle: { type: String },
    expandedAtStart: { type: Boolean, default: true },
    readOnly: { type: Boolean }
})

const auditCheckKeys = ref([] as string[])
const auditCheckResults = ref([] as AuditCheckResult[])
const isExpanded = ref(false)
const auditCheckIcon = ref('')
const mounted = ref(false)
const viewSectionBody = ref()
//#endregion

const loanAuditChecks = computed(() => store.loanAuditChecks)

//#region WATCH
watch(() => loanAuditChecks.value, () => 
{ 
    if (mounted.value) {
        setAuditChecks()
    }
})
//#endregion

//#region INITIALIZE
const { displayAuditCheckErrors } = useDisplayAuditCheckErrors()

initialize()

onMounted(() => {
    if (props.readOnly) {
        userPermissionValidatorService.disableControlsByIds([`${props.id}-viewSectionBody`])
    }

    setAuditCheckKeys()
    setAuditChecks()

    mounted.value = true
})

function initialize() {
    isExpanded.value = props.expandedAtStart
}
//#endregion

function setAuditCheckKeys() {
    auditCheckKeys.value = jsonPathTestingService.buildJsonPathsFromElement(viewSectionBody.value)
}

function setAuditChecks() {
    if (!auditCheckKeys.value) return

    const auditCheckResultsForBuilding: AuditCheckResult[] = []

    for (const wildCardKey of auditCheckKeys.value) {
        const matchingKeys = Object.keys(loanAuditChecks.value).filter((x) => x.startsWith(wildCardKey))
        if (matchingKeys.length > 0) {
            for (const key of matchingKeys) {
                if (loanAuditChecks.value[key]) {
                    const storeAuditChecksForKey = cloneDeep(loanAuditChecks.value[key])
                    auditCheckResultsForBuilding.push(...storeAuditChecksForKey)
                }
            }
        }
    }

    auditCheckResults.value = [...auditCheckResultsForBuilding]

    if (auditCheckResultsForBuilding.length) {
        auditCheckIcon.value = auditCheckResultsForBuilding.some((x) => x.level === 'Error')
            ? 'Error'
            : auditCheckResultsForBuilding.some((x) => x.level === 'Warning')
            ? 'Warning'
            : 'Success'
    }
}
</script>

<style scoped lang="scss">
.view-section {
    &:first-child {
        padding-top: 0.1rem;
    }

    &.expanded {
        .view-section-header {
            padding-bottom: 0;

            .sub-title {
                display: none;
            }
        }
    }

    .view-section-header {
        display: flex;
        margin-top: 0.75rem;
        margin-bottom: 0.65rem;
        font-weight: bold;
        font-size: 1rem;
        color: $very-dark-gray;

        .title {
            width: 20rem;
            min-width: 20rem;

            .custom-icon {
                &.section-closed,
                &.section-open {
                    color: $dark-gray;
                    margin-left: -0.25rem;
                }
            }
        }
    }

    .view-section-body {
        margin-bottom: 1.5rem;
    }

    &:not(.has-header) {
        .view-section-body {
            :deep > div:first-child > h1:first-child, // handles components with an extra div wrapper, like Lender -> Closing Conditions
        > h1:first-child {
                margin-top: 0;
            }
        }
    }
}

.title {
    cursor: pointer;
}

.sub-title {
    color: $dark-gray;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 0.125rem;
}
</style>
