export const regCheckPartnerRoutes = [
    {
        path: 'regcheck-test-suites',
        name: 'partner-regcheck-test-suites',
        component: () => import('@/regcheck/views/partners/regcheck-test-suites.vue'),
        meta: {
            title: 'Test Suites',
            itemInfo: true,
            getter: 'partner',
            readPermission: 'admindata.partners.settings:read',
            writePermission: 'admindata.partners.settings.regcheck:write'
        } 
    }
]

export const regCheckClientRoutes = [
    {
        path: 'regcheck-lender-information',
        name: 'regcheck-lender-information',
        component: () => import('@/regcheck/views/clients/regcheck-lender-profile.vue'),
        meta: {
            title: 'Lender Information',
            itemInfo: true,
            getter: 'client',
            readPermission: 'admindata.clients.profile',
            writePermission: 'admindata.clients.profile:write'
        }
    },
    {
        path: 'regcheck-data-interface',
        name: 'regcheck-lender-data-interface',
        component: () => import('@/regcheck/views/clients/regcheck-data-interface.vue'),
        meta: {
            title: 'Data Interface',
            itemInfo: true,
            getter: 'client',
            readPermission: 'admindata.clients.settings.regcheck',
            writePermission: 'admindata.clients.settings.regcheck:write'
        }
    },
    {
        path: 'regcheck-test-suites',
        name: 'lender-regcheck-test-suites',
        component: () => import('@/regcheck/views/clients/regcheck-test-suites.vue'),
        meta: {
            title: 'Test Suites',
            itemInfo: true,
            getter: 'client',
            readPermission: 'admindata.clients.settings.regcheck',
            writePermission: 'admindata.clients.settings.regcheck:write'
        }
    },
    {
        path: 'regcheck-other-options',
        name: 'lender-regcheck-other-options',
        component: () => import('@/regcheck/views/clients/regcheck-other-options.vue'),
        meta: {
            title: 'Other Options',
            itemInfo: true,
            getter: 'client',
            readPermission: 'admindata.clients.settings.regcheck',
            writePermission: 'admindata.clients.settings.regcheck:write'
        }
    },
    {
        path: 'regcheck-print-options',
        name: 'lender-regcheck-print-options',
        component: () => import('@/regcheck/views/clients/regcheck-print-options.vue'),
        meta: {
            title: 'Print Settings',
            itemInfo: true,
            getter: 'client',
            readPermission: 'admindata.clients.settings.regcheck',
            writePermission: 'admindata.clients.settings.regcheck:write'
        }
    },
    {
        path: 'regcheck-lender-calculations',
        name: 'regcheck-lender-calculations',
        component: () => import('@/regcheck/views/clients/regcheck-client-calculations.vue'),
        meta: {
            title: 'Calculations',
            itemInfo: true,
            getter: 'client',
            readPermission: 'admindata.clients.settings.regcheck',
            writePermission: 'admindata.clients.settings.regcheck:write'
        }
    },
    {
        path: 'regcheck-lender-cocs',
        name: 'lender-regcheck-cocs',
        component: () => import('@/regcheck/views/clients/regcheck-coc-types.vue'),
        meta: {
            title: 'COC Configurations',
            itemInfo: true,
            getter: 'client',
            readPermission: 'admindata.clients.settings.regcheck',
            writePermission: 'admindata.clients.settings.regcheck:write'
        }
    },
    {
        path: 'regcheck-contacts',
        name: 'regcheck-lender-contacts',
        component: () => import('@/regcheck/views/clients/regcheck-contacts.vue'),
        meta: {
            title: 'Originator Contact Library', // named this to sync up with side menu
            itemInfo: true,
            getter: 'client',
            readPermission: 'admindata.clients.settings.contacts',
            writePermission: 'admindata.clients.settings.contacts:write'
        }             
    },
    {
        path: 'regcheck-contacts/:id',
        name: 'regcheck-lender-contact',
        component: () => import('@/regcheck/views/clients/regcheck-contact.vue'),
        //has no meta title data so that "Originator Contact Library" remains shown when a contact is selected
        meta: {
            readPermission: 'admindata.clients.settings.contacts',
            writePermission: 'admindata.clients.settings.contacts:write'
        }
    },
    {
        path: 'business-day-settings',
        name: 'lender-business-day-settings',
        component: () => import('@/regcheck/views/clients/business-day-settings.vue'),
        meta: {
            title: 'General Business Days',
            itemInfo: true,
            getter: 'client',
            readPermission: 'admindata.clients.settings.regcheck',
            writePermission: 'admindata.clients.settings.regcheck:write'
        }
    },
    {
        path: 'regcheck-document-security',
        name: 'regcheck-document-security',
        component: () => import('@/regcheck/views/clients/regcheck-document-security.vue'),
        meta: {
            title: 'Document Security',
            itemInfo: true,
            getter: 'client',
            readPermission: 'admindata.clients.config',
            writePermission: 'admindata.clients.config:write'
        }
    }
]
