import { Overridable } from "./index";

export interface CashToClose {
    disclosureType: ClosingDisclosureType
    totalClosingCosts: OverrideableCashToCloseItem
    paidBeforeClosing: OverrideableCashToCloseItem
    financed: OverrideableCashToCloseItem
    adjustmentsAndOtherCredits: CashToCloseItem
    deposit: CashToCloseItem
    downPayment: OverrideableCashToCloseItem
    fundsForBorrower: CashToCloseItem
    sellerCredits: CashToCloseItem
    total: CashToCloseTotal

    loanAmount: CashToCloseItem
    totalPayoffsAndPayments: CashToCloseItem
    
}

export enum ClosingDisclosureType {
    Standard="Standard",
    Alternate="Alternate"
}

export interface CashToCloseItem {
    loanEstimate: number | null
    final: number | null
    hasChanged: boolean
    changeDescription: string
}

export interface OverrideableCashToCloseItem {
    loanEstimate: Overridable<number>
    final: number
    hasChanged: boolean
    changeDescription: string
}

export interface CashToCloseTotal {
    loanEstimate: number 
    final: number
}

export enum CashToCloseItemType {
    AdjustmentsAndOtherCredits="AdjustmentsAndOtherCredits",
    CashToCloseTotal="CashToCloseTotal",
    ClosingCostsFinanced="ClosingCostsFinanced",
    ClosingCostsPaidBeforeClosing="ClosingCostsPaidBeforeClosing",
    Deposit="Deposit",
    DownPayment="DownPayment",
    FundsForBorrower="FundsForBorrower",
    FundsFromBorrower="FundsFromBorrower",
    LenderCredits="LenderCredits",
    LoanAmount="LoanAmount",
    LoanCostsAndOtherCostsTotal="LoanCostsAndOtherCostsTotal",
    SellerCredits="SellerCredits",
    TotalClosingCosts="TotalClosingCosts",
    TotalPayoffsAndPayments="TotalPayoffsAndPayments"
}

export enum CashToCloseItemPaymentType {
    FromBorrower="FromBorrower",
    ToBorrower="ToBorrower"
}

