<template>
    <div :id="id" class="content-block" :class="size" v-click-outside="clickOutside" @mousedown="onMouseDown()" @mouseup="onMouseUp()">
        <div class="content-area" v-show="hasCustomEdit || !itemIsInEditMode" :class="{ disabled: disabled }">
            <div v-if="!!slots.secondaryActions" class="action-items secondary">
                <slot name="secondaryActions"></slot>
            </div>
            <div class="content" @click="edit()">
                <slot name="view"></slot>
            </div>
            <div class="action-items primary" v-if="(!!slots.primaryActions || showEditHint) && showActionItems">
                <slot name="primaryActions"></slot>
                <custom-icon icon="Edit" v-if="showEditHint" title="Edit Item" @click="edit()" />
            </div>
        </div>
        <div class="edit-area" :class="alwaysOpen && 'always-open'" v-if="!hasCustomEdit && itemIsInEditMode">
            <div class="edit-slot">
                <slot name="edit"></slot>
            </div>
            <div class="button-row" v-if="showButtonRow">
                <custom-button @click="closeEditMode()" label="Close" v-if="showCloseButton" />
                <custom-button @click="closeEditMode()" icon="Save" title="Save changes" v-if="showSaveButton" :disabled="!itemCanSave(item)" />
                <custom-button @click="cancelEditMode()" icon="Close" title="Cancel changes" v-if="showCancelButton" />
                <slot name="editButtons" v-bind:closeEditMode="closeEditMode"></slot>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineEmits, defineProps, PropType, ref, computed, useSlots, useListeners } from 'vue'
import { ContentBlockEditModeEnum } from '@/common/models'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e: 'editItem', item: any, index: number | undefined): void
    (e: 'openEdit', item: any, index: number | undefined): void
    (e: 'closeEdit', item: any, index: number | undefined): void
    (e: 'cancelEdit', item: any, index: number | undefined): void
    (e: 'mousedown'): void
    (e: 'mouseup'): void
}>()
const props = defineProps({
    id: { type: String, required: true },
    size: { type: String, default: 'l' },
    closeText: { type: String, default: 'Close' },
    readOnly: { type: Boolean, default: false },
    showEditHint: { type: Boolean, default: false },
    editMode: { type: Number as PropType<ContentBlockEditModeEnum>, default: ContentBlockEditModeEnum.AutoClose },
    index: { type: Number },
    item: { type: [] as PropType<any> },
    managedEdit: { type: Boolean, default: undefined },
    itemCanSave: { type: Function as PropType<(item: any) => boolean>, default: () => true }
})

const slots = useSlots()
const listeners = useListeners() //obsolete in vue 3
const isBeingEdited = ref(false)

const blockTriggeredMouseDown = ref(false)

//#endregion

//#region COMPUTED
const hasCustomEdit = computed<boolean>(() => {
    return !!listeners.editItem
})

const alwaysOpen = computed<boolean>(() => {
    return props.editMode === ContentBlockEditModeEnum.AlwaysOpen
})

const showActionItems = computed(() => {
    return !disabled.value && !props.readOnly
})

const disabled = computed(() => {
    return props.editMode === ContentBlockEditModeEnum.ReadOnly
})

const useManagedEdit = computed(() => {
    return props.managedEdit !== undefined
})

const showButtonRow = computed<boolean>(() => {
    return showCloseButton.value || showSaveButton.value || showCancelButton.value || !!slots.editButtons
})

const showCloseButton = computed<boolean>(() => {
    return props.editMode === ContentBlockEditModeEnum.ManualClose
})

const showSaveButton = computed<boolean>(() => {
    return props.editMode === ContentBlockEditModeEnum.SaveCancel
})

const showCancelButton = computed<boolean>(() => {
    return props.editMode === ContentBlockEditModeEnum.SaveCancel
})

const itemIsInEditMode = computed(() => {
    return alwaysOpen.value || isBeingEdited.value || props.managedEdit
})
//#endregion

// TODO: content-block-list's functionality for cloning and editing changes
// should be moved here
function edit() {
    if (disabled.value) return
    if (hasCustomEdit.value) {
        emit('editItem', props.item, props.index)
    } else if (slots.edit && !useManagedEdit.value) {
        isBeingEdited.value = true
    }
    emit('openEdit', props.item, props.index)
}

function closeEditMode() {
    if (useManagedEdit.value) {
        emit('closeEdit', props.item, props.index)
    }
    isBeingEdited.value = false
}

function cancelEditMode() {
    if (useManagedEdit.value) {
        emit('cancelEdit', props.item, props.index)
    }
    isBeingEdited.value = false
}

function clickOutside() {
    if (useManagedEdit.value || hasCustomEdit.value || !isBeingEdited.value || props.editMode === ContentBlockEditModeEnum.ManualClose) return
    if (blockTriggeredMouseDown.value) {
        blockTriggeredMouseDown.value = false
        return
    }
    isBeingEdited.value = false
}

function onMouseDown() {
    emit('mousedown')
    blockTriggeredMouseDown.value = true
}

function onMouseUp() {
    emit('mouseup')
    blockTriggeredMouseDown.value = false
}
</script>

<style lang="scss" scoped>
$content-breakpoint-s: 500px;
$content-breakpoint-m: 1100px;

.content-block {
    margin-bottom: 0.5rem;

    &.s {
        width: 25rem;

        @media only screen and (max-width: $content-breakpoint-s) {
            width: auto;
        }
    }

    &.m {
        width: 50rem;

        @media only screen and (max-width: $content-breakpoint-m) {
            width: auto;
        }
    }

    &.l {
        width: 100%;
    }

    .content-area {
        display: flex;
        align-items: center;
        min-height: 3rem;
        background: $white;
        border: 1px solid $light-gray;
        padding: 0.625rem 0.375rem;

        &:hover {
            background: $highlight-color;
            cursor: pointer;
        }

        > div {
            margin: 0 0.375rem;
        }

        .handle {
            cursor: move;
        }

        .content {
            flex-grow: 1;
            align-self: stretch; // Ensures there is a clickable content area even if there is no content

            display: flex;
            flex-wrap: wrap;
            align-items: center;

            :deep(.standard-columns) {
                // the <div> inside a <template v-slot> should have class="standard-columns" to use these styles
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 0.5rem 2rem;

                > div {
                    margin: 0.375rem 0;

                    &:not(.wrap) {
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }

                    &:first-of-type {
                        font-weight: bold;
                    }

                    .subtitle {
                        font-weight: normal;
                        font-style: italic;
                        padding-right: 0.125rem; // italics causes far-right edge of text to cut off

                        &.wrap {
                            overflow-wrap:all;
                        }
                    }

                    h2 {
                        margin: 0 0 0.125rem 0;
                        font-size: 0.625rem;
                    }

                    .ellipsis1 {
                        white-space: pre-wrap;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        line-break:anywhere;
                    }

                    .ellipsis4 {
                        white-space: pre-wrap;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        line-break:anywhere;
                    }

                    .ellipsis6 {
                        white-space: pre-wrap;
                        display: -webkit-box;
                        -webkit-line-clamp: 6;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        line-break:anywhere;
                    }
                }
            }
        }

        .action-items {
            display: flex;
            gap: 0.25rem;

            &.secondary {
                .custom-icon {
                    color: $dark-gray;

                    &.clickable:hover {
                        color: $link-hover-color;
                    }
                }
            }

            button {
                margin-right: 0.125rem;

                .b-icon {
                    margin-left: 0;
                }
            }

            > .b-icon {
                &:hover {
                    color: $bright-blue;
                }

                &.bi-x {
                    padding: 0.15rem;
                    height: 1.75rem;
                    width: 1.75rem;
                    margin-top: -0.5rem;
                    margin-bottom: -0.5rem;

                    &:hover {
                        color: $red;
                    }
                }
            }
        }

        &.disabled {
            background-color: $light-gray;
            border-color: $medium-gray;
            cursor: default;
        }
    }

    .edit-area {
        border: 1px solid $medium-gray;
        padding: 0.75rem;

        :deep(.edit-slot .field-section:last-of-type > .form-group:last-child) {
            margin-bottom: 0;
        }

        .header-label {
            font-weight: bold;
            margin-bottom: 1rem;
        }

        .button-row {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            margin-top: 1rem;
            gap: 0.5rem;
        }

        &.always-open {
            border: none;
            padding: 0;
            margin: 0;

            .button-row {
                margin-top: 0;
            }
        }
    }
}
</style>
