import Vue from 'vue'
import App from './app.vue'
import router from '@/common/router/router'
import store from '@/common/store/store'
import LeftNav from '@/common/components/navigation/left-nav.vue'
import LoadingOverlay from '@/common/components/loading-overlay.vue'
import SummarySidebar from '@/common/components/summary-sidebar.vue'
import SummarySidebarAuditInfo from '@/common/components/summary-sidebar-audit-info.vue'
import AdminSummarySidebar from '@/common/components/admin-summary-sidebar.vue'
import FileDrop from '@/common/components/file-drop.vue'
import DatePicker from '@/common/components/date-picker.vue'
import DisplayAddress from '@/common/components/display-address.vue'
import FormGroupAddress from '@/common/components/form-group-address.vue'
import ViewSection from '@/common/components/view-section.vue'
import InputText from '@/common/components/input-text.vue'
import SearchInput from '@/common/components/search-input.vue'
import InputSelect from '@/common/components/input-select.vue'
import ConfirmationModal from '@/common/components/modals/confirmation-modal.vue'
import DeleteConfirmationModal from '@/common/components/modals/delete-confirmation-modal.vue'
import PdfPreviewModal from '@/common/components/modals/pdf-preview-modal.vue'
import InputSelectYesNo from '@/common/components/input-select-yes-no.vue'
import CheckboxSelectList from '@/common/components/checkbox-select-list.vue'
import FormGroupInputText from '@/common/components/form-group-text-input.vue'
import FormGroupMultipleInputText from '@/common/components/form-group-text-input-multiple.vue'
import FormGroupTextarea from '@/common/components/form-group-textarea.vue'
import FormGroupCheckbox from '@/common/components/form-group-checkbox.vue'
import FormGroupDatePicker from '@/common/components/form-group-date-picker.vue'
import FormGroupInputSelect from '@/common/components/form-group-input-select.vue'
import FormGroupInputSelectOverridable from '@/common/components/form-group-input-select-overridable.vue'
import InputRadioOverridable from '@/common/components/input-radio-overridable.vue'
import FormGroupLabel from '@/common/components/form-group-label.vue'
import FormGroupRadioButton from '@/common/components/form-group-radio-button.vue'
import ReferenceSelectOverridable from '@/common/components/reference-select-overridable.vue'
import FormGroupTextInputOverridable from '@/common/components/form-group-text-input-overridable.vue'
import FormGroupTextareaOverridable from '@/common/components/form-group-textarea-overridable.vue'
import FormGroupDatePickerOverridable from '@/common/components/form-group-date-picker-overridable.vue'
import CheckboxOverridable from '@/common/components/checkbox-overridable.vue'
import InputPatternMask from '@/common/components/input-pattern-mask.vue'
import FormGroupInputPatternMask from '@/common/components/form-group-input-pattern-mask.vue'
import FormGroupDefault from '@/common/components/form-group-default.vue'
import BasePage from '@/common/views/base-page.vue'
import AdminPage from '@/common/views/admin/admin-page.vue'
import AdminGrid from '@/common/views/admin/admin-grid.vue'
import SearchableTable from '@/common/components/searchable-table.vue'
import DocumentsPage from '@/propel/views/setup/documents-page.vue'
import LoanPage from '@/propel/views/loans/loan-page.vue'
import CustomIcon from '@/common/components/custom-icon.vue'
import FieldValue from '@/propel/components/field-value.vue'
import ProtectedEditIcon from '@/common/components/icons/protected-edit-icon.vue'
import AuditCheckIcon from '@/common/components/icons/audit-check-icon.vue'
import SystemTypeValuesList from '@/common/components/system-types-values-list.vue'
import RuleCriteriaBlockList from '@/propel/components/rule-criteria-block-list.vue'
import ContentBlockList from '@/common/components/content-block-list.vue'
import ContentBlock from '@/common/components/content-block.vue'
import RuleCriteriaEditor from '@/propel/components/rule-criteria-editor.vue'
import RuleConditionContentBlockColumn from '@/propel/components/rule-condition-content-block-column.vue'
import FormRuleEditor from '@/common/components/form-rule-editor.vue'
import AuditCheckManager from '@/propel/components/audit-check-manager.vue'
import DefaultValueManager from '@/propel/components/default-value-manager.vue'
import FieldValueRuleManager from '@/propel/components/field-value-rule-manager.vue'
import FormRuleBlockList from '@/common/components/form-rule-block-list.vue'
import TestContainer from '@/propel/components/test-container.vue'
import TestDocumentContainer from '@/propel/components/test-document-container.vue'
import ItemListEntry from '@/common/components/item-list-entry.vue'
import CustomToggleButton from '@/common/components/custom-toggle-button.vue'
import PermissionTree from '@/common/views/admin/users/permission-tree.vue'
import PermissionNode from '@/common/views/admin/users/permission-node.vue'
import CustomButton from '@/common/components/custom-button.vue'
import Blurb from '@/common/components/blurb.vue'
import FormGroupInputSelectInlineLabel from '@/common/components/form-group-input-select-inline-label.vue'
import FormGroupPhoneAndExtension from '@/common/components/form-group-phone-and-extension.vue'
import RegCheckLogo from '@/common/components/regcheck-logo.vue'
import RegCheckLoanPage from '@/regcheck/views/loans/regcheck-loan-page.vue'

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import LiquorTree from 'liquor-tree'
import ToggleButton from 'vue-js-toggle-button'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'
import draggable from 'vuedraggable'
import VueAutosuggest from "vue-autosuggest"
import { createPinia, PiniaVuePlugin } from 'pinia'

import { messageService, userService, sessionService, backendService } from '@/common/services'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/js/dist/dropdown'
import { ClickOutside } from '@/common/directives/click-outside-container-event'
import Badge from '@/common/components/badge.vue'
import Helocs from '@/common/views/admin/clients/helocs/helocs.vue'
import MaintenanceFeeAutoCompleteInput from '@/common/components/maintenance-fee-auto-complete-input.vue'
import BaseOverridable from './common/components/base-overridable.vue'
import VueRouter from 'vue-router'

Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule] // assign message
    })
})
Vue.config.errorHandler = async (err, vm, info) => {
    // err: error trace
    // vm: component in which error occured
    // info: Vue specific error information such as lifecycle hooks, events etc.    
    console.error(err)
    console.info(info)
    console.info(vm)
    // TODO: Perform any custom logic or log to server
    if (err != null) {
        const user = await userService.getCurrentUserProfile()
        const correlationId = await backendService.logClientErrors(err, info, user.name) || undefined
        messageService.showSystemError("Propel encountered an unexpected error: please contact support for assistance.", 'Application Error', err.stack, correlationId)
    } else {
        messageService.showSystemError("Propel encountered an unexpected error: please contact support for assistance.", 'Application Error')
    }
}

Vue.config.productionTip = false

Vue.component('left-nav', LeftNav)
Vue.component('loading-overlay', LoadingOverlay)
Vue.component('summary-sidebar', SummarySidebar)
Vue.component('summary-sidebar-audit-info', SummarySidebarAuditInfo)
Vue.component('admin-summary-sidebar', AdminSummarySidebar)
Vue.component('file-drop', FileDrop)
Vue.component('date-picker', DatePicker)
Vue.component('display-address', DisplayAddress)
Vue.component('fg-address', FormGroupAddress)
Vue.component('view-section', ViewSection)
Vue.component('input-text', InputText)
Vue.component('search-input', SearchInput)
Vue.component('delete-confirmation-modal', DeleteConfirmationModal)
Vue.component('confirmation-modal', ConfirmationModal)
Vue.component('pdf-preview-modal', PdfPreviewModal)
Vue.component('input-select', InputSelect)
Vue.component('input-select-yes-no', InputSelectYesNo)
Vue.component('checkbox-select-list', CheckboxSelectList)
Vue.component('badge', Badge)
Vue.component('fg-text', FormGroupInputText)
Vue.component('fg-text-multiple', FormGroupMultipleInputText)
Vue.component('fg-checkbox', FormGroupCheckbox)
Vue.component('fg-date', FormGroupDatePicker)
Vue.component('fg-label', FormGroupLabel)
Vue.component('fg-textarea', FormGroupTextarea)
Vue.component('fg-select', FormGroupInputSelect)
Vue.component('fg-select-overridable', FormGroupInputSelectOverridable)
Vue.component('fg-select-inline-label', FormGroupInputSelectInlineLabel)
Vue.component('fg-radio-button', FormGroupRadioButton)
Vue.component('base-overridable', BaseOverridable)
Vue.component('reference-select-overridable', ReferenceSelectOverridable)
Vue.component('fg-textarea-overridable', FormGroupTextareaOverridable)
Vue.component('fg-text-overridable', FormGroupTextInputOverridable)
Vue.component('fg-dp-overridable', FormGroupDatePickerOverridable)
Vue.component('checkbox-overridable', CheckboxOverridable)
Vue.component('input-radio-overridable', InputRadioOverridable)
Vue.component('input-pattern-mask', InputPatternMask)
Vue.component('fg-input-pattern-mask', FormGroupInputPatternMask)
Vue.component('fg-defaultable', FormGroupDefault)
Vue.component('fg-phone-and-extension', FormGroupPhoneAndExtension)
Vue.component('searchable-table', SearchableTable)
Vue.component('base-page', BasePage)
Vue.component('admin-page', AdminPage)
Vue.component('admin-grid', AdminGrid)
Vue.component('documents-page', DocumentsPage)
Vue.component('loan-page', LoanPage)
Vue.component('vue-dropzone', vue2Dropzone)
Vue.component('custom-icon', CustomIcon)
Vue.component('field-value', FieldValue)
Vue.component('protected-edit-icon', ProtectedEditIcon)
Vue.component('audit-check-icon', AuditCheckIcon)
Vue.use(ToggleButton)
Vue.component('validation-observer', ValidationObserver)
Vue.component('validation-provider', ValidationProvider)
Vue.component('system-types-values-list', SystemTypeValuesList)
Vue.component('rule-criteria-block-list', RuleCriteriaBlockList)
Vue.component('content-block-list', ContentBlockList)
Vue.component('content-block', ContentBlock)
Vue.component('rule-criteria-editor', RuleCriteriaEditor)
Vue.component('rule-condition-content-block-column', RuleConditionContentBlockColumn)
Vue.component('form-rule-editor', FormRuleEditor)
Vue.component('draggable', draggable)
Vue.component('audit-check-manager', AuditCheckManager)
Vue.component('default-value-manager', DefaultValueManager)
Vue.component('field-value-rule-manager', FieldValueRuleManager)
Vue.component('form-rule-block-list', FormRuleBlockList)
Vue.component('test-container', TestContainer)
Vue.component('test-document-container', TestDocumentContainer)
Vue.component('item-list-entry', ItemListEntry)
Vue.component('custom-toggle-button', CustomToggleButton)
Vue.component('permission-tree', PermissionTree)
Vue.component('permission-node', PermissionNode)
Vue.component('custom-button', CustomButton)
Vue.component('blurb', Blurb)
Vue.component('helocs', Helocs)
Vue.component('maintenance-fee-auto-complete-input', MaintenanceFeeAutoCompleteInput)
Vue.component('regcheck-logo', RegCheckLogo)
Vue.component('regcheck-loan-page', RegCheckLoanPage)

Vue.use(BootstrapVue, {
    BTooltip: { delay: 750 },
    BModal: { noCloseOnBackdrop: true, noCloseOnEsc: true, scrollable: true },
    BPopover: { delay: { show: 100, hide: 250 } }
})
Vue.use(IconsPlugin)
Vue.use(LiquorTree)
Vue.use(VueAutosuggest)
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.use(VueRouter) // needs to be after pinia due to our use of the store within the router

Vue.directive('click-outside', ClickOutside);

new Vue({
    pinia,
    router,
    store,
    render: h => h(App),
    created() {
        window.addEventListener('beforeunload', this.onUnload)
    },
    methods: {
        onUnload: function handler() {
            sessionService.setLastActiveTime()
        }
    }
}).$mount('#app')
