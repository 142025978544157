<template>
    <validation-observer>
        <div class="testable-area-wrap">
            <b-tabs class="tabs-fixed" v-model="selectedTab">
                <b-tab>
                    <template v-slot:title>Details</template>
                    <div class="field-section">
                        <div class="field-group">
                            <fg-select
                                id="formId"
                                label="Form"
                                size="s"
                                :source="getForms"
                                :searchable="true"
                                :multiple="false"
                                :showEmptyOption="false"
                                v-model="vm.formId"
                                placeholder="Form"
                                :disabled="isForm || readOnly ? true : editingExistingItem"
                                optionDisplayType="SplitWithSecondaryTextBelow"
                                @input="setNameAndDescription"
                            />
                            <fg-text id="name" label="Name" class="required" v-model="vm.name" size="l" validationRules="required" :disabled="readOnly" />
                        </div>

                        <fg-text id="description" label="Description" size="" v-model="vm.description" :disabled="readOnly" />

                        <fg-textarea id="notes" :rows="7" size="" label="Notes" v-model="vm.notes" :disabled="readOnly" />
                    </div>
                    <div class="field-section">
                        <fg-select
                            id="status"
                            label="Status"
                            size="s"
                            v-model="vm.status"
                            referenceType="statuses"
                            referenceSource="metadata"
                            :showEmptyOption="false"
                            :disabled="readOnly"
                        />
                    </div>
                </b-tab>

                <b-tab :active="editingExistingItem">
                    <template v-slot:title>Configuration</template>
                    <!-- todo: there doesnt look like there is a saveFormRule emit on the rule-criteria-editor component -->
                    <!-- See if the save method is ever called -->
                    <rule-criteria-editor
                        v-if="vm.conditions"
                        :conditions="vm.conditions"
                        :formRule="vm"
                        :saveNewRules="true"
                        @saveFormRule="saveFormRule"
                        :readOnly="readOnly"
                        @openItemsChanged="openItemsChanged"
                    >
                        <template v-slot:additional-fields>
                            <fg-checkbox
                                id="isOptional"
                                v-model="vm.isOptional"
                                label="Make this form available as an optional document"
                                :disabled="readOnly"
                            />
                        </template>
                    </rule-criteria-editor>
                </b-tab>

                <b-tab>
                    <template v-slot:title>Legacy Sort Order Rules</template>
                    <p>
                        <blurb variant="info">
                            <template v-slot:shortMessage>These rules override the form's Sort Order Rules</template>
                            <template v-slot:longMessage>
                                If this form selection rule is used to select a loan, the first applicable Sort Order rule on this tab will be used before
                                considering any of the Sort Order Rules set at the form level.
                            </template>
                        </blurb>
                    </p>
                    <placement-rule-list
                        id="sortOrderOverridePlacementRules"
                        :placementRules.sync="vm.placementRules"
                        :currentFormId="vm.formId"
                        :readOnly="readOnly"
                        @itemEditing="openItemsChanged"
                        @openItemsChanged="openItemsChanged"
                    />
                </b-tab>
            </b-tabs>
            <aside v-if="showSidebar && selectedTab !== 2">
                <summary-sidebar>
                    <h2>Rule Testing</h2>
                    <test-container :conditions="vm.conditions" conditions-type="Rule" />
                    <template v-slot:footer v-if="vm && vm.audit">
                        <summary-sidebar-audit-info :audit="vm.audit" />
                    </template>
                </summary-sidebar>
            </aside>
        </div>
    </validation-observer>
</template>

<script setup lang="ts">
import { messageService } from '@/common/services'
import { metadataService } from '@/propel/services'
import { DocumentDetails, DocumentSummary, FormRule, ItemsChangedEventArgs, PlacementRule } from '@/common/models'
import { MultiSelectItem } from '@/common/components/multi-select-item'
import { cloneDeep } from 'lodash'
import PlacementRuleList from '@/propel/views/setup/rules/placement-rules/placement-rule-list.vue'
import { PropType, computed, defineProps, defineEmits, ref, toRef } from 'vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:"openItemsChanged", event: ItemsChangedEventArgs)
}>()

const props = defineProps({
    formRule: { type: Object as PropType<FormRule>, required: true },
    isForm: { type: Boolean},
    showSidebar: { type: Boolean},
    readOnly: { type: Boolean},
})

const vm = toRef(props, "formRule")
const originalFormRule = ref({} as FormRule)
const selectedTab = ref(0)
//#endregion

//#region COMPUTED
const editingExistingItem = computed<boolean>(() => (vm.value.id ? vm.value.id : 0) !== 0)
//#endregion

//#region INITIALIZE
initialize()

function initialize(){
    vm.value.placementRules ??= [] as PlacementRule[]
    originalFormRule.value = cloneDeep(vm.value)
}
// #endregion

async function saveFormRule() {
    await metadataService.postFormRule(vm.value)
    messageService.showSaveSuccess(vm.value.name)
}

function openItemsChanged(e: ItemsChangedEventArgs): void {
    emit('openItemsChanged', e)
}

async function  setNameAndDescription() {
    const formDetails = await getFormDetails()
    vm.value.name = vm.value.formId === originalFormRule.value.formId ? originalFormRule.value.name : formDetails.formId
    vm.value.description = vm.value.formId === originalFormRule.value.formId ? originalFormRule.value.description : formDetails.title
}

async function getForms(searchTerm?: string): Promise<MultiSelectItem[]> {
    const params = new URLSearchParams()
    params.append('searchTerm', searchTerm ? searchTerm : vm.value.formId ? vm.value.formId : '')
    params.append('pageSize', '50')
    const documents = await metadataService.getAllDocuments(params)
    return documents.items.map((r: DocumentSummary) => ({ value: r.formId, text: r.formId, secondaryText: r.title ?? r.formId }))
}

async function getFormDetails(): Promise<DocumentDetails> {
    if (vm.value.formId) {
        return await metadataService.getDocument(vm.value.formId)
    } else {
        return {} as DocumentDetails
    }
}

</script>