import { regCheckLoanMainRoute, regCheckLoanRoutes } from '@/regcheck/router/loans'
import { config } from '@/config'

export default [
    {
        path: '/order-grid',
        name: 'order-grid',
        component: () => import('@/propel/views/loans/orders/order-grid.vue'),
        meta: {
            title: 'Orders',
            readPermission: ['loans:read', 'orders:read']
        }
    },
    {
        path: '/delivery-grid',
        name: 'delivery-grid',
        component: () => import('@/propel/views/loans/delivery/delivery-grid.vue'),
        meta: {
            title: 'Delivery',
            readPermission: ['loans:read']
        }
    },
    {
        path: '/loans',
        component: () => import('@/propel/views/loans/loans-area-layout.vue'),
        children: config.app.regCheckOnly
            ? [
                ...regCheckLoanMainRoute,
                ...regCheckLoanRoutes
            ]
            : [
                {
                    path: '',
                    name: 'loan-grid',
                    component: () => import('@/propel/views/loans/grid.vue'),
                    meta: {
                        title: 'Loans',
                        readPermission: 'loans:read',
                        writePermission: 'loans:write'
                    }
                },
                ...regCheckLoanRoutes,
                {
                    path: '/loans/:id',
                    component: () => import('@/propel/views/loans/loans-layout.vue'),
                    children: [
                        {
                            path: '',
                            name: 'loan-info',
                            component: () => import('@/propel/views/loans/loan-data/index.vue'),
                            meta: {
                                title: 'Loan Data',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'loan-data',
                            name: 'loan-data',
                            component: () => import('@/propel/views/loans/loan-data/loan-data.vue'),
                            meta: {
                                title: 'Loan Data',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'loan-data-legacy-heloc',
                            name: 'loan-data-legacy-heloc',
                            component: () => import('@/propel/views/loans/loan-data/loan-data-legacy-heloc.vue'),
                            meta: {
                                title: 'Loan Data',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'loan-data-standard-heloc',
                            name: 'loan-data-standard-heloc',
                            component: () => import('@/propel/views/loans/loan-data/loan-data-standard-heloc.vue'),
                            meta: {
                                title: 'Loan Data',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'loan-data-mod',
                            name: 'loan-data-mod',
                            component: () => import('@/propel/views/loans/loan-data/loan-data-mod.vue'),
                            meta: {
                                title: 'Loan Data',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'loan-data-assumption',
                            name: 'loan-data-assumption',
                            component: () => import('@/propel/views/loans/loan-data/loan-data-assumption.vue'),
                            meta: {
                                title: 'Loan Data',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'property',
                            name: 'loan-property',
                            component: () => import('@/propel/views/loans/property/property.vue'),
                            meta: {
                                title: `Property`,
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'borrowers',
                            name: 'loan-borrowers',
                            component: () => import('@/propel/views/loans/borrowers/borrowers.vue'),
                            meta: {
                                title: 'Borrowers',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'sellers',
                            name: 'sellers',
                            component: () => import('@/propel/views/loans/sellers/sellers.vue'),
                            meta: {
                                title: 'Sellers',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'trust-info',
                            name: 'trust-info',
                            component: () => import('@/propel/views/loans/trusts/trusts.vue'),
                            meta: {
                                title: 'Trusts',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'fees',
                            name: 'fees',
                            component: () => import('@/propel/views/loans/fees/fees.vue'),
                            meta: {
                                title: 'Fees',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'escrows-property-costs',
                            name: 'escrows-property-costs',
                            component: () => import('@/common/views/loans/escrows/escrows.vue'),
                            meta: {
                                title: 'Escrows/Property Costs',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'investor',
                            name: 'investor',
                            component: () => import('@/propel/views/loans/investor/investor.vue'),
                            meta: {
                                title: 'Investor',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'title-settlement',
                            name: 'title-settlement',
                            component: () => import('@/propel/views/loans/title-settlement/title-settlement.vue'),
                            meta: {
                                title: 'Title & Settlement',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'lender',
                            name: 'lender',
                            component: () => import('@/propel/views/loans/lender/lender.vue'),
                            meta: {
                                title: 'Lender',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'amortization-schedules',
                            name: 'amortization-schedules',
                            component: () => import('@/propel/views/loans/amortization-schedules/amortization-schedules.vue'),
                            meta: {
                                title: 'Amortization Schedules',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'change-of-circumstance-cd',
                            name: 'change-of-circumstance-cd',
                            component: () => import('@/propel/views/loans/change-of-circumstance/change-of-circumstance.vue'),
                            meta: {
                                title: 'Change of Circumstance',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'calculations',
                            name: 'calculations',
                            component: () => import('@/propel/views/loans/calculations/calculations.vue'),
                            meta: {
                                title: 'Loan Calculations',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'ssa-89',
                            name: 'ssa-89',
                            component: () => import('@/propel/views/loans/ssa-89/ssa-89-info.vue'),
                            meta: {
                                title: 'SSA-89',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'taxpayer-information',
                            name: 'taxpayer-information',
                            component: () => import('@/propel/views/loans/4506-c/taxpayer-information.vue'),
                            meta: {
                                title: 'Taxpayer Information',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'release-of-lien',
                            name: 'release-of-lien',
                            component: () => import('@/propel/views/loans/release-of-lien/release-of-lien.vue'),
                            meta: {
                                title: 'Release of Lien',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'core-logic-release-of-lien',
                            name: 'core-logic-release-of-lien',
                            component: () => import('@/propel/views/loans/release-of-lien/core-logic-release-of-lien.vue'),
                            meta: {
                                title: 'Release of Lien',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'real-estate-brokers',
                            name: 'real-estate-brokers',
                            component: () => import('@/propel/views/loans/real-estate-brokers/selling-broker.vue'),
                            meta: {
                                title: 'Real Estate Brokers',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'loan-disclosures',
                            name: 'loan-disclosures',
                            component: () => import('@/propel/views/loans/loan-disclosures/loan-disclosures.vue'),
                            meta: {
                                title: 'Loan Disclosures',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'payoffs',
                            name: 'payoffs',
                            component: () => import('@/propel/views/loans/payoffs/payoffs.vue'),
                            meta: {
                                title: 'Payoffs',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: "urla-assets-liabilities",
                            name: "urla-assets-liabilities",
                            component: () => import("@/propel/views/loans/urla/assets-liabilities/urla-assets-liabilities.vue"),
                            meta: {
                                title: "Assets and Liabilities",
                                itemInfo: true,
                                readPermission: "loans:read",
                                writePermission: "loans:write"
                            }
                        },
                        {
                            path: 'urla-declarations',
                            name: 'urla-declarations',
                            component: () => import('@/propel/views/loans/urla/declarations/urla-declarations.vue'),
                            meta: {
                                title: 'Declarations',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'urla-demographics',
                            name: 'urla-demographics',
                            component: () => import('@/propel/views/loans/urla/demographics/urla-demographics.vue'),
                            meta: {
                                title: 'Demographics',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'urla-employment-income',
                            name: 'urla-employment-income',
                            component: () => import('@/propel/views/loans/urla/employment-income/urla-employment-income.vue'),
                            meta: {
                                title: 'Employment & Income',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'lender-loan-information',
                            name: 'lender-loan-information',
                            component: () => import('@/propel/views/loans/urla/lender-loan-information/urla-lender-loan.vue'),
                            meta: {
                                title: `Lender Loan Information`,
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'urla-loan-originator',
                            name: 'urla-loan-originator',
                            component: () => import('@/propel/views/loans/urla/loan-originator/loan-originator.vue'),
                            meta: {
                                title: `Loan Originator Information`,
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'urla-supplemental-consumer-info',
                            name: 'urla-supplemental-consumer-info',
                            component: () => import('@/propel/views/loans/urla/supplemental-consumer-info/urla-supplemental-consumer-info.vue'),
                            meta: {
                                title: `Supplemental Consumer Info`,
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'urla-loan-property',
                            name: 'urla-loan-property',
                            component: () => import('@/propel/views/loans/urla/loan-property/urla-loan-property.vue'),
                            meta: {
                                title: 'Loan & Property',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'urla-military-service',
                            name: 'urla-military-service',
                            component: () => import('@/propel/views/loans/urla/military-service/urla-military-service.vue'),
                            meta: {
                                title: 'Military Service',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'urla-real-estate',
                            name: 'urla-real-estate',
                            component: () => import('@/propel/views/loans/urla/real-estate/urla-real-estate.vue'),
                            meta: {
                                title: 'Real Estate',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'va-addendum',
                            name: 'va-addendum',
                            component: () => import('@/propel/views/loans/urla/va-addendum/va-addendum.vue'),
                            meta: {
                                title: `VA Addendum`,
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'fha-addendum',
                            name: 'fha-addendum',
                            component: () => import('@/propel/views/loans/urla/fha-addendum/fha-addendum.vue'),
                            meta: {
                                title: `FHA Addendum`,
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'other-considerations',
                            name: 'other-considerations',
                            component: () => import('@/propel/views/loans/initial-disclosures/other-considerations/index.vue'),
                            meta: {
                                title: `Other Considerations`,
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'service-providers',
                            name: 'service-providers',
                            component: () => import('@/propel/views/loans/initial-disclosures/service-providers.vue'),
                            meta: {
                                title: 'Service Providers',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'change-of-circumstance-le',
                            name: 'change-of-circumstance-le',
                            component: () => import('@/propel/views/loans/initial-disclosures/change-of-circumstance.vue'),
                            meta: {
                                title: `Change of Circumstance`,
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'borrowers-transaction',
                            name: 'borrowers-transaction',
                            component: () => import('@/propel/views/loans/borrower-transaction/borrower-transaction.vue'),
                            meta: {
                                title: `Borrower's Transaction`,
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'escrow-holdback',
                            name: 'escrow-holdback',
                            component: () => import("@/propel/views/loans/escrow-holdback/escrow-holdback.vue"),
                            meta: {
                                title: 'Escrow Holdback',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'security-instrument-corrections',
                            name: 'security-instrument-corrections',
                            component: () => import("@/propel/views/loans/security-instrument-corrections/security-instrument-corrections.vue"),
                            meta: {
                                title: 'Security Instrument Corrections',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'seller-transaction',
                            name: 'seller-transaction',
                            component: () => import('@/propel/views/loans/seller-transaction/seller-transaction.vue'),
                            meta: {
                                title: "Seller's Transaction",
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'le-cash-to-close',
                            name: 'le-cash-to-close',
                            component: () => import('@/propel/views/loans/cash-to-close/cash-to-close.vue'),
                            meta: {
                                title: 'Cash To Close',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write',
                                isLePage: true
                            }
                        },
                        {
                            path: 'cd-cash-to-close',
                            name: 'cd-cash-to-close',
                            component: () => import('@/propel/views/loans/cash-to-close/cash-to-close.vue'),
                            meta: {
                                title: 'Cash To Close',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'document-options',
                            name: 'document-options',
                            component: () => import('@/propel/views/loans/document-options/document-options.vue'),
                            meta: {
                                title: 'Document Options',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'under-development',
                            name: 'under-development',
                            component: () => import('@/propel/views/loans/under-development/under-development.vue'),
                            meta: {
                                title: 'Under Development',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'va-other-data',
                            name: 'va-other-data',
                            component: () => import('@/propel/views/loans/va-information/other-data.vue'),
                            meta: {
                                title: 'VA Other Data',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'va26-1820',
                            name: 'va26-1820',
                            component: () => import('@/propel/views/loans/va-information/va26-1820.vue'),
                            meta: {
                                title: 'Form 26-1820',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'refinance-comparison',
                            name: 'refinance-comparison',
                            component: () => import('@/propel/views/loans/va-information/refinance-comparison.vue'),
                            meta: {
                                title: 'Refinance Comparison',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'closing-information',
                            name: 'closing-information',
                            component: () => import('@/propel/views/loans/closing-information/closing-information.vue'),
                            meta: {
                                title: 'Closing Information',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'order-documents',
                            name: 'order-documents',
                            component: () => import('@/propel/views/loans/documents/order-documents.vue'),
                            meta: {
                                title: 'Order Documents',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'package-history',
                            name: 'package-history',
                            component: () => import('@/propel/views/loans/documents/package-history.vue'),
                            meta: {
                                title: 'Package History',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'compliance',
                            name: 'compliance',
                            component: () => import('@/propel/views/loans/compliance/compliance-page.vue'),
                            meta: {
                                title: 'Compliance',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'ucd',
                            name: 'ucd',
                            component: () => import('@/propel/views/loans/ucd/ucd-fields.vue'),
                            meta: {
                                title: 'UCD',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'contractors',
                            name: 'contractors',
                            component: () => import('@/propel/views/loans/contractors/contractors.vue'),
                            meta: {
                                title: 'Contractors',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'tangible-net-benefits',
                            name: 'tangible-net-benefits',
                            component: () => import('@/propel/views/loans/tangible-net-benefits/tangible-net-benefits.vue'),
                            meta: {
                                title: 'Tangible Net Benefits',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'custom-fields',
                            name: 'custom-fields',
                            component: () => import('@/propel/views/loans/custom-fields/custom-fields.vue'),
                            meta: {
                                title: 'Custom Fields',
                                itemInfo: true, //not sure what this metadata property does.. 
                                //(note from Andrew R: It is used by the Browser Tab service to generate tab titles that have information about the loan (item) in them)
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'

                            }
                        },
                        {
                            path: 'fha-transmittal-summary',
                            name: 'fha-transmittal-summary',
                            component: () => import('@/propel/views/loans/fha-transmittal-summary/fha-transmittal-summary.vue'),
                            meta: {
                                title: 'Transmittal Summary (92900-LT)',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'  
                            }
                        },
                        {
                            path: 'va-loan-analysis',
                            name: 'va-loan-analysis',
                            component: () => import('@/propel/views/loans/va-loan-analysis/va-loan-analysis.vue'),
                            meta: {
                                title: 'Loan Analysis (VA 26-6393)',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'       
                            }
                        },
                        {
                            path: 'underwriting-summary',
                            name: 'underwriting-summary',
                            component: () => import('@/propel/views/loans/underwriting/underwriting-summary.vue'),
                            meta: {
                                title: 'Underwriting Summary (1008)',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'       
                            }
                        },
                        {
                            path: 'adverse-action',
                            name: 'adverse-action',
                            component: () => import('@/propel/views/loans/adverse-action/adverse-action.vue'),
                            meta: {
                                title: 'Adverse Action',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'       
                            }
                        },
                        {
                            path: 'alaska',
                            name: 'alaska',
                            component: () => import("@/propel/views/loans/state-specific/ak/alaska-screen.vue"),
                            meta: {
                                title: "Alaska-Specific",
                                itemInfo: true,
                                readPermission: "loans:read",
                                writePermission: "loans:write"
                            }
                        },
                        {
                            path: 'arizona',
                            name: 'arizona',
                            component: () => import('@/propel/views/loans/state-specific/az/arizona-screen.vue'),
                            meta: {
                                title: 'Arizona-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'arkansas',
                            name: 'arkansas',
                            component: () => import('@/propel/views/loans/state-specific/ar/arkansas-screen.vue'),
                            meta: {
                                title: 'Arkansas-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'california',
                            name: 'california',
                            component: () => import('@/propel/views/loans/state-specific/ca/california-screen.vue'),
                            meta: {
                                title: 'California-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'connecticut',
                            name: 'connecticut',
                            component: () => import('@/propel/views/loans/state-specific/ct/connecticut-screen.vue'),
                            meta: {
                                title: 'Connecticut-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'dc',
                            name: 'dc',
                            component: () => import("@/propel/views/loans/state-specific/dc/dc-screen.vue"),
                            meta: {
                                title: "DC-Specific",
                                itemInfo: true,
                                readPermission: "loans:read",
                                writePermission: "loans:write"
                            }
                        },
                        {
                            path: 'delaware',
                            name: 'delaware',
                            component: () => import('@/propel/views/loans/state-specific/de/delaware-screen.vue'),
                            meta: {
                                title: 'Delaware-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'georgia',
                            name: 'georgia',
                            component: () => import('@/propel/views/loans/state-specific/ga/georgia-screen.vue'),
                            meta: {
                                title: 'Georgia-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'idaho',
                            name: 'idaho',
                            component: () => import('@/propel/views/loans/state-specific/id/idaho-screen.vue'),
                            meta: {
                                title: 'Idaho-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'iowa',
                            name: 'iowa',
                            component: () => import('@/propel/views/loans/state-specific/ia/iowa-screen.vue'),
                            meta: {
                                title: 'Iowa-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'illinois',
                            name: 'illinois',
                            component: () => import('@/propel/views/loans/state-specific/il/illinois-screen.vue'),
                            meta: {
                                title: 'Illinois-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'kansas',
                            name: 'kansas',
                            component: () => import('@/propel/views/loans/state-specific/ks/kansas-screen.vue'),
                            meta: {
                                title: 'Kansas-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'kentucky',
                            name: 'kentucky',
                            component: () => import('@/propel/views/loans/state-specific/ky/kentucky-screen.vue'),
                            meta: {
                                title: 'Kentucky-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'massachusetts',
                            name: 'massachusetts',
                            component: () => import('@/propel/views/loans/state-specific/ma/massachusetts-screen.vue'),
                            meta: {
                                title: 'Massachusetts-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'maryland',
                            name: 'maryland',
                            component: () => import('@/propel/views/loans/state-specific/md/maryland-screen.vue'),
                            meta: {
                                title: 'Maryland-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'minnesota',
                            name: 'minnesota',
                            component: () => import('@/propel/views/loans/state-specific/mn/minnesota-screen.vue'),
                            meta: {
                                title: 'Minnesota-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'missouri',
                            name: 'missouri',
                            component: () => import("@/propel/views/loans/state-specific/mo/missouri-screen.vue"),
                            meta: {
                                title: "Missouri-Specific",
                                itemInfo: true,
                                readPermission: "loans:read",
                                writePermission: "loans:write"
                            }
                        },
                        {
                            path: 'mississippi',
                            name: 'mississippi',
                            component: () => import("@/propel/views/loans/state-specific/ms/mississippi-screen.vue"),
                            meta: {
                                title: "Mississippi-Specific",
                                itemInfo: true,
                                readPermission: "loans:read",
                                writePermission: "loans:write"
                            }
                        },
                        {
                            path: 'north-carolina',
                            name: 'north-carolina',
                            component: () => import('@/propel/views/loans/state-specific/nc/north-carolina-screen.vue'),
                            meta: {
                                title: 'North Carolina-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'north-dakota',
                            name: 'north-dakota',
                            component: () => import('@/propel/views/loans/state-specific/nd/north-dakota-screen.vue'),
                            meta: {
                                title: 'North Dakota-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'new-jersey',
                            name: 'new-jersey',
                            component: () => import('@/propel/views/loans/state-specific/nj/new-jersey-screen.vue'),
                            meta: {
                                title: 'New Jersey-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'nevada',
                            name: 'nevada',
                            component: () => import('@/propel/views/loans/state-specific/nv/nevada-screen.vue'),
                            meta: {
                                title: 'Nevada-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'new-mexico',
                            name: 'new-mexico',
                            component: () => import('@/propel/views/loans/state-specific/nm/new-mexico-screen.vue'),
                            meta: {
                                title: 'New Mexico-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'new-york',
                            name: 'new-york',
                            component: () => import('@/propel/views/loans/state-specific/ny/new-york-screen.vue'),
                            meta: {
                                title: 'New York-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'ohio',
                            name: 'ohio',
                            component: () => import('@/propel/views/loans/state-specific/oh/ohio-screen.vue'),
                            meta: {
                                title: 'Ohio-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'oklahoma',
                            name: 'oklahoma',
                            component: () => import('@/propel/views/loans/state-specific/ok/oklahoma-screen.vue'),
                            meta: {
                                title: 'Oklahoma-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'rhode-island',
                            name: 'rhode-island',
                            component: () => import('@/propel/views/loans/state-specific/ri/rhode-island-screen.vue'),
                            meta: {
                                title: 'Rhode Island-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'south-carolina',
                            name: 'south-carolina',
                            component: () => import('@/propel/views/loans/state-specific/sc/south-carolina-screen.vue'),
                            meta: {
                                title: 'South Carolina-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: "state-specific",
                            name: "state-specific",
                            component: () => import("@/propel/views/loans/state-specific/generic/generic.vue"),
                            meta: {
                                title: "State Specific",
                                itemInfo: true,
                                readPermission: "loans:read",
                                writePermission: "loans:write"
                            }
                        },
                        {
                            path: 'tennessee',
                            name: 'tennessee',
                            component: () => import("@/propel/views/loans/state-specific/tn/tennessee-screen.vue"),
                            meta: {
                                title: "Tennessee-Specific",
                                itemInfo: true,
                                readPermission: "loans:read",
                                writePermission: "loans:write"
                            }
                        },
                        {
                            path: 'texas',
                            name: 'texas',
                            component: () => import('@/propel/views/loans/state-specific/tx/texas-screen.vue'),
                            meta: {
                                title: 'Texas-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'virginia',
                            name: 'virginia',
                            component: () => import("@/propel/views/loans/state-specific/va/virginia-screen.vue"),
                            meta: {
                                title: "Virginia-Specific",
                                itemInfo: true,
                                readPermission: "loans:read",
                                writePermission: "loans:write"
                            }
                        },
                        {
                            path: 'vermont',
                            name: 'vermont',
                            component: () => import("@/propel/views/loans/state-specific/vt/vermont-screen.vue"),
                            meta: {
                                title: "Vermont-Specific",
                                itemInfo: true,
                                readPermission: "loans:read",
                                writePermission: "loans:write"
                            }
                        },
                        {
                            path: 'washington',
                            name: 'washington',
                            component: () => import('@/propel/views/loans/state-specific/wa/washington-screen.vue'),
                            meta: {
                                title: 'Washington-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        },
                        {
                            path: 'wisconsin',
                            name: 'wisconsin',
                            component: () => import('@/propel/views/loans/state-specific/wi/wisconsin-screen.vue'),
                            meta: {
                                title: 'Wisconsin-Specific',
                                itemInfo: true,
                                readPermission: 'loans:read',
                                writePermission: 'loans:write'
                            }
                        }
                    ]
                }
            ]
    }
]