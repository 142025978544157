<template>
    <custom-icon
        v-if="showButton"
        :icon="isDefaulted ? 'Edit' : 'Reset'"
        :title="isDefaulted ? 'Override default value' : 'Reset to default value'"
        :class="[isDefaulted ? 'default-toggle-button-edit' : 'default-toggle-button-reset']"
        ref="toggleDefault"
        @click="toggleDefaulted()"
    />
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, watch } from 'vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:'defaultChange', defaultChange: boolean)
    (e:'change', defaultValue: any)
}>()

const props = defineProps({
    currentValue: { type: null, required: true },
    defaultValue: { type: null, required: true },
    isDefaulted: {type: Boolean, required: true },
    disabled: {type: Boolean },
})
//#endregion

//#region COMPUTED
const hasDefault = computed(() => props.defaultValue)
const showButton = computed(() => hasDefault.value && (!props.disabled || !props.isDefaulted))

// This doesn't' look like it's used anymore?
// const valueIsDefault = computed(() => { 
//     if (typeof props.currentValue === 'string') 
//         return props.currentValue?.toUpperCase() === props.defaultValue.toUpperCase()
//     else
//         return props.defaultValue === props.currentValue
// })
//#endregion

//#region WATCH
watch(() => props.isDefaulted, () => {
    if (props.isDefaulted) 
        emit('change', props.defaultValue)
})
//#endregion


//The reset to default button should still show but be disabled if the input is disabled so users know that the value is an override
function toggleDefaulted() {
    emit('defaultChange', !props.isDefaulted)
}
</script>
