import { Ref } from 'vue'

// needs to be called in onMounted for the inputElement to be populated
export function useDisableAutoComplete(inputElement : Ref<HTMLInputElement | HTMLTextAreaElement | null> ) {
    // ignore if element is null or if the element has already been updated
    if (inputElement.value && !inputElement.value.id.includes("__")) {
        const newId = `${inputElement.value.id}__${new Date().getTime()}`
        // update input
        inputElement.value.id = newId
        inputElement.value.setAttribute('autocomplete', 'docweb_no_autocomplete')
        // update ids on related labels if they exist
        setLabelFor(inputElement.value.previousElementSibling, newId)
        setLabelFor(inputElement.value.nextElementSibling, newId)
    }    
}

function setLabelFor(label: Element | null, newId: string) {
    if (label) {
        label.setAttribute('for', newId)
    }
}