import { PackageType } from '../metadata'

export class DocumentSelectionState {
    packageType: PackageType | null
    redraw: boolean
    docIds: string[]

    constructor(packageType: PackageType | null, redraw: boolean, docIds: string[]) {
        // clone all input to ensure different object references
        this.packageType = packageType === null ? null : Object.assign({}, packageType)
        this.redraw = redraw
        this.docIds = [...docIds]
    }
}

export class StoredDocumentSelectionState extends DocumentSelectionState {
    allDocsSelected: boolean
    loanId: string
    constructor(packageType: PackageType | null, redraw: boolean, docIds: string[], allDocsSelected: boolean, loanId: string){
        super(packageType, redraw, docIds)
        this.allDocsSelected = allDocsSelected
        this.loanId = loanId
    }
}