<template>
    <validation-observer v-slot="{ invalid }">
        <content-block-list
            :id="id"
            :listItems.sync="placementRuleList"
            :editMode="ContentBlockEditModeEnum.SaveCancel"
            :onAdd="addPlacementRule"
            addLabelText="Add Rule"
            deletable
            draggable
            :size="size"
            @itemEditing="itemEditing"
            @openItemsChanged="resetCriteriaEditing"
            @dragged="saveChanges"
            @itemUpdated="saveChanges"
            @itemDeleted="saveChanges"
            :itemCanSave="() => !invalid && canSave()"
            @update:listItems="refreshCriteriaItems"
        >
            <template v-slot:view="slotParams">
                <div class="standard-columns">
                    <div class="m wrap">
                        {{ placementDisplay(slotParams.item) }}
                    </div>
                    <rule-condition-content-block-column
                        v-if="slotParams.item.conditions.criteriaItems && slotParams.item.conditions.criteriaItems.length"
                        :conditions="slotParams.item.conditions"
                    />
                    <div class="wrap m" v-else>Default placement</div>
                </div>
            </template>
            <template v-slot:edit="slotParams">
                <h1>Conditions</h1>
                <div class="field-section">
                    <fg-select
                        id="placementRulePackageTypes"
                        label="Package Types"
                        :source="packageTypesMultiSelect"
                        size="xl"
                        searchable
                        multiple
                        :showEmptyOption="false"
                        :showSelectedItemsvalueOnDisplay="true"
                        optionDisplayType="SplitWithValue"
                        v-model="slotParams.item.conditions.packageTypes"
                        placeholder="Package Type(s)"
                        :disabled="readOnly"
                    />
                    <rule-criteria-block-list
                        :criteriaItems="slotParams.item.conditions.criteriaItems"
                        :readOnly="readOnly"
                        addLabelText="Add Criteria"
                        :enableFieldExpression="false"
                        @openItemsChanged="toggleEdit"
                    />
                </div>
                <div class="field-section">
                    <fg-radio-button
                        id="formPlacementRadioSortOrder"
                        :options="formPlacementOptions"
                        v-model="slotParams.item.formPlacement"
                        @change="clearOldData(slotParams.item)"
                        name="formPlacementRadio"
                        label="Place this form:"
                        :disabled="readOnly"
                    />
                    <fg-select
                        v-if="slotParams.item.formPlacement === FormPlacement.AfterOtherForms"
                        id="afterFormSelection"
                        label="Print after:"
                        size=" "
                        :source="getOtherForms"
                        :searchable="true"
                        :multiple="true"
                        :showEmptyOption="false"
                        :showSelectedItemsValueOnDisplay="true"
                        optionDisplayType="SplitWithValue"
                        v-model="slotParams.item.afterFormIds"
                        placeholder="Form(s)"
                        :disabled="readOnly"
                        validationRules="required"
                        :immediateValidation="true"
                    />

                    <template v-else>
                        <fg-select
                            id="sortGroups"
                            label="Sort Group"
                            size="xl"
                            :source="getSortGroups"
                            :searchable="true"
                            :multiple="false"
                            :showEmptyOption="false"
                            v-model="slotParams.item.sortGroupId"
                            placeholder="Sort Group"
                            @input="setNextSortOrder(slotParams.item, slotParams.originalValue)"
                            :disabled="readOnly"
                            optionDisplayType="SplitWithSecondaryText"
                            showDescriptionAfterSelect
                            :immediateValidation="true"
                            validationRules="required"
                        />
                        <fg-text
                            id="sortOrder"
                            label="Sort Order"
                            v-model="slotParams.item.sortOrder"
                            size="xs"
                            :disabled="readOnly"
                            :immediateValidation="true"
                            :validationRules="{ required: true, regex: /^\d+(\.\d*)?$/, min: 0 }"
                        />
                    </template>
                </div>
            </template>
        </content-block-list>
    </validation-observer>
</template>

<script setup lang="ts">
import { MultiSelectItem } from "@/common/components/multi-select-item";
import { Condition, ContentBlockEditModeEnum, Criteria, DocumentSummary, FieldSummary, FormPlacement, ItemsChangedEventArgs, PackageType, PlacementRule, SortGroup } from "@/common/models";
import { adminDataService } from "@/common/services";
import { metadataService } from "@/propel/services";
import { defineProps, defineEmits, PropType, ref, computed } from "vue"
import RuleConditionContentBlockColumn from "@/propel/components/rule-condition-content-block-column.vue"

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e: "update:placementRules", value: PlacementRule[]): void
    (e: "itemEditing", event: ItemsChangedEventArgs): void
}>()

const props = defineProps({
    placementRules: { type: Array as PropType<Array<PlacementRule>>, required: true },
    id: { type: String, required: true },
    size: { type: String },
    currentFormId: { type: String },
    readOnly: { type: Boolean },
    savePlacementRules: { type: Function as PropType<() => Promise<void>>},
})

const allFields = ref([] as MultiSelectItem[])
const packageTypes = ref([] as PackageType[])
const sortGroupDisplay = ref({} as any)
const fieldDisplayLookup = ref<any>()
const criteriaEditing = ref(false)

const formPlacementOptions: MultiSelectItem[] =
[
    { text: "In a sort group", value: FormPlacement.SortOrder },
    { text: "After other forms", value: FormPlacement.AfterOtherForms }
]
//#endregion

//#region COMPUTED
const packageTypesMultiSelect = computed(() => packageTypes.value?.map(p => ({text: p.displayName, value: p.code}) as MultiSelectItem))

const placementRuleList = computed({
    get() {
        return props.placementRules
    },
    set(value: PlacementRule[]) {
        emit("update:placementRules", value)
    }
})
//#endregion

//#region INITIALIZE
initialize()

async function initialize(){
    await getCriteriaFields()
    packageTypes.value = await adminDataService.getPackageTypes()
    sortGroupDisplay.value = (await getSortGroups())?.reduce((obj, sortGroup) => {
        obj[sortGroup.value] = sortGroup.secondaryText
        return obj
    }, {}) || {}
}
//#endregion

// Hack to get display of items in view slot to update after adding a new one.
// Without this the new line shows "undefined undefined undefined" until something else triggers a refresh.
function refreshCriteriaItems(){
    placementRuleList.value.forEach((x) => (x.conditions.criteriaItems = [...x.conditions.criteriaItems]))
}

function addPlacementRule(){
    return {
        formPlacement: FormPlacement.SortOrder,
        afterFormIds: [] as string[],
        sortGroupId: "",
        sortOrder: 0,
        conditions: {
            packageTypes: [] as string[],
            criteriaItems: [] as Criteria[]
        } as Condition
    } as PlacementRule
}

function placementDisplay(placementRule: PlacementRule){
    let placementString: string;
    if(placementRule.formPlacement === FormPlacement.SortOrder){
        placementString = `Sort group ${(placementRule.sortGroupId in sortGroupDisplay.value &&
            sortGroupDisplay.value[placementRule.sortGroupId]) || placementRule.sortGroupId}, position ${placementRule.sortOrder}`
    }
    else 
        placementString = `After ${placementRule.afterFormIds?.join(", ") || ""}`
    if(!placementRule.conditions?.packageTypes?.length)
        placementString += " in any package"
    else
        placementString += ` in ${placementRule.conditions.packageTypes.join(", ")}`

    return placementString
}

function clearOldData(placementRule: PlacementRule) {
    if (placementRule.formPlacement === FormPlacement.SortOrder) {
        placementRule.afterFormIds = []
    } else {
        placementRule.sortGroupId = ""
        placementRule.sortOrder = 0
    }
}

async function getOtherForms(searchTerm?: string | string[]): Promise<MultiSelectItem[]> {
    const params = new URLSearchParams()
    if (searchTerm && !Array.isArray(searchTerm)) 
        params.append("searchTerm", searchTerm)
    
    // use regex to search for multiple form IDs at one time so all selected
    // items in the multiselect are populated on load
    else if (searchTerm?.length) 
        params.append("formId", `~${(searchTerm as string[]).join("|")}`)
    
    params.append("pageSize", "50")
    const forms = await metadataService.getAllDocuments(params)

    //filtering out the form we are editing
    return forms.items
        .filter((f: DocumentSummary) => f.formId !== props.currentFormId)
        .map((f) => ({
            text: f.title,
            value: f.formId
        }))
}

async function setNextSortOrder(placementRule: PlacementRule, originalValue: PlacementRule) {
    if (placementRule.sortGroupId) {
        placementRule.sortOrder =
            placementRule.sortGroupId === originalValue?.sortGroupId ?
                originalValue.sortOrder : await getRuleCountBySortGroup(placementRule.sortGroupId)
    } 
    else 
        placementRule.sortOrder = 0
}

async function getRuleCountBySortGroup(sortGroupId): Promise<number> {
    const params = new URLSearchParams()
    params.append("sortGroupId", sortGroupId)
    return await metadataService.getNextSortGroupPosition(sortGroupId, props.currentFormId)
}

async function getCriteriaFields() {
    const params = new URLSearchParams()
    params.append("isUsedInClosingConditionRules", "true")
    params.append("forDropdown", "true");
    const fields = await metadataService.getFields(params)
    allFields.value = fields.items.map((f: FieldSummary) => ({ value: f.name, text: f.displayName ||  f.name, secondaryText: f.description || f.displayName || f.name })) as MultiSelectItem[];
    fieldDisplayLookup.value = fields.items
        .reduce((obj, field) => {
            obj[field.name] = field.displayName || field.name
            return obj;
        }, {});
}

async function getSortGroups(searchTerm?: string): Promise<MultiSelectItem[]> {
    const params = new URLSearchParams()
    params.append("searchTerm", searchTerm ? searchTerm : "")
    params.append("pageSize", "250")
    const sortGroups = await metadataService.getAllSortGroups(params)
    return sortGroups.items.map((r: SortGroup) => ({ value: r.id, text: r.name, secondaryText: r.code }))
}

function toggleEdit(event: ItemsChangedEventArgs) {
    criteriaEditing.value = !!event.openItemsCount
}

function resetCriteriaEditing(event: ItemsChangedEventArgs) {
    criteriaEditing.value = !!event.openItemsCount
    emit("itemEditing", { openItemsCount: 0, item: null } as ItemsChangedEventArgs)
}

function itemEditing(item: PlacementRule) {
    emit("itemEditing", { openItemsCount: 1, item: item  } as ItemsChangedEventArgs)
}

async function saveChanges() {
    if(props.savePlacementRules)
        await props.savePlacementRules()
    
    emit("itemEditing",  { openItemsCount: 0, item: null  } as ItemsChangedEventArgs)
}

function canSave() {
    return !criteriaEditing.value
}
</script>