import { PermissionLevels, ReadonlyPermissions, UserPermissionSet } from '@/common/models'
import { useCurrentUserStore } from '@/common/store'

export class UserPermissionValidator {
    permissionSet?: UserPermissionSet[]

    constructor(permissionSet?: UserPermissionSet[]) {
        this.permissionSet = permissionSet
    }

    get userPermissionSet(): UserPermissionSet[] {
        if (this.permissionSet) {
            return this.permissionSet
        } else {
            const userStore = useCurrentUserStore()
            return userStore.userPermissionSet
        }
    }

    disableExcludedIds = ['helpIcon', 'auditCheckIcon', 'errorIcon']

    initialize(userPermissionSet: UserPermissionSet) {
        userPermissionSet.permissionLevels = this.getPermissionLevels(userPermissionSet.permissions)
    }

    private getPermissionLevels(permissions: string[]): PermissionLevels[] {
        const permissionLevel: PermissionLevels[] = []
        permissions.forEach(p => {
            permissionLevel.push(this.getPermissionLevel(p))
        })

        return permissionLevel
    }

    getPermissionLevel(permission: string): PermissionLevels {
        const currentLevel: PermissionLevels = {} as PermissionLevels
        const pSplit = permission.split(':')
        currentLevel.level = pSplit[0]
        currentLevel.action = pSplit.length > 1 ? pSplit[1] : null
        return currentLevel
    }

    isSystemAdmin(): boolean {
        for (const per of this.userPermissionSet) {
            if (per.permissions.includes('system-admin')) {
                return true
            }
        }
        return false
    }

    hasPermission(permissions: string[], client?: string, partner?: string): boolean {
        // Determines whether a user has specific permissions, whether granted an
        // individual permission or its parent
        if (this.isSystemAdmin()) {
            return true
        }
        for (const p of permissions) {
            for (const userPermission of this.userPermissionSet) {
                if (!this.hasPermissionForClientOrPartner(client, userPermission.clients)) {
                    continue
                }
                if (!this.hasPermissionForClientOrPartner(partner, userPermission.partners)) {
                    continue
                }
                if (userPermission.permissions.includes(p)) {
                    return true
                }
                const currentLevel = this.getPermissionLevel(p)
                if (p.split(':').length >= 1) {
                    for (const permissionLevel of userPermission.permissionLevels) {
                        if ((permissionLevel.level == currentLevel.level || currentLevel.level.startsWith(permissionLevel.level + '.')) && (permissionLevel.action === null || currentLevel.action === null || permissionLevel.action === currentLevel.action)) {
                            return true
                        }
                    }
                }
            }
        }
        return false
    }

    hasPermissionsForSinglePartnerOrClient(): SingleClientOrPartner {
        const clientOrPartner = new SingleClientOrPartner()
        for (const userPermission of this.userPermissionSet) {
            if ((userPermission.partners?.length ?? 0) == 1) {
                clientOrPartner.code = userPermission.partners[0]
                clientOrPartner.isPartner = true
                clientOrPartner.single = true
                return clientOrPartner
            }
            if ((userPermission.clients?.length ?? 0) == 1) {
                clientOrPartner.code = userPermission.clients[0]
                clientOrPartner.single = true
                return clientOrPartner
            }
        }
        return clientOrPartner;
    }

    hasAnyPermission(permissions: string[], client?: string, partner?: string): boolean {
        // Determines whether the user has a given permission or one of its child permissions
        if (this.isSystemAdmin()) {
            return true
        }
        for (const p of permissions) {
            for (const userPermission of this.userPermissionSet) {
                if (!this.hasPermissionForClientOrPartner(client, userPermission.clients)) {
                    continue
                }
                if (!this.hasPermissionForClientOrPartner(partner, userPermission.partners)) {
                    continue
                }
                if (userPermission.permissions.includes(p)) {
                    return true
                }
                const currentLevel = this.getPermissionLevel(p)
                for (const pl of userPermission.permissionLevels) {
                    if ((pl.level == currentLevel.level || pl.level.startsWith(currentLevel.level + '.')) && (pl.action === null || currentLevel.action === null || pl.action === currentLevel.action)) {
                        return true
                    }
                }
            }
        }
        return false
    }

    hasPermissionForClientOrPartner(requestedEntity?: string, userPermission?: string[]): boolean {
        if (!requestedEntity) {
            return true
        }
        
        if (!userPermission || userPermission.length === 0)
            return true

        if (userPermission) {
            if (userPermission.includes(requestedEntity)) {
                return true
            }
        }

        return false
    }

    canUpdateReadonlyLoanData(type: ReadonlyPermissions) {
        if(!type) return false
        if(type == ReadonlyPermissions.None) return true

        return this.hasPermission([`readonly.${type}:write`])
    }

    canUpdateReadonlyField(fieldId: string | null) {
        if(!fieldId) return false;

        return this.hasPermission([`readonly.fieldDefaults.${fieldId}:write`])
    }

    disableControlsByIds(idList: string[]) {
        idList.forEach(id => {
            const element = document.getElementById(id)
            this.disableControls(element)
        });
    }

    disableControls(parentElement: HTMLElement | null) {
        if (parentElement) {
            const elements = parentElement.children;
            for (let i = 0; i < elements.length; ++i) {
                const childElement = elements[i] as HTMLElement
                const isDisableExcluded = this.disableExcludedIds.some(x => childElement.id.includes(x))

                //Special rules for the edit toggle button for default values
                if(childElement.classList.contains("default-toggle-button-edit")){
                    parentElement.removeChild(childElement)
                    i--
                    continue
                }

                if (childElement.nodeName !== 'BUTTON' && !isDisableExcluded) {
                    if (elements[i].children.length > 0) {
                        this.disableControls(childElement)
                    }
                }
                if (!isDisableExcluded) {
                    if (childElement.nodeName === 'DIV') {
                        childElement.classList.add('unselectable')
                        childElement.classList.add('disabled')
                        childElement.setAttribute('tabindex', '-1')
                    }
                    childElement.setAttribute('disabled', 'true')
                }
                else {
                    childElement.classList.add('unselectable-exception')
                }
            }
        }
    }

    getLendersByPermissions(permissions: string[]): string[] {
        return this.populateEntityIdsByPermission(permissions, 'clients')
    }

    getPartnersByPermissions(permissions: string[]): string[]{
        return this.populateEntityIdsByPermission(permissions, 'partners')
    }
    
    private populateEntityIdsByPermission(permissions: string[], property: string): string[] {
        //make sure permission has :read at the end
        const sanitizedPermissions = permissions.map(permission => `${permission}:read`)

        //for each user permission in the user permission set 
        for (const userPermission of this.userPermissionSet) {
            for (const sp of sanitizedPermissions) {
                if (userPermission.permissions.includes(sp)) {
                    return Reflect.get(userPermission, property)
                }
                
                //checking for child permissions
                const currentLevel = this.getPermissionLevel(sp)
                for (const pl of userPermission.permissionLevels) {
                    if ((pl.level == currentLevel.level || pl.level.startsWith(currentLevel.level + '.')) && (pl.action === null || currentLevel.action === null || pl.action === currentLevel.action)) {
                        return Reflect.get(userPermission, property)
                    }
                }
            }
        }

        return [] as string[]
    }
}

export const userPermissionValidatorService = new UserPermissionValidator()

export class SingleClientOrPartner {
    single = false
    code: string | null = null
    isPartner = false
}

