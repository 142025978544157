export interface ClientContact {
    contactId: string | null
    type: LoanContactType 
}

export interface ClientTrusteeContact {
    states: string[] | null
    primaryTrustee: ClientContact | null
    secondaryTrustee: ClientContact | null
}

export enum LoanContactType {
    Broker = 'Broker',
    Closer = 'Closer',
    LoanOfficer = 'LoanOfficer',
    ClosingInstructions = 'ClosingInstructions',
    DocumentsReturn = 'DocumentsReturn',
    DtMortgageReturn = 'DtMortgageReturn',
    FirstPaymentLetter = 'FirstPaymentLetter',
    Funder = 'Funder',
    Investor = 'Investor',
    MortgageeClause = 'MortgageeClause',
    NotePay = 'NotePay',
    NoticeOfRightToCancel = 'NoticeOfRightToCancel',
    Servicer = 'Servicer',
    Ssa89 = 'Ssa89',
    TaxServiceProvider = 'TaxServiceProvider',
    PrimaryTrustee = 'PrimaryTrustee',
    SecondaryTrustee = 'SecondaryTrustee',
    Beneficiary = 'Beneficiary',
    PackageNotification = 'PackageNotification',
    WarehouseBank = 'WarehouseBank',
    Branch = "Branch",
    Processor = "Processor"
}