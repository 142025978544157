<template>
    <div v-if="prependValue || appendValue" :class="['input-group', auditCheckLevelClass]">
        <div v-if="prependValue" class="input-group-prepend">
            <span class="input-group-text">{{ prependValue }}</span>
        </div>
        <!-- If the input is numeric, the mask will take care of the value being assigned,
            so we don't need to assign a value to the input in that case -->
        <input
            v-if="!isText"
            ref="inputRef"
            type="text"
            class="form-control"
            :id="id"
            :disabled="disabled"
            :tabindex="disabled ? -1 : 0"
            :placeholder="placeholder"
            :maxlength="maxLength"
            :jsonpath="jsonPathValue"
            @input="onInput"
            @change="$emit('change', $event)"
            @paste="onPaste"
        />
        <input v-else
            ref="inputRef"
            type="text"
            class="form-control"
            :id="id"
            :disabled="disabled"
            :value="vm"
            :tabindex="disabled ? -1 : 0"
            :placeholder="placeholder"
            :maxlength="maxLength"
            :jsonpath="jsonPathValue"
            @input="onInput"
            @change="onChange"
            @paste="onPaste"
        />
        <div v-if="appendValue" class="input-group-append">
            <span class="input-group-text">{{ appendValue }}</span>
        </div>
    </div>
    <input
        v-else
        ref="inputRef"
        type="text"
        class="form-control"
        :class="auditCheckLevelClass"
        :id="id"
        :disabled="disabled"
        :value="vm"
        :tabindex="disabled ? -1 : 0"
        :placeholder="placeholder"
        :maxlength="maxLength"
        :jsonpath="jsonPathValue"
        @input="onInput"
        @change="onChange"
        @paste="onPaste"
    />
</template>

<!-- To Do: Resolve this issue with input's maxlength being a Numberish|null while our input-text maxlength is a number|null -->
<script setup lang="ts">
import { PropType, computed, onMounted, defineEmits, defineProps, ref, watch, onBeforeUnmount, toRef } from 'vue'
import { InputType } from '../models'
import { useAuditChecks } from '@/common/composables/audit-check-composable'
import { InputMask, MaskedNumber } from 'imask'
import { round } from '../utilities/math'
import { useDisableAutoComplete } from '@/common/composables/disable-autocomplete-composable'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e: "input", value: any): void
    (e: "change", value: any): void
}>()

const props = defineProps({
    // TODO: Add the following type to value, and fix all vue warnings it causes. These warnings are caused by initialization issues.
    // "type: [String, Number]"
    value: { required: true }, 
    id: { type: String, required: true },
    inputType: { type: String as PropType<InputType>, default: InputType.Text },
    jsonPath: { type: String, default: '' },
    jsonProperty: { type: String },
    disabled: { type: Boolean },
    append: { type: String, default: '' },
    prepend: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    min: { type: Number as PropType<number|null>, default: null },
    max: { type: Number as PropType<number|null>, default: null },
    scale: { type: Number as PropType<number|null>, default: null },
    maxLength: { type: Number as PropType<number|null>, default: null },
})

const mask = ref()
const inputRef = ref<HTMLInputElement | null>(null)

const valueChangedDueToInput = ref(false)

const vm = toRef(props, 'value')
//#endregion

//#region WATCH
watch(() => props.value, (value) => handleValueChange(value as string))
//#endregion

//#region COMPUTED
const isText = computed(() => props.inputType === InputType.Text)

const numberMask = computed(() => {
    if (props.inputType === InputType.Currency) {
        return new MaskedNumber({
            mask: Number,
            signed: true,
            min: props.min ?? -1000000000,
            max: props.max ?? 1000000000,
            thousandsSeparator: ',',
            padFractionalZeros: true,
            scale: props.scale ?? 2,
            radix: '.'
        })
    }        
    else if (props.inputType === InputType.Percentage) {
        return new MaskedNumber({
            mask: Number,
            min: props.min ?? -100,
            max: props.max ?? 100,
            padFractionalZeros: true,
            scale: props.scale ?? 3,
            radix: "."
        })
    }
    else if (props.inputType === InputType.Days) {
        return new MaskedNumber({
            mask: Number,
            min: props.min ?? -999,
            max: props.max ?? 999,
            scale: props.scale ?? 0
        })
    }
    else if (props.inputType === InputType.Decimal) {
        return new MaskedNumber({
            mask: Number,
            signed: true,
            min: props.min ?? -1000000000,
            max: props.max ?? 1000000000,
            thousandsSeparator: ',',
            scale: props.scale ?? 3,
            radix: "."
        })
    }
    else {
        return new MaskedNumber({
            mask: Number,
            min: props.min ?? 0,
            max: props.max ?? 999,
            scale: props.scale ?? 0
        })
    }
})

const prependValue = computed(() => {
    if (props.prepend) {
        return props.prepend
    }

    if (props.inputType === InputType.Currency) {
        return '$'
    }
    else {
        return ''
    }
})

const appendValue = computed(() => {
    if (props.append) {
        return props.append
    }
    
    if (props.inputType === InputType.Days) {
        return 'days'
    }
    else if (props.inputType === InputType.Months) {
        return 'mo.'
    }
    else if (props.inputType === InputType.Years) {
        return 'yr.'
    }
    else if (props.inputType === InputType.Hours) {
        return 'hours'
    }
    else if (props.inputType === InputType.Percentage) {
        return '%'
    }
    else {
        return ''
    }
})
//#endregion

//#region INITIALIZE
const { auditCheckLevelClass, jsonPathValue } = useAuditChecks(props)

onMounted(() => {
    useDisableAutoComplete(inputRef)
    if (!isText.value) {
        mask.value = new InputMask(inputRef.value as HTMLElement, numberMask.value).on('accept', () => {
            //ignore negative sign and process once a real numeric value is entered
            if (mask.value.value === '-') {
                return 
            }
            valueChangedDueToInput.value = Number(vm.value) !== mask.value.typedValue
            emit('input', mask.value.value ? mask.value.typedValue : null)
        })

        if (vm.value !== null) {
            mask.value.typedValue = round(Number(vm.value), mask.value?.masked?.scale ?? 2) // round is needed else 24.6667 will translate to 24.66 incorrectly. With round, it will translate to 24.67 correctly
        }
    }
})

onBeforeUnmount(() => {
    if (!isText.value && mask.value) {
        mask.value.destroy()
    }
})
//#endregion


function onInput(event) {
    if(isText.value) {
        emit('input', event.target.value.replace(/[\r\n]+/gm, ""))
    }
}

function onChange(event){
    if(isText.value){
        emit("change", event.target.value.replace(/[\r\n]+/gm, ""))
    }
}

function handleValueChange(value: string) {
    //check to see if value changed by parent component or input text component
    if (!isText.value) {
        if(valueChangedDueToInput.value && Number(vm.value) === mask.value.typedValue) {
            valueChangedDueToInput.value = false
        } else {
            if (value !== null && value !== undefined) {
                mask.value.typedValue = Number(value)
            }
            //handle if the value is changed to null in the model / parent component
            else{
                mask.value.typedValue = NaN
            }
        }
    }
}

function onPaste(e: ClipboardEvent) {
    if (e.clipboardData && isText.value) {
        e.preventDefault();
        const plainText = e.clipboardData.getData('text/plain').replace(/[\r\n]+/gm, "")
        const input = inputRef.value as HTMLInputElement
        const position = input.selectionStart
        let finalValue = insertString(input.value, plainText, input.selectionStart ?? 0, input.selectionEnd ?? 0)
        if(props.maxLength){
            finalValue = finalValue.substring(0, props.maxLength)
        }
        input.value = finalValue
        input.selectionStart = position ? position + plainText.length : plainText.length
        input.selectionEnd = input.selectionStart
        emit('input', finalValue)
    }
}

function insertString(mainString: string, stringToInsert: string, positionStart: number, positionEnd: number) {
    return mainString.slice(0, positionStart) + stringToInsert + mainString.slice(positionEnd)
}
</script>