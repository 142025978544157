<template>
    <custom-icon :icon="icon" :title="title" v-if="showProtectedEdit" :disabled="!allowProtectedEdit" @click="handleClick($event)" />
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps } from 'vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e: 'noop', value: any): void
    (e: 'click', value: any): void
}>()

const props = defineProps({
    showProtectedEdit: { type: Boolean },
    allowProtectedEdit: { type: Boolean },
    icon: { type: String, default: "Locked" }
})
//#endregion

//#region COMPUTED
const title = computed(() => props.icon === "Locked" ? 'This field is locked: click to edit' : 'Click to edit' )
//#endregion

function handleClick(event: any) {
    if (!props.allowProtectedEdit) {
        emit('noop', event)
    } else {
        emit('click', event)
    }
}
</script>