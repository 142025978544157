import { VaNearestRelative } from './va-nearest-relative'
import { RefiComparison } from './refi-comparison';

export interface Va {
    nearestRelative: VaNearestRelative
    refiComparison: RefiComparison
    procedure: string
    vaLoanPurpose: string
    loanPurpose: string
    dulyAuthorizedAgents: DulyAuthorizedAgents[]
    occupancy: string
    exceedsReasonableValue: string
    maintenanceAssessmentAmount: number
    titleVestingType: string
    titleVestingTypeOtherDescription: string
    priorCalculatedLoanAmount: number
    underwriterVaId: string
    certificateOfEligibilityCondition: string
    isRefinancedLoanModified: boolean
    modifiedInterestRate: number | null
    modifiedTermMonths: number | null
    isRefinancedLoanArm: boolean
    armRate: number | null
    energyEfficiencyAmount: number
    energyImprovements: string
}

export interface DulyAuthorizedAgents {
    name: string | null
    address: string | null
    function: string | null
}
export enum VaLoanPurposeType {
    //old
    RefinanceRefi = 'RefinanceRefi',
    PurchaseExistingHomeNotPreviouslyOccupied = 'PurchaseExistingHomeNotPreviouslyOccupied',
    FinanceImprovementsToExistingProperty = 'FinanceImprovementsToExistingProperty',
    PurchasePermanentlySitedManufacturedHome = 'PurchasePermanentlySitedManufacturedHome',
    PurchaseExistingHomePreviouslyOccupied = 'PurchaseExistingHomePreviouslyOccupied',
    ConstructHome = 'ConstructHome',
    PurchaseNewCondoUnit = 'PurchaseNewCondoUnit',
    PurchaseExistingCondoUnit = 'PurchaseExistingCondoUnit',
    RefiPermanentlySitedManufacturedHomeToBuyLot = 'RefiPermanentlySitedManufacturedHomeToBuyLot',
    RefiPermanentlySitedManufacturedHomeLotLoan = 'RefiPermanentlySitedManufacturedHomeLotLoan',
    PurchasePermanentlySitedManufacturedHomeAndLot = 'PurchasePermanentlySitedManufacturedHomeAndLot',

    //new
    IRRR = 'IRRR',
    CashOutRefi = 'CashOutRefi',
    ConstructionTwoClosing = 'ConstructionTwoClosing',
    ConstructionOneClosing = 'ConstructionOneClosing',
    AlterationAndRepair = 'AlterationAndRepair',
    PurchaseManufacturedNotPermanent = 'PurchaseManufacturedNotPermanent',
    PurchaseCondo = 'PurchaseCondo',
    PurchaseMultiUnit = 'PurchaseMultiUnit',
    PurchaseExistingHome = 'PurchaseExistingHome'
}