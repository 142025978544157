import { Address, MonthlyEscrowRoundingType, FeePaidToType, Overridable } from './index'

export interface Escrow {
    isPropertyTaxWaived: boolean
    isHazardInsuranceWaived: boolean
    isFloodInsuranceWaived: boolean
    cushionMonths: number
    escrowItems: EscrowItem[]
    aggregateAdjustment: AggregateAdjustment
    hoiEffectiveDate: Date
    escrowAccountIndicatorType?: EscrowAccountIndicatorType
    roundingType?: MonthlyEscrowRoundingType //remove after schema update in loans api if we end up using one
}

export interface PropertyCosts{
    totalMonthlyCosts: number
}
export interface AggregateAdjustment {
    paidBy?: string
    deductFromWire: boolean
    overrideAmount: number | null
}

export interface EscrowItem {
    key: string
    paymentFrequency?: PaymentFrequencyType
    type: EscrowItemType
    description: string
    escrowPaidToType?: FeePaidToType
    payments: EscrowItemPayment[]
    estimatedDeposit?: number
    annualAmount?: number
    monthlyAmount?: number
    cushionMonths?: number
    dueDate?: Date | null
    info?: EscrowItemInformation
    unequalPayments?: UnequalPaymentDetail[] | null
    monthsCollectedOverride?: Overridable<number>
    roundingType?: MonthlyEscrowRoundingType
}

export interface EscrowItemPayment {
    escrowPaidByType?: EscrowPaidByType
    initialDeposit?: number | null
    deductFromWire?: boolean
    includeInPointsAndFees?: boolean
}

export enum PaymentFrequencyType{
    Annual = 'Annual',
    Monthly = 'Monthly',
    Other = 'Other',
    Quarterly = 'Quarterly',
    SemiAnnual = 'SemiAnnual',
    Unequal = 'Unequal'
}
export interface EscrowItemInformation {
    tax: EscrowItemTaxInformation
    insurance: EscrowItemInsuranceInformation
    address: Address
    companyName: string
    contactName: string
    contactPhoneNumber: string
}

export interface EscrowItemTaxInformation {
    delinquentDate: Date
    dateLastPaid: Date
    discountDate: Date
}

export interface EscrowItemInsuranceInformation {
    policyNumber: string
    policyCoverageAmount: number
    policyInTheNameOf: string
    effectiveDate: Date
}

export interface UnequalPaymentDetail {
    dueDate?: Date
    amount?: number
}

export enum EscrowPaidByType {
    Buyer = 'Buyer',
    LenderPremium = 'LenderPremium',
    Seller = 'Seller',
    ThirdParty = 'ThirdParty'
}

export enum EscrowItemType {
    AssessmentTax = 'AssessmentTax',
    CityBondTax = 'CityBondTax',
    CityPropertyTax = 'CityPropertyTax',
    CondominiumAssociationDues = 'CondominiumAssociationDues',
    CondominiumAssociationSpecialAssessment = 'CondominiumAssociationSpecialAssessment',
    ConstructionCompletionFunds = 'ConstructionCompletionFunds',
    CooperativeAssociationDues = 'CooperativeAssociationDues',
    CooperativeAssociationSpecialAssessment = 'CooperativeAssociationSpecialAssessment',
    CountyBondTax = 'CountyBondTax',
    CountyPropertyTax = 'CountyPropertyTax',
    DistrictPropertyTax = 'DistrictPropertyTax',
    EarthquakeInsurance = 'EarthquakeInsurance',
    EnergyEfficientImprovementFunds = 'EnergyEfficientImprovementFunds',
    FloodInsurance = 'FloodInsurance',
    HailInsurancePremium = 'HailInsurancePremium',
    HomeownersAssociationDues = 'HomeownersAssociationDues',
    HomeownersAssociationSpecialAssessment = 'HomeownersAssociationSpecialAssessment',
    HomeownersInsurance = 'HomeownersInsurance',
    MortgageInsurance = 'MortgageInsurance',
    Other = 'Other',
    ParishTax = 'ParishTax',
    PestInsurance = 'PestInsurance',
    PropertyTax = 'PropertyTax',
    RehabilitationFunds = 'RehabilitationFunds',
    SchoolPropertyTax = 'SchoolPropertyTax',
    StatePropertyTax = 'StatePropertyTax',
    TownPropertyTax = 'TownPropertyTax',
    TownshipPropertyTax = 'TownshipPropertyTax',
    VillagePropertyTax = 'VillagePropertyTax',
    VolcanoInsurance = 'VolcanoInsurance',
    WindstormInsurance = 'WindstormInsurance'
}

export enum EscrowAccountIndicatorType {
    WillHave = 'WillHave',
    Declined = 'Declined',
    NotOffered = 'NotOffered'
}