import { axiosDeliveryService, axiosFilesService } from '@/common/services'
import { FileType, PackageSummary, Transaction } from '@/propel/models'
import { PagedCollection } from '@/common/models'
import { saveAs } from 'file-saver'

class FilesService {
    async getFiles(clientCode: string, loanNumber: string): Promise<PagedCollection<PackageSummary>> {
        const response = await axiosFilesService.get(`?clientCode=${clientCode}&loanNumber=${loanNumber}`)
        return response.data
    }

    async getAllPackages(clientCode: string, loanNumber: string, params?: URLSearchParams): Promise<PagedCollection<PackageSummary>> {
        const response = await axiosFilesService.get(`?clientCode=${clientCode}&loanNumber=${loanNumber}`, { params })
        return response.data
    }
    
    async getFile(fileId: string, fileType: string): Promise<string> {
        const response = await axiosFilesService.get(`/${fileId}/${fileType}/uri`)
        return response.data
    }

    async getFilePassword(fileId: string): Promise<string> {
        const response = await axiosFilesService.get(`/${fileId}/password`)
        return response.data
    }

    async getFileViewPassword(fileId: string): Promise<string> {
        const response = await axiosFilesService.get(`/${fileId}/viewpassword`)
        return response.data
    }

    async delete(fileId: string): Promise<string> {
        const response = await axiosFilesService.delete(`/${fileId}/pdf`)
        return response.data
    }

    async getTransactionById(transactionId: string): Promise<Transaction> {
        const response = await axiosDeliveryService.get(`/transactions/${transactionId}`)
        return response.data
    }

    async getPackageById(packageId: string): Promise<PackageSummary> {
        const response = await axiosFilesService.get(`/${packageId}`)
        return response.data
    }

    async download(fileType: string, uri: string, item: PackageSummary) {
        // If there is a uri provided, use it, else look it up
        const fileUrl = uri ? uri : await this.getFile(item.id, fileType);
        // get file from azure
        const response = await fetch(fileUrl);

        const blob = await response.blob();
        let fileName;
        // get filename
        switch (fileType) {
            case FileType.Pdf:
            case FileType.SignedPdf:
            case FileType.AuditTrail:
                fileName = `${item.description} (${item.clientCode} - ${item.loanNumber}).pdf`
                break;
            case FileType.XmlImportData:
                fileName = `${item.description} (${item.clientCode} - ${item.loanNumber}).xml`
                break;
            case FileType.Ucd:
                fileName = `${item.loanNumber}.ucd.xml`
                break;
            case FileType.Loan:
                fileName = `${item.description} (${item.clientCode} - ${item.loanNumber}).json`
                break;
            case FileType.SignedZip:
                fileName = `${item.description} (${item.clientCode} - ${item.loanNumber}).zip`
                break;
            default:
                fileName = `${item.description} (${item.clientCode} - ${item.loanNumber}).txt`
        }
        // download file to client machine
        saveAs(blob, fileName);
    }

    async getFilesByFileType(clientCode: string, loanNumber: string, fileTypes: FileType[]) {
        const response = await axiosFilesService.get(`fileTypes?clientCode=${clientCode}&loanNumber=${loanNumber}&fileTypes=${fileTypes}`)
        return response.data
    }
}

export const filesService = new FilesService()