export default [
    {
        path: '/setup',
        component: () => import('@/propel/views/setup/documents-area-layout.vue'),
        children: [
            {
                path: 'fields',
                name: 'document-fields',
                component: () => import('@/propel/views/setup/document-data/fields/fields-grid.vue'),
                meta: {
                    title: 'Fields',
                    readPermission: 'metadata.forms',
                    writePermission: 'metadata.forms:write'
                }
            },
            {
                path: 'fields/json-paths',
                name: 'json-path-view',
                component: () => import('@/propel/views/setup/document-data/json-paths/json-path-view.vue'),
                meta: {
                    title: 'JSON Paths',
                    readPermission: 'metadata.forms',
                    writePermission: 'metadata.forms:write'
                },
            },
            {
                path: 'fields/field-formats',
                name: 'document-fieldFormat',
                component: () => import('@/propel/views/setup/document-data/fieldFormats/fieldFormat-grid.vue'),
                meta: {
                    title: 'Field Formats',
                    readPermission: 'metadata',
                    writePermission: 'metadata.forms:write'
                },
            },
            {
                path: 'fields/field-formats/:fieldFormatId',
                name: 'document-fieldFormat-detail',
                component: () => import('@/propel/views/setup/document-data/fieldFormats/fieldformat-detail.vue'),
                meta: {
                    readPermission: 'metadata',
                    writePermission: 'metadata.forms:write'
                }
            },
            {
                path: 'fields/images',
                name: 'document-images',
                component: () => import('@/propel/views/setup/document-data/images/configure-image.vue'),
                meta: {
                    title: 'Images',
                    readPermission: 'metadata',
                    writePermission: 'metadata.forms:write'
                },
            },
            {
                path: 'fields/:fieldId',
                name: 'document-field-detail',
                component: () => import('@/propel/views/setup/document-data/fields/field-detail.vue'),
                meta: {
                    readPermission: 'metadata.forms',
                    writePermission: 'metadata.forms:write'
                }
            },           

            {
                path: 'forms',
                name: 'documents-main',
                component: () => import('@/propel/views/setup/document-management/documents-grid.vue'),
                meta: {
                    title: 'Form Library',
                    readPermission: 'metadata.forms',
                    writePermission: 'metadata.forms:write'
                },
            },
            {
                path: 'forms/notary-configurations',
                name: 'notary-configurations',
                component: () => import('@/propel/views/setup/notary-acknowledgments/notary-configurations.vue'),
                meta: {
                    title: 'Notary Configurations',
                    readPermission: 'metadata.forms',
                    writePermission: 'metadata.forms:write'
                },
            },
            {
                path: 'forms/jurat-configurations',
                name: 'jurat-configurations',
                component: () => import('@/propel/views/setup/notary-acknowledgments/jurat-configurations.vue'),
                meta: {
                    title: 'Jurat Configurations',
                    readPermission: 'metadata.forms',
                    writePermission: 'metadata.forms:write'
                },
            },
            {
                path: 'forms/mers-data',
                name: 'mers-data',
                component: () => import('@/common/views/system/mers-data.vue'),
                meta: {
                    title: 'Mers Data',
                    readPermission: 'metadata.forms',
                    writePermission: 'metadata.forms:write'
                },
            },
            {
                path: 'forms/rule-library',
                name: 'rule-library',
                component: () => import('@/propel/views/setup/rules/rule-grid.vue'),
                meta: {
                    title: 'Rule Library',
                    readPermission: 'metadata.forms',
                    writePermission: 'metadata.forms:write'
                },
            },
            {
                path: 'forms/rule-library/:ruleId',
                name: 'rule-detail',
                component: () => import('@/propel/views/setup/rules/rule-detail.vue'),
            },
            {
                path: 'forms/form-rules',
                name: 'form-rules',
                component: () => import('@/propel/views/setup/rules/form-rules-grid.vue'),
                meta: {
                    title: 'Form Rules',
                    readPermission: 'metadata.forms',
                    writePermission: 'metadata.forms:write'
                },
            },
            {
                path: 'forms/form-rules/:formId/:formRuleId',
                name: 'form-rule-detail',
                component: () => import('@/propel/views/setup/rules/form-rule-detail.vue'),
            },
            {
                path: 'forms/sort-groups',
                name: 'sort-groups',
                component: () => import('@/propel/views/setup/rules/sort-groups.vue'),
                meta: {
                    title: 'Sort Groups',
                    readPermission: 'metadata.forms',
                    writePermission: 'metadata.forms:write'
                },
            },
            {
                path: 'forms/:documentId',
                name: 'document-detail',
                component: () => import('@/propel/views/setup/document-management/document-detail/document-detail.vue'),
                meta: {
                    title: 'Form Library',
                    itemInfo: true,
                    readPermission: 'metadata.forms',
                    writePermission: 'metadata.forms:write'
                },
            },
            {
                path: 'field-tests',
                name: 'field-tests',
                component: () => import('@/common/views/admin/tests/field-tests.vue'),
                meta: {
                    readPermission: 'metadata.forms',
                    writePermission: 'metadata.forms:write'
                }
            },
            {
                path: 'programs',
                name: 'programs',
                component: () => import('@/common/views/system/programs/program-grid.vue'),
                meta: {
                    title: 'Programs',
                    readPermission: 'metadata.programs',
                    writePermission: 'metadata.programs:write'
                }
            },
            {
                path: 'programs/:key',
                name: 'program',
                component: () => import('@/common/views/system/programs/program.vue'),
                meta: {
                    readPermission: 'metadata.programs',
                    writePermission: 'metadata.programs:write'
                }
            },
        ]
    }
]