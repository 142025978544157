<template>
    <div>
        <div v-if="props.inline">
            <span>{{ inlinevm }}</span>
        </div>
        <div v-else>
            <div>{{ street }}</div>
            <div>{{ cityCountyStateZipCountry }}</div>
        </div>    
    </div>
    </template>
    
<script setup lang="ts">
import { Address,} from '@/common/models'
import { PropType, computed, defineProps, toRef } from 'vue'

//#region DEFINE VARIABLES
const props = defineProps({
    address: {type: Object as PropType<Address>, required: true },
    county: {type: Boolean, default: false },
    inline: {type: Boolean, default: false }
})

const vm = toRef(props, 'address')
//#endregion

//#region COMPUTED
const street = computed(() => {
    return (vm.value?.street ? vm.value.street : "") +  (vm.value?.unit ? ", Unit # " +vm.value.unit : "")
})

const cityCountyStateZipCountry = computed(() => {
    return (vm.value?.city ? vm.value?.city + ", ": "") + ((props.county && vm.value?.county) ? vm.value?.county + ", ": "") +
        (vm.value?.state ? vm.value.state + " ": "") + (vm.value?.zip ? " " + vm.value.zip + " ": "") +
        (vm.value?.country ? vm.value.country : "")
})

const inlinevm = computed(() => {
    let vm = street.value
        if(cityCountyStateZipCountry.value)        
            vm += ", "
        vm += cityCountyStateZipCountry.value

        return vm
})
//#endregion
</script>