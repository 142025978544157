import { startCase } from "lodash"
import { ChangeOfCircumstanceReasonType, Fee, NewTridDisclosureRequest, PrepaidItem, ReferenceSource, ReferenceType, TridDisclosure, TridDisclosureCopyType } from "../models"
import { currencyFilter, dateFilter } from "../filters"
import { axiosLoanService, referenceService } from "."
import { MultiSelectItem } from "../components/multi-select-item"
import { RawLocation } from "vue-router"
import { useLoanStore } from "@/common/store"

class TridDisclosureService {
    getDisclosureTitle(disclosure: TridDisclosure){
        if (disclosure && disclosure.type) {
            return `${startCase(disclosure.type)} - ${dateFilter(disclosure.issuedDate, "MM/DD")}` 
        } else { 
            return 'New Disclosure'
        }
    }

    getFeeAndPrepaidDetailsForTridDisclosure(currentDisclosureFees: Fee[], currentDisclosurePrepaids: PrepaidItem[]) {
        const disclosureDetails: TridDisclosureItemDetails[] = []
        const store = useLoanStore()
        const allFeesAndPrepaids = store.tridDisclosures.flatMap((i: TridDisclosure) => 
        { 
            const disclosureTitle = this.getDisclosureTitle(i)
            const disclosureFees = i.loan.fees.map(i => ({ key: i.key, description: i.description, amount: i.totalAmount, isPrepaid: false }))
            const disclosurePrepaids = (i.loan.closing?.prepaidItems ?? []).map(i => ({ key: i.key, description: i.description, amount: i.totalAmount, isPrepaid: true }))

            const feesAndPrepaids = [...disclosureFees, ...disclosurePrepaids]

            disclosureDetails.push({ title: disclosureTitle, feeAndPrepaidDetails: feesAndPrepaids })

            return feesAndPrepaids
        })

        const allFeeAndPrepaidIds = allFeesAndPrepaids.map((i: TridDisclosureItemDetail) => i.key)
        const distinctFeeAndPrepaidIds = [... new Set<string>(allFeeAndPrepaidIds)]

        const currentDisclosureFeesAndPrepaids = [...currentDisclosurePrepaids, ...currentDisclosureFees]
        const currentDisclosureFeeAndPrepaidIds = currentDisclosureFeesAndPrepaids.map((i: Fee | PrepaidItem) => i.key)
        
        const feeAndPrepaidIdsToShow = distinctFeeAndPrepaidIds.filter(i => !currentDisclosureFeeAndPrepaidIds.includes(i))
        const feeAndPrepaidIdsToSelectFrom = feeAndPrepaidIdsToShow
            .map(id => allFeesAndPrepaids.find((i: TridDisclosureItemDetail) => i.key === id)) // find first item with id, assuming it has details we want
            .filter(i => i) // only want items we can find, otherwise above line will return undefined
            .map((itemToTransform: TridDisclosureItemDetail | undefined) => {
                if (itemToTransform) {
                    return { 
                        value: itemToTransform.key, 
                        text: `${itemToTransform.description} (${currencyFilter(itemToTransform.amount)})`, 
                        secondaryText: this.getTridDisclosureTitleForItem(itemToTransform, disclosureDetails), // do we intend this to only show the first disclosure? 
                        isPrepaid: itemToTransform.isPrepaid
                    } as TridDisclosureItemLinkSelectItem
                }
            }) as TridDisclosureItemLinkSelectItem[]

        const currentDisclosureFeeAndPrepaidDetails = currentDisclosureFeesAndPrepaids.map((i: Fee | PrepaidItem) => this.getInclusionAndTridDisclosureDetailsForItem(i, disclosureDetails))

        return {
            distinctFeeAndPrepaidIds,
            currentDisclosureFeeAndPrepaidDetails,
            feeAndPrepaidIdsToSelectFrom,
        }             
    }

    buildRawLocationForTridDisclosures(id: string, indexToNavigateTo: number, refreshDisclosure: boolean, actionParameter: string): RawLocation {
        const query = { t: new Date().toISOString() }
        if (refreshDisclosure) {
            query['refreshDisclosure'] = `${true}`
        } else {
            query[actionParameter] = `${true}`
        }

        return { 
            name: 'regcheck-trid-disclosure', 
            params: { 
                id: id, 
                index: `${indexToNavigateTo}`, 
            },
            query: query
        }
    }

    disclosureCocTypesToFilter = [
        ChangeOfCircumstanceReasonType.AdditionOfPrepaymentPenalty,
        ChangeOfCircumstanceReasonType.APR,
        ChangeOfCircumstanceReasonType.LoanProduct,
        ChangeOfCircumstanceReasonType.ClericalErrorCorrection,
        ChangeOfCircumstanceReasonType.Item24HourAdvancedPreview,
        ChangeOfCircumstanceReasonType.ToleranceCure
    ] 

    async getFeeCocTypes(withOther = true) { 
        const typesBeforeFilter = await referenceService.getMultiSelectItems(ReferenceType.cocTypes, ReferenceSource.loan)
        const typesAfterFilter = typesBeforeFilter.filter(i => !this.disclosureCocTypesToFilter.includes(i.value))
        if(!withOther) {
            const otherIndex = typesAfterFilter.findIndex(i => i.value === ChangeOfCircumstanceReasonType.Other)
            if (otherIndex !== -1) { 
                typesAfterFilter.splice(otherIndex, 1)
            }
        }
        return typesAfterFilter
    }

    private getTridDisclosureTitleForItem(item: TridDisclosureItemDetail, tridDisclosureFeeDetails: TridDisclosureItemDetails[]) {
        for (let i = 0; i < tridDisclosureFeeDetails.length; i++) {
            const disclosure = tridDisclosureFeeDetails[i]
            const disclosureFeeDetail = disclosure.feeAndPrepaidDetails.find(i => i.key === item.key)
            if (disclosureFeeDetail) {
                return disclosure.title
            }
        }

        return ""
    }

    private getInclusionAndTridDisclosureDetailsForItem(item: Fee | PrepaidItem, tridDisclosureFeeDetails: TridDisclosureItemDetails[]): TridDisclosureFeeOrPrepaidDetails {
        let includedInAllDisclosures = true
        const disclosureFeeDetails = [] as { 
            title: string 
            amount: string 
        }[]
        for (let i = 0; i < tridDisclosureFeeDetails.length; i++) {
            const disclosure = tridDisclosureFeeDetails[i]
            const disclosureFeeDetail = disclosure.feeAndPrepaidDetails.find(i => i.key === item.key)
            if (disclosureFeeDetail) {
                disclosureFeeDetails.push({ title: disclosure.title, amount: `(${currencyFilter(disclosureFeeDetail.amount)})` })
            } else {
                includedInAllDisclosures = false
            }
        }

        return {
            key: item.key,
            includedInAllDisclosures,
            disclosureFeeDetails
        }
    }

    async addTridDisclosure(loanId: string, request: NewTridDisclosureRequest) {
        // updating values in request to make control simpler to use
        const isCurrentReq = request.copyType === TridDisclosureCopyType.Current
        const tridDisclosureRequest: NewTridDisclosureRequest = {
            copyType: isCurrentReq ? TridDisclosureCopyType.Current : TridDisclosureCopyType.Index,
            indexToCopyFrom: isCurrentReq ? null : Number(request.copyType),
            issuedDate: request.issuedDate,
            type: request.type,
            loanRequestOptions: request.loanRequestOptions,
            isProjected: request.isProjected
        }
        const response = await axiosLoanService.post(`/${loanId}/trid-disclosures`, tridDisclosureRequest)
        return response.data
    }

    async deleteTridDisclosure(loanId: string, index: number) {
        const response = await axiosLoanService.delete(`/${loanId}/trid-disclosures/${index}`, this.rcRequestOptionsConfig)
        return response.data
    }

    async postTridDisclosure(loanId: string, indexToUpdate: number, tridDisclosureData: TridDisclosure) {
        const response = await axiosLoanService.post(`/${loanId}/trid-disclosures/${indexToUpdate}`, tridDisclosureData)
        return response.data
    }

    private rcRequestOptionsConfig = {
        params: {
            'includeCd': 'true',
            'includeCompliance': 'true'
        }
    }
}

interface TridDisclosureItemDetails {
    title: string
    feeAndPrepaidDetails: TridDisclosureItemDetail[]
}

interface TridDisclosureItemDetail { 
    key: string 
    amount: number | undefined
    description: string
    isPrepaid: boolean
}

export interface TridDisclosureFeeOrPrepaidDetails {
    key: string
    includedInAllDisclosures: boolean
    disclosureFeeDetails: { 
        title: string 
        amount: string 
    }[]
}

export interface TridDisclosureItemLinkSelectItem extends MultiSelectItem {
    isPrepaid: boolean
}

export const tridDisclosureService = new TridDisclosureService()