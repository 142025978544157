import { Overridable } from './index'

export interface Arm {
    marginRate: number
    firstAdjustmentDate: Date
    firstRateAdjMonths: number
    firstCapPercentage: number
    subsequentRateAdjMonths: number
    subsequentCapPercentage: number
    subsequentPaymentAdjMonths: number
    rateAdjLifetimeCapPercentage: number
    indexType: ArmIndexType
    indexRate: Overridable<number>
    indexDate: Date | null
    floorRate: Overridable<number>
    rounding: Overridable<string | null>
    indexRateAtRateLock: Overridable<number>
}

export enum ArmIndexType {
    TreasuryOneYear = 'TreasuryOneYear',
    TreasuryThreeYear = 'TreasuryThreeYear',
    TreasuryFiveYear = 'TreasuryFiveYear',
    WsjPrime = 'WsjPrime',
    SofrThirtyDayAverage = 'SofrThirtyDayAverage'
}