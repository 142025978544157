

<template>
    <div>
        <span class="label">
            <fg-checkbox
                v-if="isAllPartnerClient"
                :id="`userPermissions${node.id}`"
                :class="`level-${node.level}`"
                v-model="node.isAllPCPermissionChecked"
                :label="node.description"
                :disabled="node.id == 'loans' ? true: false"
                @change="handlePermissionNode(category, node, node.isAllPCPermissionChecked)"
            />
            <fg-checkbox
                v-else-if="isSystem"
                :id="`userPermissions${node.id}`"
                :class="`level-${node.level}`"
                v-model="node.isSystemPermissionChecked"
                :label="node.description"
                @change="handlePermissionNode(category, node, node.isSystemPermissionChecked)"
            />
            <fg-checkbox
                v-else-if="isPartner"
                :id="`userPermissions${node.id}`"
                :class="`level-${node.level}`"
                v-model="node.isPartnerPermissionChecked"
                :label="node.description"
                :disabled="node.id == 'loans' ? true: false"
                @change="handlePermissionNode(category, node, node.isPartnerPermissionChecked)"
            />
            <fg-checkbox
                v-else-if="isClient"
                :id="`userPermissions${node.id}`"
                :class="`level-${node.level}`"
                v-model="node.isClientPermissionChecked"
                :label="node.description"
                :disabled="node.id == 'loans' ? true: false"
                @change="handlePermissionNode(category, node, node.isClientPermissionChecked)"
            />
        </span>
        <div v-if="node.children && node.children.length">
            <permission-node v-for="child in node.children" :node="child" :key="child.id" :category="category"> </permission-node>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ExtendedPermissionGroup, PermissionCategory } from '@/common/models'
import { permissionMapperService } from '@/common/services'
import { computed, defineProps, PropType, toRef } from 'vue'
//#region DEFINE VARIABLES
const props = defineProps({
    node: { type: Object as PropType<any>, required: true },
    category: { type: String as PropType<PermissionCategory>, required: true }
})

const node = toRef(props, 'node')
//#endregion

//#region COMPUTED
const isSystem = computed(() => props.category === PermissionCategory.System)
const isPartner = computed(() => props.category === PermissionCategory.Partner)
const isClient = computed(() => props.category === PermissionCategory.Client)
const isAllPartnerClient = computed(() => props.category === PermissionCategory.AllPartnersClient)
//#endregion

function handlePermissionNode(category: PermissionCategory, permission: ExtendedPermissionGroup, isPermissionChecked: boolean) {
    permissionMapperService.checkAllParent(category, permission)
    if (permission.children.length > 0) {
        permissionMapperService.checkAllChildren(category, permission.children, isPermissionChecked)
    }
}
</script>

<style lang="scss" scoped>
.level-2 {
    padding-left: 15px;
}
.level-3 {
    padding-left: 39px;
}
.level-4 {
    padding-left: 63px;
}
.level-5 {
    padding-left: 87px;
}
</style>
