<template>
    <b-modal
        :id="id"
        @ok="ok"
        @cancel="cancel"
        :ok-title="okTitle"
        :ok-only="okOnly"
        v-model="internalValue"
        :ok-variant="okVariant"
        :cancel-title="cancelTitle"
        :cancel-variant="cancelVariant"
        hide-header
    >
        <blurb :type="messageType">
            <template v-slot:shortMessage><slot name="shortMessage"></slot></template>
            <template v-slot:longMessage><slot name="longMessage"></slot> </template>
        </blurb>
    </b-modal>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps } from 'vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:'ok')
    (e:'cancel')
    (e:'input', value: any)
}>()

const props = defineProps({
    id: { type: String },
    value: { type: Boolean },
    okOnly: { type: Boolean },
    messageType: { type: String, default: "Warning" },
    okTitle: { type: String, default: "OK" },
    okVariant: { type: String, default: "primary" },
    cancelTitle: { type: String, default: "Cancel" },
    cancelVariant: { type: String, default: "secondary" },
})

//#endregion

//#region COMPUTED
const internalValue = computed({
    get() {
        return props.value
    },
    set(value: any) {
        emit('input', value)
    }
})
//#endregion

function ok() {
    emit('ok')
}

function cancel() {
    emit('cancel')
}
</script>

<style scoped lang="scss">
:deep(.modal) {
    top: 75px;

    .modal-content {

        .modal-body {
            border-radius: 0.25rem;
            padding-top: 1.25rem;
        }
        .modal-footer {
            background: $background-color;
            border: none;
            border-radius: 0.25rem;
            padding-top: 0;
        }
    }
}
</style>

