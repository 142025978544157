import { Condition } from './condition';
import { AuditInfo } from './audit-info';
export interface FormRuleSummary {
    id: string
    formId: string
    formTitle: string
    name: string
    description: string
    placement: FormPlacement
    sortGroupId: string
    afterFormIds: string[]
    sortOrder: number
    status: number
    isOptional: boolean
    created: Date
    createdBy: string
    updated: Date
    updatedBy: string
}

export interface FormRule {
    id: string | null
    formId: string
    formTitle: string
    name: string
    description: string
    notes: string
    placement: FormPlacement
    status: string
    isOptional: boolean
    conditions: Condition
    audit: AuditInfo
    active: boolean
    placementRules: PlacementRule[]
}

export interface PlacementRule{
    conditions: Condition
    formPlacement: FormPlacement
    sortGroupId: string
    sortOrder: number
    afterFormIds: string []
}

export interface CopiesRule {
    conditions: Condition
    copies: number
}

export enum FormPlacement{
    SortOrder = "SortOrder",
    AfterOtherForms = "AfterOtherForms"
}