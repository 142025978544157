<template>
    <v-date-picker
        ref="datePicker"
        v-model="dateToChange"
        :mode="mode"
        :update-on-input="false"
        locale="en"
        timezone="UTC"
        :model-config="modelConfig"
        :masks="{ input: ['MM/DD/YYYY', 'M/D/YYYY'] }"
        :popover="{ placement: placement, visibility: 'hidden' }"
        @input="$emit('input', $event)"
        :attributes="[{dates: new Date(), key: 'Today', bar: true}]"
        :class="mode == DatePickerMode.Time ? 'hide-date' : ''"
    >
        <template v-slot="{ inputValue, inputEvents, togglePopover }">
            <div :class="['input-group', auditCheckLevelClass]">
                <input
                    type="text"
                    class="form-control"
                    :readonly="restricted"
                    :value="inputValue"
                    :placeholder="placeholder"
                    :id="`${uid}`"
                    v-on="inputEvents"
                    :jsonpath="jsonPath"
                    @change="validateInput"
                    ref="input"
                />
                <div class="input-group-append">
                    <span
                        :class="`input-group-text${restricted ? '' : ' open-calendar'}`"
                        @click="restricted ? 'noop' : togglePopover({ placement: placement })"
                    >
                        <b-icon-calendar3 :class="{ disabled: !!restricted }" />
                    </span>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div v-if="mode != DatePickerMode.Time" class="footer">
                <a href="#" @click="moveToToday()">Today</a>
            </div>
        </template>
    </v-date-picker>
</template>

<script setup lang="ts">
import {  defineEmits, defineProps, PropType, ref, watch, onMounted } from 'vue'
import { DatePicker as VDatePicker } from 'v-calendar/lib/v-calendar.umd'
import { useAuditChecks } from '@/common/composables/audit-check-composable'
import { useDisableAutoComplete } from '@/common/composables/disable-autocomplete-composable'
import { DatePickerMode } from './date-picker-mode'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e: 'input', value: boolean): void
    (e: 'update:value', value: Date | null): void
}>()

const props = defineProps({
    value: { type: null as unknown as PropType<Date | null>, default: null },
    restricted: { type: Boolean, default: false },
    placement: { type: String, default: 'top' },
    mode: { type: String as PropType<DatePickerMode>, default: DatePickerMode.Date },
    jsonPath: { type: String, default: '' },
    modelConfig: {},
    placeholder: { type: String, default: null }
})

const dateToChange = ref<Date | null>(null)
const uid = ref('datePickerId-' + Math.random().toString(16).slice(2))
const input = ref<HTMLInputElement | null>(null)
const datePicker = ref(null)
//#endregion

//#region INITIALIZE
const { auditCheckLevelClass } = useAuditChecks(props)
onMounted(() => useDisableAutoComplete(input))
//#endregion

//#region WATCH
watch(
    () => dateToChange.value,
    () => {
        emit('update:value', dateToChange.value )
    }
)

watch(
    () => props.value,
    () => dateToChange.value = props.value,
    { immediate: true }
)
//#endregion

function validateInput() {
    const inputElement = input.value as HTMLInputElement
    if (input.value && inputElement.value.length < 6) {
        input.value.value = ''
    }
}

function moveToToday() {
    type VDatePicker = typeof VDatePicker
    (datePicker.value as VDatePicker).move(new Date())
    dateToChange.value = new Date()
}

</script>

<style lang="scss" scoped>
.open-calendar {
    cursor: pointer;

    &:hover {
        .b-icon:not(button.icon-only) {
            &:not(.disabled) {
                color: $link-hover-color;
            }
        }
    }
}
.b-icon:not(button.icon-only) {
    margin: 0px;
}

.footer {
    width: 25%;
    text-align: center;
    z-index: 1;
    padding-bottom: .625rem;
    margin: 0 auto;
    margin-top: -1.875rem;

    a {
        position:relative;
        z-index: 1;
        font-weight: bold;

        &:hover {
            text-decoration: none;
        }
    }
}
.vc-time-picker + .footer {
    margin-top:0;
}

.hide-date {
    :deep(.vc-date) {
      display: none
    }
}
</style>