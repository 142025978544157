import { render, staticRenderFns } from "./field-value.vue?vue&type=template&id=22bd4d7b&scoped=true"
import script from "./field-value.vue?vue&type=script&setup=true&lang=ts"
export * from "./field-value.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./field-value.vue?vue&type=style&index=0&id=22bd4d7b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22bd4d7b",
  null
  
)

export default component.exports