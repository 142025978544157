<template>
    <custom-icon v-if="displayAuditCheckIcon" :icon="icon" :id="`${id}-auditCheckIcon`" :title="text" />
</template>

<script setup lang="ts">
import { PropType, computed, defineProps } from 'vue'
import { useAuditCheckLevelClass, useDisplayAuditCheckErrors } from '@/common/composables/audit-check-composable'
import { AuditCheckResult } from '@/propel/models'

//#region DEFINE VARIABLES
const props = defineProps({
    id: { type: String, required: true },
    auditChecks: { type: Array as PropType<Array<AuditCheckResult>>, default: () => [] }
})
//#endregion

//#region COMPUTED
const text = computed(() => props.auditChecks.map((x) => x.message).join('<br>'))
const icon = computed(() => {
    if (props.auditChecks.length) {
            switch (auditCheckLevelClass.value) {
                case 'error': return 'Error' 
                case 'warning': return 'Warning'
                case 'success': return 'Success'
            }
        }

        return 'Error'
})
const auditChecks = computed(() => props.auditChecks)
const displayAuditCheckIcon = computed(() => displayAuditCheckErrors.value && props.auditChecks.length)
//#endregion

//#region INITIALIZE
const { auditCheckLevelClass } = useAuditCheckLevelClass(auditChecks)
const { displayAuditCheckErrors } = useDisplayAuditCheckErrors()
//#endregion
</script>