<template>
    <summary-sidebar v-if="loanRecord" @sidebarStateChanged="onSidebarStateChanged" :state="sidebarState">
        <template v-slot:header>
            <div class="subtitle">
                <span class="loan-number">
                    <router-link v-if="canAccessLenderSettings" :to="{ name: 'client-lender-profiles', params: { code: clientCode } }">{{
                        clientCode
                    }}</router-link>
                    <span v-else>{{ clientCode }}</span>
                    <custom-icon icon="Bullet" /> {{ loanRecord.loanNumber }}
                </span>
                <custom-icon icon="Copy" class="clickable" title="Copy loan number to clipboard" @click="copyLoanNumberToClipboard" />
            </div>
            <div class="title">{{ primaryBorrower }}</div>
            <div class="address">{{ loanAddress }}</div>
            <div class="address">{{ cityState }}</div>
        </template>
        <div class="summary-section">
            <div>{{ programName }}</div>
        </div>
        <div class="summary-section label-value-list">
            <div>
                <div >Purpose:</div>
                <div>{{ loanRecord.data.terms.loanPurpose }}</div>
            </div>
            <div >
                <div >Occupancy Type:</div>
                <div>{{ occupancyType }}</div>
            </div>
        </div>
        <div class="summary-section label-value-list">
            <div >
                <div >Loan Amount:</div>
                <div>{{ currencyFilter(loanRecord.data.terms.baseLoanAmount) }}</div>
            </div>
            <div  v-if="!isLoanHeloc">
                <div >Upfront MI:</div>
                <div>{{ currencyFilter(upfrontAmount) }}</div>
            </div>
            <div >
                <div >Total Loan Amt:</div>
                <div>{{ currencyFilter(loanRecord.calculations?.totalLoanAmount) }}</div>
            </div>
            <div >
                <div >Rate:</div>
                <div>{{ numericFilter(loanRecord.data.terms.noteRate, "0.000") }}%</div>
            </div>
            <div >
                <div >Term:</div>
                <div>{{ loanRecord.data.terms.noteTerm }}</div>
            </div>
            <div  v-if="!isLoanHeloc">
                <div >P&I Payment:</div>
                <div>{{ currencyFilter(loanRecord.calculations?.initialPrincipalAndInterestPaymentAmount) }}</div>
            </div>
            <div  v-if="!isLoanHeloc">
                <div >APR:</div>
                <div>{{ numericFilter(loanRecord.calculations?.apr, "0.000") }}%</div>
            </div>
            <div  v-if="!isLoanHeloc">
                <div >LTV:</div>
                <div>{{ numericFilter(loanRecord.calculations?.rawLtv, "0.000") }}%</div>
            </div>
            <div  v-if="!isLoanHeloc">
                <div >CLTV:</div>
                <div>{{ numericFilter(loanRecord.calculations?.rawCltv, "0.000") }}%</div>
            </div>
        </div>
        <div class="summary-section label-value-list">
            <div >
                <div >Appraised Value:</div>
                <div>{{ currencyFilter(loanProperty.propertyValue) }}</div>
            </div>
            <div >
                <div >Sales Price:</div>
                <div>{{ currencyFilter(loanProperty.salesPrice) }}</div>
            </div>
        </div>
        <div class="summary-section label-value-list">
            <div >
                <div >Closing:</div>
                <div>{{ dateFilter(loanRecord.data.closing.closingDate) }}</div>
            </div>
            <div  v-if="loanRecord.data.closing.rescissionDate.value">
                <div >Rescission:</div>
                <div>{{ dateFilter(loanRecord.data.closing.rescissionDate.value) }}</div>
            </div>
            <div >
                <div >Disbursement:</div>
                <div>{{ dateFilter(loanRecord.data.closing.fundingDate.value) }}</div>
            </div>
            <div >
                <div >First Payment:</div>
                <div>{{ dateFilter(loanRecord.data.firstPaymentDate)}}</div>
            </div>
            <div >
                <div >Maturity:</div>
                <div>{{ dateFilter(loanRecord.calculations?.maturityDate) }}</div>
            </div>
        </div>
        <div class="summary-section label-value-list">
            <div >
                <div >Lock Exp:</div>
                <div>{{ dateFilter(loanRecord.data.product.lockExpiration) }}</div>
            </div>
        </div>
        <template v-slot:footer>
            <blurb small v-if="loanRecord.audit.loanReview">
                <template v-slot:shortMessage>Submitted for Loan Review</template>
                <template v-slot:longMessage>{{ loanRecord.audit.loanReview.user }} submitted this loan for review on {{ loanReviewDate }}</template>
            </blurb>
            <blurb type="Error" small v-if="!calcsAreOk">
                <template v-slot:shortMessage>An error occurred while running calculations</template>
            </blurb>
            <div class="audit-data">
                <loading-overlay :show="isSaveLoading" :text="isPersist || isCommitLoading ? 'Saving/Recalculating' : 'Recalculating'">
                    <div class="summary-section audit-item" v-if="loanLock">
                        <div>Locked by {{ loanLock.user }}</div>
                        <div class="timestamp">{{ dateTimeDiffFilter(loanLock.updateTime) }}</div>
                    </div>
                    <div class="summary-section audit-item" v-else>
                        <div>Last updated by {{ loanAudit.updated.user }}</div>
                        <div class="timestamp">{{ dateTimeDiffFilter(loanAudit.updated.timestamp) }}</div>
                    </div>
                    <div class="summary-section audit-item">
                        <div>Created by {{ loanAudit.created.user }}</div>
                        <div class="timestamp">{{ dateTimeDiffFilter(loanAudit.created.timestamp) }}</div>
                    </div>
                </loading-overlay>
            </div>
        </template>
        <template v-slot:collapsed>
            <loading-overlay :show="isSaveLoading" :text="isPersist || isCommitLoading ? 'Saving/Recalculating' : 'Recalculating'">
                <div class="subtitle">
                    <span class="loan-number">
                        <router-link v-if="canAccessLenderSettings" :to="{ name: 'client-lender-profiles', params: { code: clientCode } }">{{
                            clientCode
                        }}</router-link>
                        <span v-else>{{ clientCode }}</span>
                        <custom-icon icon="Bullet" /> {{ loanRecord.loanNumber }}
                    </span>
                    <custom-icon icon="Copy" class="clickable" title="Copy loan number to clipboard" @click="copyLoanNumberToClipboard" />
                </div>
                <div class="title" :title="primaryBorrower">{{ primaryBorrower }}</div>
                <div :title="programName">{{ programName }}</div>
                <blurb type="Error" small v-if="!calcsAreOk">
                    <template v-slot:shortMessage>An error occurred while running calculations</template>
                </blurb>
                <div v-else :title="amountAndPurpose">{{ amountAndPurpose }}</div>
                <div class="audit" v-if="loanLock" :title="`Locked by ${loanLock.user} - ${dateTimeDiffFilter(loanLock.updateTime)}`">
                    Locked by {{ loanLock.user }} <custom-icon icon="Bullet" /> {{ dateTimeDiffFilter(loanLock.updateTime) }}
                </div>
                <div class="audit" v-else :title="`Last updated by ${loanAudit.updated.user} - ${dateTimeDiffFilter(loanAudit.updated.timestamp)}`">
                    Updated by {{ loanAudit.updated.user }} <custom-icon icon="Bullet" /> {{ dateTimeDiffFilter(loanAudit.updated.timestamp) }}
                </div>
            </loading-overlay>
        </template>
    </summary-sidebar>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue"
import { dateFilter, dateTimeDiffFilter, dateTimeFilter, numericFilter, currencyFilter } from "@/common/filters"
import { AuditInfo, CalculatedData, LoanRecord, MortgageType, Property, SidebarState } from "@/common/models"
import { DisplayHelper } from "@/common/utilities/helper"
import { adminDataService, messageService, userPermissionValidatorService, userPreferenceService } from "@/common/services"
import useClipboard from "vue-clipboard3"
import SummarySidebar from "@/common/components/summary-sidebar.vue"
import { useLoanStore, usePageActionsStore } from "@/common/store"
//#region DEFINE VARIABLES
const clientCode = ref("")
const canAccessLenderSettings = ref(false)
const pageActions = usePageActionsStore()
const store = useLoanStore()
//#endregion

//#region COMPUTED
const isPersist = computed<boolean | null>(() => store.persist)
const loanRecord = computed<LoanRecord>(() => store.loanRecord ?? {} as LoanRecord)
const loanProperty = computed<Property>(() => store.property)
const isCommitLoading = computed<boolean>(() => store.cacheCommitLoading)
const calculatedData = computed<CalculatedData>(() => store.loanRecordCalculations)
const isLoanHeloc = computed<boolean>(() => store.terms.mortgageType == MortgageType.HELOC)
const upfrontAmount = computed<number>(() => loanRecord.value.calculations?.pmi?.totalUpfrontFee ?? 0)
const loanAudit = computed<AuditInfo>(() => store.loanRecord?.audit ?? ({} as AuditInfo))
const loanLock = computed<any>(() => store.loanLock)

const isSaveLoading = computed<boolean>(() => pageActions.saveLoading.isLoading)

const loanReviewDate = computed<string>(() => {
    return loanRecord.value.audit.loanReview.timestamp ? dateTimeFilter(loanRecord.value.audit.loanReview.timestamp) : ''
})

const loanAddress = computed<string>(() => {
    const propAddress = loanProperty.value.address
    const street = propAddress?.street ?? ""
    const description = propAddress?.unitDescription ?? ""
    const number = propAddress?.unit ?? ""

    if (description != "" && number != "")
        return  `${street}, ${description} ${number}`

    return street
})

const calcsAreOk = computed<boolean>(() => {
    // release of lien loans do not run calculations
    if (store.isReleaseOfLien)
        return true

    if (calculatedData.value)
        return !(calculatedData.value.errors?.hasErrors ?? true)

    return false
})

const cityState = computed<string>(() => {
    const propAddress = loanProperty.value.address
    const state = propAddress?.state ?? ""
    const zip = propAddress?.zip ?? ""

    let city = propAddress?.city ?? ""
    if (city) city += ", "

    return `${city}${state} ${zip}`
})

const primaryBorrower = computed<string | undefined>(() => {
    const borrower = loanRecord.value.data.borrowers?.length > 0 ? loanRecord.value.data.borrowers[0] : undefined
    if (borrower) return DisplayHelper.getBorrowerOrSellerName(borrower)
    else return undefined
})

const occupancyType = computed<string>(() => {
    let type = loanProperty.value.propertyUsageType || ""
    type = type.split(/(?=[A-Z])/).join(" ")

    return `${type}`
})

const programName = computed<string>(() => {
    if (store.isReleaseOfLien)
        return "Release of Lien"
    else
        return loanRecord.value.data.product?.displayName ?? ""
})

const amountAndPurpose = computed<string>(() => {
    const purpose = loanRecord.value.data.terms?.loanPurpose ?? ""
    const amount = loanRecord.value.data.terms?.baseLoanAmount
    
    return `${numericFilter(amount, "$0,0")} ${purpose}`
})

const sidebarState = computed<SidebarState>(() => {
    const sideBarState = userPreferenceService.getLoanSummarySidebarState()
    return sideBarState ? SidebarState.Collapsed : SidebarState.Expanded
})
//#endregion

//#region INITIALIZE
initialize()

async function initialize() {
    const client = await adminDataService.getClient(store.clientCode)
    clientCode.value = client.code
    canAccessLenderSettings.value = userPermissionValidatorService.hasAnyPermission(["admindata.clients.settings:read"], client.code)
}

// the default is for this to be set to true, if it set to true, the click the package sets up to trigger clipboardjs is outside of the vue component and messes with the content-block-list close behavior
const { toClipboard } = useClipboard({ appendToBody: false }) 
//#endregion

function onSidebarStateChanged(state: SidebarState) {
    userPreferenceService.setLoanSummarySidebarState(state === SidebarState.Collapsed)
}

async function copyLoanNumberToClipboard() {
    const loanNumber = loanRecord.value.loanNumber
    toClipboard(loanNumber).then(function () {
        messageService.showSuccess(`Loan number ${loanNumber} copied to clipboard`)
    })
}
</script>

<style scoped lang="scss">
// this file's scss needs to be kept in sync with @/regcheck/views/loans/regcheck-loan-summary.vue to maintain the same look and feel
// they use the same summary-sidebar file but each has the below entry due to components used in the summary-sidebar components slots
.summary-sidebar {
    .summary-content.collapsed {
        .blurb {
            :deep {
                &.small-blurb {
                    font-size: 0.75rem;
                    gap: 0.375rem;

                    .custom-icon {
                        height: 1.125rem;
                    }
                }
            }
        }
    }

    .summary-footer {
        .blurb {   
            :deep {
                &.small-blurb .custom-icon {
                    height: 1.5rem;
                }
            }
        }
    }
}
</style>