<template>
    <div :id="`${id}-group`" :class="`field ${size} form-group text-area-group`">
        <!-- PROPEL-4750 - there is an issue when using the validation provider for a textarea inside a modal. -->
        <!-- Commenting out for now since this functionality is not used. If it does end up being needed, we will need a workaround. One possibility is to create a fg-textarea-validated component that has a validation provider wrapped around the fg-textarea -->
        <!-- <validation-provider :name="label" :rules="validationRules" :immediate="immediateValidation" v-slot="{ errors }"> -->
            <div class="label-container" v-if="label || helpText">
                <label :for="id" v-if="label">{{ label }}</label>
                <span v-if="jsonPathLabel" class="json-path-label">{{jsonPathLabel}}</span>
                <custom-icon icon="Help" :id="`${id}-help`" v-if="helpText" :title="helpText" />
            </div>
            <div class="base-input-container">
                <textarea
                    :id="`${id}`"
                    class="form-control"
                    :class="auditCheckLevelClass ? auditCheckLevelClass : getErrorMessage() ? 'error' : ''"
                    :rows="rows"
                    :disabled="isDisabled"
                    :value="value"
                    @input="onInput"
                    @change="onChange"
                    ref="textareaRef"
                    @paste.prevent="onPaste"
                    :maxlength="maxLength"
                    :placeholder="`${placeholder || ''}`"
                    :jsonpath="jsonPathValue"
                />
                <div class="base-input-icons">
                    <slot name="icon"></slot>
                    <protected-edit-icon :icon="protectedEditIconString" :showProtectedEdit="showProtectedEdit" :allowProtectedEdit="allowProtectedEdit" @click="turnOffProtectedDisabled" />
                    <fg-defaultable
                        :currentValue="value"
                        :defaultValue="defaultValue"
                        :isDefaulted="isDefaulted"
                        @change="onDefaultValueChange"
                        @defaultChange="onDefaultChange"
                        :disabled="!showDefaultFieldEdit"
                    />
                    <custom-icon icon="Error" :id="`${id}-error`" v-if="getErrorMessage()" :title="getErrorMessage()" />
                    <audit-check-icon :id="`${id}-audit-check`" :auditChecks="auditChecks" />
                </div>
            </div>
        <!-- </validation-provider> -->
    </div>
</template>

<script setup lang="ts">
import { PropType, defineEmits, defineProps, ref, onMounted } from 'vue'
import { useFormGroupBase, baseProps } from './form-group-base-composable'
import { useDisableAutoComplete } from '@/common/composables/disable-autocomplete-composable'

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'input', value: string): void
    (e: 'unlocked'): void
    (e: 'change', event: any): void
}>()

const props = defineProps({
    // TODO: Add the following type to value, and fix all vue warnings it causes. These warnings are caused by initialization issues.
    // "type: String"
    value: { required: true},
    id: { type: String, required: true },
    size: { type: String as PropType<string|null>, default: '' },
    rows: { type: Number },
    label: { type: String },
    helpText: { type: String },
    maxLength: { type: String },
    placeholder: { type: String },
    ...baseProps
})

const textareaRef = ref<HTMLTextAreaElement | null>(null)
//#endregion

//#region INITIALIZE
const { auditCheckLevelClass, isDisabled, jsonPathLabel, jsonPathValue, protectedEditIconString, showProtectedEdit, allowProtectedEdit, showDefaultFieldEdit,  defaultValue, isDefaulted, auditChecks,
 onDefaultChange, turnOffProtectedDisabled, onDefaultValueChange, getErrorMessage } = useFormGroupBase(props, emit)
 onMounted(() => useDisableAutoComplete(textareaRef))
 //#endregion

function onPaste(e: ClipboardEvent) {
    if (e.clipboardData) {
        const plainText = e.clipboardData.getData('text/plain')
        const textarea = textareaRef.value as HTMLTextAreaElement
        const position = textarea.selectionStart
        const finalValue = insertString(textarea.value, plainText, textarea.selectionStart ?? 0, textarea.selectionEnd ?? 0)
        textarea.value = finalValue
        textarea.selectionStart = position ? position + plainText.length : plainText.length
        textarea.selectionEnd = textarea.selectionStart
        emit('input', finalValue)
    }
}

function insertString(mainString: string, stringToInsert: string, positionStart: number, positionEnd: number) {
    return mainString.slice(0, positionStart) + stringToInsert + mainString.slice(positionEnd)
}

function onInput(event) {
    emit('input', event.target.value)
}

function onChange(event){
    emit("change", event.target.value)
}
</script>

<style lang="scss">
.text-area-icons {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
}
</style>