import { ref, computed } from 'vue'
import { MultiSelectItem } from '@/common/components/multi-select-item'
import { Lender, LenderInfo, LenderProfile, Officer, } from '@/common/models'
import { adminDataService } from '@/common/services'
import { useLoanStore } from '@/common/store'

export function useLenderSelect() {
    const store = useLoanStore()
    const lenderProfiles = ref<LenderProfile[]>([])
    const lenderProfile = ref<LenderProfile>({} as LenderProfile)
    const lender = ref<Lender>({} as Lender)

    const availableLenderProfiles = computed<MultiSelectItem[]>(() => 
        lenderProfiles.value?.map(p => ({
            text: p.displayName,
            value: p.id
        } as MultiSelectItem))
    )

    const initializeLender = async (loanLender: Lender | undefined) => {
        initializeLenderinfo(loanLender)
        lenderProfile.value = store.lenderProfile || {} as LenderProfile
        await initializeLenderProfiles()
    }

    function initializeLenderinfo(loanLender: Lender | undefined) {
        lender.value = loanLender || {} as Lender
        
        lender.value = {
            ...lender.value,
            info: lender.value.info || ({} as LenderInfo),
            officer: lender.value.officer || ({} as Officer),
        }

        lender.value.info.stateLicenseType ??= ""
    }

    async function initializeLenderProfiles() {
        const clientCode = store.clientCode
        const client = await adminDataService.getClient(clientCode)
        lenderProfiles.value = client?.lenderProfiles || []
    }

    return {
        lender,
        lenderProfile,
        lenderProfiles,
        availableLenderProfiles,
        initializeLender
    }
}