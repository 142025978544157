<template>
    <summary-sidebar @sidebarStateChanged="onSidebarStateChanged" :state="sidebarState">
        <template v-slot:header v-if="model">
            <div class="subtitle" v-if="model.subtitle">{{ model.subtitle }}</div>
            <div class="title">{{ model.title }}</div>
        </template>
        <slot />
        <template v-slot:footer v-if="item && item.audit">
            <summary-sidebar-audit-info :audit="item.audit" />
        </template>
    </summary-sidebar>
</template>

<script lang="ts" setup>
import { computed, PropType, defineProps } from "vue"
import { AuditInfo, SidebarState } from "@/common/models"
import SummarySidebarAuditInfo from "@/common/components/summary-sidebar-audit-info.vue"
import SummarySidebar from "@/common/components/summary-sidebar.vue"
import { userPreferenceService } from "@/common/services"

interface SummaryItem {
    title: string
    subtitle: string
    audit: AuditInfo
}

//#region DEFINE VARIABLES
const props = defineProps({
    item: { type: Object as PropType<any>}
})
//#endregion

//#region COMPUTED
const model = computed<SummaryItem | null>(() => {
    return props.item
        ? ({
                title: props.item.displayName || props.item.businessDetails?.name || props.item.name,
                subtitle: props.item.partnerCode,
                audit: props.item.audit
            } as SummaryItem)
        : null
})

const sidebarState = computed<SidebarState>(() => {
    const sideBarState = userPreferenceService.getLoanSummarySidebarState()
    return sideBarState ? SidebarState.Collapsed : SidebarState.Expanded
})
//#endregion

function onSidebarStateChanged(state: SidebarState) {
    userPreferenceService.setLoanSummarySidebarState(state === SidebarState.Collapsed)
}
</script>