export interface NotaryAcknowledgment {
    id: string | null
    state: string
    sections: NotaryAcknowledgmentSection[]
}

export interface NotaryAcknowledgmentSection {
    paragraph: string
    label: string
    sealLabel: string
    labelLocation: string
    type: string
    display: string
    isBordered: boolean
}

export const enum NotaryAcknowledgmentType{
    Individual = "Individual",
	Corporate = "Corporate",
}

export const enum SectionType{
    StateCountyBlock = "StateCountyBlock",
	Paragraph = "Paragraph",
	NotarySignature = "NotarySignature",
	NotaryName = "NotaryName",
	NotaryTitle = "NotaryTitle",
	CommissionNumber = "CommissionNumber",
	CommissionExpiration = "CommissionExpiration",
    CustomLineLabel = "CustomLineLabel"
}

export const enum SectionDisplayTypes{
	AllClosings = "AllClosings",
	RonClosingOnly = "RonClosingOnly",
	StandardClosingsOnly = "StandardClosingsOnly",
}

export const enum LabelLocation{
    Beneath = "Beneath",
	NextTo = "NextTo",
}