import { PermissionCategory, PermissionGroup, ExtendedPermissionGroup } from '@/common/models'

class UserPermissionMapper {
    allPermissions: ExtendedPermissionGroup[] = []
    systemPermissions: ExtendedPermissionGroup[] = []
    allPartnerClientPermissions: ExtendedPermissionGroup[] = []
    clientPermissions: ExtendedPermissionGroup[] = []
    partnerPermissions: ExtendedPermissionGroup[] = []

    initializeBuckets(allPermissions: PermissionGroup[]){
       this.allPermissions = this.buildCategoryTree(allPermissions, PermissionCategory.All)
       this.systemPermissions = this.buildCategoryTree(allPermissions.filter(x=>x.categories.includes(PermissionCategory.System)), PermissionCategory.System)
       this.partnerPermissions = this.buildCategoryTree(allPermissions.filter(x=>x.categories.includes(PermissionCategory.Partner)), PermissionCategory.Partner)
       this.clientPermissions = this.buildCategoryTree(allPermissions.filter(x=>x.categories.includes(PermissionCategory.Client)), PermissionCategory.Client)
       this.allPartnerClientPermissions = this.buildCategoryTree(allPermissions.filter(x=> x.categories.includes(PermissionCategory.Partner) || x.categories.includes(PermissionCategory.Client)), PermissionCategory.AllPartnersClient)
    }

    get SystemPermissions(){
        return this.systemPermissions
    }

    get AllPartnerClientPermissions(){
        return this.allPartnerClientPermissions
    }

    get PartnerPermissions(){
        return this.partnerPermissions
    }

    get ClientPermissions(){
        return this.clientPermissions
    }

    getPermissionById(id: string){        
        return this.findIdFromTree(this.allPermissions, id)
    }

    private findIdFromTree(permissions: ExtendedPermissionGroup[], id: string): ExtendedPermissionGroup{ 
        let foundPermission
        for(let i=0; i< permissions.length; i++){
            const subNodes = permissions[i]
            if(subNodes.id == id){
                if(!foundPermission)
                foundPermission = subNodes
                break            
            }
            else{
                 //Check if children exists and if so add those children recursively to map to their parent and flatten it out
                if (Object.prototype.hasOwnProperty.call(subNodes, 'children') == true && subNodes.children.length > 0) {
                    if(!foundPermission)
                    foundPermission = this.findIdFromTree(subNodes.children, id)
                }
            }           
        }
        return foundPermission
        
    }

    getFilteredPermissionByCategory(category: PermissionCategory) {
        switch(category){
            case PermissionCategory.AllPartnersClient: return this.AllPartnerClientPermissions
            case PermissionCategory.Client: return this.ClientPermissions
            case PermissionCategory.Partner: return this.PartnerPermissions
            case PermissionCategory.System: return this.SystemPermissions
            case PermissionCategory.SystemAdmin: return this.allPermissions.filter(x=> x.categories.includes(PermissionCategory.SystemAdmin))
            default: return this.allPermissions
        }
    }


    private buildCategoryTree(permissionGroups: PermissionGroup[], category: PermissionCategory): ExtendedPermissionGroup[]{
        const extendedGroup: ExtendedPermissionGroup[] = []
        permissionGroups.forEach(x=> {
            if(category === PermissionCategory.All){
                extendedGroup.push(this.buildExtendedPermissionGroup(x, null, 1, category))
            }
            else if(category === PermissionCategory.AllPartnersClient){
                if(x.categories.includes(PermissionCategory.Partner) || x.categories.includes(PermissionCategory.Client)){
                    extendedGroup.push(this.buildExtendedPermissionGroup(x, null, 1, category))
                }
            }
            else if(x.categories.includes(category)){
                extendedGroup.push(this.buildExtendedPermissionGroup(x, null, 1, category))
            }            
        })
        return extendedGroup
    }


    private buildExtendedPermissionGroup(permissionGroup: PermissionGroup, parent: ExtendedPermissionGroup | null, level: number, category: PermissionCategory): ExtendedPermissionGroup{        
        const newGroup = this.setExtendedPermission(permissionGroup, parent, level)
        permissionGroup.children.forEach(x=> {
            if(category === PermissionCategory.All){
                newGroup.children.push(this.buildExtendedPermissionGroup(x, null, 1, category))
            }
            else if(category === PermissionCategory.AllPartnersClient){
                if(x.categories.includes(PermissionCategory.Partner) || x.categories.includes(PermissionCategory.Client)){
                    newGroup.children.push(this.buildExtendedPermissionGroup(x, newGroup, (1 + level), category))
                }
            }
            else if(x.categories.includes(category)){
                newGroup.children.push(this.buildExtendedPermissionGroup(x, newGroup, (1 + level), category))
            }
        })
        return newGroup
    }

    private setExtendedPermission(permissionGroup: PermissionGroup, parent: ExtendedPermissionGroup | null, level: number) {
        const newGroup = {} as ExtendedPermissionGroup
        newGroup.id = permissionGroup.id
        newGroup.categories = permissionGroup.categories
        newGroup.description = permissionGroup.description
        newGroup.permissions = permissionGroup.permissions
        newGroup.children = []
        newGroup.parent = parent
        newGroup.level = level
        newGroup.isAllPCPermissionChecked = false
        newGroup.isClientPermissionChecked = false
        newGroup.isPartnerPermissionChecked = false
        newGroup.isSystemPermissionChecked = false
        return newGroup
    }

    buildBuckets(systemPermissions: ExtendedPermissionGroup[], parent: PermissionGroup | null, level: number){
        if (systemPermissions.length == 0) {
            return 
        }
        for (let i = 0; i < systemPermissions.length; i++) {
            const subNodes = systemPermissions[i]
            if (Object.prototype.hasOwnProperty.call(subNodes, 'children') == true && subNodes.children.length > 0) {
                const children = this.transferToUserPermissionsByCategory(subNodes.children);
                this.assignParent(children,  level, subNodes);
                this.buildBuckets(children, subNodes, (1 + level))
            } else {
                this.assignParent(systemPermissions, level, null)
            }
        }      
    }

    private transferToUserPermissionsByCategory(permissions: PermissionGroup[]){
        return permissions.map(x => { return{ 
            categories: x.categories,
            children: x.children,
            clients: x.clients,
            id: x.id,
            description: x.description,
            partners: x.partners,
            parent: null,
            level: 0
       } as ExtendedPermissionGroup })
    }

    private assignParent(systemPermissions: ExtendedPermissionGroup[], parentLevel: number, parent: ExtendedPermissionGroup | null){
        systemPermissions.forEach(element => {
            element.parent = parent,
            element.level = parentLevel
        });
    }

    checkAllParent(category: PermissionCategory, permission: ExtendedPermissionGroup){
        if (this.checkParent(category, permission) == true) {
            if(permission.parent)
            this.enabledisableCheckbox(category, permission.parent, true)            
        }
        else {
            if(permission.parent)
            this.enabledisableCheckbox(category, permission.parent, false)
        }
        if(permission.parent){
            this.checkAllParent(category, permission.parent)
        }
    }

    enabledisableCheckbox(category: PermissionCategory, permission: ExtendedPermissionGroup, permissionValue: boolean) {
        if (category == PermissionCategory.System) {
            permission.isSystemPermissionChecked = permissionValue
        }
        else if (category == PermissionCategory.AllPartnersClient) {
            permission.isAllPCPermissionChecked = permissionValue
        }
        else if (category == PermissionCategory.Partner) {
            permission.isPartnerPermissionChecked = permissionValue
        }
        else if (category == PermissionCategory.Client) {
            permission.isClientPermissionChecked = permissionValue
        }
    }

    checkParent(category: PermissionCategory, parentPermission: ExtendedPermissionGroup): boolean {
        let functionResult = true
        if (parentPermission.parent == null) {
            return false
        }
        parentPermission.parent?.children.forEach(ch => {
            if (ch) {
                if (category == PermissionCategory.System) {
                    if (ch.isSystemPermissionChecked == undefined || !ch.isSystemPermissionChecked) {
                        functionResult = false
                        return
                    }
                }
                if (category == PermissionCategory.AllPartnersClient) {
                    if (!ch.isAllPCPermissionChecked) {
                        functionResult = false
                        return
                    }
                }
                if (category == PermissionCategory.Partner) {
                    if (!ch.isPartnerPermissionChecked) {
                        functionResult = false
                        return
                    }
                }
                if (category == PermissionCategory.Client) {
                    if (!ch.isClientPermissionChecked) {
                        functionResult = false
                        return
                    }
                }
            }
            else {
                functionResult = false
                return
            }
        })
        return functionResult
    }

    // //Recursively check all the children till the end and set the corresponding permission
    checkAllChildren(category: PermissionCategory, permission: ExtendedPermissionGroup[], isPermissionChecked) {
        if (permission == null) {
            return
        }
        for (let i = 0; i < permission.length; i++) {
            const subNodes = permission[i]
            if (Object.prototype.hasOwnProperty.call(subNodes, 'children') == true && subNodes.children.length > 0) {
                this.enabledisableCheckbox(category, subNodes, isPermissionChecked)
                this.checkAllChildren(category, subNodes.children, isPermissionChecked)
            }else{
                this.enabledisableCheckbox(category, subNodes, isPermissionChecked)
            }
        }
    }
}


export const permissionMapperService = new UserPermissionMapper()