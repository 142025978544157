<template>
    <validation-provider :name="validationFieldName" :rules="validationRules" :immediate="immediateValidation" v-slot="{ errors }">
        <multi-select
            :id="id"
            v-model="selectedValue"
            :multiple="multiple"
            :searchable="searchable"
            :useLocalSearch="useLocalSearch"
            :searchMinimumCharacters="searchMinimumCharacters"
            :source="sourceItems"
            :stickyItems="stickyItems"
            :disabled="disabled"
            :show-empty-option="showEmptyOption"
            :placeholder="placeholder"
            :optionsToFilter="optionsToFilter"
            :showSelectedItemsValueOnDisplay="showSelectedItemsValueOnDisplay"
            :showDescriptionAfterSelect="showDescriptionAfterSelect"
            :clearSelectedItemsOnSelection="clearSelectedItemsOnSelection"
            :clearSearchOnSelection="clearSearchOnSelection"
            :optionDisplayType="optionDisplayType"
            :class="getErrorMessage(errors[0]) ? 'error' : ''"
            :searchMessages="searchMessages"
            :jsonPath="jsonPath"
            @change="$emit('change')"
        />
    </validation-provider>
</template>

<script setup lang="ts">
import { PropType, computed, onMounted, defineEmits, defineProps } from 'vue'
import { ReferenceSource, ReferenceType, Reference } from '../models'
import { referenceService } from '../services'
import { validationProps, useValidation } from '@/common/composables/audit-check-composable'
import { MultiSelectItem } from './multi-select-item'
import { DropdownOptionDisplayType } from './dropdown-option-display'
import MultiSelect from './multi-select.vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e: "input", value: any): void
    (e: "change"): void
}>()

const props = defineProps({
    value: { required: true }, // Not including a type defaults the type to any
    id: { type: String, required: true },
    referenceType: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    referenceSource: { type: String as PropType<ReferenceSource>, default: ReferenceSource.loan },
    source: { type: [Array, Function] as PropType<MultiSelectItem[] | ((searchTerm?: string) => Promise<MultiSelectItem[]>)>, default: null },
    stickyItems: { type: Array as PropType<Array<MultiSelectItem>>, default: () => [] },
    showEmptyOption: { type: Boolean, default: true },
    disabled: { type: Boolean },
    multiple: { type: Boolean },
    searchable: { type: Boolean },
    useLocalSearch: { type: Boolean },
    searchMinimumCharacters: { type: Number, default: 1 },
    optionsToFilter: { type: Array as PropType<Array<string>>, default: () => [] },
    showSelectedItemsValueOnDisplay: { type: Boolean },
    showDescriptionAfterSelect: { type: Boolean },
    clearSelectedItemsOnSelection: { type: Boolean },
    clearSearchOnSelection: { type: Boolean },
    optionDisplayType: { type: String as PropType<DropdownOptionDisplayType>, default: DropdownOptionDisplayType.Text },
    searchMessages: { type: [Array, null] as PropType<Array<string>> | null, default: null },
    jsonPath: { type: String, default: '' },
    ...validationProps
})

// To Do - Move this Map (and associating logic below) to referenceService
const referenceServiceMapping: Map<string, string> = new Map([
    ['states', 'getStates'],
    ['states-incorporation', 'getIncorporationStates'],
    ['system-types', 'getSystemTypes'],
    ['fields', 'getFields'],
    ['field-formats', 'getFieldFormats']
])
//#endregion

//#region COMPUTED
const selectedValue = computed({
    get: () => {
        return props.value
    },
    set: (newValue: any) => {
        emit("input", newValue)
    }
})
const sourceItems = computed(() => {
    if (!props.referenceType)
        return props.source
    else 
        return (async () => await getItems())
})
//#endregion

//#region INITIALIZE
const { getErrorMessage } = useValidation(props)

onMounted(async () => {
    if (!props.referenceType && !props.source)
        throw new Error('Either referenceType or source must be provided')

    if (props.referenceType && props.source)
        throw new Error('Both referenceType and source cannot be provided at the same time')
})
//#endregion

// To Do - This function is relying heavily on logic that should be handled in the reference service itself. Move out such logic (such as
// the referenceServiceMapping, handling states/states-incorporation, etc) to referenceService.ts to be handled in its getMultiSelectItems
// method. Then, use that method here
async function getItems(): Promise<MultiSelectItem[]> {
    let items = [] as MultiSelectItem[]
    try {
        const methodName = referenceServiceMapping.get(props.referenceType) as string
        if (methodName) {
            const mappedReferences = await referenceService[methodName]() 
            if ((props.referenceType) === 'states' || (props.referenceType) === 'states-incorporation') {
                items = mappedReferences.map((x: string) => ({ value: x, text: x } as MultiSelectItem))
            }
            else{
                items = mappedReferences
            }
        } else {
            const references = await referenceService.get(props.referenceType as ReferenceType, props.referenceSource)
            items = references.map((x: Reference) => ({ value: x.key, text: x.displayValue } as MultiSelectItem))
        }
    } catch (err: any) {
        console.error(`Error loading reference data for type '${props.referenceType}':`, err)
    }
    return items
}
</script>