import { PropertyCosts } from "./escrow";

export interface CalculatedData {
    apr: number
    hoepaApr: number
    qM_ShortTermArmApr: number
    rawLtv: number
    ltv: number
    rawCltv: number
    cltv: number
    totalLoanAmount: number
    concurrentSecondPrincipalAndInterestPaymentAmount: number
    initialPitiPaymentAmount: number
    initialPrincipalAndInterestPaymentAmount: number
    firstPaymentDate: Date
    maturityDate: Date
    adjustableRateMortgageDisclosure: AdjustableRateMortgageDisclosure
    pmi: PrivateMortgageInsurance
    amortizationSchedules: Map<AmortizationScheduleType, AmortizationScheduleItem[]>
    totalOfPayments: TotalOfPayments
    financeCharge: number
    amountFinanced: number
    wireAmountTotal: number
    totalInterestPercentage: number
    escrow: EscrowCalculations
    propertyCosts: PropertyCosts
    loanComparisonCalculations: LoanComparisonCalculations
    stateSpecificCalculations: StateSpecificCalculations
    subsidyPeriodPayments : SubsidyPeriodPayments
    errors: CalcErrors
}

export interface AdjustableRateMortgageDisclosure {
    lifetimeMaximumRate: number | null
}

export interface PrivateMortgageInsurance {
    ltv80PercentDate: Date
    ltv80PercentPaymentNumber: number
    cancellationPaymentNumber: number
    terminationPercentage: number
    terminationDate: Date
    borrowerUpfrontFeeFinancedAmount: number
    totalUpfrontFee: number
}

export interface AmortizationScheduleItem {
    date: Date
    number: number
    principalPayment: number
    interestPayment: number
    mortgageInsurancePayment: number
    totalPayment: number
    balance: number
    ltv: number
    fhaBalance: number
    rate: number
}

export enum AmortizationScheduleType {
    Normal = 'normal',
    BestCase = 'bestCase',
    WorstCase = 'worstCase',
    CFPB = 'cfpb',
    OneTimeClose = 'oneTimeClose',
    QmShortTermArm = 'qM_ShortTermArm'
}

export interface TotalOfPayments {
    loanAmount: number
    prepaidInterest: number
    totalInterest: number
    originationChargesTotal: number
    borrowerPrepaidFinanceCharges: number
    borrowerDidNotShopForTotal: number
    borrowerShoppedForTotal: number
    prepaidMortgageInsurance: number
    initialEscrowMortgageInsurance: number
    mortgageInsuranceTotal: number
    totalPaymentAmount: number
}

export interface EscrowCalculations {
    initialDeposit: number
    cushionAmount: number
    activity: EscrowActivity[]
    aggregateAdjustment: number
    initialPayments: InitialPaymentAtClosing[]
    disbursementSummaries: EscrowDisbursementSummary[]
}

export interface EscrowDisbursementSummary{
    escrowKey: string
    disbursements: EscrowDisbursement[]
}

export interface EscrowDisbursement {    
    disbursementDate: Date
    disbursementAmount: number
}

export interface EscrowActivity {
    date: Date
    paymentAmount: number
    disbursementDescription: string
    disbursementAmount: number
    balance: number
}

export interface InitialPaymentAtClosing {
    key: string
    monthlyAmount: number
    months: number
    total: number
    displayString: string
}

export interface LoanComparisonCalculations {
    existingLoan: ExistingLoanCalculations
    newLoan: NewLoanCalculations
    impactOfRefinance: ImpactOfRefinanceCalculations
}

export interface ExistingLoanCalculations {
    homeEquityRemaining: number
    totalRemainingScheduledPayments: number
}

export interface NewLoanCalculations {
    ltv: number
    homeEquityRemaining: number
    totalRemainingScheduledPayments: number
}

export interface ImpactOfRefinanceCalculations {
    loanBalance: number
    monthlyPayment: number
    remainingTerm: number
    interestRate: number
    totalRemainingScheduledPayments: number
    ltv: number
    homeEquityRemaining: number
}

export interface StateSpecificCalculations {
    nyCalculations: NyCalculations
}

export interface SubsidyPeriodPayments {
    totalAnnualAmount: number
    periods: SubsidyPeriod[]
}

export interface SubsidyPeriod {
    termStart: number
    termEnd: number
    subsidizedRate: number
    fromBorrower: number
    fromBuydown: number
    annualAmount: number
}

export interface NyCalculations {
    cemaCalculations: NyCemaCalculations
    subPrimeApr: number
}

export interface NyCemaCalculations {
    principalAndInterestAmount: number
}

export interface CalcErrors {
    errorList: CalcError[]
    hasErrors: boolean
    hasFatalError: boolean
}

export interface CalcError {
    message: string
    isFatal: boolean
}
