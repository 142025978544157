import { FeePaidByType, Overridable, OddDaysInterestAprHandlingType, FeePaidToType, ChangeOfCircumstanceReasonType } from './index'

export interface PrepaidItem {
    key: string
    paidToType: FeePaidToType
    paidToTypeDescription: string
    paidToName: string | null
    type: string
    description: string
    totalAmount: number
    estimatedAmount: number
    monthlyAmount: number
    monthsPaid: number
    payments: PrepaidItemPayment[]
    changeOfCircumstanceReason: ChangeOfCircumstanceReasonType
    changeOfCircumstanceDate: Date
}

export interface PrepaidItemPayment {
    actualAmount: number
    estimatedAmount: number
    paidByType: FeePaidByType
    paidByTypeOtherDescription: string
    timingType: PrepaidItemPaymentTimingType
    timingTypeOtherDescription: string
    isPaidOutsideOfClosing: boolean
    isFinanced: boolean
    isIncludedInApr: boolean
    includeInPointsAndFees: boolean
    deductFromWire: boolean
    key: string
}

export interface PrepaidInterest {
    totalAmount: number
    estimatedAmount: number
    paidFromDate: Overridable<Date>
    paidThroughDate: Overridable<Date>
    daysPaid: Overridable<number>
    perDiemAmount: Overridable<number>
    perDiemCalculationType: string
    perDiemRoundingPrecision: number
    oddDaysInterestAprHandlingType: OddDaysInterestAprHandlingType
    paidByType: string
    paidByTypeOtherDescription: string
    timingType: PrepaidItemPaymentTimingType
    timingTypeOtherDescription: string
    isPaidOutsideOfClosing: boolean
    isFinanced: boolean
    isIncludedInApr?: boolean
    includedInAprIsConfigurable: boolean
    deductFromWire: boolean
    includeInPointsAndFees: boolean
    hasShortPayCredit: boolean
    use30DayMonthForPerDiemDays: boolean
    authorizedPerDiemDays: number
}

export enum PrepaidItemPaymentTimingType {
    AtClosing = 'AtClosing',
    BeforeClosing = 'BeforeClosing',
    FirstPayment = 'FirstPayment',
    Other = 'Other'
}