import { axiosOrdersService } from '@/common/services'
import { OrderSummary, PagedCollection } from '@/common/models'
import moment from 'moment'
import { BlobSaverHelper } from '@/common/utilities/helper';

class OrdersService {   
    
    async getAllOrders(params?: URLSearchParams): Promise<PagedCollection<OrderSummary>> {
        const response = await axiosOrdersService.get('', { params })
        return response.data
    }

    async downloadOrdersCsvFile(params?: URLSearchParams) {
        const response = await axiosOrdersService.get('csv', { params });
        const fileName = `Orders-${moment.utc()}.csv`
        BlobSaverHelper.SaveBlob(response.data, response.headers["content-type"], fileName)
    }
}

export const ordersService = new OrdersService()