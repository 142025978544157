import { LoanDefaultValueResult } from '@/common/models'

class DefaultValueService {
    isInputProtectedFromFieldDefault (fieldDefaultsAppliedToLoan: Record<string, LoanDefaultValueResult> ,jsonPath: string): boolean {
        //check if fieldDefaultsAppliedToLoan has a key equal to the json path of the input.
        //if it does, return that key's prevent change value. If it does not, return false. 
        return jsonPath in fieldDefaultsAppliedToLoan ? fieldDefaultsAppliedToLoan[jsonPath].preventChange : false
    }

    getDefaultValue(fieldDefaultsAppliedToLoan: Record<string, LoanDefaultValueResult> ,jsonPath: string) {
        return jsonPath in fieldDefaultsAppliedToLoan ? fieldDefaultsAppliedToLoan[jsonPath].value : null
    }

    getDefaultFieldId(fieldDefaultsAppliedToLoan: Record<string, LoanDefaultValueResult> ,jsonPath: string) {
        return jsonPath in fieldDefaultsAppliedToLoan ? fieldDefaultsAppliedToLoan[jsonPath].fieldId : null
    }

    getOverriddenDefaultPath(jsonPath: string){
        const path = `data.${jsonPath}`
        return path
    }
}

export const defaultValueService = new DefaultValueService()