import { Overridable } from './meta/overridable'

export interface WarrantyDeed {
    type: string | null
    isShortSale: boolean | null
    sellerRetainsMineralRights: boolean | null
    invoiceDeedAmount: number | null
    sellerVestingOverride: Overridable<string | null>
    borrowerVestingOverride: Overridable<string | null>
    secondLienHolder: string | null
    secondLienHolderLoanAmount: number | null
    secondLienTrustee: string | null
    secondLienDate: string | null
    titleWillPrepareWarrantyDeed: boolean | null
}

export enum DeedType {
    General = "General",
    Special = "Special",
    OweltyDeed = "OweltyDeed"
}
