<template>
    <input ref="inputRef" type="text" :class="['form-control', auditCheckLevelClass]" :id="id" :readonly="readonly" :jsonpath="jsonPathValue" @change="$emit('change', $event)">
</template>

<script setup lang="ts">
import { defineEmits, defineProps, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useAuditChecks } from '@/common/composables/audit-check-composable'
import { InputMask, MaskedPattern } from 'imask'
import { useDisableAutoComplete } from '@/common/composables/disable-autocomplete-composable'

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'input', value: any): void // type for value is not set to "string" because vue complains that the emit in markup does not return a string
    (e: 'unlocked'): void
    (e: 'change', event: any): void
}>()

const props = defineProps({
    value: { required: true },
    id: { type: String, required: true },
    pattern: { type: String, required: true },
    readonly: { type: Boolean },
    jsonPath: { type: String, default: '' },
    jsonProperty: { type: String, default: '' }
})

const inputRef = ref<HTMLInputElement | null>(null)
const valueChangedDueToInput = ref(false)
const mask = ref() // Used to be set as "mask! InputMask<MaskedPattern>", but throws red squigglies everywhere so leaving it as type any
const patternMasks = new Map([
    ['phone', '(000) 000-0000'],
    ['ssn', '000-00-0000'],
    ['zip', '00000-0000'],
    ['tin', '00-0000000'],
    ['phoneExt', '000000'],
    ['fhaCaseNum', '000-0000000-000'],
    ['vaCaseNum', '00-00-0-0000000'],
    ['mers', '0000000-0000000000-0']
])
//#endregion

//#region WATCH
watch(() => props.pattern, (value) => onPatternChange(value))
watch(() => props.value, (value) => handleValueChange(value))
//#endregion

//#region INITIALIZE
const { auditCheckLevelClass, jsonPathValue } = useAuditChecks(props)

onMounted(() => {
    useDisableAutoComplete(inputRef)
    const patternMask = getPatternMask(props.pattern)

        mask.value = new InputMask(inputRef.value as HTMLElement, new MaskedPattern({
            mask: patternMask
        })).on('accept', () => {
            valueChangedDueToInput.value = props.value !== mask.value.unmaskedValue
            // make sure any lingering placeholder characters are fully removed if unmasked value is empty
            if (!mask.value.unmaskedValue)
                mask.value.value = ''
            
            const checkPattern = props.pattern === 'zip' ? mask.value.value : mask.value.unmaskedValue
            emit('input', checkPattern || '')
        })

        mask.value.unmaskedValue = props.value || ''
})

onBeforeUnmount(() => {
    mask.value.destroy()

})
//#endregion

function onPatternChange(value: string) {
    const patternMask = getPatternMask(value)
    mask.value.updateOptions({ mask: patternMask })        
    mask.value.unmaskedValue = props.value || ''
}

function handleValueChange(value: any) {
    if(valueChangedDueToInput.value) {
        valueChangedDueToInput.value = false
    } else {
        mask.value.unmaskedValue = value || ''
    } 
}

function getPatternMask(pattern: string): string {
    const patternMask = patternMasks.get(pattern)
    
    if (!patternMask) throw new Error('Invalid pattern specified.')
    
    return patternMask
}
</script>