import FileSaver from 'file-saver';

class FileSaverService {
    downloadFile(fileResponse, fileName) {
        const fileData = this.getFileData(fileResponse, fileName);
        FileSaver.saveAs(fileData, fileName)
    }

    getFileData(fileResponse, fileName){
        return `${this.generateBase64WithMimeType(fileName.split('.')[1])}${fileResponse.file}`;
    }

    generateBase64WithMimeType(fileExtension: string) {
        const mimeMap: Map<string, string> = new Map([
            ['bmp', 'image/bmp'],
            ['csv', 'text/csv'],
            ['doc', 'application/msword'],
            ['docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
            ['jpeg', 'image/jpeg'],
            ['jpg', 'image/jpeg'],
            ['png', 'image/png'],
            ['pdf', 'application/pdf'],
            ['rtf', 'application/rtf'],
            ['txt', 'text/plain']
        ]);
        return `data:${mimeMap.get(fileExtension)};base64,`;
    }

}

export const fileSaverService = new FileSaverService()