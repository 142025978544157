import { Contact, LoanContactType } from ".";

export interface ContactDefault {
    contactType: LoanContactType
    contact: Contact
}

export interface DefaultContactsPreviewParameters {
    defaultType: ContactDefaultType
    lenderProfileId: string | null
    investorCode: string | null
}

export enum ContactDefaultType {
    Investor = "Investor",
    LenderProfile = "LenderProfile"
}