export interface HousingExpense {
    key: string
    type: HousingExpenseType
    description: string
    monthlyAmount?: number
}

export enum HousingExpenseType {
    Cable = 'Cable',
    Electricity = 'Electricity',
    EscrowShortage = 'EscrowShortage',
    FirstMortgagePITI = 'FirstMortgagePITI',
    FirstMortgagePrincipalAndInterest = 'FirstMortgagePrincipalAndInterest',
    FloodInsurance = 'FloodInsurance',
    GroundRent = 'GroundRent',
    Heating = 'Heating',
    HomeownersAssociationDuesAndCondominiumFees = 'HomeownersAssociationDuesAndCondominiumFees',
    HomeownersInsurance = 'HomeownersInsurance',
    LeaseholdPayments = 'LeaseholdPayments',
    MaintenanceAndMiscellaneous = 'MaintenanceAndMiscellaneous',
    MIPremium = 'MIPremium',
    Other = 'Other',
    OtherMortgageLoanPrincipalAndInterest = 'OtherMortgageLoanPrincipalAndInterest',
    OtherMortgageLoanPrincipalInterestTaxesAndInsurance = 'OtherMortgageLoanPrincipalInterestTaxesAndInsurance',
    RealEstateTax = 'RealEstateTax',
    Rent = 'Rent',
    SupplementalPropertyInsurance = 'SupplementalPropertyInsurance',
    Telephone = 'Telephone'
}