<template>
    <div :id="`${id}-group`" :class="`field ${size} form-group`">
        <validation-provider :name="label" :rules="validationRules" :immediate="immediateValidation" v-slot="{ errors }">
            <div class="label-container" v-if="label || helpText">
                <label :for="id" v-if="label">{{ label }}</label>
                <span v-if="jsonPathLabel" class="json-path-label">{{jsonPathLabel}}</span>
                <custom-icon icon="Help" :id="`${id}-help`" v-if="helpText" :title="helpText" />
            </div>
            <div class="base-input-container">
                <date-picker
                    :id="`${id}`"
                    :jsonPath="jsonPathValue"
                    v-model="dateToChange"
                    :mode="mode"
                    :placement="placement"
                    :restricted="isRestricted"
                    :modelConfig="modelConfig"
                    :class="getErrorMessage(errors[0]) ? 'error' : ''"
                    @input="$emit('input', $event)"
                />
                <span class="base-input-icons">
                    <protected-edit-icon :icon="protectedEditIconString" :showProtectedEdit="showProtectedEdit" :allowProtectedEdit="allowProtectedEdit" @click="turnOffProtectedDisabled" />
                    <fg-defaultable
                        :currentValue="value"
                        :defaultValue="defaultValue"
                        :isDefaulted="isDefaulted"
                        @change="onDefaultValueChange"
                        @defaultChange="onDefaultChange"
                        :disabled="!showDefaultFieldEdit"
                    />
                    <custom-icon icon="Error" :id="`${id}-error`" v-if="getErrorMessage(errors[0])" :title="getErrorMessage(errors[0])" />
                    <audit-check-icon :id="`${id}-audit-check`" :auditChecks="auditChecks" />
                </span>
            </div>
        </validation-provider>
    </div>
</template>

<script setup lang="ts">
import { WritableComputedRef, computed, defineProps, defineEmits, defineExpose, PropType } from 'vue' 
import { useFormGroupBase, baseProps } from './form-group-base-composable'
import { DatePickerMode } from './date-picker-mode'

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'update:value', date: Date | null ): void
    (e: 'input', value: boolean): void 
    (e: 'unlocked'): void
}>()

const props = defineProps({
    value: { type: null as unknown as PropType<Date | null>, default: null },
    id: { type: String, required: true },
    label: { type: String },
    size: { type: String, default: 's' },
    placement: { type: String, default: 'top' },
    restricted: { type: Boolean, default: false },
    helpText: { type: String },
    mode: { type: String as PropType<DatePickerMode>, default: DatePickerMode.Date },
        modelConfig: {},
    ...baseProps
})
//#endregion

//#region COMPUTED
const dateToChange: WritableComputedRef<Date| null> = computed({
    get(): Date | null {
        return props.value
    },
    set(date: Date | null): void {
        emit('update:value', date)
    }
})

const isRestricted = computed(() => {
    return isDisabled.value || props.restricted
})
//#endregion

//#region INITIALIZE
const { isDisabled, jsonPathLabel, jsonPathValue, protectedEditIconString, showProtectedEdit, allowProtectedEdit, turnOffProtectedDisabled, showDefaultFieldEdit, onDefaultValueChange,
 onDefaultChange, defaultValue, isDefaulted, auditChecks, getErrorMessage } = useFormGroupBase(props, emit)

 defineExpose({ onDefaultChange })
 //#endregion
</script>