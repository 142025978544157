import { adminDataService } from './index'
class FeatureFlagService {
    async getFeatureFlag(featureFlagName: string, clientCode: string){
        const featureFlag = await adminDataService.getFeatureFlagStatus(featureFlagName, clientCode)
        return featureFlag
    }
    
    async isFeatureEnabled(featureFlagName: string, clientCode: string){
        const featureFlag = await adminDataService.getFeatureFlagStatus(featureFlagName, clientCode)
        return featureFlag.isEnabled
    }
}

export const featureFlagService = new FeatureFlagService()
