<template>
    <div>
        <div class="label-container" v-if="label">
            <label>{{ label }}</label>
        </div>
        <div
            :class="[
                'input-switch',
                { expression: displayExpressionEntry },
                { 'list-items': displayCompareValues },
                { standard: !displayExpressionEntry && !displayCompareValues }
            ]"
        >
            <div :key="`${props.fieldName}-value`" v-if="canAssignCriteriaValue" class="input-container">
                <fg-textarea
                    id="conditionValues"
                    v-if="displayExpressionEntry"
                    class="code-input"
                    :rows="3"
                    label=""
                    placeholder="Enter an expression"
                    v-model="compareExpression"
                    :disabled="props.readOnly"
                />
                <input-select
                    v-else-if="displayCriteriaDropDown && props.allowMultiple"
                    id="systemFieldType"
                    :source="getFieldSystemTypeValues"
                    v-model="compareValues"
                    :multiple="true"
                    :useLocalSearch="isSearchable"
                    :showEmptyOption="false"
                    :showSelectedItemsValueOnDisplay="showSelectedValue"
                    :disabled="props.readOnly"
                    :optionDisplayType="showCodeAndValue ? 'SplitWithValue' : 'Text'"
                />
                <input-select
                    v-else-if="displayCriteriaDropDown"
                    id="systemFieldType"
                    :source="getFieldSystemTypeValues"
                    v-model="compareExpression"
                    :multiple="false"
                    :useLocalSearch="isSearchable"
                    :showEmptyOption="false"
                    :showSelectedItemsValueOnDisplay="showSelectedValue"
                    :disabled="props.readOnly"
                    :optionDisplayType="showCodeAndValue ? 'SplitWithValue' : 'Text'"
                />
                <date-picker
                    v-else-if="displayDatePicker"
                    id="datePicker"
                    :modelConfig="{ type: 'string', mask: 'MM/DD/YYYY' }"
                    v-model="compareExpression"
                    @input="compareExpression = wrapExpression(compareExpression)"
                />
                <input-select
                    v-else-if="displayBooleanDropdown"
                    id="compareExpression"
                    :source="booleanList"
                    v-model="compareExpression"
                    :showEmptyOption="false"
                    :disabled="props.readOnly"
                />
                <item-list-entry
                    v-else-if="displayCompareValues"
                    :items="compareValues"
                    alphabetical-sort
                    :validateNumber="isNumericProperty"
                    :rows="!props.allowMultiple && selectedFieldPropertyType === FieldPropertyType.String ? 3 : 1"
                    :limit="props.allowMultiple ? null : 1"
                    :addOnChange="addOnChange"
                />                
            </div>
            <custom-icon
                icon="Switch"
                v-if="enableFieldExpression && allowExpressionSwitch"
                @click="toggleExpression()"
                title="Switch to Expression/Field-Type"
            />
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, ref, watch, defineProps, defineEmits, PropType } from 'vue'
import { FieldDetail, FieldPropertyType, OperatorType, Reference } from '@/common/models'
import { MultiSelectItem } from '@/common/components/multi-select-item'
import { metadataService } from '@/propel/services'
import { AdminDataSystemTypes } from '@/propel/models/metadata'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:'update:compareValues', value: string[] | undefined)
    (e:'update:compareExpression', value: string | undefined)
}>()

const props = defineProps({
    label: String,
    readOnly: Boolean,
    enableFieldExpression: Boolean,
    allowMultiple: Boolean,

    expressionOnlyDates: Boolean,
    disableWrappedExpressions: Boolean,

    fieldName: {type: String, default() {return '' as string}},
    operatorType: {type: String, default() {return 'Equal' as string}},

    compareExpression: String,
    compareValues: {type: Array as PropType<Array<string>>, default() {return []}},

    addOnChange: {type: Boolean, default() {return true}},
})
const _compareValues = ref<string[] | undefined>()
const _compareExpression = ref<string | undefined>()

const displayCriteriaDropDown = ref(false)
const displayDatePicker = ref(false)
const displayBooleanDropdown = ref(false)
const displayCompareValues = ref(false)
const displayExpressionEntry = ref(false)
const selectedSystemFieldType = ref('')
const selectedFieldPropertyType = ref('')
const searchableTypes = [
    AdminDataSystemTypes.ClientCode, 
    AdminDataSystemTypes.Partner, 
    AdminDataSystemTypes.InvestorCode, 
    AdminDataSystemTypes.Program
] as string[]

const showSelectedValueTypes = [
    AdminDataSystemTypes.ClientCode,
    AdminDataSystemTypes.Partner,
    AdminDataSystemTypes.InvestorCode,
    AdminDataSystemTypes.Program
] as string[]

const showCodeAndValueTypes = [
    AdminDataSystemTypes.ClientCode,
    AdminDataSystemTypes.Partner,
    AdminDataSystemTypes.InvestorCode,
    AdminDataSystemTypes.Program,
    AdminDataSystemTypes.PackageType
] as string[]

const booleanList = [
    { value: 'true', text: 'TRUE' },
    { value: 'false', text: 'FALSE' }
] as MultiSelectItem[]
//#endregion

//#region COMPUTED
const canAssignCriteriaValue = computed(() => props.operatorType !== OperatorType.Empty && props.operatorType !== OperatorType.NotEmpty)
const showSelectedValue = computed(() => showSelectedValueTypes.indexOf(selectedSystemFieldType.value) > -1)
const isSearchable = computed(() => searchableTypes.indexOf(selectedSystemFieldType.value) > -1)
const showCodeAndValue = computed(() => showCodeAndValueTypes.indexOf(selectedSystemFieldType.value) > -1)
const isNumericProperty = computed(() => selectedFieldPropertyType.value === FieldPropertyType.Integer || selectedFieldPropertyType.value === FieldPropertyType.Decimal)
const allowExpressionSwitch = computed(() => {
    if (canAssignCriteriaValue.value && !props.readOnly) {
        switch (selectedFieldPropertyType.value) {
            case FieldPropertyType.Boolean:
            case FieldPropertyType.String:
            case FieldPropertyType.Integer:
            case FieldPropertyType.Decimal:
            case FieldPropertyType.SystemType:
                return true
            case FieldPropertyType.Date:
                return !props.expressionOnlyDates
        }
    }
    return false
})

const compareValues = computed({
    get: () => _compareValues.value ?? props.compareValues,
    set: (value) => _compareValues.value = value
})

const compareExpression = computed({
    get: () => _compareExpression.value ?? props.compareExpression,
    set: (value) => emit('update:compareExpression', _compareExpression.value = value)
})
//#endregion

//#region WATCH
watch(() => compareValues.value, () => emitCompareValues(compareValues.value), {deep:true})

watch(() => props.fieldName, async () => {
    displayCriteriaDropDown.value = false
    displayBooleanDropdown.value = false
    displayCompareValues.value = false
    displayExpressionEntry.value = false
    displayDatePicker.value = false
    selectedSystemFieldType.value = ''
    selectedFieldPropertyType.value = ''

    if (!props.fieldName) return
    try {
        const criteriaField = await metadataService.getField(props.fieldName)
        setFieldType(criteriaField)
    } catch (ex: any) {
        console.error(`Error loading Field '${props.fieldName}':`, ex)
    }
}, {immediate: true})

watch(() => props.operatorType, () =>  {
    if (props.operatorType === OperatorType.Empty || props.operatorType === OperatorType.NotEmpty) {
        compareValues.value = []
        compareExpression.value = ''
    }
}, {immediate: true})
//#endregion

function emitCompareValues(values : string[]) {
    if (selectedFieldPropertyType.value === FieldPropertyType.String && !props.disableWrappedExpressions )
        for (var i = 0; i < values.length; i++)
            values[i] = wrapExpression(values[i]) ?? ''
      
    emit('update:compareValues', values)
}

function setFieldType(criteriaField: FieldDetail) {
    selectedFieldPropertyType.value = criteriaField.propertyType
    switch (selectedFieldPropertyType.value) {
        case FieldPropertyType.SystemType:
            displayCriteriaDropDown.value = true
            selectedSystemFieldType.value = criteriaField.systemTypeName
            break
        case FieldPropertyType.Boolean:
            compareExpression.value = compareExpression.value || 'true'

            if (compareExpression.value !== 'true' && compareExpression.value !== 'false') {
                displayExpressionEntry.value = true
            } else {
                displayBooleanDropdown.value = true
            }
            break
        case FieldPropertyType.Decimal:
        case FieldPropertyType.Integer:
            if (!compareExpression.value)
                displayCompareValues.value = true
            else if (/^[\d.]+$/.test(compareExpression.value)) {
                //if our expression is entirely numeric, show it in the values list instead
                compareValues.value = [compareExpression.value]
                displayCompareValues.value = true
            }
            else
                displayExpressionEntry.value = true
            break;
        case FieldPropertyType.String:
            if (!compareExpression.value)
                displayCompareValues.value = true
            else if (wrapExpression(compareExpression.value) == compareExpression.value) {
                //if our expression is a quoted string, show it in the values list instead
                compareValues.value = [compareExpression.value]
                displayCompareValues.value = true
            }
            else
                displayExpressionEntry.value = true
            break
        case FieldPropertyType.Date:
            // show the date picker if the expression is empty or only contains a date value in the format of
            // the date picker. Both the date picker and the expression use the compareExpression field
            // The date picker was added to prevent errors from improperly formatted dates
            displayDatePicker.value = !props.expressionOnlyDates && (!compareExpression.value || /^"\d{2}\/\d{2}\/\d{4}"$/.test(compareExpression.value))
            displayExpressionEntry.value = !displayDatePicker.value
            break
        default:
            displayExpressionEntry.value = true
    }
}
 
async function getFieldSystemTypeValues(): Promise<MultiSelectItem[]> {
    const values = await metadataService.getSystemTypeValues(selectedSystemFieldType.value)

    if (props.disableWrappedExpressions) {
        return values.map((t: Reference) => ({ value: t.key, text: t.displayValue } as MultiSelectItem))
    }

    return values.map((t: Reference) => ({ value: wrapExpression(t.key), text: t.displayValue } as MultiSelectItem))
}

function wrapExpression(value: string | undefined) : string | undefined {
    //This component assumes that its results are ultimately going to be used as an expression in code (this might not always be the case, at which point this should become optional).
    //Some results (strings and dates) must therefore be wrapped in quotes in order to be executed properly.
    //The 'replace' handles escaping quotes when doing this wrapping. The regular expression tests to make sure a value is not getting double-wrapped.
    if (value && !/^"([^"]|\\")+"$/.test(value))
        return `"${value.replace(/"/g, '\\"')}"`
    return value
}

function toggleExpression(showExpression: boolean | null = null) {
    displayExpressionEntry.value = showExpression ?? !displayExpressionEntry.value

    switch (selectedFieldPropertyType.value) {
        case FieldPropertyType.SystemType:
            displayCriteriaDropDown.value = !displayExpressionEntry.value
            break
        case FieldPropertyType.Boolean:
            displayBooleanDropdown.value = !displayExpressionEntry.value
            break
        case FieldPropertyType.Integer:
        case FieldPropertyType.Decimal:
        case FieldPropertyType.String:
            displayCompareValues.value = !displayExpressionEntry.value
            break
        case FieldPropertyType.Date:
            displayDatePicker.value = !displayExpressionEntry.value
    }
}
</script>

<style lang="scss" scoped>
.code-input {
    padding-left: 0;
}

.input-switch {
    margin-top: 0.5em;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &.list-items {
        align-items: baseline;
    }
    &.expression {
        align-items: baseline;

        .form-group.col {
            padding-right: 0;
        }
    }

    .input-container {
        flex-grow: 1;
    }
}
</style>