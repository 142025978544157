export interface AdverseAction {
    formType: AdverseActionFormType
    decisionSources: DecisionSources
    maLendingStandards: MaLendingStandards
    c1: C1
    c4: C4
    c6: C6
}

export interface C1 {
    requestedCredit: string
    actionTaken: string
    denialReasons: DenialReasons
}

export interface C4 {
    reasonForDecline: string
    counteroffer: Counteroffer
}

export interface C6 {
    missingInformation: string
    dueDate: Date
}

export interface MaLendingStandards {
    lendingStandards: boolean
    lendingStandardsDescription: string
}

export interface DenialReasons {
    applicationIncomplete: boolean
    insufficientCreditReferences: boolean
    unacceptableCreditReferences: boolean
    unableToVerifyCreditReferences: boolean
    temporaryIrregularEmployment: boolean
    unableToVerifyEmployment: boolean
    lengthOfEmployment: boolean
    insufficientIncome: boolean
    excessiveObligations: boolean
    unableToVerifyIncome: boolean
    lengthOfResidence: boolean
    temporaryResidence: boolean
    unableToVerifyResidence: boolean
    noCreditFile: boolean
    limitedCredit: boolean
    poorCreditPerfomanceWithUs:boolean
    delinquentObligationsWithOthers: boolean
    collectionOrJudgment: boolean
    garnishmentOrAttachment: boolean
    foreclosureOrRepossession: boolean
    bankruptcy: boolean
    recentInquiries: boolean
    valueCollateralNotSufficient: boolean
    other: boolean
    otherDescription: string
}

export interface DecisionSources {
    creditReport: boolean
    creditScore: boolean
    affiliateOrOutsideInformation: boolean
}

export interface Counteroffer {
    loanType: string
    loanAmount: number
    term: number
    interestRate: number
    notificationDeadline: string //double check if this is a string
}

export enum AdverseActionFormType {
    None = "None",
    C1 = "C1",
    C4 = "C4",
    C6 = "C6"
}