export interface Vesting {
    borrowerVesting: string
    sellerVesting: string
}
export const VestingOptions = [
    { displayText: 'husband and wife' },
    { displayText: 'a married man' },
    { displayText: 'a married person' },
    { displayText: 'a married woman' },
    { displayText: 'an unmarried man' },
    { displayText: 'an unmarried woman' },
    { displayText: 'a single man' },
    { displayText: 'a single person' },
    { displayText: 'a single woman' },
    { displayText: 'a widow' },
    { displayText: 'a widower' },
    { displayText: 'wife and husband' },
    { displayText: 'her husband' },
    { displayText: 'his husband' },
    { displayText: 'her wife' },
    { displayText: 'his wife' },
    { displayText: 'spouse' }
]
