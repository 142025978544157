<template>
    <label class="badge" :class="[{'active': value}]" :disabled="disabled">
        <input type="checkbox" :checked="value" :disabled="disabled" autocomplete="off" @change="onChange" />
        {{ label }}
    </label>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{ (e: 'input', value: boolean): void }>()
defineProps({
    disabled: { type: Boolean },
    value: { type: Boolean },
    label: { type: String }
})
//#endregion

function onChange(e: Event) {
    emit('input', (e.target as HTMLInputElement)?.checked)
}
</script>

<style lang="scss" scoped>
.badge {
    color: $black;
    background-color: transparent;
    min-width: 3.5rem !important;
    max-width: 3.5rem !important;
    word-wrap: break-word;
    border: 1px solid $medium-gray;
    border-radius: 0;
    font-size: 0.6875rem;
    height: 1.125rem;
    line-height: 1em;
    margin: 0;
    padding: 0.1875rem 0.25rem;
    font-weight: normal;

    cursor: pointer;

    &:hover,
    &.active:not([disabled='disabled']):hover {
        color: $white;
        background-color: $orange;
    }

    &.active {
        color: $white;
        background-color: $bold-blue;
    }

    input {
        display: none;
    }
}

.badge[disabled='disabled'] {
    cursor: default;
    color: $medium-gray;

    &.active {
        color: $white;
    }

    &:not(.active):hover {
        background-color: transparent;
    }
}
</style>

<style lang="scss">
.badges-container {
    // !important needed to ensure styles aren't overridden by other styles like .form-group
    display: flex !important;
    flex-wrap: wrap !important;
    gap:.25rem !important;
}
</style>