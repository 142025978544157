<template>
    <div :class="`field ${size} form-group custom-control custom-checkbox`">
        <base-overridable
            :label="label"
            :jsonPathLabel="jsonPathLabel"
            label-class="custom-control-label"
            :inverse-layout="true"
            v-model="vm"
            :id="id"
            :auditChecks="auditChecks"
            :helpText="helpText"
            @undoOverride="undoOverride"
        >
            <template v-slot:input>
                <input :jsonpath="jsonPath" type="checkbox" class="custom-control-input" :id="id" v-model="vm.value" :disabled="!vm.isOverridden" />
            </template>
        </base-overridable>
    </div>
</template>

<script setup lang="ts">
import { PropType, defineProps, defineEmits, toRef } from 'vue'
import BaseOverridable from './base-overridable.vue'
import { Overridable } from '../models'
import { useAuditChecks } from '@/common/composables/audit-check-composable'

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'undoOverride', args: any) 
}>()

const props = defineProps({
    id: { type: String, required: true },
    value: { type: Object as PropType<Overridable<any>>, required: true },
    size: { type: String as PropType<string|null>, default: '' },
    label: { type: String },
    helpText: { type: String },
    jsonPath: { type: String, default: '' }
})

const vm = toRef(props, 'value')
//#endregion

//#region INITIALIZE
const { auditChecks, jsonPathLabel } = useAuditChecks(props)
//#endregion

function undoOverride(args: any) {
    emit('undoOverride', args)
}
</script>
