import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { FeeDefaults, FeeSections, SystemConfig } from '@/common/models'
import { systemService } from '@/common/services'
import { cloneDeep } from 'lodash'

export const useSystemConfigStore = defineStore('systemConfig', () => {
    const systemConfig = ref<SystemConfig | null>(null)
    const initializedSystemConfig = computed(() => cloneDeep(systemConfig.value || {} as SystemConfig))
    const feeDefaults = computed(() => initializedSystemConfig.value.feeDefaults || {} as FeeDefaults)
    const feeSections = computed(() => feeDefaults.value.feeSections || {} as FeeSections)
    
    async function getSystemConfig() {
        systemConfig.value = await systemService.getSystemConfig()
    }

    return { systemConfig, initializedSystemConfig, feeDefaults, feeSections, getSystemConfig }
})