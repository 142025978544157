<template>
    <div>
        <base-overridable
            :label="label"
            :jsonPathLabel="jsonPathLabel"
            :value="vm"
            :id="id"
            :validationRules="validationRules"
            :validationFieldName="label"
            :immediateValidation="immediateValidation"
            :auditChecks="auditChecks"
            :isRadio="true"
            :helpText="helpText"
            @undoOverride="undoOverride"
        >
            <template v-slot:input>
                {{ text }}
                <div :jsonpath="jsonPath" class="custom-control custom-radio">
                    <div v-for="(option, index) in options" :key="index">
                        <input
                            type="radio"                            
                            :id="id+option.value"
                            :disabled="!vm.isOverridden"
                            class="custom-control-input"
                            :text="option.text"
                            :value="option.value"
                            v-model="vm.value"
                            :checked="option.value === vm.value"
                            @change="$emit('change', option.value)"
                        />
                        <label class="custom-control-label" :for="id + option.value">{{option.text}}</label>
                    </div>
                </div>
            </template>
        </base-overridable>
    </div>
</template>

<script setup lang="ts">
import { PropType, defineProps, defineEmits, toRef } from 'vue'
import BaseOverridable from './base-overridable.vue'
import { Overridable } from '../models'
import { useAuditChecks, validationProps } from '@/common/composables/audit-check-composable'
import { MultiSelectItem } from './multi-select-item'

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'undoOverride', args: any) 
    (e: 'change', value: any): void 
}>()

const props = defineProps({
    value: { type: Object as PropType<Overridable<string>>, required: true },
    id: { type: String, required: true },
    options: { type: Array as PropType<Array<MultiSelectItem>>, required: true },
    text: { type: String, default: '' },
    label: { type: String },
    helpText: { type: String },
    jsonPath: { type: String, default: '' },
    ...validationProps
})

const vm = toRef(props, 'value')
//#endregion

//#region INITIALIZE
const { auditChecks, jsonPathLabel } = useAuditChecks(props)
//#endregion

function undoOverride(args: any) {
    emit('undoOverride', args)
}
</script>
