<template>
    <div class="breadcrumbs">
        <div class="nav-items">
            <span class="breadcrumb-item" v-for="i in navItems" :key="i.text">
                <router-link v-if="i.routeName" :to="{ name: i.routeName, params: i.routeParams, query: i.routeQuery }">{{ i.text }}</router-link>
                <span v-else>{{ i.text }}</span>
                <i class="bi-chevron-right" />
            </span>
        </div>
        <div class="page-title">
            {{ pageTitle }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { PropType, computed, defineProps } from 'vue'
import { BreadcrumbItem } from './breadcrumb-item'

//#region DEFINE VARIABLES
const props = defineProps({
    items: { type: Array as PropType<Array<BreadcrumbItem>>, required: true },
    title: { type: String },
})
//#endregion

//#region COMPUTED
const navItems = computed(() => props.items.slice(0, props.items.length - 1))
const pageTitle = computed(() => props.items[props.items.length - 1]?.text)
//#endregion
</script>

<style scoped lang="scss">
.breadcrumbs {
    color: $dark-gray;

    .nav-items {
        margin: 0;
        text-transform: uppercase;
        padding-left: 0.125rem;

        .breadcrumb-item {
            font-size: 0.75rem;
            font-weight: bold;
            letter-spacing: 0.025rem;

            &:last-of-type {
                i {
                    display: none;
                }
            }

            a {
                color: $bold-blue;

                &:hover {
                    color: $link-hover-color;
                    text-decoration: none;
                }
            }
        }
    }
    .page-title {
        color: $orange;
        font-size: 1.625rem;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 0.03125rem;
    }

    i {
        margin: 0 0.2rem 0 0.2rem;
    }
}
</style>