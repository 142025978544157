class CacheService {
    private cachedItems: Map<string, Promise<any>> = new Map<string, Promise<any>>()

    async getItem(id: string, func: () => Promise<any>): Promise<any> {
        let result = this.cachedItems.get(id)
        if (!result){
            const getPromise = new Promise<any>(resolve => {
                setTimeout(async () => {
                    const promiseResponse = await func()
                    resolve(promiseResponse)
                }, 0)
            });            
            result = getPromise
            this.cachedItems.set(id, result)
        }
        return result
    }

    removeItem(id: string) {
        this.cachedItems.delete(id) 
    }

    // listName is the beginning string of the "list". Passing in "feature_flag" for example will remove all feature flags cached
    removeList(listName: string)
    {
        const keys = [...this.cachedItems.keys()]

        keys.forEach(key => {
            if (key.startsWith(listName))
                this.removeItem(key)
        });
    }
}

export const cacheService = new CacheService()