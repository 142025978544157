import { IncomeTranscript, Irs4506TInfo } from '@/common/models'
import { cloneDeep } from "lodash"
import { TranscriptTypes } from "./tax-info-view-model"

export interface Irs4506TInfoViewModel extends Irs4506TInfo {
    returnTranscriptCheckbox: boolean
    accountTranscriptCheckbox: boolean
    recordOfAccountCheckbox: boolean
    taxFormSeriesCheckbox: boolean
}

class Irs4506TInfoViewModelConverter {
    toViewModel(model: Irs4506TInfo): Irs4506TInfoViewModel {
        const taxInfo = cloneDeep(model) as Irs4506TInfoViewModel
        taxInfo.recordTypes = taxInfo.recordTypes || []
        taxInfo.returnTranscriptCheckbox = taxInfo.recordTypes.includes(TranscriptTypes.ReturnTranscript)
        taxInfo.accountTranscriptCheckbox = taxInfo.recordTypes.includes(TranscriptTypes.AccountTranscript)
        taxInfo.recordOfAccountCheckbox = taxInfo.recordTypes.includes(TranscriptTypes.RecordOfAccount)
        taxInfo.taxFormSeriesCheckbox = taxInfo.recordTypes.includes(TranscriptTypes.TaxFormSeriesTranscript)

        taxInfo.incomeTranscript = taxInfo.incomeTranscript || {} 
        taxInfo.incomeTranscript.formNumbers = taxInfo.incomeTranscript.formNumbers || []
        return taxInfo
    }

    fromViewModel(viewModel: Irs4506TInfoViewModel): Irs4506TInfo {
        viewModel.recordTypes.splice(0)

        if (viewModel.returnTranscriptCheckbox)
            viewModel.recordTypes.push(TranscriptTypes.ReturnTranscript)
        if (viewModel.accountTranscriptCheckbox)
            viewModel.recordTypes.push(TranscriptTypes.AccountTranscript)
        if (viewModel.recordOfAccountCheckbox)
            viewModel.recordTypes.push(TranscriptTypes.RecordOfAccount)
        if (viewModel.taxFormSeriesCheckbox)
            viewModel.recordTypes.push(TranscriptTypes.TaxFormSeriesTranscript)
        else
            viewModel.incomeTranscript = {
                formNumbers: [] as string[]
            } as IncomeTranscript

        return Object({
            ...viewModel            
        })
    }
}

export const irs4506TInfoViewModelConverter = new Irs4506TInfoViewModelConverter()