<template>
    <div :id="`${id}-group`" :class="`field ${size} form-group text-area-group`">
        <base-overridable :label="label" :jsonPathLabel="jsonPathLabel" :value="vm" :id="id" :auditChecks="auditChecks" :validationRules="validationRules" :helpText="helpText"
                    :validationFieldName="label" :immediateValidation="immediateValidation" :errorMessage="errorMessage" @undoOverride="undoOverride">
            <template v-slot:input="{ errors }">
                <textarea
                    :jsonpath="jsonPath" 
                    class="form-control" 
                    :class="auditCheckLevelClass ? auditCheckLevelClass : getErrorMessage(errors[0]) ? 'error' : ''"
                    :rows="rows" 
                    :id="id" 
                    v-model="vm.value" 
                    :disabled="!vm.isOverridden" 
                    ref="textareaRef" 
                    @paste.prevent="onPaste">
                </textarea>
            </template>
        </base-overridable>
    </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, PropType, toRef, ref, onMounted } from 'vue'
import { Overridable } from '../models'
import BaseOverridable from './base-overridable.vue'
import { useAuditChecks, useValidation, validationProps } from '@/common/composables/audit-check-composable'
import { useDisableAutoComplete } from '@/common/composables/disable-autocomplete-composable'

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'input', value: Overridable<string>): void
    (e: 'undoOverride', event: any): void
}>()

const props = defineProps({
    value: { type: Object as PropType<Overridable<string>>, required: true },
    id: { type: String, required: true },
    rows: { type: Number},
    size: { type: String as PropType<string|null> },
    label: { type: String },
    helpText: { type: String },
    jsonPath: { type: String, default: '' },
    ...validationProps
})

const textareaRef = ref<HTMLTextAreaElement | null>(null)

const { auditCheckLevelClass, auditChecks, jsonPathLabel } = useAuditChecks(props)
const { getErrorMessage } = useValidation(props)

const vm = toRef(props, 'value')
//#endregion

onMounted(() => useDisableAutoComplete(textareaRef))

function undoOverride(args: any) {
    emit('undoOverride', args)
}

function onPaste(e: ClipboardEvent) {
    if (e.clipboardData) {
        const plainText = e.clipboardData.getData('text/plain')
        const textarea = textareaRef.value as HTMLTextAreaElement
        const position = textarea.selectionStart
        const finalValue = insertString(textarea.value, plainText, textarea.selectionStart ?? 0, textarea.selectionEnd ?? 0)
        vm.value.value = finalValue
        textarea.value = finalValue
        textarea.selectionStart = position ? position + plainText.length : plainText.length
        textarea.selectionEnd = textarea.selectionStart
        emit('input', props.value)
    }
}

function insertString(mainString: string, stringToInsert: string, positionStart: number, positionEnd: number) {
    return mainString.slice(0, positionStart) + stringToInsert + mainString.slice(positionEnd)
}
</script>

