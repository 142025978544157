import { Overridable } from './index';

export interface ExistingLoan {
    originalBorrower: string
    originalLender: string
    loanNumber: string
    baseLoanAmount: number
    loanAmount: number
    loanBalance: number
    monthlyPayment: number
    term: number
    remainingTerm: number
    mortgageType: string
    vaLoanType: string
    interestRate: number
    ltv: number
    existingLienDate: Date
    recording: string
    refinanceType: RefiComparisonType
    impactOfRefinance: ImpactOfRefinance
    homeEquityRemaining: Overridable<number>
    amountDirectlyDisbursedToBorrower: number
    payoffsDisbursedOnBehalfOfBorrower: number
    amountIncreaseInTotalPaidOverLifeOfLoan: number | null
    monthlyDecreaseInPayment: number | null
    isTxHomeEquity: boolean | null
    closingDate: Date | null
    firstPaymentDate: Date | null
    sixthConsecutivePaymentDate: Date | null
    amortizationType: string
    prepaymentPenaltyProvisions: string
    debtToIncomeRatio: string
    loanPurpose: string
    loanType: string
    originalMaturityDate: Date
    sixConsecutivePaymentsMade: boolean | null
}

export interface ImpactOfRefinance {
    loanNumber: string
    loanBalance: Overridable<number>
    monthlyPayment: Overridable<number>
    loanTerm: Overridable<number>
    interestRate: Overridable<number>
    remainingTerm: Overridable<number>
    totalRemainingScheduledPayments: Overridable<number>
    ltv: Overridable<number>
    homeEquityRemaining: Overridable<number>
    totalClosingCosts: number
    monthsToRecoupClosingCosts: number

}

export enum RefiComparisonType {
    Type1= "Type1",
    Type2= "Type2",
    IRRRL = "IRRRL"    
}

export enum LoansType {
    Fixed = "Fixed",
    ARM = "ARM",
    HybridARM = "HybridARM",
    Heloc = "Heloc"
}

