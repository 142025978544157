import { EFillableField } from './eFillableField'
import { CopiesRule, PlacementRule, AuditInfo } from './index'

export interface DocumentSummary {
    id: string | null
    formId: string
    title: string
    description: string
    type: string
    clientCodes: string[]
    states: string[]
    status: string
    created: Date
    createdBy: string
    updated: Date
    updatedBy: string
}

export interface DocumentDetailsPreview {
    shortTitle: string
    longTitle: string
    document: DocumentDetails
}

export interface DocumentDetails {
    id: string | null
    formId: string
    title: string
    description: string
    customType: string | null
    type: string
    formTypes: string[]
    notes: string
    tags: string[]
    states: string[]
    clientCodes: string[]
    investorId: string
    copyCount: string
    collectionFieldId: string
    signatureSettings: DocumentSignatureSettings[] 
    status: string
    isMultiState: boolean
    isStateSpecific: boolean
    isClientSpecific: boolean
    canBeEsigned: boolean
    expectedFileNames: string[]
    customDatasets: string[]
    placementRules: PlacementRule[]
    copiesRules: CopiesRule[]
    eFillableFields: EFillableField[]
    audit: AuditInfo
}

export interface DocumentSignatureSettings {
    displayOptions: string[]
    layoutType: string
    dateValue: string | null
    dateLocation: string
    sealFormat: string
    borrowerLabel: string
    nonBorrowerLabel: string
    noteEndorsementType: string
    ssnDisplayType: string
    includeAllSignersInBorrowerHeader: boolean
    excludeDateOnSignatureLine: boolean
    title: string
    canBeEsigned: boolean
    isPrintAsTrustee: boolean
    includeSettlors: boolean
    settlorLabel: string
    sellerLabel: string
}

export interface DocumentTestDataParams {
    loanId: string
    packageType: string
    document: DocumentDetails
    form: FormDetail
}

export interface DocumentTestResult {
    type: ResponseType
    fileUri: string
}
export interface FormDetail {
    fileName: string
    file: string | undefined
}

export interface ExpectedFile {
    fileName: string
    exists: boolean
}
export interface ExpectedFileParameters {
    formId: string | null
    documentType: string | null
    isMultiState: boolean
    isStateSpecific: boolean
    states: string[]
}

export enum ResponseType {
    Success = 'Success',
    Warning = 'Warning',
    Error = 'Error'
}