<template>
    <base-page :breadcrumbs="getBreadcrumbs()" :pageIsBusy="pageIsBusy">
        <template v-slot:pageActions>
            <!-- Save (note there are two versions of the custom-button) -->
            <custom-button
                v-if="!isPersist"
                icon="Save"
                label="Save"
                split
                @click="onPersistSave(false)"
                :loading="isCommitLoading"
                :disabled="isSaveDisabledValue"
                :title="persistMessage"
            >
                <template v-slot:menuItems>
                    <b-dropdown-item :disabled="isPersist || isPersistToggleDisabledValue" @click.prevent="onPersistToggle"
                        ><custom-icon icon="Selected" :class="isPersist ? 'checked' : ''" />Auto-Save On</b-dropdown-item
                    >
                    <b-dropdown-item :disabled="!isPersist || isPersistToggleDisabledValue" @click.prevent="onPersistToggle"
                        ><custom-icon icon="Selected" :class="!isPersist ? 'checked' : ''" />Auto-Save Off</b-dropdown-item
                    >
                </template>
            </custom-button>
            <custom-button
                v-else
                :icon="isPersist ? 'Refresh' : 'Save'"
                label="Save"
                split
                @click="save"
                :loading="isSaveLoading"
                :disabled="isSaveDisabledValue"
                :title="persistMessage"
            >
                <template v-slot:menuItems>
                    <b-dropdown-item :disabled="isPersist || isPersistToggleDisabledValue" @click.prevent="onPersistToggle"
                        ><custom-icon icon="Selected" :class="isPersist ? 'checked' : ''" />Auto-Save On</b-dropdown-item
                    >
                    <b-dropdown-item :disabled="!isPersist || isPersistToggleDisabledValue" @click.prevent="onPersistToggle"
                        ><custom-icon icon="Selected" :class="!isPersist ? 'checked' : ''" />Auto-Save Off</b-dropdown-item
                    >
                </template>
            </custom-button>

            <!-- Preview -->
            <custom-button
                v-if="showPreviewButton"
                icon="Preview"
                :label="previewDocument && previewDocument.shortTitle"
                :title="previewDocument && 'Preview the ' + previewDocument.longTitle"
                :loading="loadingPreview"
                split
                @click="defaultPreview"
                class="preview-button"
            >
                <template v-slot:menuItems>
                    <b-dropdown-item v-for="(doc, index) in availableDocuments" :key="index" @click="changeDocument(doc)">{{ doc.longTitle }}</b-dropdown-item>
                </template>
            </custom-button>

            <!-- Screen Mode -->
            <custom-toggle-button
                v-if="documentModeIsLeOrCd"
                id="document-mode-toggle"
                @onToggle="toggleDocumentMode"
                :value="!loanInLeMode"
                :disabled="isDocumentModeToggleDisabled"
                :backgroundColor="documentModeToggleBackground"
                offLabel="LE"
                onLabel="CD"
                :tooltipDelay="100"
                :fontSize="13"
            >
                <template v-slot:tooltip>
                    <div v-if="loanInLeMode">
                        <p><b>This loan is in LE/Initial Disclosure mode.</b></p>
                        <p>On the Fees screen, Sections B and C are the LE versions of those sections.</p>
                        <p>Some screens that are specific to the CD or closings are not visible.</p>
                        <p>Click the toggle switch to change to CD/Closing mode.</p>
                    </div>
                    <div v-else>
                        <p><b>This loan is in CD/Closing mode.</b></p>
                        <p>On the Fees screen, fees are displayed as they will appear on the CD.</p>
                        <p>
                            Sections B and C are the CD versions of those sections. Switching back to LE mode is not allowed, but you can still use the Fees
                            screen to arrange fees for the LE.
                        </p>
                        <span>Fees will appear in Section C of the LE if:</span>
                        <ul>
                            <li>They are in Section C on the Fees screen</li>
                            <li>They are in Section B on the Fees screen but have the CanShop badge selected</li>
                        </ul>
                    </div>
                </template>
            </custom-toggle-button>

            <!-- Lock -->
            <custom-toggle-button
                id="lockButton"
                @onToggle="onLockToggle"
                on-icon="lock-fill"
                :off-icon="isLockedByOther ? 'lock-fill' : 'unlock-fill'"
                :title="lockMessage"
                v-if="useLoanLocking"
                :value="isLockedByMe"
                :disabled="isLockToggleDisabledValue"
                :backgroundColor="isLockedByOther ? '#ffaa34' : null"
            />
            <!-- Page-Specific Components -->
            <slot name="pageActions"></slot>

            <template v-if="isDebugModeEnabled">
                <custom-button v-if="!debugModeEnabled" label="Enable Debug Mode" @click="onDebugModeToggle"></custom-button>
                <custom-button v-else split icon="Refresh" label="Refresh Debug" @click="refreshDebugMode">
                    <template v-slot:menuItems>
                        <b-dropdown-item @click.prevent="onDebugModeToggle">
                            Disable Debug Mode
                        </b-dropdown-item>
                    </template>
                </custom-button>
            </template>

        </template>
        <template v-if="debugModeEnabled">
            <div class="json-path-warnings">
                <blurb v-if="jsonPathWarnings" type="Warning" borderVariant="Warning" class="warning-border">
                    <template v-slot:longMessage>
                        <div v-for="(warnings, errorType) in jsonPathWarnings" :key="errorType">
                        <span>{{errorType}}: {{getJsonPathErrorDescription(errorType)}}</span>
                        <ul>
                            <li v-for="(path, index) in warnings" :key="index">
                                {{path}}
                            </li>
                        </ul>
                    </div>
                    </template>
                </blurb>
                <blurb v-else type="Success">
                    <template v-slot:longMessage>
                        <span>No json path errors were found on this page. Click the refresh button if that does not appear correct.</span>
                    </template>
                </blurb>
            </div>
        </template>
        <div ref="mainContent">
            <slot />
        </div>
        <template v-slot:summarySidebar>
            <loan-summary />
        </template>
        <b-modal id="persistModal" v-model="showPersistModal" @ok="onPersistSave(true)" @cancel="onPersistDiscard">
            <template v-slot:modal-title>Turn Auto-Save On</template>
            <template v-slot:default>
                <blurb type="Question">
                    <template v-slot:shortMessage>You are about to turn auto-save ON. </template>
                    <template v-slot:longMessage
                        ><p>What do you want to do with your changes?</p>
                        <ul>
                            <li>Save them and overwrite any changes made by others while you had auto-save turned off.</li>
                            <li>Discard them and start with the version most recently saved by others?</li>
                        </ul>
                    </template>
                </blurb>
            </template>
            <template #modal-footer="{ ok, cancel, close }">
                <custom-button variant="secondary" @click="close()" label="Leave Auto-Save Off" />
                <custom-button variant="danger" @click="cancel()" label="Discard My Changes" />
                <custom-button @click="ok()" label="Save My Changes" />
            </template>
        </b-modal>
        <confirmation-modal id="overwriteModal" title="Save Changes" v-model="showOverwriteModal" @ok="onCommit" ok-title="Save" cancel-title="Cancel">
            <template v-slot:shortMessage>Are you sure you want to save changes to this loan?</template>
            <template v-slot:longMessage
                >Since this loan is not locked, someone else could have made changes to this loan, and their changes will be lost.</template
            >
        </confirmation-modal>
        <pdf-preview-modal id="preview-modal" v-model="showPreviewModal" :src="previewDocumentUrl" />
        <confirmation-modal id="lockedModal" title="Locked" v-model="showLockedModal" messageType="Info" ok-only ok-title="Continue">
            <template v-slot:shortMessage>This loan is locked.</template>
            <template v-slot:longMessage>This loan has been locked by {{ lockedByName }}. You will not be able to save any changes.</template>
        </confirmation-modal>
        <confirmation-modal
            id="forceLockModal"
            title="Locked"
            v-model="showForceLockModal"
            @ok="onForceLock"
            ok-title="Confirm"
            ok-variant="danger"
            cancel-title="Cancel"
        >
            <template v-slot:shortMessage>Take the lock from {{ lockedByName }}?</template>
            <template v-slot:longMessage
                ><p>This loan was locked by {{ lockedByName }} at {{ lockedTime }}. Are you sure you want to remove their lock and lock it yourself?</p>
                You will lose any changes you have not saved.</template
            >
        </confirmation-modal>
        <confirmation-modal id="afterLockModal" title="Locked" v-model="showLockForcedModal" messageType="Info" ok-only ok-title="Continue">
            <template v-slot:shortMessage>You no longer have a lock on this loan.</template>
            <template v-slot:longMessage>The lock on this loan has been taken by {{ lockedByName }}. Changes you make will no longer be saved.</template>
        </confirmation-modal>
        <b-modal
            id="programModal"
            v-model="showProgramModal"
            title="Missing Data"
            @ok="onProgramAndLenderSave()"
            ok-title="Save"
            :ok-disabled="!programAndLenderSelected"
            @cancel="quit()"
            @close="quit()"
            size="md"
            :scrollable="false"
        >
            <template v-slot:default>
                <p>Please provide the following data before editing any other information in this loan:</p>
                <div class="field-section" v-if="showLenderDropdown">
                    <fg-select
                        id="lenderProfileSelect"
                        label="Lender Profile"
                        :showEmptyOption="false"
                        :source="availableLenderProfiles"
                        :useLocalSearch="true"
                        :searchable="true"
                        v-model="lender.info.lenderProfileId"
                        size="xl"
                    />
                    <fg-checkbox
                        id="updateLenderProfileCheckbox"
                        v-model="updateLenderDefaults"
                        label="Update this loan's data with the lender profile's defaults"
                    />
                </div>
                <div class="field-section">
                    <fg-select
                        v-if="showProgramDropdown && loanRecord"
                        id="programCode"
                        label="Loan Program"
                        :source="programSelectItems"
                        v-model="loanRecord.data.product.programCode"
                        size="xl"
                    />
                </div>
            </template>
        </b-modal>
        <b-modal
            id="documentModeModal"
            v-model="showDocumentModeModal"
            @ok="convertLoanToClosing()"
            ok-title="Switch to CD Mode"
            cancel-title="Stay in LE Mode"
            title="Switch to CD/Closing Mode"
        >
            <template v-slot:default>
                <blurb type="Question">
                    <template v-slot:shortMessage>You have chosen to switch this loan to CD mode.</template>
                    <template v-slot:longMessage
                        ><p>Fees in LE Sections B and C will be moved to Sections B and C of the CD.</p>
                        <p>Do you want the CD amounts to match the estimated amounts?</p>
                        <div class="field-section">
                            <fg-radio-button
                                :options="documentModeConversionOptions"
                                id="documentModeConversionType"
                                v-model="selectedDocumentModeConversion"
                                name="documentModeConversionType"
                            />
                        </div>
                    </template>
                </blurb>

                <blurb variant="warning">
                    <template v-slot:shortMessage>This operation cannot be undone.</template>
                    <template v-slot:longMessage>
                        Once a loan is in CD mode, it cannot be returned to LE mode. A Loan Estimate generated from a loan in CD mode may not be
                        accurate.</template
                    >
                </blurb>
            </template>
        </b-modal>
    </base-page>
</template>
<style scoped lang="scss">
:deep(.modal-xl .modal-body) {
    height: 70vh !important;
}

.page-actions .dropdown-item {
    .custom-icon.selected {
        font-size: 0.75rem;
        &:not(.checked) {
            visibility: hidden;
        }
    }
}

.page-spinner {
    position: initial !important;
}

:deep(.preview-button) {
    button:not(.dropdown-toggle) {
        min-width: 5.25rem;
    }
}

.btn-group.focus,
.btn-group:focus {
    outline: 0;
    box-shadow: none !important;
}

#documentModeModal .blurb:last-of-type {
    margin-top: 1rem;
}

:deep(#document-mode-toggle-container) {
    .v-switch-label {
        line-height: 1.365rem !important;
        &.v-left {
            left: 0.4375rem;
        }
        &.v-right {
            right: 0.5625rem;
        }
    }
}

.json-path-warnings {
    margin-bottom: 1rem;
    .blurb {
        padding: 1rem 0 .5rem 1rem;
        max-height: 250px;
        overflow-y: auto;
    }
}
</style>
<script lang="ts" setup>
import { PropType, computed, defineProps, onMounted, onUnmounted, ref, watch } from "vue"
import { config } from '@/config'
import LoanSummary from '@/propel/components/loan-summary.vue'
import { BreadcrumbItem } from '@/common/components/navigation/breadcrumb-item'
import { 
    Client,
    DocumentDatasets, 
    DocumentDetailsPreview, 
    DocumentModeConversionType, 
    LenderProfileContactDefaultType, 
    Loan, 
    LoanLockRequest, 
    LoanPersistRequest, 
    LoanRecord, 
    LoanRequestOptions,
    Program, 
    ReferenceSource, 
    ReferenceType,
    UpdateLenderProfileRequest, 
    UpdateDocumentModeRequest, 
} from '@/common/models'
import {  docGenService, jsonPathTestingService, metadataService, saveLoanSelectionsService } from '@/propel/services'
import { loanService, referenceService, userPermissionValidatorService, userPreferenceService, adminDataService } from '@/common/services'
import { dateTimeDiffFilter } from '@/common/filters'
import { MultiSelectItem } from '@/common/components/multi-select-item'
import { DocumentMode } from '@/propel/models'
import { JsonPathErrorType } from '@/propel/models/metadata/json-path-test'
import { useRoute, useRouter } from "vue-router/composables"
import { useLenderSelect } from "./lender/lender-select-composable"
import { storeToRefs } from "pinia"
import { useAuditCheckStore, useClientStore, useCurrentUserStore, useLoanStore, usePageActionsStore, useSideNavStore } from "@/common/store"

//#region DEFINE VARIABLES
const props = defineProps({
    onSave: { type: Function as PropType<() => Promise<void>>, required: true},
    pageTitle: { type: String, default: null },
    noMainContentPadding: { type: Boolean },
    disablePageSpinner: { type: Boolean },
    isSaveDisabled: { type: Boolean },
    isPersistToggleDisabled: { type: Boolean },
    isLockToggleDisabled: { type: Boolean },
})

const route = useRoute()
const router = useRouter()
const { selectedSideNavMenuItem } = storeToRefs(useSideNavStore())
const userStore = useCurrentUserStore()
const pageActionsStore = usePageActionsStore()
const clientStore = useClientStore()
const auditCheckStore = useAuditCheckStore()
const store = useLoanStore()

const interval = ref<number|undefined>()
const previewDocumentUrl = ref<string|null>()

const availableDocuments = ref([] as DocumentDetailsPreview[])
const documentModeConversionOptions = ref([] as MultiSelectItem[])
const jsonPathWarnings = ref<Record<string, string[]> | null>(null)
const previewDocument = ref({ shortTitle: "" } as DocumentDetailsPreview)

const isDebugModeEnabled = ref(false)
const showPreviewModal = ref(false)
const showProgramModal = ref(false)
const loadingPreview = ref(false)
const showForceLockModal = ref(false)
const showPersistModal = ref(false)
const showOverwriteModal = ref(false)
const persistAfterSave = ref(false)
const showLenderDropdown = ref(false)
const showProgramDropdown = ref(false)
const showDocumentModeModal = ref(false)

const updateLenderDefaults = ref(true)

const selectedDocumentModeConversion = ref("")
const mainContent = ref()
//#endregion

//#region COMPUTED
const loanRecord = computed<LoanRecord | null>(() => store.loanRecord)
const lockedByName = computed(() => store.loanLock?.user)
const lockedTime = computed<string>(() => dateTimeDiffFilter(store.loanLock?.updateTime))
const currentUser = computed(() => userStore.currentUser?.emailAddress)
const documentModeIsLeOrCd = computed<boolean>(() => store.loanInCdMode || store.loanInLeMode)
const programAndLenderSelected = computed<boolean>(() => store.loan?.product?.programCode != null && lender?.value.info?.lenderProfileId != null)
const client = computed<Client>(() => clientStore.initializedClient)
const isPersist = computed<boolean>(() => store.persistGetter)
const isCommitLoading = computed<boolean>(() => store.cacheCommitLoading)
const isPersistToggleDisabledValue = computed<boolean>(() => props.isPersistToggleDisabled || isCommitLoading.value || isSaveLoading.value || isLockedByOther.value)// check me
const useLoanLocking = computed<boolean>(() => !isLockToggleDisabledByAdmin.value)
const isLockToggleDisabledValue = computed<boolean>(() => props.isLockToggleDisabled || isCommitLoading.value || isSaveLoading.value)
const isLockToggleDisabledByAdmin = computed<boolean>(() => !client.value?.loanLock?.value?.enable)
const isLocked = computed<boolean>(() => store.loanLock != null)
const isLockedByMe = computed<boolean>(() => isLocked.value && lockedByName.value === currentUser.value)
const isLockedByOther = computed<boolean>(() => isLocked.value && lockedByName.value !== currentUser.value)
const isSaveDisabledValue = computed<boolean>(() => props.isSaveDisabled || isSaveLoading.value || isLockedByOther.value)
const isSaveLoading = computed<boolean>(() => pageActionsStore.saveLoading.isLoading)
const pageIsBusy = computed<boolean>(() => pageActionsStore.pageLoading.isLoading)
const loanInLeMode = computed<boolean>(() => store.loanInLeMode)
const loanInModificationMode = computed<boolean>(() => store.isModification)
const isDocumentModeToggleDisabled = computed<boolean>(() => !loanInLeMode.value || isCommitLoading.value || isSaveLoading.value)
const documentModeToggleBackground = computed<string>(() => loanInLeMode.value ? '#0064f9' : '#2d59ab')
const debugModeEnabled = computed<boolean>(() => auditCheckStore.debugModeEnabled)

const showPreviewButton = computed<boolean>(() => {
    //Hiding the preview button when we do not have a document to preview
    return !loanInModificationMode.value && availableDocuments.value.length > 0
})
const showLockedModal = computed({
    get() {
        return store.showLockedModal
    },
    set(show: boolean) {
        store.showLockedModal = show
    }
})
const showLockForcedModal = computed({
    get() {
        return store.showLockForcedModal
    },
    set(show: boolean) {
        store.showLockForcedModal = show
    }
})

const persistMessage = computed<string>(() => {
    return isPersist.value
        ? '<div><b>Auto-save is ON</b></div><div>Loan data will be saved automatically as you make changes, but you can also save manually at any time.</div>'
        : '<div><b>Auto-save is OFF</b></div><div>Loan data will be saved only when you click Save.</div>'
})
const lockMessage = computed<string>(() => {
    return isLockedByMe.value
        ? '<div><b>This loan is LOCKED by you</b></div><div>You are the only one who can save changes.</div>'
        : isLockedByOther.value
        ? `<div><b>This loan is LOCKED by ${lockedByName.value}</b></div><div><b>${lockedTime.value}</b></div><div>You cannot save changes.</div><div>Click to lock the loan yourself.</div>`
        : "<div><b>This loan is NOT locked</b></div><div>Someone else's changes may overwrite yours.</div>"
})
//#endregion

//#region WATCH
watch(() => route, () => routeUpdated(), { immediate: true })
watch(() => lockedByName.value, () => onLockChange(lockedByName.value))
watch(() => auditCheckStore.loanJsonPathTests.value, () => onJsonPathResultsChanged())

watch(() => [store.loanRecord?.data?.documentDatasets?.disclosures?.cashToClose?.disclosureType, store.loanRecord?.documentMode, store.loanRecord?.data?.property?.address?.state], 
    () => getAvailableDocuments())
//#endregion

//#region INITIALIZE
onMounted(() => refreshDebugMode())
onUnmounted(() => clearInterval(interval.value))

const { lender, availableLenderProfiles, initializeLender } = useLenderSelect()

initialize()

async function initialize() {
    isDebugModeEnabled.value = await setIsDebugModeEnabled()
    interval.value = setInterval(async () => {
        if (config.app.pingInterval <= 0) {
            //setting the interval to 0 disables this feature
            clearInterval(interval.value)
            return
        }
        await store.ping()
    }, config.app.pingInterval * 1000)

    
    await store.ping()
    await fixPersist()
    await getAvailableDocuments()
    await initializeLender(loanRecord.value?.data.lender)
    // Checking if document mode is LE or CD because Release of lien loans automatically assign a lender profile and dont have programs 
    if (!store.isReleaseOfLien){
        checkForLenderAndProgram()
    } 
    checkForHasShownLockedModal()
}

//#endregion

function routeUpdated() {
    const readPermission: string = route?.meta?.readPermission
    if (readPermission) {
        const hasPermission = userPermissionValidatorService.hasAnyPermission([readPermission])
        if (!hasPermission) {
            router.push({ name: 'unauthorized' })
        }
    }
}

function onLockChange(previous: string | undefined) {
    if (isLockedByOther.value) {
        if (previous === undefined) {
            showLockedModal.value = true
        }
        //was unlocked, but is now locked by someone else
        else if (previous === currentUser.value) {
            showLockForcedModal.value = true //was locked by me, but is now locked by someone else
        }
        fixPersist()
    }
}

function onJsonPathResultsChanged() {
    const jsonPathResults = jsonPathTestingService.getLoanPageResults(route?.name || '') ?? []
    const warnings = jsonPathResults.filter(p => p.level != 'Success')
    if(!warnings?.length)
        jsonPathWarnings.value = null
    else {
        const messages: Record<string, string[]> = {}
        warnings.forEach(result => {
            const errorType = result.errorType || 'Other'
            if(!messages[errorType]) {
                messages[errorType] = []
            }

            if(!result.errorType){
                messages[errorType].push(result.message)
            } else {
                messages[errorType].push(result.jsonPath)
            }
        })

        jsonPathWarnings.value = messages
    }
}

async function getAvailableDocuments(overwriteCache = false) {
    availableDocuments.value = await metadataService.getAvailablePreviewDocuments(loanRecord.value, overwriteCache)        
    previewDocument.value = availableDocuments.value.find((d) => d.document.formId === store.previewDocument) ?? availableDocuments.value[0]
}

async function onCommit() {
    store.cacheCommitLoading = true
    await save()
    await store.commitLoan()
    if (persistAfterSave.value) await onPersistDiscard()
    persistAfterSave.value = false
    store.cacheCommitLoading = false
}

async function onPersistToggle() {
    if (isPersist.value) await store.setLoanPersist({ requestCachedId: true } as LoanPersistRequest)
    else showPersistModal.value = true
}

function onPersistSave(persistAfter: boolean) {
    if (!isLockedByMe.value) {
        showOverwriteModal.value = true
    } else {
        onCommit()
    }
    persistAfterSave.value = persistAfter
}

async function onPersistDiscard() {
    await store.setLoanPersist({ requestCachedId: false, updateLoanRecord: true } as LoanPersistRequest)
}

async function fixPersist() {
    if (isLockedByOther.value && isPersist.value)
        //not allowed to persist if the loan is locked by someone else
        await store.setLoanPersist({ requestCachedId: true } as LoanPersistRequest)
}

function checkForLenderAndProgram() {
    showProgramDropdown.value = !loanRecord.value?.data.product?.programCode
    showLenderDropdown.value = !lender?.value.info?.lenderProfileId

    if (showProgramDropdown.value || showLenderDropdown.value) showProgramModal.value = true
}

async function programSelectItems(): Promise<MultiSelectItem[]> {
    let matchedPrograms = [] as Program[]
    if (loanRecord.value) {
        matchedPrograms = await loanService.getFilteredPrograms(loanRecord.value.data, loanRecord.value.partnerCode, loanRecord.value.clientCode)
    }
    return matchedPrograms.map((item: Program) => ({ value: item.code, text: item.displayName } as MultiSelectItem))
}

async function onProgramAndLenderSave() {
    const updatedData: Loan = {} as Loan
    updatedData.documentDatasets = {} as DocumentDatasets

    if (showProgramDropdown.value && loanRecord.value?.data.product) {
        updatedData.product = loanRecord.value.data.product
    } 

    if (showLenderDropdown.value) {
        await store.updateLenderProfile({
            lenderProfileId: lender.value.info.lenderProfileId,
            defaultType: updateLenderDefaults.value ? LenderProfileContactDefaultType.Initial : LenderProfileContactDefaultType.None
        } as UpdateLenderProfileRequest)
    }

    //specifically handles then loan product update
    const patchData = {
        data: updatedData
    }

    await store.patchLoanRecord({ patchData: patchData, options: {} as LoanRequestOptions })
}

async function changeDocument(document: DocumentDetailsPreview) {
    previewDocument.value = document
    store.previewDocument = document.document.formId
    await defaultPreview()
}

async function defaultPreview() {
    loadingPreview.value = true
    await save()
    await getDocument()
    showPreviewModal.value = true
    loadingPreview.value = false
}

async function onLockToggle() {
    if (isLockedByOther.value) showForceLockModal.value = true
    else await store.setLoanLock({ lock: !isLocked.value } as LoanLockRequest)
}

async function onForceLock() {
    await store.setLoanLock({ lock: true, force: true } as LoanLockRequest)
    //auto-save is already off, but we want the endpoint to give us a fresh cached copy
    await store.setLoanPersist({ requestCachedId: true, updateLoanRecord: true } as LoanPersistRequest)
}

async function getDocument() {
    if (loanRecord.value) {
        const data = await docGenService.generatePreviewPackage({
            loanRecord: loanRecord.value,
            isRedraw: false,
            formIds: [previewDocument.value.document.formId],
            isStateSpecific: previewDocument.value.document.isStateSpecific,
            isClientSpecific: previewDocument.value.document.isClientSpecific,
            states: previewDocument.value.document.states,
            clientCodes: previewDocument.value.document.clientCodes
        })
        previewDocumentUrl.value = data.pdfUri
    }
}

function checkForHasShownLockedModal() {
    if (isLockedByOther.value && !store.hasShownLockedModal) {
        store.hasShownLockedModal = true
        showLockedModal.value = true
    } else {
        showLockedModal.value = false
    }
}

function getBreadcrumbs(): BreadcrumbItem[] {   
    return [
        { text: 'Loans', routeName: 'loan-grid', routeQuery: store.loanGridQuery },
        { text: store.loanRecord?.loanNumber ?? ""},
        { text: props.pageTitle ?? selectedSideNavMenuItem.value?.name }
    ]
}

function quit() {
    //go back to loan grid with the stored query
    router.push({ name: 'loan-grid', query: store.loanGridQuery })
}

async function setIsDebugModeEnabled() {
    if (!loanRecord.value?.clientCode)
        return false
    
    const enableDebugModeFlag = await adminDataService.getFeatureFlagStatus("enable-debug-mode", loanRecord.value?.clientCode)

    return enableDebugModeFlag.isEnabled
}

async function onDebugModeToggle() {
    if(debugModeEnabled.value){
        auditCheckStore.debugModeEnabled = false
    } else {
        auditCheckStore.debugModeEnabled = true

        await jsonPathTestingService.initializeJsonPathsTest()

        refreshDebugMode()
    }
}

function refreshDebugMode() {
    if(debugModeEnabled.value) {
        jsonPathTestingService.checkJsonPaths(mainContent.value, route?.name || '')
    }
}

function getJsonPathErrorDescription(type: string) {
    if(type == JsonPathErrorType.FormInputMissing) {
        return 'A metadata Field exists for these paths, and the Audit Check Keys for this section indicate it should be found on this page, but the input was not found. This may be caused by the input being hidden by certain logic. This is only an issue if the paths specified could not appear on this page, in which case they should be excluded from the Audit Check Keys.'
    } else if (type == JsonPathErrorType.AuditCheckKeyMissing) {
        return 'These paths exist on the current loan page, but were not expected due to no corresponding Audit Check Key available for this page. Check to make sure that the json path and the Audit Check Keys match.'
    } else {
        return 'Additional Warnings'
    }
}

async function toggleDocumentMode() {
    const conversionPreference = userPreferenceService.getDocumentModeConversionType()
    documentModeConversionOptions.value = (await referenceService.getMultiSelectItems(ReferenceType.documentModeConversionTypes, ReferenceSource.loan))
    selectedDocumentModeConversion.value = conversionPreference || DocumentModeConversionType.MatchAllEstimatedAmounts

    showDocumentModeModal.value = true
}

async function convertLoanToClosing() {
    await userPreferenceService.setDocumentModeConversionType(selectedDocumentModeConversion.value)
    await store.updateDocumentMode({
        documentMode: DocumentMode.Closing,
        matchEstimatedAmountsOption: selectedDocumentModeConversion.value
    } as UpdateDocumentModeRequest)

    saveLoanSelectionsService.setDocSelection(null)
}

async function save () {
    if (props.onSave) await props.onSave()
    await getAvailableDocuments(true)
}
</script>