import { render, staticRenderFns } from "./regcheck-loan-summary.vue?vue&type=template&id=77b0a42a&scoped=true"
import script from "./regcheck-loan-summary.vue?vue&type=script&setup=true&lang=ts"
export * from "./regcheck-loan-summary.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./regcheck-loan-summary.vue?vue&type=style&index=0&id=77b0a42a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77b0a42a",
  null
  
)

export default component.exports