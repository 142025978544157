import { ChangeOfCircumstanceReasonType } from "./trid-disclosure"

export interface Fee {
    isBorrowerChosen: boolean
    totalAmount?: number
    estimatedAmount?: number
    description: string
    paidToType: FeePaidToType
    paidToTypeOtherDescription: string
    paidToName: string | null
    type: string
    typeOtherDescription: string
    section: FeeSectionType
    feePayments: FeePayment[]
    isPostConsummation: boolean
    responsibleParty: FeeResponsiblePartyType
    isOneTimeCloseConstruction?: boolean
    isOptionalCost?: boolean
    borrowerCouldShopButDidNot: boolean
    changeOfCircumstanceReason: ChangeOfCircumstanceReasonType | null
    changeOfCircumstanceDate: Date | null
    key: string
}

export enum FeeSectionType {
    DueFromBorrowerAtClosing = 'DueFromBorrowerAtClosing',
    DueFromSellerAtClosing = 'DueFromSellerAtClosing',
    DueToSellerAtClosing = 'DueToSellerAtClosing',
    InitialEscrowPaymentAtClosing = 'InitialEscrowPaymentAtClosing',
    OriginationCharges = 'OriginationCharges',
    Other = 'Other',
    OtherCosts = 'OtherCosts',
    PaidAlreadyByOrOnBehalfOfBorrowerAtClosing = 'PaidAlreadyByOrOnBehalfOfBorrowerAtClosing',
    PayoffsAndPayments = 'PayoffsAndPayments',
    Prepaids = 'Prepaids',
    ServicesBorrowerDidNotShopFor = 'ServicesBorrowerDidNotShopFor',
    ServicesBorrowerDidShopFor = 'ServicesBorrowerDidShopFor',
    ServicesYouCannotShopFor = 'ServicesYouCannotShopFor',
    ServicesYouCanShopFor = 'ServicesYouCanShopFor',
    TaxesAndOtherGovernmentFees = 'TaxesAndOtherGovernmentFees',
    TotalClosingCosts = 'TotalClosingCosts',
    TotalLoanCosts = 'TotalLoanCosts',
    TotalOtherCosts = 'TotalOtherCosts'
}

export enum FeePaidByType {
    Broker = 'Broker',
    Buyer = 'Buyer',
    Correspondent = 'Correspondent',
    Lender = 'Lender',
    Seller = 'Seller',
    ThirdParty = 'ThirdParty'
}

export enum FeePaidToType
{
    AffiliateProvider = 'AffiliateProvider',
    Broker = 'Broker',
    BrokerAffiliate = 'BrokerAffiliate',
    Investor = 'Investor',
    Lender = 'Lender',
    LenderAffiliate = 'LenderAffiliate',
    Other = 'Other',
    ThirdPartyProvider = 'ThirdPartyProvider'
}
export enum FeeResponsiblePartyType {
    Borrower = 'Borrower',
    Seller = 'Seller'
}

export interface FeePayment {
    key: string
    paidByType: FeePaidByType
    amount?: number
    estimatedAmount?: number
    isPaidOutsideOfClosing: boolean
    isFinanced: boolean
    isIncludedInApr: boolean
    includeInPointsAndFees: boolean
    isRefundable: boolean
    deductFromWire: boolean
    tridComparisonAmount?: number | null
    paidByName?: string | null
}

export enum UpfrontMortageInsuranceType{
    USDARuralDevelopmentGuaranteeFee = 'USDARuralDevelopmentGuaranteeFee',
    VAFundingFee = 'VAFundingFee',
    MIUpfrontPremium = 'MIUpfrontPremium',
    MIInitialPremium = 'MIInitialPremium'
}

export const UpfrontMortageInsuranceTypes: string[] = [UpfrontMortageInsuranceType.USDARuralDevelopmentGuaranteeFee, UpfrontMortageInsuranceType.VAFundingFee, UpfrontMortageInsuranceType.MIUpfrontPremium, UpfrontMortageInsuranceType.MIInitialPremium]