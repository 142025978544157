<template>
    <div>
        <h1>{{ type }}s</h1>
        <content-block-list
            :id="`list-${type}`"
            :listItems.sync="editableList"
            :onAdd="addListItem"
            @itemDeleted="removeListItemSelectedStates"
            deletable
            draggable
            :addLabelText="`Add ${type}`"
            size="m"
        >
            <template v-slot:view="slotParams">
                <div class="standard-columns">
                    <slot name="viewItems" v-bind:item="slotParams.item"></slot>
                    <div class="s wrap">
                        {{ stateFormatter(slotParams.item.states) }}
                        <custom-icon
                            icon="Error"
                            id="states-error-icon"
                            v-if="displayStatesError(slotParams.item.states)"
                            title="Select at least one state"
                        />
                    </div>
                </div>
            </template>
            <template v-slot:edit="slotParams">
                <div class="field-section">
                    <slot name="editItems" v-bind:item="slotParams.item" v-bind:index="slotParams.index"></slot>
                    <div class="form-group field m">
                        <checkbox-select-list
                            label="States"
                            :selectedValues.sync="slotParams.item.states"
                            :id="`states${slotParams.index}`"
                            :source="applicableStates(slotParams.item.states)"
                        />
                    </div>
                </div>
            </template>
        </content-block-list>
    </div>
</template>

<script lang="ts" setup>
import { PropType, computed, ref, watch, defineProps, defineEmits } from "vue"
import { referenceService, userPermissionValidatorService } from "@/common/services"
import { useRoute } from "vue-router/composables"
import { SelectableItem } from "@/common/components/multi-select-item"

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: "update:itemList", list: any[]) 
}>()

const props = defineProps({
    type: { type: String, required: true },
    itemList: { type: Array as PropType<Array<any>>, required: true },
})
const masterStateList = ref([] as SelectableItem[])

const pageIsReadOnly = ref(false)

const route = useRoute()
//#endregion

//#region COMPUTED
const editableList = computed({
    get() {
        return props.itemList
    },
    set(list: any[]) {
        emit("update:itemList", list)
    }
})
//#endregion

//#region WATCH
watch(() => route, () => routeUpdated(), { immediate: true })

function routeUpdated() {
    const writePermission: string = route?.meta?.writePermission

    if (writePermission)
        pageIsReadOnly.value = !userPermissionValidatorService.hasPermission([writePermission])
    else
        pageIsReadOnly.value = false
}
//#endregion

//#region INITIALIZE
initialize()

async function initialize(){
    const states = await referenceService.getFullNameStates()

    masterStateList.value = states.map((s) => ({ value: s.abbreviation, text: s.name, selected: false }))
    masterStateList.value.forEach((s) => (s.selected = editableList.value.some((mr) => mr.states.includes(s.value)))) // Set selected to true if included in any minimumRate's states array
}
//#endregion

// Set all state's selected bool to false if that state was located in the deleted item's states array
function removeListItemSelectedStates(item: any) {
    masterStateList.value.map((s) => {
        if (item.states.includes(s.value)) s.selected = false
    })
}

function addListItem(): any {
    return { states: [] as string[] }
}

// Display error if there is more than one listItem with an empty list of states. Only one listItem may have an empty list of states (i.e. "All (Other) States"
function displayStatesError(listItemStates) {
    return editableList.value.filter((li) => li.states?.length == 0).length > 1 && listItemStates.length == 0
}

// Keep all states that are NOT selected OR are selected in the listItemStates array. If it is selected and not in the listItemStates array, it has already been selected by another listItem.
function applicableStates(listItemStates: string[]) {
    return masterStateList.value.filter((s) => (s.selected && listItemStates.includes(s.value)) || !s.selected)
}

function stateFormatter(states: SelectableItem[]) {
    const allState = editableList.value.length > 1 ? "All Other States" : "All States"
    return states && states.length > 0 ? states.join(", ") : allState
}
</script>