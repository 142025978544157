import qs from 'qs'
import { axiosLoanService } from './index'
import { 
    LoanSummary, 
    LoanRecord, 
    Cd, 
    XmlParseResult, 
    UpdateLenderProfileRequest, 
    UpdateInvestorRequest, 
    Loan, 
    Program, 
    ComplianceApiResult,
    PagedCollection,
    ContactDefault, 
    ContactDefaultType,
    RegCheckSuiteOptions
} from '@/common/models'

class LoanService {
    async getAll(params?: URLSearchParams): Promise<PagedCollection<LoanSummary>> {
        const response = await axiosLoanService.get('/', { params })
        return response.data
    }

    async get(id: string, includeCd = true, includeHoepaCheck = false, includeCompliance = false): Promise<LoanRecord | null> {
        const config = {
            params: {
                'includeCd': `${includeCd}`,
                'includeHoepaCheck': `${includeHoepaCheck}`,
                'includeCompliance': `${includeCompliance}`
            }
        }

        const response = await axiosLoanService.get(`/${id}`, config)
        
        return response.data
    }

    async update(loan: LoanRecord): Promise<LoanRecord> {
        const response = await axiosLoanService.post(`/${loan.id}`, loan.data)
        return response.data
    }

    async delete(id: string) {
        const response = await axiosLoanService.delete(`/${id}`)
        return response.data
    }

    async createNewLoan(clientCode: string, loanNumber: string, documentMode: string, loanData: Loan, overwrite = false, applyDefaults = true, originatingSystem: string | null = null, sanitize = false): Promise<LoanRecord> {
        const config = {
            headers: {'Content-Type': 'application/json'},
            params: {
                'mode': `${documentMode}`,
                'loanNumber': `${loanNumber}`,
                'clientCode': `${clientCode}`,
                'overwrite': `${overwrite}`,
                'applyDefaults': `${applyDefaults}`,
                'originatingSystem': originatingSystem,
                'sanitize': `${sanitize}`,
            }
        }

        const response = await axiosLoanService.post('/', loanData, config)
        return response.data
    }

    async getCdData(id: string): Promise<Cd> {
        const response = await axiosLoanService.get(`/${id}/cd`);
        return response.data
    }
    
    async getFilteredPrograms(loan: Loan, partnerCode: string, clientCode: string): Promise<Program[]> {
        const response = await axiosLoanService.post(`/filtered-programs?partnerCode=${partnerCode}&clientCode=${clientCode}`, loan)
        return response.data
    }
    
    async getCompliance(loanId: string, suiteOptions: RegCheckSuiteOptions = {enabledTests: [], enableOfacExtendedSearch: false}): Promise<ComplianceApiResult> {
        const url = `${loanId}/compliance-report?bindData=true`
        const response = await axiosLoanService.get(url, {
            params: {
                suiteOptions
            },
            //TODO update to maybe handle this in the base axios instance
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: "repeat",  allowDots: true })
            }
        }) 
        return response.data
    }

    async getCompliancePdfUri(loanId: string, suiteOptions: RegCheckSuiteOptions = {enabledTests: [], enableOfacExtendedSearch: false}): Promise<string> {
        const response = await this.getCompliancePdfResponse(loanId, suiteOptions, true)
        return response.data
    }

    async getCompliancePdf(loanId: string, suiteOptions: RegCheckSuiteOptions = {enabledTests: [], enableOfacExtendedSearch: false}): Promise<Blob> {
        const response = await this.getCompliancePdfResponse(loanId, suiteOptions, false)  
        return new Blob([response.data], { type: 'application/pdf'})
    }

    private async getCompliancePdfResponse(loanId: string, suiteOptions: RegCheckSuiteOptions, requestPdfUri: boolean) {
        let url = `${loanId}/compliance-report/pdf`
        url = requestPdfUri ? url + '/uri' : url
        return await axiosLoanService.get(url, {
            responseType: requestPdfUri ? undefined : 'arraybuffer',
            params: { 
                suiteOptions
            },
            //TODO update to maybe handle this in the base axios instance
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: "repeat",  allowDots: true})
            }
        })      
    }

    async parseLoanXml(xml: string, schema: string, sanitize = false, clientCode: string|null = null): Promise<XmlParseResult> {
        let url = '/import/parse'
        const urlParams = new URLSearchParams()
        if (schema)
            urlParams.append('schema', schema)
        if (sanitize)
            urlParams.append('sanitize', 'true')
        if (clientCode)
            urlParams.append('clientCode', clientCode)

        url += `?${urlParams.toString()}`

        const config = {
            headers: {'Content-Type': 'application/xml'}
        }
        const response = await axiosLoanService.post(url, xml, config)
        return response.data
    }

    async updateLenderProfile(loanId: string, request: UpdateLenderProfileRequest): Promise<LoanRecord> {
        const response = await axiosLoanService.post(`/${loanId}/lender-profile`, request)
        return response.data
    }

    async updateInvestor(loanId: string, request: UpdateInvestorRequest): Promise<LoanRecord> {
        const response = await axiosLoanService.post(`/${loanId}/investor`, request)
        return response.data
    }

    async getDefaultContactsPreview(loanId: string, defaultType: ContactDefaultType, investorCode: string|null, lenderProfileId: string | null): Promise<ContactDefault[]> {
        const response = await axiosLoanService.get(`/${loanId}/default-contacts-preview/?defaultType=${defaultType}` 
            + (lenderProfileId ? `&lenderProfileId=${lenderProfileId}` : '')
            + (investorCode ? `&investorCode=${investorCode}` : ''))
        return response.data
    }
}

export const loanService = new LoanService()
