import { Condition, TestDefaultValueRequest, TestRuleRequest, RuleTestOptions, TestRuleResponse } from '@/common/models'
import { metadataService } from './index'

class TestService {
    async testRule(loanId: string, packageType: string, conditions: Condition | undefined, ruleTestOptions: RuleTestOptions) {
        const testRequest: TestRuleRequest = {
            loanId: loanId,
            packageType: packageType,
            conditions: conditions,
            options: ruleTestOptions
        }
 
        let testRuleResponse = {} as TestRuleResponse
        testRuleResponse = await metadataService.testRule(testRequest)
 
        if(testRuleResponse.passed){
            testRuleResponse.class =  'success-border'
            testRuleResponse.value = 'All conditions match'
        }
        else{
            testRuleResponse.class = 'error-border'
            testRuleResponse.value = testRuleResponse.failMessages.join('\n\n')
        }

        return testRuleResponse
    }

    async testDefaultValue(fieldId: string, loanId: string, request: TestDefaultValueRequest): Promise<TestRuleResponse>{
        const testRuleResponse = {} as TestRuleResponse;
        const response = await metadataService.testDefaultValue(fieldId, loanId, request);
        if(response.errorMessage) {
            testRuleResponse.class = 'error-border';
            testRuleResponse.value = response.errorMessage;
        }
        else{
            testRuleResponse.class = 'success-border';
            testRuleResponse.value = response.defaultValue?.toString();
        }
        return testRuleResponse;
    }
}

export const testService = new TestService()