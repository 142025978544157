<template>
    <div class="top-nav-bar">
        <router-link :to="{ name: 'loan-grid' }" class="logo">
            <svg class="icon" v-if="!regCheckOnly" viewBox="0 -1 220 59">
                <use xlink:href="../../../../public/img/collection.svg#logo" />
            </svg>
            <regcheck-logo v-else />
        </router-link>
        <div v-if="currentAppArea && appAreasForPopover.length > 1">
            <div id="nav-area-selection">
                <custom-icon :icon="currentAppArea.icon" />
                <span class="area-title">
                    {{ currentAppArea.name }}
                </span>
            </div>
            <b-popover target="nav-area-selection" placement="bottomright" custom-class="nav-popover" triggers="hover" :show.sync="showPopover">
                <div class="popover-area" v-for="(appArea, indexA) of appAreasForPopover" :key="`${appArea?.name}_${indexA}`">
                    <div
                        class="popover-item"
                        v-for="(page, indexP) of getVisiblePages(appArea)"
                        :key="`${appArea.name}_${page.name}_${indexP}`"
                        @click="closePopover"
                    >
                        <custom-icon :icon="appArea.icon" />
                        <router-link :to="{ path: page.routeName }">
                            {{ page.name }}
                        </router-link>
                    </div>
                </div>
            </b-popover>
        </div>
        <nav>
            <router-link v-for="(page, index) of topNavAreaPages" :key="`${page.name}_nav_${index}`" :to="{ path: page.routeName }">
                {{ page.name }}
            </router-link>
        </nav>

        <b-dropdown class="username">
            <template v-slot:button-content>{{ userName }}</template>
            <b-dropdown-item @click="$bvModal.show('user-profile-modal')"><custom-icon icon="UserProfile" /> My Profile</b-dropdown-item>
            <user-profile-modal />
            <b-dropdown-item @click="logout"><custom-icon icon="Logout" />Logout</b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import { authService, userPermissionValidatorService } from '@/common/services'
import UserProfileModal from '@/common/components/user-profile-modal.vue'
import { AppArea, AreaPage, AppAreaName, SingleEntityDisplayType } from '@/common/components/navigation/app-area'
import { MAIN_APP_AREAS } from '@/common/components/navigation/propel-area-navigation-options'
import { cloneDeep } from 'lodash'
import { useCurrentUserStore  } from '@/common/store'
import { config } from '@/config'

//#region DEFINE VARIABLES
const route = useRoute()
const regCheckOnly = config.app.regCheckOnly
const appAreas = ref<AppArea[]>([])
const store = useCurrentUserStore()

//syncs with the trigger when pop over is displayed becoming true
const showPopover = ref(false)
//#endregion

//#region COMPUTED
const userName = computed<string>(() => store.currentUser?.name ?? '')

const appAreasForPopover = computed<AppArea[]>(() => {
    const appAreaClone = cloneDeep(appAreas.value)
    appAreaClone.forEach((area) => {
        if (area.name === AppAreaName.LoanData && area.areaPages[0].name === 'Loans') {
            area.areaPages[0].name = 'Loan Data'
        }
    })

    return appAreaClone.filter((area) => getVisiblePages(area).length > 0)
})
const currentAppArea = computed<AppArea | undefined>(() => appAreas.value.find((area) => area.areaPages.find((page) => route.matched.some((routeRecord) => page.routeName?.includes(routeRecord.path)))))
//used for nav parts to the left of the button
const topNavAreaPages = computed<AreaPage[] | undefined>(() => currentAppArea.value?.areaPages.filter((p) => p.visible === true)) 
//#endregion

//#region INITIALIZE
initialize()
function initialize() {
    appAreas.value = getAppAreas()
}
//#endregion

//Applies checks to app area pages based on user authorization
//this is used for the popover and nav menu
//changes to data should not be done here
function getAppAreas(): AppArea[] {
    return MAIN_APP_AREAS.map((area) => {
        //check the page permissions for the area
        const pagePermissionResult = checkPagePermissions(area.areaPages)
        area.areaPages = [...pagePermissionResult['pages']]
        return area
    })
}

function getVisiblePages(appArea: AppArea): AreaPage[] {
    return appArea.areaPages.filter((x) => x.visible ?? false)
}

//when clicking a popover navigation link set showPopover to false to close the popover window
function closePopover() {
    showPopover.value = false
}

//note: logic taken from pages that had tab menus
function checkPagePermissions(pages: AreaPage[]) {
    //go through each of the main app area's pages and:
    //set page options visible property to true if a user has permission
    let client: string
    let partner: string
    let hasVisiblePages = false

    pages.forEach((page) => {
        if (page.readPermission) {
            const permission = Array.isArray(page.readPermission) ? (page.readPermission as string[]) : [page.readPermission] //make them all arrays, eliminate this check

            page.visible =
                userPermissionValidatorService.hasAnyPermission(permission, client, partner) ||
                userPermissionValidatorService.hasPermission(permission, client, partner)

            //checks for pages that display only for one lender or only for one p
            if (page.visible && page.singleEntityDisplayType === SingleEntityDisplayType.Lender) {
                const lenders = userPermissionValidatorService.getLendersByPermissions(permission)
                setPageVisibilityBasedOnIds(page, lenders)
            }
            //abstract this logic
            if (page.visible && page.singleEntityDisplayType === SingleEntityDisplayType.Partner) {
                const partners = userPermissionValidatorService.getPartnersByPermissions(permission)
                setPageVisibilityBasedOnIds(page, partners)
            }
        }
        if (page.options) {
            page.options.forEach((o) => {
                if (o.readPermission) {
                    const permission = Array.isArray(o.readPermission) ? (o.readPermission as string[]) : [o.readPermission]
                    o.visible =
                        userPermissionValidatorService.hasAnyPermission(permission, client, partner) ||
                        userPermissionValidatorService.hasPermission(permission, client, partner)
                }
            })
        }
        //might not need has visible pages anymore
        if (page.visible === true) hasVisiblePages = true
    })

    return { pages: pages, hasVisiblePages: hasVisiblePages } //create a interface for this object
}

function setPageVisibilityBasedOnIds(page: AreaPage, ids: string[]) {
    //page is for a single entity and user has permission for one ID
    if (page.singleEntityDisplay && ids.length === 1) {
        page.routeName = page.routeName?.replace(':code', ids[0])
        page.visible = true
        //page is not for a single entity and user does not have permission for only one id (O can indicate all entities)
    } else if (!page.singleEntityDisplay && ids.length !== 1) {
        page.visible = true
    }
    //otherwise set the visibility to false
    else {
        page.visible = false
    }
}

function logout() {
    authService.logout()
}
</script>

<style scoped lang="scss">
.top-nav-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: $header-height;
    padding: 0 1rem;
    margin: 0 auto;
    border-bottom: 1px solid $layout-border-color;
    z-index: $layout-zindex + 1;

    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;

    &::after {
        content: '';
        background: linear-gradient(to right, transparent, #fff 85%), #fff url(../../../../public/img/header-bg.svg) no-repeat;
        // opacity: 1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
    }

    .logo {
        width: calc($sidebar-width - 1rem);

        @media only screen and (max-width: $breakpoint-m) {
            width: 12rem;
        }

        @media only screen and (max-width: $top-nav-shrink-point) {
            width: 0;
            display: none;
        }

        .icon {
            height: calc($header-height - 0.125rem);
            padding-top: 0.125rem;
        }
    }

    #nav-area-selection {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $very-dark-gray;
        font-weight: 500;
        margin-right: 1.25rem;
        border-right: 2px solid $medium-gray; // 1.25rem on either side of the border
        padding: 0 1.25rem 0 0.25rem;

        &:hover {
            cursor: pointer;
        }

        .custom-icon {
            color: $very-dark-gray;
            font-size: 1.25rem;
            margin: -0.125rem 0.75rem 0 0;
        }

        .area-title {
            font-size: 1rem;
        }
    }

    nav {
        flex-grow: 1;
        display: flex;
        gap: 1.5rem;

        @media only screen and (max-width: $top-nav-shrink-point) {
            gap: 1.25rem;
        }

        a {
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0.125rem 0.5rem;
            text-decoration: none;
            color: $very-dark-gray;
            font-weight: 500;
            font-size: 1rem;
            border-bottom: 2px solid transparent;

            @media only screen and (max-width: $breakpoint-m) {
                padding: 0.3rem 0.15rem;
            }

            @media only screen and (max-width: $top-nav-shrink-point) {
                font-size: 0.75rem;
                padding: 0.3rem 0;
            }

            &:hover {
                border-bottom: 2px solid $light-gray;
            }

            &.router-link-active {
                font-weight: bold;
                border-bottom: 2px solid $orange;

                @media only screen and (max-width: $top-nav-shrink-point) {
                    font-size: 0.75rem;
                }
            }
        }
    }

    :deep(.username) {
        &:hover .btn {
            color: $link-hover-color !important;
        }
        .btn {
            @media only screen and (max-width: $top-nav-shrink-point) {
                font-size: 0.75rem;
            }
        }
        .dropdown-menu {
            z-index: 11;
        }
    }

    :deep(.btn-secondary),
    :deep(.btn-secondary:active),
    :deep(.show > .btn-secondary.dropdown-toggle) {
        background: none !important;
        border: none !important;
        color: $body-color !important;
    }

    :deep(.btn-secondary.dropdown-toggle:focus) {
        box-shadow: none !important;
    }
}

.nav-popover {
    :deep(.popover-body) {
        display: flex;
        flex-direction: column;
        gap: 1rem; // gap between areas
        padding: 0.625rem 1.25rem;
    }

    .popover-area {
        display: flex;
        flex-direction: column;
        gap: 0.125rem;
        min-width: 11rem;
        font-family: $title-font;
        font-size: 1.125rem;

        .popover-item {
            display: flex;
            flex-direction: row;
            gap: 0.75rem; // space between icon and label
            align-items: center;
            line-height: 1.875rem;

            *:first-child {
                flex-basis: 1rem;
            }

            .custom-icon {
                color: $dark-gray;
                font-size: 1.25rem;
                margin-top: 0.125rem;
            }

            // show an icon only for the first item in each area
            &:not(:first-child) {
                .custom-icon {
                    visibility: hidden;
                }
            }
        }

        a {
            color: $dark-gray;
            font-weight: 500;
            &:hover {
                color: $link-hover-color;
                text-decoration: none;
            }
        }
    }
}
</style>