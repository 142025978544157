import { Loan, LoanRequestOptions } from "."
import { MultiSelectItem } from "../components/multi-select-item"
import { TridDisclosureFeeOrPrepaidDetails, TridDisclosureItemLinkSelectItem } from "../services/trid-disclosure-service"

export interface TridDisclosure {
    type: TridDisclosureType
    issuanceType: TridDisclosureIssuanceType
    issuedDate: Date
    closingDate: Date
    receivedDate: Date
    changeOfCircumstanceReason: ChangeOfCircumstanceReasonType
    changeOfCircumstanceReasonOtherDescription: string
    changeOfCircumstanceDate: Date
    isProjected: boolean
    loan: Loan
}

export enum TridDisclosureType {
    LoanEstimate = "LoanEstimate",
    ClosingDisclosure = "ClosingDisclosure"
}

export enum TridDisclosureIssuanceType {
    Initial = "Initial",
    Revised = "Revised"
}

export enum ChangeOfCircumstanceReasonType {
    AdditionOfPrepaymentPenalty = "AdditionOfPrepaymentPenalty",
    APR = "APR",
    ClericalErrorCorrection = "ClericalErrorCorrection",
    DelayedSettlementDate = "DelayedSettlementDate",
    Eligibility = "Eligibility",
    Expiration = "Expiration",
    InterestRateDependentCharges = "InterestRateDependentCharges",
    Item24HourAdvancedPreview = "Item24HourAdvancedPreview",
    LoanProduct = "LoanProduct",
    Other = "Other",
    RevisionsRequestedByBorrower = "RevisionsRequestedByBorrower",
    SettlementCharges = "SettlementCharges",
    ToleranceCure = "ToleranceCure"
}

// request types unrelated to the model
export enum TridDisclosureCopyType {
    Current = "Current",
    Index = "Index"
}

export interface NewTridDisclosureRequest {
    type: TridDisclosureType
    issuedDate: null | Date
    copyType: TridDisclosureCopyType
    indexToCopyFrom: null | number
    loanRequestOptions: LoanRequestOptions
    isProjected: boolean
}

export interface TridDisclosurePatchRequest {
    index: number
    updatedTridDisclosure: TridDisclosure
}

export class TridDisclosureProps {
    disclosureIndex?: number
    isMultiDisclosure = false
    // if true we want to show cocs and pull data from relevant disclosure, 
    //otherwise if props.isMultiDisclosure is true and no index is passed we want to hide le amounts/labels in main regcheck fees page
    get isMultiDisclosureDisplay(): boolean {
        return this.isMultiDisclosure && this.disclosureIndex !== undefined
    }
    isInitialLoanEstimate = false
    cocTypes: MultiSelectItem[] = []
    feeAndPrepaidIdsToSelectFrom: TridDisclosureItemLinkSelectItem[] = []
    everyDisclosureFeeAndPrepaidId: string [] = []
    feeAndPrepaidDetailsForDisclosure: TridDisclosureFeeOrPrepaidDetails[] = []
    get showCocsAtFeeLevel(): boolean {
        return this.isMultiDisclosureDisplay && !this.isInitialLoanEstimate
    }
}