import { numericFilter } from './numeric'

export function currencyFilter(value: number | undefined | null, decimalPlaces = 2): string {
    if(value === undefined || value === null) return ''

    let format = '$0,0.'
    
    for (let i = 0; i < decimalPlaces; i++)
        format += '0'

    return numericFilter(value, format)
}