import { defineStore } from 'pinia'
import { ref } from 'vue'
import { LoanAuditChecks } from '@/common/models'
import { JsonPathTestResult } from '@/propel/models/metadata/json-path-test'
import { userPreferenceService } from '@/common/services'
import { metadataService } from '@/propel/services'
import { LoanAuditCheckResult } from '@/propel/models'
import { TreeMenuDataItem } from '../components/navigation/tree-menu/tree-menu-data-item'

export const useAuditCheckStore = defineStore('auditCheck', () => {

    const debugModeEnabled = ref(false)
    const displayAuditCheckErrors = ref(false)
    const loanAuditChecks = ref({} as LoanAuditChecks)
    const loanAuditCheckKeys = ref({} as Record<string, string[]>)
    const loanJsonPathTests = ref({} as Record<string, JsonPathTestResult[]>)
    const loanPackageType = ref<string | null>(null)

    async function checkDisplayAndUpdateAuditChecks(loanId: string | undefined) {
        if (loanId) {
            const displayAuditChecks = userPreferenceService.getDisplayAuditCheckErrors()
            setDisplayAuditCheckErrors(displayAuditChecks)
            if (displayAuditChecks) {
                const loanAuditChecks = await metadataService.getAuditCheck(loanId, loanPackageType.value)
                updateLoanAuditChecks(loanAuditChecks)
            }
        }
    }

    async function getAuditChecks(loanId: string | undefined) {
        if (loanId) {
            const loanAuditChecks = await metadataService.getAuditCheck(loanId, loanPackageType.value)
            updateLoanAuditChecks(loanAuditChecks)
        }
    }

    function setDisplayAuditCheckErrors(displayErrors: boolean) {
        userPreferenceService.setDisplayAuditCheckErrors(displayErrors)
        displayAuditCheckErrors.value = displayErrors
    }

    function setLoanAuditKeysFromMenuItems(menuItems: TreeMenuDataItem[]) {
        loanAuditCheckKeys.value = {}
        for (let i = 0; i < menuItems.length; i++){
            setLoanAuditCheckKeysFromMenuItem(menuItems[i])
        }
    }

    function setLoanAuditCheckKeysFromMenuItem(menuItem: TreeMenuDataItem){
        if (menuItem.options) {
            for(let i = 0; i < menuItem.options.length; i++){
                setLoanAuditCheckKeysFromMenuItem(menuItem.options[i])
            }
        }

        if (menuItem.routeName && menuItem.auditCheckKeys)
            loanAuditCheckKeys.value[menuItem.routeName] = menuItem.auditCheckKeys
    }

    function updateLoanAuditChecks(result: LoanAuditCheckResult) {
        const auditChecks: LoanAuditChecks = {}

        for (const check of result.results.filter(x => x.jsonPath)) {
            const key = check.jsonPath.replace('data.', '').trim()

            if (!auditChecks[key]) {
                auditChecks[key] = []
            }

            auditChecks[key].push(check)
        }

        loanAuditChecks.value = auditChecks
    }

    function updateLoanJsonPathTests(results: JsonPathTestResult[]) {
        const jsonPathTests: Record<string, JsonPathTestResult[]> = {}

        for (const check of results.filter(x => x.jsonPath)) {
            const key = check.jsonPath.replace('data.', '').trim()

            if (!jsonPathTests[key]) {
                jsonPathTests[key] = []
            }

            jsonPathTests[key].push(check)
        }

        loanJsonPathTests.value = jsonPathTests
    }

    return { 
        debugModeEnabled,
        displayAuditCheckErrors, 
        loanAuditChecks,
        loanAuditCheckKeys,
        loanJsonPathTests,
        loanPackageType,

        checkDisplayAndUpdateAuditChecks,
        getAuditChecks,
        setDisplayAuditCheckErrors,
        setLoanAuditKeysFromMenuItems,
        updateLoanAuditChecks,
        updateLoanJsonPathTests
    }
})