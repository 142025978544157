

export interface ReferenceCallback{
    type: string
    serviceCall: (type: string) => Promise<any>
    dataType: ReferenceDataType
}

export const enum ReferenceDataType{
    string,
    MultiSelectItem,
    Field,
    FieldFormatSummary
}