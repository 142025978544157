export interface AutomatedUnderwriting {
    caseNumber: string
    systemType: AutomatedSystemType
    description: string
}

export enum AutomatedSystemType {
    Assetwise = 'Assetwise',
    Capstone = 'Capstone',
    Clues = 'Clues',
    DecisionEngine = 'DecisionEngine',
    DesktopUnderwriter = 'DesktopUnderwriter',
    ECS = 'ECS',
    FHAScorecard = 'FHAScorecard',
    FirstMortgageCreditScore = 'FirstMortgageCreditScore',
    GuaranteedUnderwritingSystem = 'GuaranteedUnderwritingSystem',
    LoanProspector = 'LoanProspector',
    Other = 'Other',
    Strategyware = 'Strategyware',
    Zippy = 'Zippy'
}