import { axiosUserService } from './index'
import { UserPermissionSet, GridDefaults, GridPreferences, LoanPreferences, ResetPasswordResponse, RowSelectionType, User, PermissionGroup, UserPreferences, UserProfile, LastSelectedFilters, DefaultLandingPages } from '@/common/models'
import { PagedCollection } from '@/common/models/meta'
import { config } from '@/config'

class UserService {
    async getAll(params?: URLSearchParams): Promise<PagedCollection<User>> {
        const response = await axiosUserService.get('/', { params })
        return response.data
    }

    async get(id: string): Promise<User> {
        const response = await axiosUserService.get(`/${id}`)
        return response.data
    }

    async createNew(name: string, emailAddress: string): Promise<User> {
        const response = await axiosUserService.post(`/new`, { name, emailAddress, authCodeDetails: {ClientId : config.auth.clientId, RedirectUri : config.auth.redirectUri, Scope: config.auth.scope} })
        return response.data
    }

    async update(user: User): Promise<User> {
        const response = await axiosUserService.post(`/`, user)
        return response.data
    }

    async delete(id: string) {
        const response = await axiosUserService.delete(`/${id}`)
        return response.data
    }

    async resetPassword(id: string): Promise<ResetPasswordResponse> {
        const response = await axiosUserService.post(`/${id}/reset`, {ClientId : config.auth.clientId, RedirectUri : config.auth.redirectUri, Scope: config.auth.scope})
        return response.data
    }

    async getUserProfile(): Promise<User> {
        const response = await axiosUserService.get(`/profile`)
        return response.data
    }

    async setUserProfile(user: User): Promise<User> {
        const response = await axiosUserService.post(`/profile`, { name: user.name, profile: user.profile })
        return response.data
    }

    async getCurrentUserProfile(): Promise<User> {
        const userProfile = await this.getUserProfile()
        userProfile.profile = userProfile.profile || {} as UserProfile
        userProfile.profile.preferences = userProfile.profile.preferences || {} as UserPreferences
        userProfile.profile.preferences.loan = userProfile.profile.preferences.loan || {} as LoanPreferences
        userProfile.profile.preferences.grids = userProfile.profile.preferences.grids || {} as GridPreferences
        userProfile.profile.preferences.grids.defaults = userProfile.profile.preferences.grids.defaults || {} as Map<string, GridDefaults>
        userProfile.profile.preferences.grids.rowSelectionType = userProfile.profile.preferences.grids.rowSelectionType || RowSelectionType.Anywhere
        userProfile.profile.lastSelectedFilters = userProfile.profile.lastSelectedFilters || {} as LastSelectedFilters
        userProfile.profile.preferences.defaultLandingPage = userProfile.profile.preferences.defaultLandingPage || DefaultLandingPages.Loans
        return userProfile
    }

    async getAllAvailablePermissions(): Promise<PermissionGroup[]> {
        const permissions = await axiosUserService.get(`/Permissions`)
        return permissions.data
    }
    
    async getFlattenedUserPermissions(id: string): Promise<UserPermissionSet[]> {
        const permissions = await axiosUserService.get(`/${id}/Permissions/flattened`)
        return permissions.data
    }

    async getUserPermissions(id: string): Promise<UserPermissionSet[]> {
        const permissions = await axiosUserService.get(`/${id}/Permissions`)
        return permissions.data
    }

    async postUserPermissions(id: string, permission: UserPermissionSet[]) {
        const permissions = await axiosUserService.post(`/${id}/Permissions`, permission)
        return permissions.data
    }
   
}

export const userService = new UserService()
