<template>
    <div :id="`${id}-group`" :class="`field ${size} form-group`">
        <validation-provider :name="label" :rules="validationRules" :immediate="immediateValidation" v-slot="{ errors }">
            <div class="label-container" v-if="label">
                <label :for="id" v-if="label">{{ label }}</label>
                <span v-if="jsonPathLabel" class="json-path-label">{{jsonPathLabel}}</span>
            </div>
            <div class="base-input-container">
                <input-pattern-mask
                    :jsonPath="jsonPathValue"
                    :pattern="pattern"
                    :id="`${id}`"
                    :readonly="isDisabled"
                    :value="vm"
                    :class="getErrorMessage(errors[0]) ? 'error' : ''"
                    @input="$emit('input', $event)"
                    @change="$emit('change', $event)"
                />
                <span class="base-input-icons">
                    <protected-edit-icon :icon="protectedEditIconString" :showProtectedEdit="showProtectedEdit" :allowProtectedEdit="allowProtectedEdit" @click="turnOffProtectedDisabled" />
                    <fg-defaultable
                        :currentValue="vm"
                        :defaultValue="defaultValue"
                        :isDefaulted="isDefaulted"
                        @change="onDefaultValueChange"
                        @defaultChange="onDefaultChange"
                        :disabled="!showDefaultFieldEdit"
                    />
                    <custom-icon icon="Error" :id="`${id}-error`" v-if="getErrorMessage(errors[0])" :title="getErrorMessage(errors[0])" />
                    <audit-check-icon :id="`${id}-audit-check`" :auditChecks="auditChecks" />
                </span>
            </div>
        </validation-provider>
    </div>
</template>

<!-- TODO: This component should be able to be combined with our form-group-text-input by checking if the "pattern" prop is populated. Investigate further and implement if possible -->
<script setup lang="ts">
import { defineProps, defineEmits, PropType, toRef } from 'vue'
import { useFormGroupBase, baseProps } from './form-group-base-composable'

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'input', value: boolean): void 
    (e: 'change', event: any): void
}>()

const props = defineProps({
    id: { type: String, required: true },
    pattern: { type: String, required: true },
    value: { type: String, default: '' },
    size: { type: String as PropType<string|null> },
    label: { type: String },
    ...baseProps
})

const vm = toRef(props, 'value')
//#endregion

//#region INITIALIZE
const { jsonPathLabel, jsonPathValue, protectedEditIconString, showProtectedEdit, allowProtectedEdit, showDefaultFieldEdit, defaultValue, isDefaulted, auditChecks, isDisabled,
    turnOffProtectedDisabled, getErrorMessage, onDefaultValueChange, onDefaultChange } = useFormGroupBase(props, emit);
//#endregion

</script>