import { VaStateFeeDeviation } from '@/regcheck/models'
import { axiosAdminService, cacheService } from './index'
import {
    SystemConfig,
    FeeSections,
    FeeReference,
    AprFeeReference,
    SystemConfigSecurity,
    PreparedBy,
    CreditAgency,
    AdminTrustees,
    DocPrepAttorney,
    SystemCalculationsConfiguration,
    GeneralSystemConfig,
    RuleBasedClosingCondition,
    FeatureFlag,
    MersAcknowledgment,
} from '@/common/models'

class SystemService {
    async getSystemConfig(): Promise<SystemConfig> {
        const response = await axiosAdminService.get('/system-config')
        return response.data
    }

    async getFeeSections(): Promise<FeeSections> {
        const response = await axiosAdminService.get('/system-config/fee-defaults/fee-sections')
        return response.data
    }

    async postFeeSections(feeSections: FeeSections): Promise<FeeSections> {
        const response = await axiosAdminService.post('/system-config/fee-defaults/fee-sections', feeSections)
        return response.data
    }

    async getEscrowsConfig(): Promise<FeeReference[]> {
        const response = await axiosAdminService.get('/system-config/fee-defaults/escrows')
        return response.data
    }

    async postEscrowsConfig(escrowsConfig: FeeReference[]): Promise<FeeReference[]> {
        const response = await axiosAdminService.post('/system-config/fee-defaults/escrows', escrowsConfig)
        return response.data
    }

    async getFeesConfig(): Promise<AprFeeReference[]> {
        const response = await axiosAdminService.get('/system-config/fee-defaults/fees')
        return response.data
    }

    async postFeesConfig(fees: AprFeeReference[]) {
        const response = await axiosAdminService.post('/system-config/fee-defaults/fees', fees)
        return response.data
    }

    async getVaStateDeviationsConfig(): Promise<VaStateFeeDeviation[]> {
        const response = await axiosAdminService.get('/system-config/va-state-fee-deviations')
        return response.data
    }

    async postVaStateDeviationsConfig(vaStateDeviations: VaStateFeeDeviation[]) {
        const response = await axiosAdminService.post('/system-config/va-state-fee-deviations', vaStateDeviations)
        return response.data
    }

    async getSystemClosingConditions(){
        const response = await axiosAdminService.get('/system-config/closing-conditions')
        return response.data
    }

    async postClosingConditionsConfig(closingConditions: RuleBasedClosingCondition[]){
        await axiosAdminService.post('/system-config/closing-conditions', closingConditions);
    }

    async getPrepaidsConfig(): Promise<AprFeeReference[]> {
        const response = await axiosAdminService.get('/system-config/fee-defaults/prepaids')
        return response.data
    }

    async postPrepaidsConfig(prepaids: AprFeeReference[]) {
        const response = await axiosAdminService.post('/system-config/fee-defaults/prepaids', prepaids)
        return response.data
    }

    async getHousingExpensesConfig(): Promise<FeeReference[]> {
        const response = await axiosAdminService.get('/system-config/fee-defaults/housing-expenses')
        return response.data
    }

    async postHousingExpensesConfig(housingExpenses: FeeReference[]): Promise<FeeReference[]> {
        const response = await axiosAdminService.post('/system-config/fee-defaults/housing-expenses', housingExpenses)
        return response.data
    }

    async getSecurityConfig(): Promise<SystemConfigSecurity> {
        const response = await axiosAdminService.get('/system-config/security')
        return response.data
    }

    async postSecurityConfig(securityConfig: SystemConfigSecurity): Promise<SystemConfigSecurity> {
        const response = await axiosAdminService.post('/system-config/security', securityConfig)
        return response.data
    }

    async getMersAcknowledgment(): Promise<MersAcknowledgment> {
        const response = await axiosAdminService.get('/system-config/mers-acknowledgment')
        return response.data
    }

    async postMersAcknowledgment(mersAcknowledgment: MersAcknowledgment): Promise<MersAcknowledgment> {
        const response = await axiosAdminService.post('/system-config/mers-acknowledgment', mersAcknowledgment)
        return response.data
    }

    async getFeatureFlags(): Promise<FeatureFlag[]> {
        const response = await axiosAdminService.get('/system-config/feature-flags')
        return response.data.items
    }

    async postFeatureFlags(featureFlags: FeatureFlag[]): Promise<FeatureFlag[]> {
        this.resetFlagCache() // Reset cache in case a flag was updated not to apply to a client, partner, or lender anymore
        const response = await axiosAdminService.post('/system-config/feature-flags', featureFlags.map(f =>  ({key: f.key, displayName: f.displayName, availableLevels: f.availableLevels, isEnabledAtSystem: f.enabledAt.system})))
        return response.data
    }

    async getPreparedByConfig(): Promise<PreparedBy[]> {
        const response = await axiosAdminService.get('/system-config/prepared-by')
        return response.data
    }

    async postPreparedByConfig(preparedBy: PreparedBy[]): Promise<PreparedBy[]> {
        const response = await axiosAdminService.post('/system-config/prepared-by', preparedBy)
        return response.data
    }

    async getCreditAgenciesConfig(): Promise<CreditAgency[]> {
        const response = await axiosAdminService.get('/system-config/credit-agencies')
        return response.data
    }

    async postCreditAgenciesConfig(creditAgencies: CreditAgency[]): Promise<CreditAgency[]> {
        const response = await axiosAdminService.post('/system-config/credit-agencies', creditAgencies)
        return response.data
    }

    async getTrusteesConfig(): Promise<AdminTrustees[]> {
        const response = await axiosAdminService.get('/system-config/trustees')
        return response.data
    }

    async postTrusteesConfig(trustees: AdminTrustees[]): Promise<AdminTrustees[]> {
        const response = await axiosAdminService.post('/system-config/trustees', trustees)
        return response.data
    }

    async getDocPrepAttorneysConfig(): Promise<DocPrepAttorney[]> {
        const response = await axiosAdminService.get('/system-config/doc-prep-attorneys')
        return response.data
    }

    async postDocPrepAttorneysConfig(docPrepAttorneys: DocPrepAttorney[]): Promise<DocPrepAttorney[]> {
        const response = await axiosAdminService.post('/system-config/doc-prep-attorneys', docPrepAttorneys)
        return response.data
    }

    async getCalculationsConfig(): Promise<SystemCalculationsConfiguration> {
        const response = await axiosAdminService.get('/system-config/calculations')
        return response.data
    }

    async postCalculationsConfig(calculations: SystemCalculationsConfiguration): Promise<SystemCalculationsConfiguration> {
        const response = await axiosAdminService.post('/system-config/calculations', calculations)
        return response.data
    }

    async getGeneralSystemConfig(): Promise<GeneralSystemConfig> {
        const response = await axiosAdminService.get('/system-config/general')
        return response.data
    }

    async postGeneralSystemConfig(generalSystemConfig: GeneralSystemConfig): Promise<GeneralSystemConfig> {
        const response = await axiosAdminService.post('/system-config/general', generalSystemConfig)
        return response.data
    }

    resetFlagCache() {
        cacheService.removeList("feature_flag_")
    }
}

export const systemService = new SystemService()
