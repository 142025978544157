<template>
    <div :id="`${id}-group`" :class="`field ${sizeValue} form-group`">
        <validation-provider :name="label" :rules="validationRules" :immediate="immediateValidation" v-slot="{ errors }">
            <div class="label-container" v-if="label || helpText || $slots['misc-slot']">
                <label :for="id" v-if="label">{{ label }}</label>
                <span v-if="jsonPathLabel" class="json-path-label">{{ jsonPathLabel }}</span>
                <custom-icon icon="Help" :id="`${id}-help`" v-if="helpText" :title="helpText" />
                <span class="misc-slot" :class="{ 'no-label': !label }" v-if="$slots['misc-slot']">
                    <slot name="misc-slot" :protectedDisabled="protectedDisabled"></slot>
                </span>
            </div>
            <div class="base-input-container">
                <input-text
                    :id="`${id}`"
                    :disabled="isDisabled"
                    :value="value"
                    :inputType="inputType"
                    :append="append"
                    :prepend="prepend"
                    :min="min"
                    :max="max"
                    :scale="scale"
                    :jsonPath="jsonPathValue"
                    :placeholder="placeholder"
                    :maxLength="maxLength"
                    :class="getErrorMessage(errors[0]) ? 'error' : ''"
                    @input="emit('input', $event)"
                    @change="emit('change', $event)"
                />
                <slot name="overlap-icon" :protectedDisabled="protectedDisabled"></slot>
                <span class="base-input-icons">
                    <slot name="icon" :disabled="isDisabled"></slot>
                    <protected-edit-icon
                        :icon="protectedEditIconString"
                        :showProtectedEdit="showProtectedEdit"
                        :allowProtectedEdit="allowProtectedEdit"
                        @click.stop="onProtectedClicked"
                    />
                    <fg-defaultable
                        :currentValue="value"
                        :defaultValue="defaultValue"
                        :isDefaulted="isDefaulted"
                        @change="onDefaultValueChange"
                        @defaultChange="onDefaultChange"
                        :disabled="!showDefaultFieldEdit"
                    />
                    <custom-icon icon="Error" :id="`${id}-error`" v-if="getErrorMessage(errors[0])" :title="getErrorMessage(errors[0])" />
                    <audit-check-icon :id="`${id}-audit-check`" :auditChecks="auditChecks" />
                </span>
            </div>
        </validation-provider>
    </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, computed } from 'vue'
import { useFormGroupBase, baseProps } from './form-group-base-composable'
import { InputType } from '@/common/models'
import { PropType } from 'vue/types/v3-component-props'

// TODO: Our form-group-input-pattern-mask should be able to be combined here by checking if the "pattern" prop is populated. Investigate further and implement if possible
//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'input', value: any): void
    (e: 'change', value: any): void 
    (e: 'unlocked'): void
    }>()

const props = defineProps({
    id: { type: String, required: true },
    value: { required: true },
    label: { type: String }, 
    size: { type: String, default: null },
    inputType: { type: String as PropType<InputType>, default: InputType.Text },
    append: { type: String, default: '' },
    prepend: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    min: { type: Number, default: null },
    max: { type: Number, default: null },
    maxLength: { type: Number, default: null },
    scale: { type: Number, default: null },
    helpText: { type: String, default: null },
    protectedFunctionOverride: { type: Function },
    ...baseProps
})
//#endregion

//#region COMPUTED
const sizeValue = computed(() => {
    if (
        props.inputType === InputType.Days ||
        props.inputType === InputType.Months ||
        props.inputType === InputType.Years ||
        props.inputType === InputType.Percentage ||
        props.inputType === InputType.Number
    ) {
        return props.size ?? 's'
    } else if (props.inputType === InputType.Currency) {
        return props.size ?? 's'
    } else {
        return props.size ?? 'm'
    }
})
//#endregion

const { jsonPathLabel, jsonPathValue, protectedEditIconString, showProtectedEdit, allowProtectedEdit, turnOffProtectedDisabled, showDefaultFieldEdit, onDefaultValueChange,
     onDefaultChange, defaultValue, isDefaulted, auditChecks, isDisabled, getErrorMessage, protectedDisabled } = useFormGroupBase(props, emit)

function onProtectedClicked() {
    props.protectedFunctionOverride ? props.protectedFunctionOverride() : turnOffProtectedDisabled()
}
</script>

<style lang="scss">
.label-container .misc-slot {
    display: flex; // We need flex for place where we have two elements, like label+dropdown in fee edit slots
    align-items: center;
    column-gap: 0.25rem;

    flex-grow: 1;
    padding-right: 0.25rem; // accounts for gap on the .base-input-container that sits below it
    text-align: right;

    &:not(.no-label) {
        // "normal" misc-slots need to be right-aligned with the input below.  
        // This may not account for having two elements in the misc-slot plus a standard input label.
        justify-content: right; 
    }

    &.no-label {
        text-align: left;
    }
    font-size: 0.7rem;

    .custom-control-label {
        font-size: 0.7rem;
        padding-top: 2px;
    }
}
.base-input-container {
    position: relative;
}
</style>
