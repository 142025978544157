<template>
    <div>
        <content-block-list
            id="defaultValueRules"
            ref="defaultValuesList"
            :listItems.sync="vm.rules"
            deletable
            copyable
            draggable
            addLabelText="Add Rule"
            :onAdd="addNewDefaultRule"
            @editListItem="showEditModal"
            @itemDeleted="deleteDefaultValueRule"
        >
            <template v-slot:view="slotParams">
                <div class="standard-columns">
                    <div class="l wrap">
                        <pre class="ellipsis6" :id="`ruleValue` + slotParams.index">{{ slotParams.item.value }}</pre>
                        <b-tooltip :target="`ruleValue` + slotParams.index" custom-class="tooltip-md" triggers="hover">
                            <slot name="tooltip"><pre>{{ slotParams.item.value }}</pre></slot>
                        </b-tooltip>
                        <div class="subtitle" id="ruleDescription">
                            {{ slotParams.item.description }}
                        </div>
                    </div>
                    <rule-condition-content-block-column :conditions="slotParams.item.conditions" />
                    <!-- <div class="s" v-if="false">
                        <div v-if="slotParams.item.preventChange">Users cannot change</div>
                        <div v-else>Users can change</div>

                        <div v-if="slotParams.item.replaceExistingData">Ignore imported data</div>
                        <div v-else>Use imported data if available</div>
                    </div> -->
                </div>
            </template>
            <template v-slot:deleteModal="slotParams"> Are you sure you want to delete {{ slotParams.item && slotParams.item.description }}? </template>
        </content-block-list>
        <!-- <div v-if="showReadonlyPermissionLabel" class="readonlyPermissionContainer">
            <fg-text 
                id="readonlyPermission"
                validationRules="required"
                v-model="vm.readonlyPermissionLabel"
                label="Permission Label"
                helpText="One or more rules on this page are set to prevent most users from changing the default value. Users with the permission specified here will be able to make loan-level changes."
            />
        </div> -->
        <b-modal
            id="edit-default-modal"
            title="Edit Field Value"
            size="xl"
            v-model="showEditDefaultModal"
            @ok="saveDefaultValueRule(selectedRule)"
            @cancel="defaultValuesList.cancelEditMode(selectedRule)"
            @close="defaultValuesList.cancelEditMode(selectedRule)"
            ok-title="Save"
            cancel-title="Cancel"
            :ok-disabled="rulesAreOpen"
            modal-class="testable"
        >
            <div class="testable-area-wrap">
                <b-tabs class="tabs-fixed">
                    <b-tab :active="editingExistingItem">
                        <template v-slot:title>Configuration</template>
                        <div class="field-section">
                            <field-value
                                id="defaultFieldValue"
                                label="Set this field's value to:"
                                :fieldName="vm.name"
                                :compareExpression="selectedRule.value"
                                :enableFieldExpression="true"
                                :expressionOnlyDates="true"
                                class="field-value"
                                @update:compareExpression="(val) => updateDefaultValue(val, undefined)"
                                @update:compareValues="(val) => updateDefaultValue(undefined, val)"
                            />
                            
                            <!-- <fg-checkbox 
                                id="lockValue" 
                                v-if="false"
                                label="Prevent this value from being changed" 
                                v-model="selectedRule.preventChange"
                            />
                            <fg-radio-button
                                :options="replaceExistingOptions"
                                v-if="false"
                                v-model="selectedRule.replaceExistingData"
                                class="replace-existing-radio"
                                id="replace-existing-buttons"
                                name="replace-existing-radio-buttons"
                                label="When importing, use this value:"
                            /> -->
                        </div>
                        <rule-criteria-editor
                            :conditions.sync="selectedRule.conditions"
                            :field="vm"
                            hide-package-types
                            :defaultRule="selectedRule"
                            :saveNewRules="true"
                            @openItemsChanged="openItemsChanged"
                        />
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>Details</template>
                        <div class="field-section">
                            <fg-textarea id="description" :rows="3" label="Description" v-model="selectedRule.description" />
                            <fg-textarea id="notes" :rows="6" label="Notes" v-model="selectedRule.notes" />
                        </div>
                    </b-tab>
                </b-tabs>
                <aside v-if="false">
                    <summary-sidebar>
                        <h2>Default Value Testing</h2>
                        <test-container :conditions="selectedRule.conditions" conditionsType="DefaultValue" :field="field" :defaultValue="selectedRule" hide-package-types />
                        <!-- <template v-slot:footer v-if="selectedRule && selectedRule.audit && selectedRule.audit.created">
                            <summary-sidebar-audit-info :audit="selectedRule.audit" />
                        </template> -->
                    </summary-sidebar>
                </aside>
            </div>
        </b-modal>
    </div>
</template>
<script setup lang="ts">
import { ref, defineProps, PropType, computed, toRef } from 'vue'
import { DefaultRule, Condition, Criteria, FieldDetail, AuditInfo, ItemsChangedEventArgs, FieldValueRule } from '@/common/models'
import RuleConditionContentBlockColumn from "@/propel/components/rule-condition-content-block-column.vue"

/* //TODO:
 * This component has a LOT in common with default-value-manager.
 * The two components should be merged (or inherit a common base) and just use properties to adjust the things that are different.
 * The key difference is that this runs off FieldValueRule and default-value-manager runs off DefaultRule.
 * On the back-end those two classes share multiple interfaces and other common properties.
 */

//#region DEFINE VARIABLES
const props = defineProps({
    field: {type: Object as PropType<FieldDetail>, required: true}
})

const selectedRule = ref<FieldValueRule>({} as FieldValueRule)
const rulesAreOpen = ref(false)
const showEditDefaultModal = ref(false)
const defaultValuesList = ref()
const vm = toRef(props, 'field')
//#endregion

//#region WATCH
// watch(() => showReadonlyPermissionLabel.value, () => {
//     if(showReadonlyPermissionLabel.value && !vm.value.readonlyPermissionLabel)
//         vm.value.readonlyPermissionLabel = vm.value.displayName //Default to display name
//     else if(!showReadonlyPermissionLabel.value && vm.value.readonlyPermissionLabel)
//         vm.value.readonlyPermissionLabel = '' //Clear if not applicable
// })
//#endregion

//#region COMPUTED
// vm.value.rules don't have preventChange properties, so we can't utilize the original computed prop
// This will get resolved when the component is merged with default-value-manager as stated in the above TODO
const editingExistingItem = computed(() => false) 

// const showReadonlyPermissionLabel= computed(() => vm.value.rules.some(r => !!r.preventChange))
// const editingExistingItem = computed(() => (selectedRule.value?.id ? selectedRule.value.id : 0) !== 0)
// const replaceExistingOptions = computed(() => {
//     return [
//         { value: false, text: OverwriteImportDataEnum.DoNotOverwrite },
//         { value: true, text: OverwriteImportDataEnum.OverwriteData }
//     ]
// })
//#endregion

//#region INITIALIZE
initialize()

function initialize() {    
    vm.value.rules ??= [] as FieldValueRule[]
}
//#endregion

async function showEditModal(item: FieldValueRule) {
    selectedRule.value = item
    showEditDefaultModal.value = true
}

function updateDefaultValue(compareExpression: string | undefined, compareValues: string[] | undefined) {
    if (!selectedRule) return        
    if (compareValues && compareValues.length > 0)
        selectedRule.value.value = compareValues[0].toString()
    else
        selectedRule.value.value = compareExpression ?? ''
}

function addNewDefaultRule(): FieldValueRule {    
    return {
        id: null,
        fieldId: vm.value.id,
        fieldName: vm.value.name,
        description: '',
        notes: '',
        value: '',
        preventChange: false,
        replaceExistingData: false, //can we rename this to indicate imported data
        conditions: {
            activationDate: null,
            expirationDate: null,
            packageTypes: [] as string[],
            ruleIds: [] as string[],
            criteriaItems: [] as Criteria[]
        } as Condition,
        audit: {} as AuditInfo
    } as FieldValueRule
}

function openItemsChanged(e: ItemsChangedEventArgs) {
    rulesAreOpen.value = e.openItemsCount > 0
}

async function saveDefaultValueRule(rule: FieldValueRule) {
    try {
        //const saved = await metadataService.postFieldDefaultValueRule(this.field.id, rule)
        //rule.id = rule.id ?? saved.id
        //messageService.showSaveSuccess(rule.description)
    } finally {
        selectedRule.value = { conditions: {criteriaItems: [] as Criteria[]} as Condition } as FieldValueRule
    }
}

async function deleteDefaultValueRule(itemToRemove: DefaultRule) {
    //await metadataService.deleteFieldDefaultValueRule(this.field.id, itemToRemove.id)
}
</script>

<style lang="scss" scoped>
h1 {
    margin-top: 0;
    margin-bottom: 0.9rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

p {
    margin-bottom: 0;
}

pre {
  margin-bottom: 0; // remove margin from all pre tags
  &+ .subtitle {
    margin-top: .5rem; // put a margin only above subtitles that come after pre tags ("+" is the adjacent sibling selector)
  }
}

.default-item-edit {
    min-width: 30%;
    max-width: 100%;

    .edit-title {
        border-bottom: none;
        color: $dark-blue;
        font-weight: bold;
        padding: 0.75rem 1rem 0.5rem 1rem;
        letter-spacing: 0.05rem;
        align-items: baseline;
        margin-bottom: 1rem;
        .title {
            padding-left: 0;
        }
    }
}

.action-items {
    text-align: right;
    margin: 0;
    padding: 0.5rem 0 0.5rem 1rem;
    button {
        margin-left: 0.5rem;
    }
}

.form-group.custom-checkbox {
    margin-left: 0;
}

.readonlyPermissionContainer{
    margin-top: 15px;

    .form-group {
        padding-left: 0;
    }
}
.field-value {
    width: 100%;
    padding: 0.25rem 0.125rem !important;
    min-height: 2.25rem !important;
}
</style>