import { StoredDocumentSelectionState } from '@/propel/models'

//Wrapper to read the user preferences from storage per Loan(ToDo: move to API)
class SaveLoanSelectionsService {
    
    private docSelectionKey = 'new-package-selections'
    getDocSelection(loanId: string): StoredDocumentSelectionState | null {
        const docSelectionString = localStorage.getItem(this.docSelectionKey)
        let docSelection: StoredDocumentSelectionState | null = null

        if (docSelectionString) {
            const storedDocSelection = JSON.parse(docSelectionString) as StoredDocumentSelectionState
            if (loanId === storedDocSelection?.loanId) {
                docSelection = storedDocSelection
            } else {
                this.setDocSelection(null)
            }
        }
        return docSelection
    }

    setDocSelection(docSelection: StoredDocumentSelectionState | null): void {
        localStorage.setItem(this.docSelectionKey, JSON.stringify(docSelection))
    }
}

export const saveLoanSelectionsService = new SaveLoanSelectionsService()