import { Overridable } from './meta/overridable'

export interface Trust {
    name: string
    date: Date
    state: string
    restatedDates: Date[]
    trustees: Trustee[]
    settlors: Settlor[]
}

export interface Trustee {
    id: number
    borrowerId?: number
    name: Overridable<string>
    signingType: SigningType
}

export interface Settlor {
    borrowerId?: number
    name: Overridable<string>
}

export enum SigningType {
    IndividuallyAndAsTrustee = 'IndividuallyAndAsTrustee',
    Trustee = 'Trustee'
}