import { render, staticRenderFns } from "./system-types-values-list.vue?vue&type=template&id=7227c81b&scoped=true"
import script from "./system-types-values-list.vue?vue&type=script&setup=true&lang=ts"
export * from "./system-types-values-list.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./system-types-values-list.vue?vue&type=style&index=0&id=7227c81b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7227c81b",
  null
  
)

export default component.exports