<template>
    <base-page :pageIsBusy="pageIsBusy" small-header>
        <template v-slot:pageActions>
            <slot name="new" v-if="onNew">
                <custom-button icon="Add" label="New" @click="onNew" :disabled="isCreateDisabledValue" />
            </slot>
        </template>
        <slot />
    </base-page>
</template>

<script lang="ts" setup>
import { computed, ref, watch, defineProps, PropType } from 'vue'
import { useRoute } from 'vue-router/composables'
import { BreadcrumbItem } from '@/common/components/navigation/breadcrumb-item'
import { userPermissionValidatorService } from '@/common/services'
import { usePageActionsStore } from '@/common/store'
//#region DEFINE VARIABLES
const props = defineProps({
    isCreateDisabled: { type: Boolean },
    onNew: { type: Function as PropType<() => void>},
    onGetBreadcrumbs: { type: Function as PropType<() => BreadcrumbItem[]>}
})

const route = useRoute()
const store = usePageActionsStore()
const pageIsReadOnly = ref(false)
//#endregion

//#region COMPUTED
const isCreateDisabledValue = computed<boolean>(() => pageIsReadOnly.value || props.isCreateDisabled)
const pageIsBusy = computed<boolean>(() => store.pageLoading.isLoading)
//#endregion


//#region WATCH
watch(() => route, () => routeUpdated(), { immediate: true })

function routeUpdated() {
    const writePermission: string = route?.meta?.writePermission
    if (writePermission)
        pageIsReadOnly.value = !userPermissionValidatorService.hasPermission([writePermission])
    else
        pageIsReadOnly.value = false
}
//#endregion
</script>
