import { metadataService } from './index'
import { JsonPathErrorType, JsonPathTestResult } from "@/propel/models/metadata/json-path-test"
import { cloneDeep } from 'lodash'
import { AuditCheckResult } from '@/propel/models'
import { useAuditCheckStore } from '@/common/store'

class JsonPathTestingService {   

    //Get the active metadata json paths to use in validation and toggle on the json path display
    async initializeJsonPathsTest() {
        const store = useAuditCheckStore()
        store.setDisplayAuditCheckErrors(false)

        const jsonPathFields = await metadataService.getFieldJsonPaths()
        const testJsonPaths = jsonPathFields.map(f => {
            return {
                fieldId: f.id,
                fieldName: f.name,
                fieldDisplayName: f.displayName,
                jsonPath: f.jsonPath,
                passed: false,
                message: 'Testing JSON paths'
            } as JsonPathTestResult
        })

        store.updateLoanJsonPathTests(testJsonPaths)
    }

    //Gets the json path results for the given json path
    getIndividualJsonPathTestResults(jsonPaths: Record<string, JsonPathTestResult[]>, key: string, append: string): JsonPathTestResult[] {
        if (key) {
            const fullKey = `${key}${append}`
            return jsonPaths[fullKey]
        }
        return []
    }

    //Gets the class corresponding to the json path level
    getJsonPathClass(displayErrors: boolean, jsonPaths: JsonPathTestResult[] | AuditCheckResult[]) {
        if (displayErrors && jsonPaths?.length) {
            return jsonPaths.some(x => x.level === 'Error') 
            ? 'error' 
            : jsonPaths.some(x => x.level === 'Warning') 
                ? 'warning'
                : 'success';
        }
        return ''
    }

    buildJsonPathsFromElement(element: any, jsonPaths: string[] = []) {
        if (typeof element?.getAttribute === 'function' ) {
            const jsonPath = element.getAttribute('jsonpath')
            if (jsonPath) {
                jsonPaths.push(jsonPath)
            }
        }
        
        if (element?.childNodes.length > 0) {
            element.childNodes.forEach(i => this.buildJsonPathsFromElement(i, jsonPaths))
        }

        return jsonPaths
    }

    //Get the json path results for the current loan page
    getLoanPageResults(routeName: string): JsonPathTestResult[] {
        const store = useAuditCheckStore()
        const loanJsonPathTests = store.loanJsonPathTests

        let loanPageResults: JsonPathTestResult[] = []
        for(const path in loanJsonPathTests){
            const pageResults = loanJsonPathTests[path].filter(t => t.routeLocation == routeName)
            loanPageResults = loanPageResults.concat(pageResults)
        }

        return loanPageResults.sort((a, b) => a.jsonPath > b.jsonPath ? 1 : (a.jsonPath < b.jsonPath ? -1 : 0))
    }

    //Given an initial element, validate against the metadata json paths and audit check keys to find successes & warnings
    checkJsonPaths(mainContent: any, routeName: string) {
        const store = useAuditCheckStore()
        const auditCheckKeySections = store.loanAuditCheckKeys

        let auditCheckKeys: string[] = []
        const loanJsonPathTests = store.loanJsonPathTests
        const loanPageJsonPaths = this.buildJsonPathsFromElement(mainContent) //get all json paths on main content
        if(auditCheckKeySections && auditCheckKeySections[routeName]) {            
            auditCheckKeys = auditCheckKeySections[routeName] as string[] //get the audit check keys for the current loan page
            //For each metadata json path that the audit check keys tell us should be on this page, verify if they actually are found on the page or not
            for(const path in loanJsonPathTests) {
                for(let i = 0; i < auditCheckKeys.length; i++) {
                    if(path.startsWith(auditCheckKeys[i])) {
                        if(!loanPageJsonPaths.some(jp => jp === path) && !path.match("\\[.*\\]")) {
                            loanJsonPathTests[path] = this.setJsonPathValue(loanJsonPathTests[path], routeName, 'Warning', JsonPathErrorType.FormInputMissing)
                        } else if (loanPageJsonPaths.some(jp => jp === path)) {
                            loanJsonPathTests[path] = this.setJsonPathValue(loanJsonPathTests[path], routeName, 'Success', JsonPathErrorType.FormInputMissing)
                        }
                    } 
                }
            }    
        }

        //For each json path specified in the UI, make sure it is matched under an audit check key for the loan page
        for(let i = 0; i < loanPageJsonPaths.length; i++){
            let auditKeyFound = false
            for(let j = 0; j < auditCheckKeys.length; j++){
                if(loanPageJsonPaths[i].startsWith(auditCheckKeys[j])){
                    auditKeyFound = true
                    break
                }
            }

            const loanAuditCheck = loanJsonPathTests[loanPageJsonPaths[i]]
            if(loanAuditCheck) {                    
                const level = auditKeyFound ? 'Success' : 'Warning'
                loanJsonPathTests[loanPageJsonPaths[i]] = this.setJsonPathValue(loanAuditCheck, routeName, level, JsonPathErrorType.AuditCheckKeyMissing)
            }
            else if(loanPageJsonPaths[i] && !loanPageJsonPaths[i].match("\\[.*\\]")){
                const defaultValue = {
                    jsonPath: loanPageJsonPaths[i]
                } as JsonPathTestResult
                const level = auditKeyFound ? 'Success' : 'Warning'
                loanJsonPathTests[loanPageJsonPaths[i]] = this.setJsonPathValue([defaultValue], routeName, level, JsonPathErrorType.AuditCheckKeyMissing)
            }
        }

        store.loanJsonPathTests = cloneDeep(loanJsonPathTests)
    }

    //Apply test results to the results array, replacing the current page's json path with the updated value
    private setJsonPathValue(results: JsonPathTestResult[], routeName: string, level: 'Warning' | 'Error' | 'Success', errorType: JsonPathErrorType){
        //Start with all results not being changed by this function call to retain items not changed by this instance
        const newResults: JsonPathTestResult[] = results.filter(r =>!!r.level && (r.routeLocation !== routeName || r.errorType !== errorType))

        //If successful and no other warnings, add a success result
        if(level == 'Success' && newResults.filter(r => r.routeLocation == routeName).length === 0) {
            const newResult = {
                ...results[0],
                level: level,
                errorType: null,
                routeLocation: routeName,
                message: 'This JsonPath is successful'
            } as JsonPathTestResult
            newResults.push(newResult)
        } else if (level !== 'Success') {
            const newResult = {
                ...results[0],
                level: level,
                errorType: errorType,
                routeLocation: routeName,
                message: this.getWarningMessage(results[0].jsonPath, errorType)
            } as JsonPathTestResult
            newResults.push(newResult)
        }

        return newResults
    }

    private getWarningMessage(jsonPath: string, errorType: JsonPathErrorType): string{
        switch(errorType) {
            case JsonPathErrorType.FormInputMissing: return `Field ${jsonPath} should be on this page, but could not be found.`
            case JsonPathErrorType.AuditCheckKeyMissing: return `Input ${jsonPath} missing from Audit Check Keys.`
        }
    }
}

export const jsonPathTestingService = new JsonPathTestingService()