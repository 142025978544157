import { render, staticRenderFns } from "./admin-page.vue?vue&type=template&id=909e97b2&scoped=true"
import script from "./admin-page.vue?vue&type=script&lang=ts&setup=true"
export * from "./admin-page.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./admin-page.vue?vue&type=style&index=0&id=909e97b2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "909e97b2",
  null
  
)

export default component.exports