import { axiosLoanService, axiosAdminService, axiosMetadataService, cacheService, axiosBackendService } from './index'
import {
    Reference,
    ReferenceType,
    ReferenceSource,
    StateSummary,
    MortgageType,
    LoanPurpose,
    TransactionDetailType,
    Terms,
    TransactionDetailFilter,
    FieldFormatSummary
} from '@/common/models'
import { MultiSelectItem } from '@/common/components/multi-select-item'
import { flatten } from 'lodash'
import { ComplianceTestType } from '@/regcheck/models'

class ReferenceService {
    private fieldFormatsCache: FieldFormatSummary[] = []
    private dynamicTransactionDetailTypes: string[] = [
        TransactionDetailType.TX50,
        TransactionDetailType.TXHomeEquity,
        TransactionDetailType.ConventionalStreamline,
        TransactionDetailType.HomeImprovement,
        TransactionDetailType.Homestyle,
        TransactionDetailType.FHAStreamline,
        TransactionDetailType.FHA203k,
        TransactionDetailType.USDARehabilitation,
        TransactionDetailType.VAIRRRL,
        TransactionDetailType.VAAlteration,
        TransactionDetailType.Section184,
        TransactionDetailType.BridgeLoan
    ]

    async get(type: ReferenceType, source: ReferenceSource): Promise<Reference[]> {
        const cacheKey = `ref_${source}_${type}`
        let func: () => Promise<any>
        const url = `/references/${type}`
        switch (source) {
            case ReferenceSource.admin:
                func = () => axiosAdminService.get(url)
                break;
            case ReferenceSource.loan:
                func = () => axiosLoanService.get(url)
                break;
            case ReferenceSource.metadata:
                func = () => axiosMetadataService.get(url)
                break;
        }
        const response = await cacheService.getItem(cacheKey, func)
        return response.data as Reference[]
    }

    async getMultiSelectItems(type: ReferenceType, source: ReferenceSource): Promise<MultiSelectItem[]> {
        const references = await this.get(type, source)
        return references.map(x => ({ value: x.key, text: x.displayValue } as MultiSelectItem))
    }

    async getFullNameStates(): Promise<StateSummary[]> {
        const cacheKey = 'states_standard_full'
        const response = await cacheService.getItem(cacheKey, () => axiosAdminService.get(`/states/standard/summaries`))
        return response.data as StateSummary[]
    }

    async getStates(): Promise<string[]> {
        const cacheKey = 'states_standard'
        const response = await cacheService.getItem(cacheKey, () => axiosAdminService.get(`/states/standard/abbreviations`))
        return response.data as string[]
    }

    async getStatusesAsMultiSelect():Promise<MultiSelectItem[]> {
        const statusValues = referenceService.getMultiSelectItems(ReferenceType.statuses, ReferenceSource.metadata)
        statusValues.then((result) => result.push( { text: "All Items", value: null } as MultiSelectItem ))
        return statusValues
    }

    async getStatesAsMultiSelect(): Promise<MultiSelectItem[]> {
        const states = await this.getStates()
        return states.map(s => ({ value: s, text: s } as MultiSelectItem))
    }

    async getIncorporationStates(): Promise<string[]> {
        const cacheKey = 'states-of-incorporation'
        const response = await cacheService.getItem(cacheKey, () => axiosAdminService.get(`/states/incorporation/summaries`))
        const statesOfIncorporation = response.data.map((state: StateSummary) => state.name) as string[]

        return statesOfIncorporation
    }

    async getDeedOfTrustStates(): Promise<StateSummary[]> {
        const cacheKey = 'states_deed-of-trust'
        const response = await cacheService.getItem(cacheKey, () => axiosAdminService.get(`/states/deedoftrust/summaries`))
        return response.data as StateSummary[]
    }

    async getFieldFormats(): Promise<MultiSelectItem[]> {
        if (this.fieldFormatsCache.length === 0) {
            const response = await axiosMetadataService.get(`/field-formats?pageSize=1000`)
            this.fieldFormatsCache = response.data.items as FieldFormatSummary[]
        }
        return this.fieldFormatsCache.map((x: FieldFormatSummary) => ({ value: x.id, text: x.name } as MultiSelectItem))
    }

    async getValueForDisplay(value: string, type: ReferenceType, source: ReferenceSource): Promise<string> {
        const references = await this.get(type, source)
        const displayValue = references.find((r: Reference) => r.key === value)?.displayValue
        return displayValue ? displayValue : "Not the right value"
    }

    async getReferenceValues(referenceList: ReferenceType[], referenceSource: ReferenceSource = ReferenceSource.loan) {
        const promises = referenceList.map(type => referenceService.get(type, referenceSource))

        const values = await Promise.all(promises)

        const flattenedValues = flatten(values)

        return flattenedValues
    }

    async getTransactionDetailReference(terms: Terms, state: string | undefined, isRegCheckLoan = false): Promise<MultiSelectItem[]> {
        const allReferenceTypes = await this.get(ReferenceType.transactionDetailTypes, ReferenceSource.loan)
        const nonDynamicTranDetails = allReferenceTypes.filter(i => !this.dynamicTransactionDetailTypes.includes(i.key))
        const dynamicTypeDetails = this.getTransactionDetailFilter(isRegCheckLoan).filter(x =>
            (x.mortageType === undefined || x.mortageType === terms?.mortgageType) &&
            ((x.loanPurpose === undefined || x.loanPurpose === terms?.loanPurpose)
                && (x.lienPosition === undefined || x.lienPosition === terms?.lienPosition)
                && (x.state === undefined || x.state === state)
                && (!x.matchesTransactionDetail || x.transactionDetail.indexOf(terms?.transactionDetail || '') > -1)))
            .flatMap(x => x.transactionDetail)
        return ([...nonDynamicTranDetails].concat(allReferenceTypes.filter(i => dynamicTypeDetails.includes(i.key)))).map(x => ({ text: x.displayValue, value: x.key }))
            .sort((a, b) => a.text.localeCompare(b.text))
    }

    getTransactionDetailFilter(isRegCheckLoan: boolean): TransactionDetailFilter[] {
        const baseFilterItems = this.getBaseTransactionDetailFilter()
        
        baseFilterItems.push({ mortageType: MortgageType.Conventional, loanPurpose: LoanPurpose.Refinance, state: "TX", matchesTransactionDetail: !isRegCheckLoan, transactionDetail: [TransactionDetailType.TXHomeEquity] })

        return baseFilterItems
    }

    // this filter is applicable for both RC and Propel, if making changes here please clear them with both teams
    getBaseTransactionDetailFilter(): TransactionDetailFilter[] {
        return [
            { mortageType: MortgageType.Conventional, transactionDetail: [TransactionDetailType.Homestyle] },
            { mortageType: MortgageType.Conventional, loanPurpose: LoanPurpose.Refinance, transactionDetail: [TransactionDetailType.ConventionalStreamline] },
            { mortageType: MortgageType.Conventional, loanPurpose: LoanPurpose.Refinance, state: "TX", transactionDetail: [TransactionDetailType.TX50] },
            { mortageType: MortgageType.Conventional, transactionDetail: [TransactionDetailType.HomeImprovement] },
            { mortageType: MortgageType.FHA, transactionDetail: [TransactionDetailType.FHA203k, TransactionDetailType.Section184] },
            { mortageType: MortgageType.FHA, loanPurpose: LoanPurpose.Refinance, transactionDetail: [TransactionDetailType.FHAStreamline] },
            { mortageType: MortgageType.USDA, transactionDetail: [TransactionDetailType.USDARehabilitation] },
            { mortageType: MortgageType.VA, transactionDetail: [TransactionDetailType.VAAlteration] },
            { mortageType: MortgageType.VA, loanPurpose: LoanPurpose.Refinance, transactionDetail: [TransactionDetailType.VAIRRRL] },
            { loanPurpose: LoanPurpose.Refinance, transactionDetail: [TransactionDetailType.BridgeLoan] },
            { loanPurpose: LoanPurpose.Modification, transactionDetail: [TransactionDetailType.TXHomeEquity] }
        ]
    }

    async getComplianceTestTypes(): Promise<ComplianceTestType[]> {
        const cacheKey = ReferenceType.complianceTestTypes
        const response = await cacheService.getItem(cacheKey, () => axiosBackendService.get("compliance/test-types"))
        return response.data as ComplianceTestType[]
    }

    async getPrepaymentPenaltyCalculationTypes(state: string, propertyUsageType: string): Promise<MultiSelectItem[]> {
        const response = await axiosLoanService.get(`/references/prepayment-penalty-calculation-types`, {
            params: {
                'state': `${state}`,
                'usageType': `${propertyUsageType}`
            }
        })

        return response.data?.map((x: any) => ({ value: x.key, text: x.displayValue } as MultiSelectItem))
    }
}

export const referenceService = new ReferenceService()