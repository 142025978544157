import { 
    TaxInfo, 
    Address,
    TaxFormRequesterContact,
    TaxFormRequesterContactAddress,
    IncomeTranscript,
    Overridable
} from '@/common/models'
import { cloneDeep } from "lodash"

export enum TranscriptTypes {
    ReturnTranscript = "ReturnTranscript",
    AccountTranscript = "AccountTranscript",
    RecordOfAccount = "RecordOfAccount",
    TaxFormSeriesTranscript = "TaxFormSeriesTranscript"
}

export interface TaxInfoViewModel extends TaxInfo {
    returnTranscriptCheckbox: boolean
    accountTranscriptCheckbox: boolean
    recordOfAccountCheckbox: boolean
    taxFormSeriesCheckbox: boolean
}

class TaxInfoViewModelConverter {
    toViewModel(model: TaxInfo): TaxInfoViewModel {
        const taxInfo = cloneDeep(model) as TaxInfoViewModel
        taxInfo.currentAddress = taxInfo.currentAddress || ({} as Address)
        taxInfo.filerPreviousAddress = taxInfo.filerPreviousAddress || ({} as Address)
        taxInfo.taxFormRequester = taxInfo.taxFormRequester || ({} as TaxFormRequesterContact)
        taxInfo.taxFormRequester.address = taxInfo.taxFormRequester.address || ({ street: '', unit: '', city: '', state: '', zip: '' } as TaxFormRequesterContactAddress)
        
        taxInfo.primaryFilerName = taxInfo.primaryFilerName || {isOverridden:false} as Overridable<string>
        taxInfo.primaryFilerFirstName = taxInfo.primaryFilerFirstName || {isOverridden:false} as Overridable<string>
        taxInfo.primaryFilerMiddleName = taxInfo.primaryFilerMiddleName || {isOverridden:false} as Overridable<string>
        taxInfo.primaryFilerLastName = taxInfo.primaryFilerLastName || {isOverridden:false} as Overridable<string>

        taxInfo.jointFilerName = taxInfo.jointFilerName || {isOverridden:false} as Overridable<string>
        taxInfo.jointFilerFirstName = taxInfo.jointFilerFirstName || {isOverridden:false} as Overridable<string>
        taxInfo.jointFilerMiddleName = taxInfo.jointFilerMiddleName || {isOverridden:false} as Overridable<string>
        taxInfo.jointFilerLastName = taxInfo.jointFilerLastName || {isOverridden:false} as Overridable<string>

        taxInfo.recordTypes = taxInfo.recordTypes || []
        taxInfo.returnTranscriptCheckbox = taxInfo.recordTypes.includes(TranscriptTypes.ReturnTranscript)
        taxInfo.accountTranscriptCheckbox = taxInfo.recordTypes.includes(TranscriptTypes.AccountTranscript)
        taxInfo.recordOfAccountCheckbox = taxInfo.recordTypes.includes(TranscriptTypes.RecordOfAccount)
        taxInfo.taxFormSeriesCheckbox = taxInfo.recordTypes.includes(TranscriptTypes.TaxFormSeriesTranscript)

        taxInfo.incomeTranscript = taxInfo.incomeTranscript || ({} as IncomeTranscript)
        taxInfo.incomeTranscript.formNumbers = taxInfo.incomeTranscript.formNumbers || []

        return taxInfo
    }

    fromViewModel(viewModel: TaxInfoViewModel): TaxInfo {
        viewModel.recordTypes.splice(0)

        if (viewModel.returnTranscriptCheckbox)
            viewModel.recordTypes.push(TranscriptTypes.ReturnTranscript)
        if (viewModel.accountTranscriptCheckbox)
            viewModel.recordTypes.push(TranscriptTypes.AccountTranscript)
        if (viewModel.recordOfAccountCheckbox)
            viewModel.recordTypes.push(TranscriptTypes.RecordOfAccount)
        if (viewModel.taxFormSeriesCheckbox)
            viewModel.recordTypes.push(TranscriptTypes.TaxFormSeriesTranscript)

        return Object({
            ...viewModel            
        })
    }
}

export const convert = new TaxInfoViewModelConverter()