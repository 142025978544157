import { defineStore } from 'pinia'
import { ref } from 'vue'
import { FormRule } from '@/common/models'

export const useDocumentStore = defineStore('documents', () => {
    const copyDocumentId = ref<string | null>(null)
    const copyFieldFormatID = ref<string | null>('')
    const copyFieldID = ref<string | null>('')
    const copyRuleID = ref<string | null>('')
    const copyFormRule = ref<FormRule | null>(null)
    const modalToRoute = ref<any>(null)
    const isDirty = ref(false)

    return { copyDocumentId, copyFieldFormatID, copyFieldID, copyRuleID, copyFormRule, modalToRoute, isDirty }
})