import { LegalDescriptionPrintOption } from '@/common/models';
import { Address } from './address';

export interface Property {
    builder: Builder
    propertyType: PropertyType[]
    estateType: EstateType
    address: PropertyAddress
    legalDescription: LegalDescription
    unitCount: number
    lotSize: number
    propertyValue: number
    propertyValueIsEstimated: boolean
    propertyUsageType: string
    isMixedUse: boolean
    manufacturedHomeSerialNumber: string
    manufacturedHomeDescription: string
    isFloodArea: boolean
    parcelId: string
    titleDate: null
    originalAcquisitionDate: Date
    titleReportItems: string
    titleEndorsements: null
    salesPrice: number
    rehabilitationCost: number
    salesPriceOfAnyPersonalProperty: number
    relatedLoans: RelatedLoan[]
    rentalIncome: RentalIncome
    cooperative: Cooperative
    isFHASecondaryResidence: boolean
    condoName: string
    secondCondoName: string
    pudName: string
    secondPudName: string
    pudDeclaration: string
    secondPudDeclaration: string
    manufacturedHomeDetails: ManufacturedHomeDetails | null
    holdHarmlessConditions: string
    surveyorName: string
    surveyDate: string
    floodZone: string
    newConstruction: boolean
    isFloodParticipatingCommunity: boolean
    floodParticipatingCommunityName: string | null
    appraisal: Appraisal
    reconsiderationOfValue: PropertyReconsiderationOfValue
    estimatedSalesPrice: number
    propertyQualificationType: string
    annualHoaDues: number
    specialAssessmentUnpaidAmount: number
    vaNonrealtyDescription: string
    vaAdditionalSecurityOthers: string
    township: string
    leaseholdExpiration: Date
    leaseholdDate: Date
    leaseholderLessorName: string
    lesseeName: string
    leaseholdRecordingInfo: string
    vaNotInspectorApprovedIndicator: boolean
    land: Land
    floodCertNumber: string
    projectDesignType: string
    attachmentType: string
}

export enum PropertyType {
    SingleFamily = 'SingleFamily',
    Condo = 'Condo',
    MultiFamily = 'MultiFamily',
    PUD = 'PUD',
    ManufacturedHome = 'ManufacturedHome',
    LotLoan = 'LotLoan',
    CoopLoan = 'CoopLoan'
}

export enum EstateType {
    FeeSimple = 'FeeSimple',
    Leasehold = 'Leasehold'
}

export interface PropertyAddress {
    street: string
    unit: string
    unitDescription: string
    city: string
    state: string
    zip: string
    county: string
    countyCode: string
    countySecond: string
    cityStateZip: string
    full: string
    country: string
    alternateAddress: Address
}

export interface RelatedLoan {
    creditor: string
    lienPosition: string
    monthlyPayment: number
    loanAmount: number
    creditLimit: number
}

export interface RentalIncome {
    gross: number | null
    net: number | null
}

export interface Cooperative {
    lot: string
    section: string
    block: string
    apartmentUnit: string
    shareValue: number
    coopLeaseDate: Date
    leaseDate: Date
    district: string
    subdivision: string
    square: string
    quadrant: string
    suffix: string
}

export interface ManufacturedHomeDetails {
    make: string
    model: string
    year: string
    serialNumber: string
    widthAndLength: string
    hudLabel: string
    condition: string
    manufacturer: string 
    widthType: string
}

export interface LegalDescription {
    description: string
    shortDescription: string
    printOption: LegalDescriptionPrintOption
    attachedDocumentText: string
}

export interface Appraisal {
    identifier: string
    valuationMethodType: string
    description: string
}

export interface PropertyReconsiderationOfValue {
    submissionInstructions: string
    submissionDeadline: Date
    requestProcessingDaysCount: number
}

export interface Land {
    acquiredDate: Date
    purchasePrice: number
}

export interface Builder {
    name: string
    phone: string
    address: Address
}