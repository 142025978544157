<template>
    <div class="test-area">
        <div class="test-section">
            <div class="field-section">
                <fg-select
                    id="loans"
                    label="Loan"
                    size=""
                    :source="getAllLoans"
                    v-model="selectedLoan"
                    searchable
                    :searchMinimumCharacters="2"
                    :showEmptyOption="false"
                    @change="loanChanged"
                    optionDisplayType="SplitWithSecondaryText"
                    :searchMessages="searchMessages"
                />
                <fg-select
                    label="Package"
                    id="packageTypes"
                    size=""
                    v-model="packageType"
                    :source="getPackageTypes"
                    :multiple="false"
                    optionDisplayType="SplitWithValue"
                />
            </div>
            <custom-button
                :icon="userTestPreference === 'test' ? 'Preview' : 'Download'"
                label="Test Form"
                @click="getDocument"
                split
                :class="{ disabled: isTestingDisabled }"
                :loading="loadingTestResult"
                :disabled="isTestingDisabled || loadingTestResult"
            >
                <template v-slot:menuItems>
                    <b-dropdown-item @click.prevent="setTestPreference('test')"><custom-icon icon="Preview" />Preview the PDF</b-dropdown-item>
                    <b-dropdown-item @click.prevent="setTestPreference('download')"><custom-icon icon="Download" />Download the PDF</b-dropdown-item>
                </template>
            </custom-button>
            <div v-if="isTestingDisabled" class="testing-disabled-error-message">*Testing is disabled when more than one new file is uploaded. Please save your document before testing.</div>
        </div>

        <div class="field-section" v-if="!loadingTestResult && testResponseText && selectedLoan">
            <fg-textarea id="testResponse" :class="testResultClass" :rows="6" label="" v-model="testResponseText" />
        </div>

        <pdf-preview-modal v-model="showTestModal" :src="testModalEmbedSrc" :title="document.title" />
    </div>
</template>

<script setup lang="ts">
import { loanService, adminDataService, userPreferenceService } from "@/common/services"
import { docGenService } from "@/propel/services"
import { DocumentDetails, DocumentTestDataParams, DocumentTestResult, FormDetail, LoanSummary, ResponseType } from "@/common/models"
import { PackageType } from "@/propel/models"
import { PropType, defineProps, defineEmits, ref } from "vue"
import { MultiSelectItem } from "@/common/components/multi-select-item"

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:"downloadBase64File", fileName: string, dataUrl: string)
}>()

const props = defineProps({
    document: { type: Object as PropType<DocumentDetails>, required: true },
    forms: { type: Array as PropType<Array<FormDetail>>, required: true },
    formsFromServer: { type: Array as PropType<Array<FormDetail>> },
    uploadedFileNames: { type: Array as PropType<Array<string>> },
    isTestingDisabled: { type: Boolean },
})

const loadingTestResult = ref(false)
const showTestModal = ref(false)

const selectedLoan = ref("")
const packageType = ref("")
const testResponseText = ref("")
const testModalEmbedSrc = ref("")
const testResultClass = ref("default-border")
const userTestPreference = ref("download")

const searchMessages = ref<string[]|null>(null)
//#endregion

//#region INITIALIZE
initialize()

function initialize() {
    selectedLoan.value = userPreferenceService.getTestingLoanId()
    userTestPreference.value = userPreferenceService.getDocumentTestOption()
}
//#endregion

async function getPackageTypes() {
    const packageTypes = await adminDataService.getPackageTypes()
    return packageTypes.map((pt: PackageType) => ({ value: pt.code, text: pt.displayName }))
}

async function getAllLoans(searchTerm?: string): Promise<MultiSelectItem[]> {
    const params = new URLSearchParams()
    params.append("searchTerm", searchTerm ? searchTerm : "")
    params.append("sort", "loanNumber")
    const pagedLoans = await loanService.getAll(params)
    searchMessages.value = pagedLoans.metadata.messages
    return pagedLoans.items.map((loan: LoanSummary) => ({ value: loan.id, text: loan.loanNumber, secondaryText: loan.clientCode }))
}

async function getDocument() {
    loadingTestResult.value = true
    testResponseText.value = ""
    const testDocumentParams = {} as DocumentTestDataParams
    testDocumentParams.loanId = selectedLoan.value
    testDocumentParams.packageType = packageType.value
    testDocumentParams.document = props.document
    if (props.forms.length > 0) {
        testDocumentParams.form = props.forms[0]
    }

    const docTestResult: DocumentTestResult = await docGenService.getDocumentTestResults(testDocumentParams)

    if (docTestResult.type == ResponseType.Success && docTestResult.fileUri) {
        testResultClass.value = "default-border"
        switch (userTestPreference.value) {
            case "test":
                testDocument(docTestResult.fileUri)
                break
            case "download":
                downloadDocument(docTestResult.fileUri)
                break
        }
    } else {
        testResultClass.value = "error-border"
        testResponseText.value = docTestResult.fileUri ?? "No File Returned"
    }
    loadingTestResult.value = false
}

function downloadDocument(fileUri: string) {
    emit("downloadBase64File", "TestDoc.pdf", fileUri)
}

function testDocument(fileUri: string) {
    showTestModal.value = true
    testModalEmbedSrc.value = fileUri
}

async function loanChanged() {
    await userPreferenceService.setTestingLoanId(selectedLoan.value)
}

function setTestPreference(preference: string) {
    userPreferenceService.setDocumentTestOption(preference)
    userTestPreference.value = userPreferenceService.getDocumentTestOption()
    getDocument()
}
</script>

<style lang="scss" scoped>
.document-test {
    // position: sticky;
    // top: $page-header-height;
    // min-width: 350px;
    // max-width: 350px;
    // height: calc(100vh - 9rem);
    // padding: 1rem;
    // margin: 0 1rem;
    // background: $light-gray;
    // color: $near-black;
    // line-height: 1.5rem;
    flex-grow: 1;

    // header {
    //     text-align: center;
    //     font-size: 130%;
    //     font-weight: 700;
    //     background: $dark-blue;
    //     color: $off-white;
    //     font-family: Arial;
    //     margin: -1rem;
    //     margin-bottom: 1rem;
    //     padding: 0.5rem 0;
    // }

    .b-dropdown.btn-group {
        margin-bottom: 1rem;
    }

    textarea {
        font-size: 110%;
        font-family: "Arial";
        padding: 1rem;
    }
}

:deep(.modal-xl .modal-body) {
    height: 70vh;
}

.testing-disabled-error-message {
    color: $red;
    font-size: 0.875rem;
}
</style>