export interface BasicContact {
    name: string
    email: string
    phone: string
    phoneExt: string
    fax: string
    attn: string
    hours: string
    insurableInterest: string
    address: CountyAddress
}

export interface CountyAddress extends BasicContactAddress {
    county: string
}

export interface BasicContactAddress {
    street: string
    unit: string
    unitDescription: UnitDescription
    city: string
    state: string
    zip: string
}

export enum UnitDescription {
    Apartment = "Apartment",
    Basement = "Basement",
    Building = "Building",
    Condo = "Condo",
    Department = "Department",
    Floor = "Floor",
    Front = "Front",
    Hangar = "Hangar",
    Key = "Key",
    Lobby = "Lobby",
    Lot = "Lot",
    Lower = "Lower",
    Office = "Office",
    Penthouse = "Penthouse",
    Pier = "Pier",
    Rear = "Rear",
    Room = "Room",
    Side = "Side",
    Space = "Space",
    Stop = "Stop",
    Suite = "Suite",
    Trailer = "Trailer",
    Unit = "Unit",
    Upper = "Upper"
}