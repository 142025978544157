<template>
    <div>
        <content-block-list
            id="formRules"
            :listItems.sync="vm"
            deletable
            addLabelText="Add Rule"
            :readOnly="readOnly"
            :onAdd="addFormRule"
            @editListItem="showEditModal"
            @itemDeleted="deleteFormRule"
            size="m"
        >
            <template v-slot:view="slotParams">
                <div class="standard-columns">
                    <div class="wrap m">
                        {{ slotParams.item.name }}
                        <div class="subtitle">
                            {{ slotParams.item.description }}
                        </div>
                    </div>
                    <rule-condition-content-block-column :conditions="slotParams.item.conditions" />
                </div>
            </template>
            <template v-slot:primaryActions="slotParams">
                <custom-icon icon="Copy" @click="copyFormRule(slotParams.item)" :disabled="readOnly" />
            </template>
            <template v-slot:deleteModal="slotParams"> Are you sure you want to delete {{ slotParams.item && slotParams.item.description }}? </template>
        </content-block-list>

        <b-modal
            id="edit-form-rule-modal"
            v-model="showEditFormRuleModal"
            title="Edit Selection Rule"
            size="xl"
            @ok="saveFormRule(selectedFormRule)"
            @cancel="cancel"
            @close="cancel"
            ok-title="Save"
            cancel-title="Cancel"
            :ok-disabled="rulesAreOpen"
            modal-class="testable"
        >
            <form-rule-editor
                @openItemsChanged="openItemsChanged"
                :formRule="selectedFormRule"
                is-form
                show-sidebar
                v-if="selectedFormRule.formId"
                :readOnly="readOnly"
            />
        </b-modal>
    </div>
</template>

<script setup lang="ts">
import { messageService } from "@/common/services"
import { metadataService } from "@/propel/services"
import { Criteria, DocumentDetails, FormRule, PlacementRule, ItemsChangedEventArgs } from "@/common/models"
import { cloneDeep } from "lodash"
import { PropType, ref, watch, defineProps, defineEmits, toRef } from "vue"
import RuleConditionContentBlockColumn from "@/propel/components/rule-condition-content-block-column.vue"

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:"setSelectedFormRule", selectedFormRule: FormRule)
    (e:"setFirstRender", value: boolean)
    (e:"refreshFormRules")
}>()

const props = defineProps({
    form: { type: Object as PropType<DocumentDetails>, required: true },
    formRules: { type: Array as PropType<Array<FormRule>>, required: true },
    readOnly: { type: Boolean, default: false },
})

const selectedFormRule = ref({} as FormRule)
const firstFormRuleEdit = ref(true)
const updateFormRule = ref(false)
const rulesAreOpen = ref(false)
const showEditFormRuleModal = ref(false)
const vm = toRef(props, "formRules")
//#endregion

//#region WATCH
watch(() => selectedFormRule.value, () => {
    if (!firstFormRuleEdit.value) {
            updateFormRule.value = true
        }
        firstFormRuleEdit.value = false
        emit("setSelectedFormRule", selectedFormRule.value)
}, { deep: true })
//#endregion

function addFormRule(): FormRule {
    return {
        id: null,
        formId: props.form.formId,
        formTitle: props.form.title,
        name: props.form.formId,
        description: props.form.title,
        notes: "",
        conditions: {
            activationDate: null,
            expirationDate: null,
            packageTypes: [] as string[],
            ruleIds: [] as string[],
            criteriaItems: [] as Criteria[]
        },
        placementRules: [] as PlacementRule[]
    } as FormRule
}

async function deleteFormRule(formRuleItem: FormRule) {
    await metadataService.deleteFormRule(props.form.id, formRuleItem.id)
}

function copyFormRule(rule: FormRule) {
    const ruleClone = cloneDeep(rule)
    ruleClone.id = null
    ruleClone.description = `${rule.description}_copy`
    vm.value.push(ruleClone)
    showEditModal(ruleClone)
}

async function saveFormRule(formRuleItem: FormRule) {
    emit("setFirstRender", true)
    const formRuleResponse = await metadataService.postFormRule(formRuleItem)
    selectedFormRule.value.id = selectedFormRule.value.id ?? formRuleResponse.id
    emit("refreshFormRules")
    messageService.showSaveSuccess(formRuleItem.description)
}

function showEditModal(item: FormRule) {
    selectedFormRule.value = item
    showEditFormRuleModal.value = true
}

function cancel() {
    updateFormRule.value = false
    emit("refreshFormRules")
}

function openItemsChanged(e: ItemsChangedEventArgs) {
    rulesAreOpen.value = e.openItemsCount > 0
}
</script>
