<template>
    <div class="footer">
        <div class="footer__left">
            <!-- <span class="footer-text">Privacy Statement</span>
            <span class="footer-text">|</span>
            <span class="footer-text">Terms of Use</span> -->
        </div>
        <div class="footer__center"></div>
        <div class="footer__right">
            <span class="footer-text">&copy; {{copyrightYear}}-{{ new Date().getFullYear() }} </span>

            <svg class="icon" fill="white" viewBox="0 0 309.2 54.1">
                <use xlink:href="~@../../../public/img/collection.svg#asurity" />
            </svg>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { config } from '@/config'

const copyrightYear = computed(() => config.app.regCheckOnly ? 2020 : 2006)
</script>
<style scoped lang="scss">
.footer {
    z-index: $layout-zindex;
    background-color: $near-black;
    height: $footer-height;
    display: flex;
    font-size: 0.75rem;
    &__left {
        padding: 0 1rem;
        display: flex;
        justify-self: flex-start;
        align-items: center;
    }
    &__center {
        flex: 1 0 auto;
    }
    &__right {
        display: flex;
        justify-self: flex-end;
        align-items: center;
        padding: 0 1rem;
        .icon {
            width: 8rem;
            height: 1rem;
        }
    }
}
.icon {
    // standard icon css
    display: block;
    cursor: pointer;
}
.footer-text {
    color: $white;
    margin-right: 0.5rem;
}
</style>
