<template>
    <div :class="`select-container form-group field ${size}`">
        <validation-provider :name="label" :rules="validationRules" :immediate="immediateValidation" v-slot="{ errors }">
            <custom-icon icon="Error" :id="`${id}-error`" v-if="errors[0]" :title="errors[0]" />
            <audit-check-icon :id="`${id}-audit-check`" :auditChecks="auditChecks" />
            <custom-icon icon="Help" :id="`${id}-help`" v-if="helpText" :title="helpText" />
            <div :class="`field ${dropdownSize}`">
                <input-select
                    :id="id"
                    :value="value"
                    :source="source"
                    :disabled="isDisabled"
                    :referenceType="referenceType"
                    :referenceSource="referenceSource"
                    :placeholder="placeholder"
                    :validationRules="validationRules"
                    :showEmptyOption="showEmptyOption"
                    :validationFieldName="label"
                    :jsonPath="jsonPathValue"
                    :immediateValidation="immediateValidation"
                    @input="$emit('input', $event)"
                    @change="$emit('change', $event)"
                />
            </div>
        </validation-provider>
        {{ label }}
        <span v-if="jsonPathLabel" class="json-path-label">{{jsonPathLabel}}</span>
        <protected-edit-icon :icon="protectedEditIconString" :showProtectedEdit="showProtectedEdit" :allowProtectedEdit="allowProtectedEdit" @click="turnOffProtectedDisabled" />
    </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, PropType } from 'vue'
import { useFormGroupBase, baseProps } from './form-group-base-composable'
import { MultiSelectItem } from './multi-select-item'
import { ReferenceSource } from '../models'

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'input', value: boolean): void 
    (e: 'change', event: any): void
}>()

const props = defineProps({
    id: { type: String, required: true },
    value: { type: [Array, String] as PropType<string[]|string>, required: true },
    source: { type: [Array, Function] as PropType<MultiSelectItem[] | ((searchTerm?: string) => Promise<MultiSelectItem[]>)>, default: null },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    referenceType: { type: String, default: '' },
    referenceSource: { type: String as PropType<ReferenceSource>, default: ReferenceSource.loan },
    size: { type: String as PropType<string|null>, default: '' },
    dropdownSize: { type: String, default: 's' },
    showEmptyOption: { type: Boolean, default: true },
    helpText: { type: String},
    ...baseProps
})
//#endregion

//#region INITIALIZE
const { jsonPathLabel, jsonPathValue, protectedEditIconString, showProtectedEdit, allowProtectedEdit, auditChecks, isDisabled, turnOffProtectedDisabled } = useFormGroupBase(props, emit);
//#endregion
</script>

<style lang="scss">
.select-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .custom-icon.edit {
        transform: scale(0.85);
    }
}
.json-path-label {
    font-size: 80%;
    word-break: break-all;
}
</style>
