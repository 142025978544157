import Vue from 'vue'
import VueRouter, { Route, NavigationGuardNext } from 'vue-router'
import NotFound from '@/common/views/not-found.vue'
import UnAuthorized from '@/common/views/unauthorized.vue'
import LoginRedirect from '@/common/views/login-redirect.vue'

import loansRoutes from './loans'
import adminRoutes from './admin'
import documentRoutes from '@/propel/router/documents'
import systemRoutes from './system'
import integrationRoutes from './integrations'

import { authService, browserTabService, versionService, userPreferenceService } from '@/common/services'
import { DefaultLandingPages } from '@/common/models'
import { config } from '@/config'
import { useAppStore, useCurrentUserStore } from '@/common/store'

const getUsersRedirectRoute = () => {
    const userDefaultLandingPage = userPreferenceService.getUserLandingPagePreference()
    return redirectRouteNames.find( r => r.landingPage === userDefaultLandingPage)?.routeName
}

const routes = [
    {
        path: '/',        
        redirect: () => {
            return {name: getUsersRedirectRoute()}
        }

    },
    ...loansRoutes,
    ...adminRoutes,
    ...documentRoutes,
    ...systemRoutes,
    ...integrationRoutes,
    {
        path: '*',
        name: 'catch-all',
        component: NotFound
    },
    {
        path: '/login-redirect',
        name: 'login-redirect',
        component: LoginRedirect
    },
    {
        path: '/unauthorized',
        name: 'unauthorized',
        component: UnAuthorized
    }
]

const redirectRouteNames = [
    { routeName: 'loan-grid', landingPage: DefaultLandingPages.Loans },
    { routeName: 'order-grid', landingPage: DefaultLandingPages.Orders },
    { routeName: 'documents-main', landingPage: DefaultLandingPages.FormLibrary },
    { routeName: 'document-fields', landingPage: DefaultLandingPages.Fields },
    { routeName: 'lender-grid', landingPage: DefaultLandingPages.LenderSettings },
    { routeName: 'system-settings', landingPage: DefaultLandingPages.SystemSettings }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_PUBLIC_APP_PATH, //process.env.BASE_URL,
    routes
})

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    if (to.name === 'login-redirect') {
        //Clear any existing auth cookies to be replaced with new information
        authService.clearExistingAuth()

        const routeQuery = to.query

        if (routeQuery.code) {
            const store = useAppStore()
            store.setAppLoading(true)
            // TODO: rework this logic so that the if check and access token request
            // are handled in a single auth service call
            // if (authService.requestAccessToken(routeQuery.code.toString()))...
            await authService.exchangeAuthCode(routeQuery.code.toString())
            store.setAppLoading(false)
            if (authService.hasAccessToken()) {
                if(routeQuery.state && routeQuery.state !== '/') {
                    next({ path: routeQuery.state as string })
                } else {
                    next({name: getUsersRedirectRoute()})
                }
            }
            else {
                authService.logout()
            }
        }
        else {
            authService.logout()
        }
    }
    else {
        if (!authService.hasAccessToken()) {
            authService.logout(to.fullPath)
        }
        else {
            if (config.app.useVersionChecker) {
                versionService.checkIfNewVersion()
                    .then(async isNewVersion => {
                        if (isNewVersion) {
                            const navigateToUrl = new URL(window.location.href)
                            navigateToUrl.pathname = to.fullPath
                            window.location.href = navigateToUrl.href
                        } else {
                            navigateToNextRoute(to, from, next)
                        }
                    })
                    .catch( async err => {
                        console.error(err)
                        navigateToNextRoute(to, from, next)
                    })
            } else {   
                navigateToNextRoute(to, from, next)
            }
        }
    }
})

const navigateToNextRoute = async (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    if (to.fullPath === "/") {
        const userStore = useCurrentUserStore()
        await userStore.getCurrentUser()
        next({name: getUsersRedirectRoute()})
    } else {
        browserTabService.generateTabTitle(to, from);
        next()
    }
}

export default router
