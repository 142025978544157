import { defineStore } from 'pinia'
import { ref } from 'vue'
import { Loading } from '@/common/models'
import { messageService } from '@/common/services'

export const usePageActionsStore = defineStore('pageActions', () => {
    const pageLoading = ref<Loading>({ isLoading: false, time: 0 })
    const saveLoading = ref<Loading>({ isLoading: false, time: 0 })

    function setBusyPage(isLoading: boolean) {
        pageLoading.value = { isLoading: isLoading, time: 0 }
    }

    async function updateSaveLoading(isLoading: boolean, isLoansPage = false, isPersistMode: boolean | null = null) {
        try {
            if (isLoading) {
                saveLoading.value = { isLoading: isLoading, time: performance.now() }
            } else {
                if (saveLoading.value.time !== 0) {
                    const timeDiff = performance.now() - saveLoading.value.time
                    //If we're in persist mode and the time it takes to save is less than 300 milliseconds,
                    //introduce a delay (for a total of 300 ms) so that the user sees the loading button for longer
                    const ignoreDelay = timeDiff > 300 || (isLoansPage && !isPersistMode)
                    const timeDelay = ignoreDelay ? 0 : 300 - timeDiff
                    await new Promise((resolve) => {
                        setTimeout(() => resolve(saveLoading.value.isLoading), timeDelay)
                    })
                }
                saveLoading.value = { isLoading: isLoading, time: 0 } as Loading
            }
        } catch (err: any) {
            messageService.showSystemError('Propel encountered an unexpected error. Please contact support for assistance.', 'Application Error')
        }
    }

    return { pageLoading, saveLoading, setBusyPage, updateSaveLoading }
})