<template>
    <summary-sidebar v-if='loanRecord' @sidebarStateChanged="onSidebarStateChanged" :state="sidebarState">
        <template v-slot:header>
            <div class="subtitle">
                <span class="loan-number">
                    <router-link v-if="canAccessLenderSettings" :to="{ name: 'client-lender-profiles', params: { code: clientCode } }">{{
                        clientCode
                    }}</router-link>
                    <span v-else>{{ clientCode }}</span>
                    <custom-icon icon="Bullet" /> {{ loanRecord.loanNumber }}
                </span>
            </div>
            <div class="title">{{ primaryBorrower }}</div>
            <div class="address">{{ loanAddress }}</div>
            <div class="address">{{ cityState }}</div>
        </template>
        <div class="summary-section">
            <regcheck-results
                :complianceResults="complianceChecks"
                :complianceErrors="complianceErrors"
                :showHeader="false"
            />
        </div>
        <template v-slot:footer>
            <blurb type="Error" small v-if="!calcsAreOk">
                <template v-slot:shortMessage>An error occurred while running calculations</template>
            </blurb>
            <div class="audit-data">
                <loading-overlay :show="isSaveLoading" :text="isPersist || isCommitLoading ? 'Saving/Recalculating' : 'Recalculating'">
                    <div class="summary-section audit-item" v-if="loanLock">
                        <div>Locked by {{ loanLock.user }}</div>
                        <div class="timestamp">{{ dateFormat(loanLock.updateTime) }}</div>
                    </div>
                    <div class="summary-section audit-item" v-else>
                        <div>Last updated by {{ loanAudit.updated.user }}</div>
                        <div class="timestamp">{{ dateFormat(loanAudit.updated.timestamp) }}</div>
                    </div>
                    <div class="summary-section audit-item">
                        <div>Created by {{ loanAudit.created.user }}</div>
                        <div class="timestamp">{{ dateFormat(loanAudit.created.timestamp) }}</div>
                    </div>
                </loading-overlay>
            </div>
        </template>
        <template v-slot:collapsed>
            <loading-overlay :show="isSaveLoading" :text="isPersist || isCommitLoading ? 'Saving/Recalculating' : 'Recalculating'">
                <div class="subtitle">
                    <span class="loan-number">
                        <router-link v-if="canAccessLenderSettings" :to="{ name: 'client-lender-profiles', params: { code: clientCode } }">{{
                            clientCode
                        }}</router-link>
                        <span v-else>{{ clientCode }}</span>
                        <custom-icon icon="Bullet" /> {{ loanRecord.loanNumber }}
                    </span>
                </div>
                <div class="title" :title="primaryBorrower">{{ primaryBorrower }}</div>
                <blurb type="Error" small v-if="!calcsAreOk">
                    <template v-slot:shortMessage>An error occurred while running calculations</template>
                </blurb>
                <template v-else>
                    <blurb v-if="dataNeededSuites > 0"  type="Error" small>
                        <template v-slot:shortMessage>{{dataNeededSuites}} suite(s) are missing data</template>
                    </blurb>
                    <blurb v-if="failSuites > 0"  type="Error" small>
                        <template v-slot:shortMessage>{{failSuites}} suite(s) failed</template>
                    </blurb>
                    <blurb v-if="displayWarning" type="Warning" small>
                        <template v-slot:shortMessage>{{warningSuites}} suite(s) have warnings</template>
                    </blurb>
                    <blurb v-if="displayPass" type="Success" small>
                        <template v-slot:shortMessage>{{passSuites}} suite(s) passed</template>
                    </blurb>
                </template>
                <div class="audit" v-if="loanLock" :title="`Locked by ${loanLock.user} - ${dateFormat(loanLock.updateTime)}`">
                    Locked by {{ loanLock.user }} <custom-icon icon="Bullet" /> {{ dateFormat(loanLock.updateTime) }}
                </div>
                <div class="audit" v-else :title="`Last updated by ${loanAudit.updated.user} - ${dateFormat(loanAudit.updated.timestamp)}`">
                    Updated by {{ loanAudit.updated.user }} <custom-icon icon="Bullet" /> {{ dateFormat(loanAudit.updated.timestamp) }}
                </div>
            </loading-overlay>
        </template>
    </summary-sidebar>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { dateTimeDiffFilter } from '@/common/filters'
import { AuditInfo, CalculatedData, LoanRecord, Property, Lock, ComplianceTestResult, TestResult, SidebarState } from '@/common/models'
import { DisplayHelper } from '@/common/utilities/helper'
import RegcheckResults from '@/common/components/regcheck-results.vue'
import { userPermissionValidatorService, userPreferenceService } from '@/common/services'
import { useLoanStore, usePageActionsStore } from '@/common/store'
//#region DEFINE VARIABLES
const pageActions = usePageActionsStore()
const store = useLoanStore()
//#endregion

//#region COMPUTED
const clientCode = computed<string>(() => store.clientCode ?? '')

const isPersist = computed<boolean | null>(() => {
    return store.persist
})

const isCommitLoading = computed<boolean>(() => {
    return store.cacheCommitLoading
})

const loanRecord = computed<LoanRecord|null>(() => {
    return store.loanRecord
})

const canAccessLenderSettings = computed<boolean>(()=> userPermissionValidatorService.hasAnyPermission(['admindata.clients.settings:read'], clientCode.value))

const primaryBorrower = computed<string>(() => {
    const borrower = store.borrowers?.length > 0 ? store.borrowers[0] : null
    if (borrower) return DisplayHelper.getBorrowerOrSellerName(borrower)
    else return ''
})

const loanProperty = computed<Property>(() => {
    return store.property
})

const loanAddress = computed<string>(() => {
        const propAddress = loanProperty.value.address
        const street = propAddress?.street ?? ''
        const description = propAddress?.unitDescription ?? ''
        const number = propAddress?.unit ?? ''

        if (description != '' && number != '')
            return  `${street}, ${description} ${number}`

        return street
})

const cityState = computed<string>(() => {
    const propAddress = loanProperty.value.address
    const state = propAddress?.state ?? ''
    const zip = propAddress?.zip ?? ''

    let city = propAddress?.city ?? ''
    if (city) city += ', '

    return `${city}${state} ${zip}`
})

const complianceChecks = computed<ComplianceTestResult[]>(() => store.complianceChecks || []) 
const passSuites = computed<number>(() => complianceChecks.value?.filter(i => i.result === TestResult.Pass)?.length ?? 0)
const failSuites = computed<number>(() => complianceChecks.value?.filter(i => i.result === TestResult.Fail)?.length ?? 0)
const warningSuites = computed<number>(() => complianceChecks.value?.filter(i => i.result === TestResult.Warning)?.length ?? 0)
const dataNeededSuites = computed<number>(() => complianceChecks.value?.filter(i => i.result === TestResult.DataNeeded)?.length ?? 0)
const displayWarning = computed(() =>  warningSuites.value > 0 && [failSuites.value, dataNeededSuites.value].filter(i => i > 0).length <= 1)
const displayPass = computed(() =>  [failSuites.value, warningSuites.value, dataNeededSuites.value].filter(i => i > 0).length <= 1)

const complianceErrors = computed<string[]>(() => store.complianceErrors || [])

const calculatedData = computed<CalculatedData>(() => {
    return store.loanRecordCalculations
})

const calcsAreOk = computed<boolean>(() => {
    if (calculatedData.value) {
        return !(calculatedData.value?.errors?.hasErrors ?? true)
    }
    return false
})

const loanAudit = computed<AuditInfo>(() => {
    return loanRecord.value?.audit ?? ({} as AuditInfo)
})

const loanLock = computed<Lock|null>(() => {
    return store.loanLock
})

const isSaveLoading = computed<boolean>(() => {
    return pageActions.saveLoading.isLoading
})

const sidebarState = computed<string>(() => {
    const sideBarState = userPreferenceService.getLoanSummarySidebarState()
    return sideBarState ? SidebarState.Collapsed : SidebarState.Expanded
})
//#endregion

function dateFormat(date: Date | null): string {
    if (date) {
        return dateTimeDiffFilter(date)
    } else {
        return ''
    }
}

function onSidebarStateChanged(state: SidebarState) {
    userPreferenceService.setLoanSummarySidebarState(state === SidebarState.Collapsed)
}
</script>
<style scoped lang="scss">
// this file's scss needs to be kept in sync with @/propel/components/loan-summary.vue to maintain the same look and feel
// they use the same summary-sidebar file but each has the below entry due to components used in the summary-sidebar components slots
.summary-sidebar {
    .summary-content.collapsed {
        .blurb {
            :deep {
                &.small-blurb {
                    font-size: 0.75rem;
                    gap: 0.375rem;

                    .custom-icon {
                        height: 1.125rem;
                    }
                }
            }
        }
    }

    .summary-footer {
        .blurb {   
            :deep {
                &.small-blurb .custom-icon {
                    height: 1.5rem;
                }
            }
        }
    }
}
</style>