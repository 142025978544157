import { Overridable } from './index';

export interface LoanDisclosure {
    partialPaymentType: PartialPaymentType | null
    hasDemandFeature: boolean
    personalPropertyDescription: string
    totalAnnualPropertyCosts: Overridable<number>
}

export enum PartialPaymentType
{
    None = "None",
    ApplyPartialPayment = "ApplyPartialPayment",
    HoldUntilCompleteAmount = "HoldUntilCompleteAmount",
    ApplyPartialAndHoldUntilComplete = "ApplyPartialAndHoldUntilComplete",
    Other = "Other"
}

export enum ServicingOption
{
    IntendToServiceLoan = 'IntendToServiceLoan',
    IntendToTransferServicing = 'IntendToTransferServicing'
}