<template>
    <div :id="`${id}-group`" :class="`field ${sizeValue} form-group`">
        <base-overridable
            :label="label"
            :jsonPathLabel="jsonPathLabel"
            :value="vm"
            :id="id"
            :auditChecks="auditChecks"
            :validationRules="validationRules"
            :validationFieldName="label"
            :immediateValidation="immediateValidation"
            :errorMessage="errorMessage"
            :helpText="helpText"
            @undoOverride="undoOverride"
        >
            <template v-slot:input="{ errors }">
                <input-text
                    :id="`${id}`"
                    :disabled="!vm.isOverridden"
                    v-model="vm.value"
                    :inputType="inputType"
                    :append="append"
                    :prepend="prepend"
                    :min="min"
                    :max="max"
                    :scale="scale"
                    :placeholder="placeholder"
                    :maxLength="maxLength"
                    :jsonPath="jsonPath"
                    :class="getErrorMessage(errors[0]) ? 'error' : ''"
                    @change="onChange"
                />
            </template>
        </base-overridable>
    </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, PropType, toRef, computed } from 'vue'
import { InputType, Overridable } from '../models'
import BaseOverridable from './base-overridable.vue'
import { useAuditChecks, useValidation, validationProps } from '@/common/composables/audit-check-composable'

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'change', event: any): void
    (e: 'undoOverride', event: any): void
}>()

const props = defineProps({
    value: { type: Object as PropType<Overridable<string>>, required: true },
    id: { type: String, required: true },
    size: { type: String as PropType<string|null> },
    inputType: { type: String as PropType<InputType>, default: InputType.Text },
    append: { type: String, default: '' },
    prepend: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    min: { type: Number as PropType<number|null>, default: null },
    max: { type: Number as PropType<number|null>, default: null },
    maxLength: { type: Number as PropType<number|null>, default: null },
    scale: { type: Number as PropType<number|null>, default: null },
    label: { type: String },
    helpText: { type: String },
    jsonPath: { type: String, default: '' },
    ...validationProps
})

const { getErrorMessage } = useValidation(props)
const { auditChecks, jsonPathLabel } = useAuditChecks(props)

const vm = toRef(props, 'value')
//#endregion

//#region COMPUTED
const sizeValue = computed(() => {
    //Setting default column value for specific input types
    //If not any of these types, return a generic value
    if (props.inputType === InputType.Days || props.inputType === InputType.Months || props.inputType === InputType.Percentage) {
        return props.size ?? 's'
    } else if (props.inputType === InputType.Currency) {
        return props.size ?? 's'
    } else {
        return props.size ?? 'm'
    }
})
//#endregion

function undoOverride(args: any) {
    emit('undoOverride', args)
}

function onChange(){
    emit('change', props.value)
}
</script>