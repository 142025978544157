<template>
    <div class="test-area">
        <div class="test-section">
            <div class="field-section">
                <fg-select
                    id="loans"
                    label="Loan"
                    size=""
                    :source="getAllLoans"
                    v-model="selectedLoan"
                    searchable
                    :searchMinimumCharacters="2"
                    :showEmptyOption="false"
                    @change="loanChanged"
                    optionDisplayType="SplitWithSecondaryText"
                    :disabled="readOnly"
                    :searchMessages="searchMessages"
                />
                <fg-select
                    v-if="!hidePackageTypes"
                    label="Package"
                    id="packageTypes"
                    size=""
                    v-model="packageType"
                    :source="getPackageTypes"
                    :multiple="false"
                    :disabled="readOnly"
                    optionDisplayType="SplitWithValue"
                />
            </div>
            <custom-button
                icon="list-check"
                label="Test Rule"
                @click="testItem"
                split
                :class="{ disabled: isTestingDisabled }"
                :loading="loadingTestResult"
                :disabled="readOnly"
            />
        </div>
        <div class="test-section results" v-if="selectedLoan">
            <textarea id="testResponse" :class="testResultClass" spellcheck="false" :rows="6" v-model="testResponseText" :disabled="readOnly" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineProps, ref, PropType } from 'vue'
import { adminDataService, loanService, userPreferenceService } from '@/common/services'
import { metadataService, testService } from '@/propel/services'
import { Condition, FieldDetail, FieldTestDataParams, LoanSummary, ResponseType } from '@/common/models'
import { MultiSelectItem } from '@/common/components/multi-select-item'
import { PackageType } from '@/propel/models'
import TestConditionsType from '@/propel/models/metadata/testConditionsType'
import { DefaultRule } from '@/common/models'

//#region DEFINE VARIABLES
const props = defineProps({
    conditions: { type: Object as PropType<Condition> },
    field: { type: Object as PropType<FieldDetail> },
    readOnly: { type: Boolean },
    hidePackageTypes: { type: Boolean },
    conditionsType: { type: String, default: TestConditionsType.Field },
    defaultValue: { type: Object as PropType<DefaultRule> },
    isTestingDisabled: { type: Boolean }         
})

const selectedLoan = ref('')
const packageType = ref('')
const loadingTestResult = ref(false)
const testResponseText = ref('')
const testResultClass = ref('default-border')
const searchMessages = ref<string[] | null>(null)
//#endregion

//#region INITIALIZE
initialize()

function initialize() {
    selectedLoan.value = userPreferenceService.getTestingLoanId()
}
//#endregion

async function getPackageTypes() {
    const packageTypes = await adminDataService.getPackageTypes()
    return packageTypes.map((pt: PackageType) => ({ value: pt.code, text: pt.displayName }))
}

async function getAllLoans(searchTerm?: string): Promise<MultiSelectItem[]> {
    const params = new URLSearchParams()
    params.append('searchTerm', searchTerm ? searchTerm : '')
    params.append('sort', 'loanNumber')
    const pagedLoans = await loanService.getAll(params)
    searchMessages.value = pagedLoans.messages
    return pagedLoans.items.map((loan: LoanSummary) => ({ value: loan.id, text: loan.loanNumber, secondaryText: loan.clientCode }))
}

async function testItem() {
    if (props.field && props.conditionsType == TestConditionsType.Field) return await testField()
    switch (props.conditionsType) {
        case TestConditionsType.DefaultValue:
            return await testDefaultValueConditions()
        case TestConditionsType.AuditCheck:
            return await testRule(true)
        default:
            return await testRule()
    }
}

async function testRule(isAuditCheck = false) {
    loadingTestResult.value = true
    const ruleTestOptions = { isAuditCheck: isAuditCheck }
    const testRuleResponse = await testService.testRule(selectedLoan.value, packageType.value, props.conditions, ruleTestOptions)
    testResponseText.value = testRuleResponse.value.replaceAll('\r\n{', '{')
    testResultClass.value = testRuleResponse.class
    loadingTestResult.value = false
}

async function testDefaultValueConditions() {
    try {
        loadingTestResult.value = true
        const response = await testService.testDefaultValue(props.field!.id!, selectedLoan.value, {
            packageType: packageType.value,
            conditions: props.conditions,
            replaceExistingData: props.defaultValue!.replaceExistingData,
            value: props.defaultValue!.value
        })
        testResponseText.value = response.value?.replaceAll('\r\n{', '{') ?? 'null'
        testResultClass.value = response.class
    } catch (exception) {
        testResponseText.value = 'Propel encountered an error'
        testResultClass.value = 'error-border'
    } finally {
        loadingTestResult.value = false
    }
}

async function testField() {
    loadingTestResult.value = true
    const testFieldParams: FieldTestDataParams = {
        loanId: selectedLoan.value,
        packageType: packageType.value,
        field: props.field!
    }

    const testResponse = await metadataService.getFieldTestResults(testFieldParams)
    testResponseText.value = testResponse.value.replaceAll('\r\n{', '{')
    if (testResponse.type === ResponseType.Success) {
        testResultClass.value = 'success-border'
    } else if (testResponse.type === ResponseType.Error) {
        testResultClass.value = 'error-border'
    } else if (testResponse.type === ResponseType.Warning) {
        testResultClass.value = 'warning-border'
    } else {
        testResultClass.value = 'default-border'
    }
    loadingTestResult.value = false
}

async function loanChanged() {
    await userPreferenceService.setTestingLoanId(selectedLoan.value)
}
</script>