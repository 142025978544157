export interface User{
    id: string
    name: string
    emailAddress: string
    profile: UserProfile
    lastLogin: Date
    created: Date
    permissionSets: UserPermissionSet[]
}

export interface PermissionGroup{
    id: string
    categories: string[]
    children: ExtendedPermissionGroup[]
    description: string
    partners: string[]
    clients: string[]
    permissions: string[]
}

export interface UserPermissionMapping{
    id: string
    categories: string[]
    children: PermissionGroup[]
    parent: PermissionGroup | null
    level: number
    description: string
    permissions: string[]    
    partners: string[]
    clients: string[]
    isSystemPermissionChecked: boolean
    isAllPCPermissionChecked: boolean
    isPartnerPermissionChecked: boolean
    isClientPermissionChecked: boolean
}

export interface PermissionLevels {
    level: string
    action: string | null
}

export interface UserPermissionSet{
    partners: string[]
    clients: string[]
    roles: string[]
    permissions: string[]
    permissionLevels: PermissionLevels[]
}

export enum PermissionCategory{
    Client = "Client",
    Partner = "Partner",
    AllPartnersClient = "AllPartnersClient",
    System = "System",
    SystemAdmin = "SystemAdmin",
    All = "All"
}

export interface UserPermissionGrid{
    currentPermissions: ExtendedPermissionGroup[]
    category: PermissionCategory
    partners: string[]
    clients: string[]    
    loansPermission: string
}


export interface ExtendedPermissionGroup extends PermissionGroup{ 
    parent: ExtendedPermissionGroup | null
    permissions: string[]
    level: number
    isSystemPermissionChecked: boolean
    isAllPCPermissionChecked: boolean
    isPartnerPermissionChecked: boolean
    isClientPermissionChecked: boolean
}

export interface PermissionType {
    read: boolean
    write: boolean
}

export interface UserProfile {
    phone: string
    phoneExt: string
    fax: string
    preferences: UserPreferences
    lastSelectedFilters: LastSelectedFilters
}

export interface UserPreferences {
    grids: GridPreferences
    loan: LoanPreferences
    defaultLandingPage: DefaultLandingPages
    dateFormat: string
    timeFormat: string
}

export interface GridPreferences {
    alternateRowColors: boolean
    rowSelectionType: RowSelectionType
    defaults: Map<string, GridDefaults>
}

export interface GridDefaults {
    pageSize: number
    clients: string
    sortBy: string
}

export interface LoanPreferences {
    defaults: Map<string, string>
    autoSave: boolean
    defaultPartner: string
    defaultLender: string
}

export enum RowSelectionType {
    Anywhere = "Anywhere",
    Link = "Link"
}

export interface ResetPasswordResponse {
    name: string
    email: string
    url: string
    encryptedData: string
}

export interface LastSelectedFilters {
    partner: string
    lender: string
}

export enum LastSelectionType {
    Partner = "LAST_PARTNER_SELECTION",
    Lender = "LAST_LENDER_SELECTION",
}

export enum DefaultLandingPages {
    Loans = "Loans",
    Orders = "Orders",
    FormLibrary = "FormLibrary",
    Fields = "Fields",
    LenderSettings = "LenderSettings",
    SystemSettings = "SystemSettings"
}