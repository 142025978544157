export enum ReadonlyPermissions {
    None = "none", //Not an actual permission, but meant to indicate a protected field that requires no permission to edit
    LenderInformation = "lenderInformation",
    TxWarrantyDeedInvoiceAmount = "txWarrantyDeedInvoiceAmount",
    ReleaseOfLienInvoiceAmount = "releaseOfLienInvoiceAmount",
    HelocMaintenanceFees = "helocMaintenanceFees",
    SignatureAndInitialOptions = "signatureAndInitialOptions",
    BarcodeCustomContent = "barcodeCustomContent",
    ArmIndexRate = "armIndexRate"
}

export default ReadonlyPermissions;