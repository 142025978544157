import { MultiSelectItem } from '@/common/components/multi-select-item'

export interface AdminTrustees {
    primaryTrustee: AdminDeedOfTrustTrustee
    secondaryTrustee: AdminDeedOfTrustTrustee
    states: string[]
    selectedTrusteeContactOption: TrusteeContactOption
    isInherited: boolean
}

export interface AdminDeedOfTrustTrustee {
    name: string
    phone: string
    phoneExt: string
    fax: string
    address: AdminDeedOfTrustTrusteeAddress
}

export interface AdminDeedOfTrustTrusteeAddress {
    street: string
    unit: string
    city: string
    state: string
    zip: string
    county: string
}

export interface EditableListAdminTrustees {
    error: string
    stateOptions: MultiSelectItem[]
    adminTrustees: AdminTrustees
}

export enum TrusteeContactOption {
    ContactForm = "ContactForm",
    LoanTitleCompany = "LoanTitleCompany",
    LoanLender = "LoanLender"
}