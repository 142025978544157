import { DocuSignPermissionRequest } from "@/common/models"
import { messageService, axiosDocuSignUserAuthService } from "@/common/services"

class DocuSignUserAuthService{
    async sendEmailRequestingDocusignPermission (email: string): Promise<void> {
        const request = { email } as DocuSignPermissionRequest;
        messageService.showLoading('Sending authorization email...')
        const response = await axiosDocuSignUserAuthService.post('user-authorization', request)

        if (response.data === "Accepted") {
            messageService.showSuccess(`An email has been sent to ${email} with authorization instructions.`, 'Email Sent')
        } else {
            messageService.showSystemError('Propel encountered an error while sending the permission request email. Check the email address and try again, or contact support for assistance.', 'System Error')
        }
    }
}

export const docuSignUserAuthService = new DocuSignUserAuthService()