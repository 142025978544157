<template>
    <div class="pagination-container">
        <div class="pagination-container__count">
            <span> Showing </span>
            <span v-if="pageSize < totalCount">
                <b-dropdown variant="light" no-caret :text="`${pageSize}`">
                    <b-dropdown-item :key="count" v-for="count in countOptions" @click="emit('count-changed', count)"> {{ count }} </b-dropdown-item>
                </b-dropdown>
                of
            </span>
            <span v-else>all</span>
            <span> {{ totalCount }} </span>
        </div>
        <div class="pagination-container__page">
            <div>
                <custom-icon icon="PageFirst" @click="emit('first', 1)" :disabled="!hasFirst" />
                <custom-icon icon="PagePrevious" @click="emit('previous', current - 1)" :disabled="!hasPrevious" />
            </div>
            <div class="pagination-center">
                <input
                    @input="debouncePageInput"
                    @keypress="isNumber($event)"
                    @keypress.delete="debouncePageInput"
                    type="text"
                    class="form-control"
                    v-model.number="current"
                />
                <span>{{ '/ ' + totalPages }}</span>
            </div>
            <div>
                <custom-icon icon="PageNext" @click="emit('next', current + 1)" :disabled="!hasNext" />
                <custom-icon icon="PageLast" @click="emit('last', totalPages)" :disabled="!hasLast" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { debounce } from 'lodash'
import { PropType, computed, ref, watch, defineEmits, defineProps } from 'vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:"page-changed", value: number)
    (e:"count-changed", count: number)
    (e:"first", count: number)
    (e:"previous", count: number)
    (e:"next", count: number)
    (e:"last", count: number)
}>()

const props = defineProps({
    countOptions: { type: Array as PropType<Array<number>>, default: [] as number[]},
    currentPage: { type: Number, default: 1},
    totalCount: { type: Number, default: 0},
    pageSize: { type: Number, default: 0},
})

const current = ref(0)
//#endregion

//#region WATCH
watch(() => props.currentPage, (value) => setCurrentPage(value))
//#endregion

//#region COMPUTED
const hasNext = computed(() => current.value + 1 <= totalPages.value)
const hasPrevious = computed(() => current.value - 1 > 0)
const hasFirst = computed(() => current.value != 1)
const hasLast = computed(() => current.value != totalPages.value)
const totalPages = computed(() => Math.ceil(props.totalCount / props.pageSize))
const debouncePageInput = computed(() => debounce(handlePageInput, 800))
//#endregion

//#region INITIALIZE
initialize()

async function initialize(){
    current.value = props.currentPage

}
// #endregion

function setCurrentPage(curr: number | null) {
    if (curr) 
        current.value = curr
}

function isNumber(evt: KeyboardEvent) {
    const charCode = evt.which || evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
    } else {
        return true
    }
}

function handlePageInput() {
    if (current.value <= 0) {
        current.value = 1
    } else if (totalPages.value < current.value) {
        current.value = totalPages.value
    }

    if (current.value != props.currentPage) {
        emit('page-changed', current.value)
    }
}
</script>

<style scoped lang="scss">
.pagination-container {
    color: $dark-gray;
    font-size: 0.75rem;
    display: flex;
    &__count {
        margin: auto;
    }
    &__page {
        margin-left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        > * {
            display: flex;
            align-items: center;
            margin-left: 0.5rem;
        }
    }

    .b-dropdown {
        margin: 0 5px;
        border: 1px solid $light-gray;
        color: $light-blue;
    }
}

// Can't get this to work for some reason
// .btn-light, .btn.dropdown-toggle.btn-light.dropdown-toggle-no-caret {
//     background:$white !important;
//     color: $near-black !important;
// }

.form-control {
    max-width: 2.5rem;
    min-width: 2.5rem;
    padding: 0 0.5rem;
    font-size: 0.75rem;
}

.pagination-center {
    display: flex;
    align-items: center;

    input {
        margin-right: 0.35rem;
        text-align: center;
    }
}

:deep(.btn.dropdown-toggle) {
    font-size: 0.75rem;
}
</style>
