import { ref, computed } from 'vue'
import { MultiSelectItem } from '@/common/components/multi-select-item'
import { Address, BasicContact, Contact, ContactAddress, FirstPaymentLetterContact, Lender, LenderAddress, LenderInfo, LenderProfile, MersSigner, NotaryPublic, Officer, SingleSignerNotary, StateLayout } from '@/common/models'
import { WarehouseBankContact } from '@/common/models/admin/warehouse-bank'
import { adminDataService } from '@/common/services'
import { DisplayHelper } from '@/common/utilities/helper'
import { useLoanStore } from '@/common/store'

export function useLenderSelect() {
    const store = useLoanStore()
    const lenderProfiles = ref<LenderProfile[]>([])
    const lenderProfile = ref<LenderProfile>({} as LenderProfile)
    const lender = ref<Lender>({} as Lender)

    const availableLenderProfiles = computed<MultiSelectItem[]>(() => 
        lenderProfiles.value?.map(p => ({
            text: p.displayName,
            value: p.id
        } as MultiSelectItem))
    )

    const initializeLender = async (loanLender: Lender | undefined) => {
        initializeLenderinfoAndContacts(loanLender)
        lenderProfile.value = store.lenderProfile || {} as LenderProfile
        await initializeLenderProfiles()
    }

    function initializeLenderinfoAndContacts(loanLender: Lender | undefined) {
        lender.value = loanLender || {} as Lender
        
        lender.value = {
            ...lender.value,
            info: lender.value.info || ({address: {} as LenderAddress} as LenderInfo),
            officer: lender.value.officer || ( {} as Officer),
            mersSigner: lender.value.mersSigner || {} as MersSigner,
            branch: lender.value.branch || {} as Contact,
            funder: initializeContact(lender.value.funder),
            closer: initializeContact(lender.value.closer),
            closingInstructions: initializeContact(lender.value.closingInstructions),
            beneficiary: initializeContact(lender.value.beneficiary),
            dtMortgageReturn: initializeContact(lender.value.dtMortgageReturn),
            noticeOfRightToCancel: initializeContact(lender.value.noticeOfRightToCancel),
            documentsReturn: initializeContact(lender.value.documentsReturn),
            notePay: initializeContact(lender.value.notePay),
            firstPaymentLetter: initializeFirstPaymentLetterContact(lender.value.firstPaymentLetter),
            mortgageeClause: initializeContact(lender.value.mortgageeClause),
            taxService: initializeContact(lender.value.taxService),
            warehouseBank: initializeWarehouseBankContact(lender.value.warehouseBank),
            processor: initializeContact(lender.value.processor)
        }

        lender.value.info.stateLicenseType ??= ""
        lender.value.info.stateOfIncorporation = DisplayHelper.getIncorporationState(lender.value.info.stateOfIncorporation)
        lender.value.branch.info = initializeContact(lender.value.branch.info)

        lender.value.info.notary = initializeNotary(lender.value.info.notary)
        lender.value.mersSigner.notary = initializeNotary(lender.value.mersSigner.notary)
    }

    async function initializeLenderProfiles() {
        const clientCode = store.clientCode
        const client = await adminDataService.getClient(clientCode)
        lenderProfiles.value = client?.lenderProfiles || []
    }

    function initializeContact(contact: BasicContact): BasicContact {
        const initializedContact = contact || {} as BasicContact
        initializedContact.address = initializedContact.address || {} as ContactAddress
        initializedContact.phone ??= ""
        return initializedContact
    }

    function initializeFirstPaymentLetterContact(contact: FirstPaymentLetterContact): FirstPaymentLetterContact {
        const initializedContact = contact || {} as FirstPaymentLetterContact
        initializedContact.address = initializedContact.address || {} as ContactAddress
        initializedContact.phone ??= ""
        return initializedContact
    }

    function initializeWarehouseBankContact(contact: WarehouseBankContact): WarehouseBankContact {
        const initializedWarehouseBank = contact || {} as WarehouseBankContact
        initializedWarehouseBank.address = initializedWarehouseBank.address || {} as Address
        return initializedWarehouseBank
    }

    function initializeNotary(notary: SingleSignerNotary) {
        const initializedNotary = notary || {} as SingleSignerNotary
        initializedNotary.stateLayout ??= {} as StateLayout
        initializedNotary.notaryPublic ??= {} as NotaryPublic
        return initializedNotary
    }

    return {
        lender,
        lenderProfile,
        lenderProfiles,
        availableLenderProfiles,
        initializeLender
    }
}