import { defineStore } from 'pinia'
import { ref } from 'vue'
import { Loading } from '@/common/models'
import { NotificationDetails } from '@/common/services'

export const useAppStore = defineStore('app', () => {
    const appLoading = ref<Loading>({ time: 0, isLoading: false})
    const notificationDetails = ref<NotificationDetails | null>(null)

    function setAppLoading(isLoading: boolean) {
        appLoading.value = { isLoading: isLoading, time: 0 } as Loading
    }

    return { appLoading, notificationDetails, setAppLoading }
})