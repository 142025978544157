<template>
    <span
        :id="id"
        ref="rootElement"
        @click="onClick($event)"
        :disabled="disabled"
        class="custom-icon"
        :class="[toKebabCase(icon), { disabled: disabled, clickable: isClickable }]"
        :title="title"
        v-b-tooltip.html.hover
    >
        <span v-if="isFont(icon)" :class="['imported-icon', `icon-${toKebabCase(icon)}`]" />
        <span v-else-if="icon == 'Loading'"><span class="spinner-border" aria-hidden="true"></span></span>
        <i v-else :class="`bi-${bootstrapIconType}`" />

        <div class="dropdown" v-show="dropdownExpanded" @click.stop>
            <div class="dropdown-menu" :class="{ show: dropdownExpanded, searchable: searchable && items.length >= config.minSearchableCount }">
                <list-box ref="listBoxElement" :items="items" @click="onSelection" :searchable="searchable" :filterOn="filterOn">
                    <!-- Pass-through slots to allow us to define item templates from grandparent component -->
                    <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
                        <slot :name="name" v-bind="data"></slot>
                    </template>
                </list-box>
            </div>
        </div>
    </span>
</template>
<script lang="ts" setup>
import { ref, computed, defineProps, defineEmits, PropType, onUnmounted, nextTick, watch, useListeners } from 'vue'
import { config } from '@/config'
import ListBox from './list-box.vue'

enum IconType {
    Action = 'Action',
    Add = 'Add',
    Bullet = 'Bullet',
    CheckboxGroup = "CheckboxGroup",
    Close = 'Close',
    Copy = 'Copy',
    Delete = 'Delete',
    Download = 'Download',
    Draft = 'Draft',
    Drag = 'Drag',
    Edit = 'Edit',
    Error = 'Error',
    ErrorCircle = 'ErrorCircle',
    Help = 'Help',
    Info = 'Info',
    Link = 'Link',
    Loading = 'Loading',
    Locked = 'Locked',
    Logout = 'Logout',
    Menu = 'Menu',
    Move = 'Move',
    PageFirst = 'PageFirst',
    PageLast = 'PageLast',
    PageNext = 'PageNext',
    PagePrevious = 'PagePrevious',
    Preview = 'Preview',
    Question = 'Question',
    Remove = 'Remove',
    Refresh = 'Refresh',
    Reset = 'Reset',
    Save = 'Save',
    Search = 'Search',
    SectionClosed = 'SectionClosed',
    SectionOpen = 'SectionOpen',
    Selected = 'Selected',
    Send = 'Send',
    SortAscending = 'SortAscending',
    SortDescending = 'SortDescending',
    Split = 'Split',
    Success = 'Success',
    Switch = 'Switch',
    SystemError = 'SystemError',
    TextInput = 'TextInput',
    TreeItemClosed = 'TreeItemClosed',
    TreeItemOpen = 'TreeItemOpen',
    Unselected = 'Unselected',
    Upload = 'Upload',
    UserProfile = 'UserProfile',
    Warning = 'Warning',
    WarningCircle = 'WarningCircle'
}

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e: 'noop', event: Event): void
    (e: 'click', event: Event): void
    (e: 'selection', payload: any): void
}>()
const props = defineProps({
    id: { type: String },
    icon: { type: String as PropType<IconType>, required: true },
    title: { type: String, default: '' },
    disabled: { type: Boolean, default: false },

    searchable: { type: Boolean, default: false },
    items: {
        type: Array as PropType<Array<any>>,
        default() {
            return [] as any[]
        }
    },
    filterOn: {
        type: Array as PropType<Array<string>>,
        default() {
            return [] as string[]
        }
    }
})

const listeners = useListeners() //obsolete in vue 3

const customFonts = [IconType.Delete, IconType.Split]
const dropdownExpanded = ref(false)
const rootElement = ref<HTMLElement | null>(null)
const listBoxElement = ref<InstanceType<typeof ListBox> | null>(null)

//#endregion

//#region INITIALIZE

onUnmounted(() => document.removeEventListener('click', onClickOutside))
//#endregion

//#region COMPUTED
const isClickable = computed(() => listeners.click || listeners.selection || props.items?.length > 0 || props.icon === 'Drag')
const hasMenu = computed(() => props.items.length > 0)
const bootstrapIconType = computed(() => {
    switch (props.icon) {
        case IconType.Action:
            return 'lightning-fill'
        case IconType.Add:
            return 'plus'
        case IconType.Bullet:
            return 'dot'
        case IconType.CheckboxGroup:
            return 'ui-checks'
        case IconType.Close:
            return 'x'
        case IconType.Copy:
            return 'files'
        case IconType.Download:
            return 'download'
        case IconType.Drag:
            return 'grip-vertical'
        case IconType.Draft:
            return 'pencil-square'
        case IconType.Edit:
            return 'pencil-fill'
        case IconType.Error:
            return 'exclamation-octagon-fill'
        case IconType.ErrorCircle:
            return 'circle-fill'
        case IconType.Help:
            return 'question-circle-fill'
        case IconType.Info:
            return 'info-square-fill'
        case IconType.Link:
            return 'link-45deg'
        case IconType.Locked:
            return 'lock-fill'
        case IconType.Logout:
            return 'box-arrow-left'
        case IconType.Menu:
            return 'three-dots-vertical'
        case IconType.Move:
            return 'arrow-down-up'
        case IconType.PageFirst:
            return 'chevron-bar-left'
        case IconType.PageLast:
            return 'chevron-bar-right'
        case IconType.PageNext:
            return 'chevron-right'
        case IconType.PagePrevious:
            return 'chevron-left'
        case IconType.Preview:
            return 'search'
        case IconType.Question:
            return 'question-circle-fill'
        case IconType.Remove:
            return 'x'
        case IconType.Refresh:
            return 'arrow-repeat'
        case IconType.Reset:
            return 'arrow-counterclockwise'
        case IconType.Save:
            return 'check-lg'
        case IconType.Search:
            return 'search'
        case IconType.SectionClosed:
            return 'caret-right'
        case IconType.SectionOpen:
            return 'caret-down'
        case IconType.Selected:
            return 'square-fill'
        case IconType.Send:
            return 'send'
        case IconType.SortAscending:
            return 'caret-up-fill'
        case IconType.SortDescending:
            return 'caret-down-fill'
        case IconType.Success:
            return 'check-square-fill'
        case IconType.Switch:
            return 'arrow-left-right'
        case IconType.SystemError:
            return 'shield-fill-x'
        case IconType.TextInput:
            return 'input-cursor-text'
        case IconType.TreeItemClosed:
            return 'plus-square'
        case IconType.TreeItemOpen:
            return 'dash-square'
        case IconType.Unselected:
            return 'dash-square'
        case IconType.Upload:
            return 'cloud-upload'
        case IconType.UserProfile:
            return 'person-lines-fill'
        case IconType.Warning:
            return 'exclamation-triangle-fill'
        case IconType.WarningCircle:
            return 'circle-fill'
        default:
            return props.icon?.toString() ?? null
    }
})
//#endregion

//#region WATCH
watch(
    () => props.items,
    () => {
        if (hasMenu.value) document.addEventListener('click', onClickOutside)
    }
)
//#endregion

function onClick(event: Event) {
    if (props.disabled) emit('noop', event)
    else if (hasMenu.value) {
        dropdownExpanded.value = true

        //Focus on search box after opening
        if (props.searchable && !!listBoxElement.value) {
            nextTick(() => {
                listBoxElement.value?.searchFilterElement?.focus()
            })
        }
    } else emit('click', event)
}
function onSelection(payload: any) {
    emit('selection', payload)
    dropdownExpanded.value = false
}
function onClickOutside(e: Event) {
    if (rootElement.value && !rootElement.value.contains(e.target as Node)) {
        dropdownExpanded.value = false
    }
}

function isFont(icon: IconType): boolean {
    return customFonts.includes(icon)
}

function toKebabCase(value: string): string {
    return value
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase()
}
</script>

<style lang="scss" scoped>
.custom-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 1rem;
    height: 1rem;
    font-size: 1rem;
    line-height: 0;
    color: $bold-blue;
    pointer-events: auto;

    :deep {
        &.standard-menu {
            .list-box-item {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &.logout,
    &.upload,
    &.refresh,
    &.reset {
        font-size: 1.125rem;
    }

    &.upload {
        width: 1.25rem; // a larger upload is wider than other icons
    }

    &.save {
        font-size: 1.25rem;
    }

    &.close,
    &.remove,
    &.add {
        font-size: 1.5rem;
    }

    &.preview {
        transform: rotateZ(90deg);

        i {
            margin-top: -0.125rem;
        }
    }

    &.send {
        transform: scale(0.9) rotateZ(45deg) !important;
    }

    &.disabled {
        pointer-events: none;
        color: $medium-gray;
    }

    &.clickable:hover {
        cursor: pointer;
        color: $link-hover-color;

        &.remove,
        &.delete {
            color: $red;
        }
    }

    &.close,
    *.close:hover {
        text-shadow: unset;
        opacity: unset;
        font-weight: unset;
        float: unset;
    }

    &.remove {
        color: $medium-gray;
    }

    &.sort-ascending,
    &.sort-descending {
        color: $bold-blue;
    }

    &.error,
    &.error-circle,
    &.unselected {
        color: $red !important;
    }

    &.system-error {
        color: $dark-red !important;
    }

    &.warning,
    &.warning-circle {
        color: $yellow-warning !important;
    }

    &.selected,
    &.success {
        color: $green !important;
    }

    &.loading {
        padding: 0 0.25rem;

        .spinner-border {
            width: 1rem;
            height: 1rem;
            border-width: 0.125rem;
        }
    }

    &.drag:hover {
        cursor: move;
    }

    .dropdown-menu {
        line-height: 21px; // resets line height for menu items

        &.searchable {
            padding-top: 0;
        }
    }
}
</style>

<style lang="scss">
* + .custom-icon {
    margin-left: 0.25rem;
}

.custom-icon + .custom-icon {
    margin-left: 0.5rem;
}

button:hover:not(.disabled),
button:active:not(.disabled),
button:focus:not(.disabled) {
    .custom-icon {
        color: $white;
    }
}

button .custom-icon.delete {
    color: $red;
}

label .custom-icon {
    zoom: 0.85;
    height: 0;
    vertical-align: middle !important;

    &:first-child {
        margin-right: 0.25rem;
    }
}

.standard-columns > div * + .custom-icon,
.standard-columns > div .custom-icon + * {
    margin-left: 0.25rem;
}

.standard-columns > div .custom-icon {
    margin-right: 0.25rem;
}

th .custom-icon {
    &.sort-ascending,
    &.sort-descending {
        font-size: 0.875rem;
    }

    &.sort-descending {
        vertical-align: middle;
    }
}

.toggle-container + .custom-icon {
    vertical-align: middle !important;
}
</style>