<template>
    <div>
        <content-block-list
            id="defaultValueRules"
            ref="defaultValuesList"
            :listItems.sync="defaultRules"
            deletable
            sortField="description"
            addLabelText="Add Default Value"
            :onAdd="addNewDefaultRule"
            @editListItem="showEditModal"
            @itemDeleted="deleteDefaultValueRule"
        >
            <template v-slot:view="slotParams">
                <div class="standard-columns">
                    <div class="s wrap">
                        <pre class="ellipsis6" :id="`ruleValue` + slotParams.index">{{ slotParams.item.value }}</pre>
                        <b-tooltip :target="`ruleValue` + slotParams.index" custom-class="tooltip-md" triggers="hover">
                            <slot name="tooltip"><pre>{{ slotParams.item.value }}</pre></slot>
                        </b-tooltip>
                        <div class="subtitle" id="ruleDescription">
                            {{ slotParams.item.description }}
                        </div>
                    </div>
                    <rule-condition-content-block-column :conditions="slotParams.item.conditions" />
                    <div class="s">
                        <div v-if="slotParams.item.preventChange">Users cannot change</div>
                        <div v-else>Users can change</div>

                        <div v-if="slotParams.item.replaceExistingData">Ignore imported data</div>
                        <div v-else>Use imported data if available</div>
                    </div>
                </div>
            </template>
            <template v-slot:deleteModal="slotParams"> Are you sure you want to delete {{ slotParams.item && slotParams.item.description }}? </template>
        </content-block-list>
        <div v-if="showReadonlyPermissionLabel" class="readonlyPermissionContainer">
            <fg-text 
                id="readonlyPermission"
                validationRules="required"
                v-model="field.readonlyPermissionLabel"
                label="Permission Label"
                helpText="One or more rules on this page are set to prevent most users from changing the default value. Users with the permission specified here will be able to make loan-level changes."
            />
        </div>
        <b-modal
            id="edit-default-modal"
            title="Edit Default Value"
            size="xl"
            v-model="showDefaultModal"
            @ok="saveDefaultValueRule(selectedRule)"
            @cancel="defaultValuesList.cancelEditMode(selectedRule)"
            @close="defaultValuesList.cancelEditMode(selectedRule)"
            ok-title="Save"
            cancel-title="Cancel"
            :ok-disabled="rulesAreOpen"
            modal-class="testable"
        >
            <div class="testable-area-wrap">
                <b-tabs class="tabs-fixed">
                    <b-tab :active="editingExistingItem()">
                        <template v-slot:title>Configuration</template>
                        <div class="field-section">
                            <field-value
                                id="defaultFieldValue"
                                label="Set this field's value to:"
                                :fieldName="field.name"
                                :compareExpression="selectedRule.value"
                                :enableFieldExpression="true"
                                :expressionOnlyDates="true"
                                class="field-value"
                                @update:compareExpression="(val) => updateDefaultValue(val, undefined)"
                                @update:compareValues="(val) => updateDefaultValue(undefined, val)"
                            />
                            
                            <fg-checkbox id="lockValue" label="Prevent this value from being changed" v-model="selectedRule.preventChange" />
                            <fg-radio-button
                                :options="replaceExistingOptions"
                                v-model="selectedRule.replaceExistingData"
                                id="replace-existing-buttons"
                                name="replace-existing-radio-buttons"
                                label="When importing, use this value:"
                            />
                        </div>
                        <rule-criteria-editor
                            :key="selectedRule.id"
                            :conditions="selectedRule.conditions"
                            :field="field"
                            hide-package-types
                            :defaultRule="selectedRule"
                            :saveNewRules="true"
                            @openItemsChanged="openItemsChanged"
                        />
                    </b-tab>
                    <b-tab>
                        <template v-slot:title>Details</template>
                        <div class="field-section">
                            <fg-textarea id="description" :rows="3" label="Description" v-model="selectedRule.description" />
                            <fg-textarea id="notes" :rows="6" label="Notes" v-model="selectedRule.notes" />
                        </div>
                    </b-tab>
                </b-tabs>
                <aside>
                    <summary-sidebar>
                        <h2>Default Value Testing</h2>
                        <test-container :conditions="selectedRule.conditions" conditionsType="DefaultValue" :field="field" :defaultValue="selectedRule" hide-package-types />
                        <template v-slot:footer v-if="selectedRule && selectedRule.audit && selectedRule.audit.created">
                            <summary-sidebar-audit-info :audit="selectedRule.audit" />
                        </template>
                    </summary-sidebar>
                </aside>
            </div>
        </b-modal>
    </div>
</template>
<script setup lang="ts">
import { defineProps, ref, PropType, computed, watch, toRefs } from 'vue'
import { messageService } from '@/common/services'
import { metadataService } from '@/propel/services'
import { DefaultRule, Condition, Criteria, FieldDetail, OverwriteImportDataEnum, AuditInfo, ItemsChangedEventArgs } from '@/common/models'
import { MultiSelectItem } from '@/common/components/multi-select-item'

//#region DEFINE VARIABLES
const props = defineProps({
    defaultRules: { type: Array as PropType<DefaultRule[]>, required: true },
    field: { type: Object as PropType<FieldDetail>, required: true },
})
const selectedRule = ref<DefaultRule>({} as DefaultRule)
const rulesAreOpen = ref(false)
const showDefaultModal = ref(false)
const defaultValuesList = ref()
const { defaultRules, field } = toRefs(props)

const replaceExistingOptions: MultiSelectItem[] = [
    { value: false, text: OverwriteImportDataEnum.DoNotOverwrite },
    { value: true, text: OverwriteImportDataEnum.OverwriteData }
]
//#endregion

//#region COMPUTED
const showReadonlyPermissionLabel = computed(() => props.defaultRules.some(r => !!r.preventChange))
//#endregion

//#region WATCH
watch(showReadonlyPermissionLabel, () => {
    if(showReadonlyPermissionLabel.value && !field.value.readonlyPermissionLabel)
        field.value.readonlyPermissionLabel = field.value.displayName //Default to display name
    else if(!showReadonlyPermissionLabel.value && field.value.readonlyPermissionLabel)
        field.value.readonlyPermissionLabel = '' //Clear if not applicable
})
//#endregion

async function showEditModal(item: DefaultRule) {
    selectedRule.value = item
    showDefaultModal.value = true
}

async function saveDefaultValueRule(rule: DefaultRule) {
    try {
        const saved = await metadataService.postFieldDefaultValueRule(field.value.id, rule)
        rule.id = rule.id ?? saved.id
        messageService.showSaveSuccess(rule.description)
    } finally {
        selectedRule.value = { conditions: {} as Condition } as DefaultRule
    }
}

async function deleteDefaultValueRule(itemToRemove: DefaultRule) {
    await metadataService.deleteFieldDefaultValueRule(field.value.id, itemToRemove.id)
}

function editingExistingItem(): boolean {
    return (selectedRule.value?.id ? selectedRule.value.id : 0) !== 0
}

function updateDefaultValue(compareExpression: string | undefined, compareValues: string[] | undefined) {
    if (!selectedRule.value) return        
    if (compareValues && compareValues.length > 0)
        selectedRule.value.value = compareValues[0].toString()
    else
        selectedRule.value.value = compareExpression ?? ''
}

function openItemsChanged(e: ItemsChangedEventArgs) {
    rulesAreOpen.value = e.openItemsCount > 0
}

function addNewDefaultRule(): DefaultRule {
    return {
        id: null,
        fieldId: field.value.id,
        fieldName: field.value.name,
        description: '',
        notes: '',
        value: '',
        preventChange: false,
        replaceExistingData: false, //can we rename this to indicate imported data
        conditions: {
            activationDate: null,
            expirationDate: null,
            packageTypes: [] as string[],
            ruleIds: [] as string[],
            criteriaItems: [] as Criteria[]
        } as Condition,
        audit: {} as AuditInfo
    } as DefaultRule
}
</script>
<style lang="scss" scoped>
.form-group.custom-checkbox {
    margin-left: 0;
}

.readonlyPermissionContainer {
    margin-top: 15px;

    .form-group {
        padding-left: 0;
    }
}

.field-value {
    width: 100%;
    padding: 0.25rem 0.125rem !important;
    min-height: 2.25rem !important;
}

</style>