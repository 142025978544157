<template>
    <div :id="`${id}-group`" :class="`field ${size} form-group`">
        <div class="label-container" v-if="label">
            <label :for="id" v-if="label">{{ label }}</label>
            <audit-check-icon :id="`${id}-audit-check`" :auditChecks="auditChecks" />
            <span v-if="jsonPathLabel" class="json-path-label">{{jsonPathLabel}}</span>
            <protected-edit-icon :icon="protectedEditIconString" :showProtectedEdit="showProtectedEdit" :allowProtectedEdit="allowProtectedEdit" @click="turnOffProtectedDisabled" />
        </div>
        <div v-else>
            <audit-check-icon :id="`${id}-audit-check`" :auditChecks="auditChecks" />
            <span v-if="jsonPathLabel" class="json-path-label">{{jsonPathLabel}}</span>
            <protected-edit-icon :icon="protectedEditIconString" :showProtectedEdit="showProtectedEdit" :allowProtectedEdit="allowProtectedEdit" @click="turnOffProtectedDisabled" />
        </div>
        <fg-defaultable :currentValue="value" :defaultValue="defaultValue" :isDefaulted="isDefaulted" @change="onDefaultValueChange" @defaultChange="onDefaultChange" :disabled="!showDefaultFieldEdit" />
        <div :jsonpath="jsonPathValue" class="custom-control custom-radio">            
            <div v-for="(option, index) in options" :key="index">
                <input
                    type="radio" 
                    :id="`${id + option.value}`" 
                    class="custom-control-input"
                    :text="option.text"
                    :value="option.value"   
                    :disabled="isDisabled"
                    v-model="vm"
                    :name="name"
                    @input="$emit('input', option.value)"
                    @change="$emit('change', vm)" 
                />
                <label class="custom-control-label" :for="`${id + option.value}`">{{option.text}}</label>
            </div>
        </div> 
    </div>
</template>

<!-- To Do - Complete the To Do's in input-select.vue first in order to accommodate passing in a reference source and type
    here as well. Then, make the necessary changes to copy input-select's functionality for reference source/type so we can pass those
    instead of requiring a MultiSelectItem[] -->
<script setup lang="ts">
import { defineProps, defineEmits, PropType, toRef } from 'vue'
import { useFormGroupBase, baseProps } from './form-group-base-composable'
import { MultiSelectItem } from './multi-select-item'

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'input', value: boolean): void 
    (e: 'change', event: any): void
}>()

const props = defineProps({
    id: { type: String, required: true },
    // TODO: Add the following type to value, and fix all vue warnings it causes. These warnings are caused by initialization issues.
    // "type: [Object,String]"
    value: { required: true },
    options: { type: Array as PropType<Array<MultiSelectItem>>, required: true },
    name: { type: String, required: true },
    label: { type: String },
    size: { type: String as PropType<string|null> },
    ...baseProps
})

const vm = toRef(props, 'value')
//#endregion

//#region INITIALIZE
const { jsonPathLabel, jsonPathValue, protectedEditIconString, showProtectedEdit, allowProtectedEdit, auditChecks, isDisabled, defaultValue, isDefaulted, showDefaultFieldEdit,
    onDefaultChange, onDefaultValueChange, turnOffProtectedDisabled } = useFormGroupBase(props, emit)
//#endregion
</script>