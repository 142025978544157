<template>
    <b-modal
        v-model="openModal"
        @ok="addDisclosure"
        @show="buildCopyOptions"
        ok-title="Add Disclosure"
        :scrollable="false"
        :ok-disabled="!fieldsAreComplete || addingDisclosure"
        :cancel-disabled="addingDisclosure"
    >
        <template v-slot:modal-title>Add Disclosure</template>
        <template v-slot:default>
            <blurb>
                <template v-slot:longMessage>
                    {{ blurbText }}
                </template>
            </blurb>
            <loading-overlay :show="addingDisclosure" text="Adding disclosure...">
                <div class="field-section">
                    <fg-checkbox 
                        id="new-disclosure-projected-indicator" 
                        size="m" 
                        v-model="newDisclosure.isProjected" 
                        label="Add as a projected disclosure"
                    />
                    <fg-select
                        id="disclosure-type"
                        label="Disclosure Type"
                        referenceType="trid-disclosure-types"
                        :showEmptyOption="false"
                        v-model="newDisclosure.type"
                        placeholder="Select a disclosure type"
                    />
                    <fg-date
                        id="disclosure-issued-date"
                        v-model="newDisclosure.issuedDate"
                        label="Issued Date"
                    />
                    <fg-select
                        id="copy-option"
                        label="Copy From:"
                        :source="copyOptions"
                        :showEmptyOption="false"
                        size="l"
                        v-model="newDisclosure.copyType"
                        placeholder="Select a disclosure type"
                    />
                </div> 
            </loading-overlay>
        </template>
    </b-modal>
</template>

<script setup lang="ts">
import { NewTridDisclosureRequest, TridDisclosure, TridDisclosureCopyType, TridDisclosureType } from "@/common/models"
import { computed, ref, defineEmits, defineProps } from "vue"
import { MultiSelectItem } from "@/common/components/multi-select-item"
import { useRoute, useRouter } from "vue-router/composables"
import { tridDisclosureService } from "@/common/services/trid-disclosure-service"
import { useLoanStore } from "@/common/store"

//#region DEFINE VARIABLES
const emit = defineEmits<{ (e: "input", value: boolean): void }>()
const route = useRoute()
const router = useRouter()

const props = defineProps({
    id: { type: String },
    value: {type: Boolean, required: true },
})
const newDisclosureDetails: NewTridDisclosureRequest = {
    type: TridDisclosureType.LoanEstimate,
    issuedDate : null,
    copyType: TridDisclosureCopyType.Current,
    indexToCopyFrom: null,
    // default regcheck options
    loanRequestOptions: {
        includeCompliance: true,
        includeCd: false,
        includeHoepaCheck: false
    },
    isProjected: false
}

const newDisclosure = ref<NewTridDisclosureRequest>({...newDisclosureDetails })
const copyOptions = ref<MultiSelectItem[]>([])
const addingDisclosure = ref(false)
const store = useLoanStore()
//#endregion

//#region COMPUTED
const openModal = computed({
    get() {
        return props.value
    },
    set(value: boolean) {
        emit("input", value)
    }
})

const fieldsAreComplete = computed(() => !!newDisclosure.value.type && !!newDisclosure.value.issuedDate)
const isMultiDisclosureMode = computed(() => store.isMultiDisclosureMode)
const endStatment = "other test suites will continue to use the current loan data and current fees under Loan Details."
const nonMultiMessage = `By adding a disclosure, this loan will be converted to multiple disclosure mode used in the expanded TRID - Multiple Disclosure test suite. All ${endStatment}`
const multiMessage = `The TRID - Multiple Disclosure test suite will utilize disclosures under Disclosure History for tolerance and timing testing, all ${endStatment}`
const blurbText = computed(() => isMultiDisclosureMode.value ? multiMessage : nonMultiMessage)
//#endregion

function buildCopyOptions() {
    const loanTridDisclosuresToCopyFrom = store.tridDisclosures
    const loanTridDisclosureOptions = loanTridDisclosuresToCopyFrom.map((dis: TridDisclosure, index: number) => {
        return {
            text: tridDisclosureService.getDisclosureTitle(dis), 
            value: index 
        }
    })

    copyOptions.value = [        
        {
            text: "Current Loan Data and Fees",
            value: "Current"
        },
        ...loanTridDisclosureOptions 
    ]
}

async function addDisclosure() {
    await store.addTridDisclosure(newDisclosure.value)
    const disclosureAddedIndex = store.tridDisclosures.findLastIndex((i: TridDisclosure) => i.type === newDisclosure.value.type && disclosureDatesAreEquivalent(i, newDisclosure.value))

    // either find failed or api call failed
    if (disclosureAddedIndex === -1) {
        router.push({ name: 'regcheck-property', params: { id: store.loanRecordId }})
    } else  {
        const refreshDisclosure = route.name === 'regcheck-trid-disclosure' && disclosureAddedIndex === Number(route.params.index)
        const location = tridDisclosureService.buildRawLocationForTridDisclosures(
            store.loanRecordId,
            disclosureAddedIndex,
            refreshDisclosure,
            'addingDisclosure'
        )
        router.push(location)
    }

    newDisclosure.value = {...newDisclosureDetails } // reset to baseline
}

function disclosureDatesAreEquivalent(disclosure: TridDisclosure, request: NewTridDisclosureRequest) {
    const disclosureDate = new Date(disclosure.issuedDate)
    const reqDate = request.issuedDate
    // using utc methods due to how the server sends the date back
    return disclosureDate.getUTCDate() === reqDate?.getUTCDate() && 
        disclosureDate.getUTCMonth() === reqDate?.getUTCMonth() &&
        disclosureDate.getUTCFullYear() === reqDate?.getUTCFullYear()
}
</script>

<style lang="scss" scoped>
.blurb {
    margin-bottom: 0.5rem;
}
</style>