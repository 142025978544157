export const regCheckSystemRoutes = [
    {
        path: 'settings/regcheck/va-state-fee-deviations',
        name: 'va-state-fee-deviations',
        component: () => import('@/regcheck/views/system/va-state-fee-deviations.vue'),
        meta: {
            title: 'VA State Deviations',
            readPermission: 'system-admin',
            writePermission: 'system-admin'
        }
    },
]