export const regCheckLoanMainRoute = [
    {
        path: '',
        name: 'loan-grid',
        component: () => import('@/regcheck/views/loans/regcheck-grid.vue'),
        meta: {
            title: 'All Loans',
            readPermission: 'loans:read',
            writePermission: 'loans:write',
            tabView: true
        }
    }
]

export const regCheckLoanRoutes = [
    {  
        path: '/regcheck-loans/:id',
        component: () => import('@/regcheck/views/loans/index.vue'),
        meta:{
            tabView: false
        },
        children: [
            {
                path: 'property',
                name: 'regcheck-loan-property',
                component: () => import('@/regcheck/views/loans/regcheck-property.vue'),
                meta: {
                    title: `Property`,
                    itemInfo: true,
                    readPermission: 'loans:read',
                    writePermission: 'loans:write'
                }
            },
            {
                path: 'terms',
                name: 'regcheck-loan-data',
                component: () => import('@/regcheck/views/loans/regcheck-loan-data.vue'),
                meta: {
                    title: `Loan Data`,
                    itemInfo: true,
                    readPermission: 'loans:read',
                    writePermission: 'loans:write'
                }
            },
            {
                path: 'fees',
                name: 'regcheck-fees',
                component: () => import('@/regcheck/views/loans/regcheck-fees.vue'),
                meta: {
                    title: `Fees`,
                    itemInfo: true,
                    readPermission: 'loans:read',
                    writePermission: 'loans:write'
                }
            },
            {
                path: 'dates',
                name: 'regcheck-loan-dates',
                component: () => import('@/regcheck/views/loans/regcheck-dates.vue'),
                meta: {
                    title: `Disclosure Dates`,
                    itemInfo: true,
                    readPermission: 'loans:read',
                    writePermission: 'loans:write'
                }
            },
            {
                path: 'refinance',
                name: 'regcheck-loan-refinance',
                component: () => import('@/regcheck/views/loans/regcheck-refinance.vue'),
                meta: {
                    title: `Refinance Terms`,
                    itemInfo: true,
                    readPermission: 'loans:read',
                    writePermission: 'loans:write'
                }
            },
            {
                path: 'borrowers',
                name: 'regcheck-loan-borrowers',
                component: () => import('@/regcheck/views/loans/regcheck-borrowers.vue'),
                meta: {
                    title: `Borrowers`,
                    itemInfo: true,
                    readPermission: 'loans:read',
                    writePermission: 'loans:write'
                }
            },
            {
                path: 'lender',
                name: 'regcheck-loan-lender',
                component: () => import('@/regcheck/views/loans/regcheck-lender.vue'),
                meta: {
                    title: `Lender/Broker`,
                    itemInfo: true,
                    readPermission: 'loans:read',
                    writePermission: 'loans:write'
                }
            },
            {
                path: 'loan-calculations',
                name: 'regcheck-loan-calculations',
                component: () => import('@/regcheck/views/loans/regcheck-loan-calculations.vue'),
                meta: {
                    title: 'Loan Calculations',
                    itemInfo: true,
                    readPermission: 'loans:read',
                    writePermission: 'loans:write'
                }
            },
            {
                path: 'amortization-schedules',
                name: 'regcheck-amortization-schedules',
                component: () => import('@/regcheck/views/loans/regcheck-amortization-schedules.vue'),
                meta: {
                    title: 'Amortization Schedules',
                    itemInfo: true,
                    readPermission: 'loans:read',
                    writePermission: 'loans:write'
                }
            },
            {
                path: 'results',
                name: 'regcheck-loan-results',
                component: () => import('@/regcheck/views/loans/regcheck-preview-results.vue'),
                meta: {
                    title: `Select Test Suites`,
                    itemInfo: true,
                    readPermission: 'loans:read',
                    writePermission: 'loans:write'
                }
            },
            {
                path: 'disclosures/:index',
                name: 'regcheck-trid-disclosure',
                component: () => import('@/regcheck/views/loans/regcheck-trid-disclosure.vue'),
                meta: {
                    title: `Disclosure History`,
                    itemInfo: true,
                    readPermission: 'loans:read',
                    writePermission: 'loans:write'
                }
            },
            {
                path: 'mcd',
                name: 'mcd',
                component: () => import('@/regcheck/views/loans/regcheck-mcd.vue'),
                meta: {
                    title: `MCD`,
                    itemInfo: true,
                    readPermission: 'loans:read',
                    writePermission: 'loans:write'
                }
            }
        ]
    }
]