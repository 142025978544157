import moment from 'moment'
import { useCurrentUserStore } from '@/common/store'

export function dateFilter(value: Date | undefined, format?: string): string {
    if (!value)
        return ''

    const store = useCurrentUserStore()
    const userDateFormat = store.currentUserProfile?.preferences?.dateFormat || 'MMM D, YYYY'
    return moment.utc(value).format(format || userDateFormat)
}
