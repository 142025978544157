import { PropType, computed, ref, watch } from 'vue'
import { ReadonlyPermissions } from '../models'
import { defaultValueService } from '@/propel/services'
import { userPermissionValidatorService } from '../services'
import { useValidation, useAuditChecks } from '@/common/composables/audit-check-composable'
import { useLoanStore } from '@/common/store'

//TODO: make this a true composable, break this up into separate composables for each piece of functionality (protected, audit checks, errors, etc)
export function useFormGroupBase(props, emit) {
    //#region DEFINE VARIABLES
    const store = useLoanStore()
    const protectedDisabled = ref(false)
    const defaultDisabled = ref(false)
    
    //#region COMPUTED
    const isDisabled = computed(() => {
        if (props.disabled)
            return props.disabled
        else if (hasDefault.value)
            return defaultDisabled.value
        else
            return protectedDisabled.value
    })

    const isProtected = computed(() => {
        if (props.protected)
            return true
        else 
            return defaultValueService.isInputProtectedFromFieldDefault(defaultedJsonPaths.value, fieldPath.value)
    })
    
    const defaultValue = computed(() => defaultValueService.getDefaultValue(defaultedJsonPaths.value, fieldPath.value))
    const defaultedFieldId = computed(() => defaultValueService.getDefaultFieldId(defaultedJsonPaths.value, fieldPath.value))
    const hasDefault = computed(() => defaultValue.value)
    const defaultedJsonPaths = computed(() => store.defaultedFields)
    const fieldPath = computed(() => props.jsonProperty ? props.jsonPath + formatJsonProperty() : props.jsonPath)
    const fieldIsProtectedBySystemRuleAndUserCanEdit = computed(() => !hasDefault.value && isProtected.value && protectedDisabled.value)
    const fieldIsProtectedByDefaultRuleAndUserCannotEdit = computed(() => hasDefault.value && isProtected.value && !userPermissionValidatorService.canUpdateReadonlyField(defaultedFieldId.value))
    const allowProtectedEdit = computed(() => showProtectedEdit.value && (userPermissionValidatorService.canUpdateReadonlyLoanData(props.protectedBy) || userPermissionValidatorService.canUpdateReadonlyField(defaultedFieldId.value)))
    const showProtectedEdit = computed(() => {
        //protected fields are only editable by users with that permission
        //default fields have additional logic, so the protected icon should be disabled if there is a field default available
        return fieldIsProtectedBySystemRuleAndUserCanEdit.value || fieldIsProtectedByDefaultRuleAndUserCannotEdit.value
    })
    const showDefaultFieldEdit = computed(() => {
        //default fields can be edited if the field is not protected or the user has permission to edit protected fields
        return !isProtected.value || userPermissionValidatorService.canUpdateReadonlyField(defaultedFieldId.value)
    })

    const isDefaulted = computed(() =>{
        if (!hasDefault.value) return false

        const path = defaultValueService.getOverriddenDefaultPath(fieldPath.value)
        const overridden = store.overriddenDefaultPathsGetter[`${path}`]
        return !overridden
    })

    const { auditChecks, auditCheckLevelClass, jsonPathValue, jsonPathLabel, formatJsonProperty } = useAuditChecks(props)

    const protectedEditIconString = computed(() => {
        return props.protectedBy == ReadonlyPermissions.None
            ? "Edit"
            : "Locked"
    })
    //#endregion

    //#region INITIALIZE
    initialize()

    function initialize() {
        protectedDisabled.value = isProtected.value
        defaultDisabled.value = !showDefaultFieldEdit.value

        if (hasDefault.value)
            initializeOverriddenDefault()
    }
    //#endregion

    //#region WATCH
    watch(() => store.isProtectedDisabled, (value: boolean) => storeProtectedDisabledChanged(value), { immediate: true})
    watch(() => isProtected.value, () => protectedDisabled.value = isProtected.value)
    watch(() => props.resetProtected, (value: boolean) => resetProtectedDisabled(value))
    //#endregion

    function storeProtectedDisabledChanged(value: boolean) {
        if (value && isProtected.value) {
            protectedDisabled.value = true
        }
    }
    
    function resetProtectedDisabled(value: boolean) {
        if(value)
            protectedDisabled.value = true
    }
    
    function turnOffProtectedDisabled() {
        protectedDisabled.value = false
        emit('unlocked')//must be defined in the calling components' setup
    }
    
    const { getErrorMessage } = useValidation(props)
    
    function initializeOverriddenDefault() {
        const overriddenDefault = store.overriddenDefaultPathsGetter
        const path = defaultValueService.getOverriddenDefaultPath(fieldPath.value)
    
        // If the lookup value does not exist in the dictionary, then no defaults have been applied so we default to the field being overridden
        if (overriddenDefault[`${path}`] === undefined)
            store.updateOverriddenDefaults({ path, isOverridden: true })
    
        const override = store.overriddenDefaultPathsGetter[`${path}`]
        defaultDisabled.value = !override || !showDefaultFieldEdit.value
    }
    
    function onDefaultValueChange(newValue: any) {
        if (!props.disabled)
            emit('input', newValue) //must be defined in the calling components' setup
    }
    
    function onDefaultChange(isDefault: boolean) {
        defaultDisabled.value = isDefault || !showDefaultFieldEdit.value
    
        const path = defaultValueService.getOverriddenDefaultPath(fieldPath.value)
        const isOverridden = !isDefault
        store.updateOverriddenDefaults({ path, isOverridden })
    }

    return {
        isDisabled,
        defaultValue,
        allowProtectedEdit,
        showProtectedEdit,
        showDefaultFieldEdit,
        isDefaulted,
        jsonPathLabel,
        jsonPathValue,
        auditChecks,
        auditCheckLevelClass,
        protectedEditIconString,
        protectedDisabled,
        turnOffProtectedDisabled,
        getErrorMessage,
        onDefaultValueChange,
        onDefaultChange,
    }
}
 // TODO: Some places pass in an object for validationRules (see placement-rule-list). Update its type to accommodate this
export const baseProps = {
    jsonPath: { type: String, default: '' }, // Used for audit checks and input protection 
    jsonProperty: { type: String, default: null }, // Used for audit checks on nested properties
    validationRules: { type: String, default: '' }, // Audit Check Related Props
    validationFieldName: { type: String, default: '' },
    immediateValidation: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    protected: { type: Boolean, default: false },
    protectedBy: { type: String as PropType<ReadonlyPermissions>, default: '' },
    resetProtected: { type: Boolean, default: false }
}
