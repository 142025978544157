import Cookies from 'js-cookie'
import store from "@/common/store/store"
import { messageService, systemService } from './index'
import IdleVue from 'idle-vue'
import Vue from 'vue'
import { SystemConfig } from '@/common/models'
import { config } from '@/config'
import { useCurrentUserStore } from '../store'

interface SessionCookie {
    lastActiveTime: number
    timeoutMinutes: number
}

class SessionService {

    private readonly cookieName = 'session'

    timeoutMinutes = 0

    async updateUserSession() {
        const userStore = useCurrentUserStore()
        await userStore.getCurrentUser()
        await this.initializeTimeout()
    }

    initializeTimeout(): Promise<void> {
        return systemService.getSystemConfig()
            .then((data: SystemConfig) => {
                this.timeoutMinutes = data.security?.sessionTimeout || 0
                const idleTimeoutSeconds = (this.timeoutMinutes * 60) - config.app.timeoutWarning
                //convert to milliseconds
                const idleViewTimeout = config.app.useInactivityTimer ? idleTimeoutSeconds * 1000 : 0
                if (idleViewTimeout > 0) {
                    Vue.use(IdleVue, {
                        store,
                        idleTime: idleViewTimeout,
                        startAtIdle: false
                    })
                }
            })
            .catch(error => {
                console.error(error)
                messageService.showSystemError('Propel encountered an unexpected error while retrieving system data.  Refresh the page to try again, or contact support for assistance.', 'System Error')
            })
    }

    /**
     * Stores the user's last known active time
     * @param lastActiveTime: the time the user was last active (defaults to Now)
    */
    setLastActiveTime(lastActiveTime = new Date()) {
        const cookie: SessionCookie = {
            lastActiveTime: lastActiveTime.getTime(),
            timeoutMinutes: this.timeoutMinutes
        }
        Cookies.set(this.cookieName, JSON.stringify(cookie))
    }

    clearLastActiveTime() {
        Cookies.remove(this.cookieName)
    }

    hasLastActiveTime() {
        const value = Cookies.get(this.cookieName)
        return value !== undefined
    }

    /**
     * Determines whether the user has an active session by checking whether a last active time has been stored
     * @returns true if there is no last active time or if the last active time occurred within a valid session timespan
     */
    hasActiveSession(): boolean {
        const cookie = Cookies.get(this.cookieName)        
        if (cookie) {
            const session = JSON.parse(cookie) as SessionCookie
            if(session && session.lastActiveTime){
                const currentTimeout = Number(session.lastActiveTime) + (session.timeoutMinutes * 60000)
                const now = new Date().getTime()
                if (now >= currentTimeout) {
                    return false
                }
            }
        }
        return true
    }
}

export const sessionService = new SessionService()
