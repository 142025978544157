import { Address, LienPosition, LoanPurpose, MortgageType, SettlementAgent, TitleCompany, TransactionDetailType } from ".";

export interface OrderSummary {
    orderNumber: string
    fileId: string
    created: OrderCreationDetails
    clientCode: string
    clientName: string
    clientBilledDirectly: boolean | null
    partnerCode: string
    loanNumber: string
    lenderCaseNumber: string
    packageType: string
    packageName: string
    isDraft: boolean
    isRedraw: boolean
    documents: OrderedDocuments
    programCode: string
    programName: string
    mortgageType: MortgageType | null
    lienPosition: LienPosition | null
    purpose: LoanPurpose | null
    transactionDetail: TransactionDetailType | null
    applicationDate: Date | null
    closingDate: Date | null
    propertyState: string //seems redundant
    propertyUsageType: PropertyUsageType | null
    lockExpirationDate: Date | null
    propertyAddress: Address
    settlementAgent: SettlementAgent
    titleCompany: TitleCompany
    lenderProfileCode: string
    txDocPrepFee: number | null
    warrantyDeedFee: number | null
    hasTrusts: boolean
    loanAmount: number | null
    borrowerFirstName: string
    borrowerLastName: string
    sellerFirstName: string
    sellerLastName: string
    investorCode: string
    amortizationType: AmortizationType
    funderName: string
}

export interface OrderCreationDetails {
    user : string
    timestamp : Date
}

export interface OrderedDocuments {
    warrantyDeedInvoice: boolean
}

export enum PropertyUsageType {
    PrimaryResidence,
    SecondHome,
    Investment
}

//TODO: It might be worth moving AmortizationType to loans enums, and use enums where we are using strings for these values
export enum AmortizationType {
    AdjustableRate = "AdjustableRate",
    Fixed = "Fixed",
    GraduatedPayment = "GraduatedPayment",
}