<template>
    <validation-provider :name="label" :rules="validationRules" :immediate="immediateValidation" v-slot="{ errors }">
        <slot name="input" v-if="inverseLayout" v-bind:errors="errors"></slot>
        <slot name="label">
            <label :class="labelClass" :for="id" v-if="label">
                {{ label }}
            </label>
            <span v-if="jsonPathLabel" class="json-path-label">{{ jsonPathLabel }}</span>
        </slot>

        <custom-icon icon="Help" :id="`${id}-help`" v-if="helpText" :title="helpText" />

        <template v-if="isRadio">
            <span class="base-input-icons">
                <custom-icon
                    :icon="vm.isOverridden ? 'Reset' : 'Edit'"
                    :title="vm.isOverridden ? 'Reset to default value' : 'Override default value'"
                    @click="toggleOverride()"
                />
                <custom-icon icon="Error" :id="`${id}-error`" v-if="getErrorMessage(errors[0])" :title="getErrorMessage(errors[0])" />
                <audit-check-icon :id="`${id}-audit-check`" :auditChecks="auditChecks" />
            </span>

            <slot name="input" v-bind:errors="errors"></slot>
        </template>
        <div v-else-if="!inverseLayout" class="base-input-container">
            <slot name="input" v-bind:errors="errors"></slot>
            <span class="base-input-icons">
                <custom-icon
                    :icon="vm.isOverridden ? 'Reset' : 'Edit'"
                    :title="vm.isOverridden ? 'Reset to default value' : 'Override default value'"
                    @click="toggleOverride()"
                />
                <custom-icon icon="Error" :id="`${id}-error`" v-if="getErrorMessage(errors[0])" :title="getErrorMessage(errors[0])" />
                <audit-check-icon :id="`${id}-audit-check`" :auditChecks="auditChecks" />
            </span>
        </div>
        <span v-else>
            <span class="base-input-icons">
                <custom-icon
                    :icon="vm.isOverridden ? 'Reset' : 'Edit'"
                    :title="vm.isOverridden ? 'Reset to default value' : 'Override default value'"
                    @click="toggleOverride()"
                />
                <custom-icon icon="Error" :id="`${id}-error`" v-if="getErrorMessage(errors[0])" :title="getErrorMessage(errors[0])" />
                <audit-check-icon :id="`${id}-audit-check`" :auditChecks="auditChecks" />
            </span>
        </span>
    </validation-provider>
</template>

<script setup lang="ts">
import { defineProps, PropType, toRef, defineEmits } from 'vue'
import { useValidation, validationProps } from '@/common/composables/audit-check-composable'
import { Overridable } from '../models'
import { AuditCheckResult } from '@/propel/models'

//#region DEFINE VARIABLES
const emit = defineEmits<{ (e: 'undoOverride', id: string): void }>()

const props = defineProps({
    value: { type: Object as PropType<Overridable<any>>, required: true },
    id: { type: String, required: true },
    label: { type: String },
    jsonPathLabel: { type: String },
    labelClass: { type: String },
    helpText: { type: String },
    inverseLayout: { type: Boolean },
    isRadio: { type: Boolean },
    auditChecks: { type: Array as PropType<Array<AuditCheckResult>>, default: () => [] },
    ...validationProps
})

const vm = toRef(props, 'value')
//#endregion

//#region INITIALIZE
const { getErrorMessage } = useValidation(props)
//#endregion

function toggleOverride() {
    vm.value.isOverridden = !vm.value.isOverridden
    if (!vm.value.isOverridden) emit('undoOverride', props.id)
}
</script>