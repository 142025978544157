export enum InputType {
    Text = 'text',
    Number = 'number',
    Hours = 'hours',
    Days = 'days',
    Months = 'months',
    Years = 'years',
    Currency = 'currency',
    Percentage = 'percentage',
    Decimal = 'decimal',
    TextArea = 'textArea'
}


export interface InputProperties{
    inputType: InputType
    append: string
    prepend: string
    min: number
    max: number
    disabled: boolean
    placeholder: string
    value: any
}