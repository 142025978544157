import { TreeMenuDataItem } from "./tree-menu/tree-menu-data-item";

export interface AppArea {
    name: string
    icon: string
    areaPages: AreaPage[]
}

export enum AppAreaName {
    LoanData = 'Loan Data',
    Admin = 'Admin',
    FormSetup = 'Form Setup',
    System = 'System'
}

export interface AreaPage extends TreeMenuDataItem {
    singleEntityDisplay?: boolean //indicates if an area page is to be displayed for a single lender or partner 
    singleEntityDisplayType?: SingleEntityDisplayType
}

export enum SingleEntityDisplayType {
    Lender = 'Lender',
    Partner = 'Partner'
}