import { defineStore } from 'pinia'
import { ref } from 'vue'
import { TreeMenuDataItem } from '@/common/components/navigation/tree-menu/tree-menu-data-item'

export const useSideNavStore = defineStore('sideNav', () => {
    const sideNavMenuItems = ref<TreeMenuDataItem[]>([])
    const selectedSideNavMenuItem = ref<TreeMenuDataItem | null>(null)

    function setSideNavMenuItems(menuItems: TreeMenuDataItem[]) {
       sideNavMenuItems.value = [...menuItems]
    }

    return { sideNavMenuItems, selectedSideNavMenuItem, setSideNavMenuItems }
})