<template>
    <admin-page :isSaveDisabled="disableSave" :onSave="save" :saveErrorMessage="saveErrorMessage" :onGetBreadcrumbs="breadcrumbs">
        <view-section id="clientDocumentsSection" :readOnly="pageIsReadOnly">         
            <h1>Display</h1>
            <div class="field-section">
                <fg-radio-button
                    size="l"
                    :options="helocDisplayOptions"
                    v-model="client.heloc.screenAndFieldSetup"
                    id="screen-and-field-setup"
                    name="screen-and-field-setup-radio-buttons"
                    label="HELOC screen and field setup:"
                />
            </div>
            <h1>HELOC Terms</h1>
            <div class="field-section">
                <fg-text
                    inputType="currency"
                    v-model="client.heloc.minimumAdvance"
                    id="heloc-minimumAdvance"
                    label="Minimum Advance"
                />
                <fg-text
                    inputType="currency"
                    v-model="client.heloc.minimumDrawPeriodPayment"
                    id="heloc-minimumDrawPeriodPayment"
                    label="Minimum Draw Period Payment"
                />
                <fg-text
                    inputType="currency"
                    v-model="client.heloc.minimumBalance"
                    id="heloc-minimumBalance"
                    label="Minimum Balance"
                />
            </div>
            <div class="field-section" v-if="screenAndFieldSetupIsStandardMode">
                <div class="field checkbox-group form-group">
                    <label>How to request a loan</label>
                    <fg-checkbox
                        label="Write a check for minimum advanced listed using special checks issued for that purpose"
                        id="writeSpecialChecks"
                        v-model="client.heloc.standardTerms.loanRequest.writeSpecialChecks"
                    />
                    <fg-checkbox
                        label="Authorize a payment to 3rd person or account"
                        id="authorizePayment"
                        v-model="client.heloc.standardTerms.loanRequest.authorizePayment"
                    />
                    <fg-checkbox
                        label="Request a withdrawal in person or by phone"
                        size="m"
                        id="requestWithdrawal"
                        v-model="client.heloc.standardTerms.loanRequest.requestWithdrawal"
                    />
                    <fg-checkbox
                        label="Use the credit cards"
                        size="m"
                        id="useCreditCard"
                        v-model="client.heloc.standardTerms.loanRequest.useCreditCard"
                    />
                </div>
            </div>
            <div class="field-section">
                <fg-text
                    v-if="usingCreditCardsForLoanRequest"
                    inputType="currency"
                    v-model="client.heloc.standardTerms.creditCardLiability"
                    id="heloc-creditCardLiability"
                    label="Credit Card Liability"
                />
            </div>
            <template v-if="screenAndFieldSetupIsStandardMode">
                <div class="field-section">
                    <fg-radio-button
                        :options="drawPeriodMinimumPaymentOptions"
                        v-model="client.heloc.standardTerms.drawPeriodMinimumPayment"
                        id="draw-period-minimum-payment"
                        name="draw-period-minimum-payment-radio-buttons"
                        label="During the draw period, your minimum payment:"
                    />
                </div>
                <div class="field-section">    
                    <fg-radio-button
                        :options="helocPaymentApplicationOptions"
                        v-model="client.heloc.standardTerms.howPaymentsAreApplied"
                        id="how-payments-are-applied"
                        name="how-payments-are-applied-radio-buttons"
                        label="Payments will be applied:"
                    />
                </div>
            </template>

            <heloc-state-default-list type="Minimum Rate" :itemList.sync="minimumRates" v-if="!screenAndFieldSetupIsStandardMode">
                <template v-slot:viewItems="slotParams">
                    <div class="s wrap">{{ slotParams.item.rate || 0}}%</div>
                </template>
                <template v-slot:editItems="slotParams">
                    <fg-text :id="`minimumRate${slotParams.index}`" inputType="percentage" v-model="slotParams.item.rate" label="Minimum Rate" />
                </template>
            </heloc-state-default-list>

            <heloc-state-default-list type="Maximum Rate" :itemList.sync="maximumRates">
                <template v-slot:viewItems="slotParams">
                    <div class="s wrap">{{ slotParams.item.rate || 0}}%</div>
                </template>
                <template v-slot:editItems="slotParams">
                    <fg-text :id="`maximumRate${slotParams.index}`" inputType="percentage" v-model="slotParams.item.rate" label="Maximum Rate" />
                </template>
            </heloc-state-default-list>

            <heloc-state-default-list type="Termination Term" :itemList.sync="terminationTerms" v-if="!screenAndFieldSetupIsStandardMode">
                <template v-slot:viewItems="slotParams">
                    <div class="s wrap">{{ slotParams.item.term || 0}} months</div>
                </template>
                <template v-slot:editItems="slotParams">
                    <fg-text :id="`terminationTerm${slotParams.index}`" inputType="months" v-model="slotParams.item.term" label="Termination Term" />
                </template>
            </heloc-state-default-list>

            <h1>Calculations</h1>
            <div class="field-section">
                <fg-radio-button
                    size="l"
                    :options="calculationOptions"
                    v-model="client.heloc.maturityDateCalculation"
                    id="calculation-buttons"
                    name="calculation-radio-buttons"
                    label="Calculate the Maturity Date for HELOCs using:"
                />
            </div>

            <h1>Maintenance Fees</h1>
            <content-block-list
                id="maintenanceFees"
                :listItems.sync="maintenanceFees"
                addLabelText="Add Fee"
                :onAdd="addMaintenanceFee"
                copyable
                deletable
                draggable
                size="m"
            >
                <template v-slot:view="slotParams">
                    <div class="standard-columns">
                        <div class="s wrap">{{ slotParams.item.description }}</div>
                        <div class="s wrap">{{ currencyFilter(slotParams.item.amount) }}</div>
                            <div class="s wrap">
                            {{ stateFormatter(slotParams.item.states) }}
                            </div>
                    </div>
                </template>
                <template v-slot:edit="slotParams">
                    <div class="field-section">
                        <div class="field-columns">
                            <div class="column">
                                <maintenance-fee-auto-complete-input :maintenanceFee="slotParams.item" />
                                <fg-text id="maintenanceFeeAmount" inputType="currency" v-model="slotParams.item.amount" label="Amount" />
                            </div>
                            <div class="column">
                                <div class="form-group field m">
                                    <checkbox-select-list
                                        label="States"
                                        :selectedValues.sync="slotParams.item.states"
                                        :id="`statesSelect`"
                                        :source="getMaintenanceFeeStates(slotParams.item.states)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </content-block-list>
            <heloc-state-default-list type="Late Charge" :itemList.sync="lateCharges">
                <template v-slot:viewItems="slotParams">
                    <div class="m wrap">{{displayPercentageCharge(slotParams.item.percentage)}}{{ slotParams.item.gracePeriod || 0}}-Day Grace Period</div>
                    <div class="s wrap" v-if="!screenAndFieldSetupIsStandardMode">{{ slotParams.item.amountDescription}}</div>
                </template>
                <template v-slot:editItems="slotParams">
                    <fg-text :id="`gracePeriod${slotParams.index}`" inputType="days" v-model="slotParams.item.gracePeriod" label="Grace Period" />
                    <fg-textarea
                        v-if="!screenAndFieldSetupIsStandardMode"
                        :id="`amountDescription${slotParams.index}`"
                        size="l"
                        :rows="10"
                        label="The amount of the charge will be"
                        v-model="slotParams.item.amountDescription"
                    />
                    <fg-text
                        v-if="screenAndFieldSetupIsStandardMode"
                        inputType="percentage"
                        v-model="slotParams.item.percentage"
                        :id="`percentage-${slotParams.index}`"
                        label="Percentage:"
                    />
                </template>
            </heloc-state-default-list>
        </view-section>
        <template v-slot:summarySidebar>
            <admin-summary-sidebar :item="client">
                <p>Default HELOC Terms are applied to a HELOC loan when it is first created and can be changed per loan.</p>
                <p>The <strong>Maturity Date</strong> calculation setting is applied to all loan data and cannot be changed on a per-loan basis.</p>
                <p><strong>Maintenance Fees</strong> will be applied to HELOC loan data when a loan is created in Propel. They can be configured per-state and will appear on loans in the order configured here.  These can be changed per-loan.</p>
                <p>A HELOC's <strong>Minimum Rate, Maximum Rate, Termination Term,</strong> and <strong>Late Charge</strong> will be pulled from the state-specific list on this page and can be changed per-loan.</p>
            </admin-summary-sidebar>
        </template>
    </admin-page>
</template>

<script setup lang="ts">
import { Client, Heloc, HelocMaturityDateCalculationType, HelocRate, HelocScreenAndFieldSetupTypes, LateCharge, MaintenanceFee, 
         ReferenceType, TerminationTerm, StandardHelocTerms, HelocLoanRequest } from '@/common/models'
import { ReferenceSource } from '@/common/models'
import { referenceService, userPermissionValidatorService } from '@/common/services'
import { MultiSelectItem, SelectableItem } from '@/common/components/multi-select-item'
import HelocStateDefaultList from "@/common/views/admin/clients/helocs/heloc-state-default-list.vue"
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
import { Route } from 'vue-router'
import { BreadcrumbItem } from '@/common/components/navigation/breadcrumb-item'
import { currencyFilter } from '@/common/filters'
import { storeToRefs } from 'pinia'
import { useClientStore, useSideNavStore } from '@/common/store'

//#region DEFINE VARIABLES
const route = useRoute()
const { selectedSideNavMenuItem } = storeToRefs(useSideNavStore())
const store = useClientStore()

const client = ref<Client>({} as Client)
const lateCharges = ref([] as LateCharge[])
const minimumRates = ref([] as HelocRate[])
const maximumRates = ref([] as HelocRate[])
const terminationTerms = ref([] as TerminationTerm[])
const maintenanceFees = ref([] as MaintenanceFee[])
const localMaintenanceFeeStates = ref([] as SelectableItem[])
const helocDisplayOptions = ref([] as MultiSelectItem[])
const drawPeriodMinimumPaymentOptions = ref([] as MultiSelectItem[])
const helocPaymentApplicationOptions = ref([] as MultiSelectItem[])
const pageIsReadOnly = ref(false)
const calculationOptions =  [
    { value: HelocMaturityDateCalculationType.ClosingDate, text: 'Closing Date' },
    { value: HelocMaturityDateCalculationType.FirstPaymentDate, text: 'First Payment Date' }
] as MultiSelectItem[]
//#endregion

//#region WATCH
watch(() => route, (route: Route) => routeUpdated(route), { immediate: true})

//#endregion

//#region COMPUTED
const disableSave= computed(() => {
    return (lateCharges.value.some((lc) => listHasStatesError(lc.states, lateCharges.value)) || 
        minimumRates.value.some((mr) => listHasStatesError(mr.states, minimumRates.value)) ||
        maximumRates.value.some((mr) => listHasStatesError(mr.states, maximumRates.value)) ||
        terminationTerms.value.some((tt) => listHasStatesError(tt.states, terminationTerms.value)))
    }
)

const saveErrorMessage = computed(() => disableSave.value ? 'Only one value with no states is allowed per list' : '')
const screenAndFieldSetupIsStandardMode = computed(() => client.value.heloc.screenAndFieldSetup === HelocScreenAndFieldSetupTypes.Standard)
const usingCreditCardsForLoanRequest = computed(() => client.value.heloc.standardTerms.loanRequest.useCreditCard)
//#endregion

//#region INITIALIZE
initialize()

async function initialize() {
    initializeClient()
    initializeStates()

    helocDisplayOptions.value = (await referenceService.get(ReferenceType.helocScreenAndFieldSetupTypes, ReferenceSource.admin)).map(t => ({ text: t.displayValue, value: t.key }))
    drawPeriodMinimumPaymentOptions.value = (await referenceService.get(ReferenceType.drawPeriodMinimumPaymentOptions, ReferenceSource.admin)).map(t => ({ text: t.displayValue, value: t.key }))
    drawPeriodMinimumPaymentOptions.value.push({ text: 'No default', value: null } as MultiSelectItem)
    helocPaymentApplicationOptions.value = (await referenceService.get(ReferenceType.helocPaymentApplicationOptions, ReferenceSource.admin)).map(t => ({ text: t.displayValue, value: t.key }))
    helocPaymentApplicationOptions.value.push({ text: 'No default', value: null } as MultiSelectItem)
    // Default maturityDateCalculation to calculate using ClosingDate
    client.value.heloc.maturityDateCalculation = client.value.heloc.maturityDateCalculation ?? HelocMaturityDateCalculationType.ClosingDate
}

function initializeClient() {
    const initializedClient = store.initializedClient
    initializedClient.heloc ??= ({} as Heloc)
    initializedClient.heloc.screenAndFieldSetup ??= HelocScreenAndFieldSetupTypes.Standard
    initializedClient.heloc.standardTerms ??= ({} as StandardHelocTerms)
    initializedClient.heloc.standardTerms.loanRequest ??= ({} as HelocLoanRequest)
    initializedClient.heloc.standardTerms.drawPeriodMinimumPayment ??= null
    initializedClient.heloc.standardTerms.howPaymentsAreApplied ??= null
    maintenanceFees.value = initializedClient.heloc.maintenanceFees || ([] as MaintenanceFee[])
    lateCharges.value = initializedClient.heloc.lateCharges || ([] as LateCharge[])
    minimumRates.value = initializedClient.heloc.minimumRates || ([] as HelocRate[])
    maximumRates.value = initializedClient.heloc.maximumRates || ([] as HelocRate[])
    terminationTerms.value = initializedClient.heloc.terminationTerms || ([] as TerminationTerm[])

    client.value = initializedClient
}

async function initializeStates() {
    const states = await referenceService.getFullNameStates()

    localMaintenanceFeeStates.value = states.map((s) => ({ value: s.abbreviation, text: s.name, selected: false }))
}
//#endregion

function routeUpdated(route: Route) {
    const writePermission: string = route?.meta?.writePermission
    if (writePermission) {
        pageIsReadOnly.value = !userPermissionValidatorService.hasPermission([writePermission])
    } else {
        pageIsReadOnly.value = false
    }
}


function addMaintenanceFee(): MaintenanceFee {
    return { states: [] as string[] } as MaintenanceFee
}

// Returns true if there is more than one listItem (lateCharge, minimumRate, maximumRate, or terminationTerm) with an empty list of states. Only one listItem may have an empty list of states (i.e. "All (Other) States"
function listHasStatesError(listItemStates, listItems) {
    return listItems.filter((li) => li.states?.length == 0).length > 1 && listItemStates.length == 0
}

// Update the array to set selected values based off of the maintenanceFeeStates
function getMaintenanceFeeStates(maintenanceFeeStates: string[]): SelectableItem[]
{
    localMaintenanceFeeStates.value.map(s => s.selected = maintenanceFeeStates.includes(s.value))
    return localMaintenanceFeeStates.value
}

function stateFormatter(states: SelectableItem[]) {
    return states && states.length > 0 ? states.join(', ') : 'All States'
}

function displayPercentageCharge(percentage): string {
    return screenAndFieldSetupIsStandardMode.value ? `${(percentage || 0).toString()}% Charge, ` : ''
}

function breadcrumbs() {
    return [
        { text: 'Admin' },
        { text: 'Lenders', routeName: 'lender-grid' },
        { text: client.value.code, routeName: 'account-setup', routeParams: { code: client.value.code } },
        { text: selectedSideNavMenuItem.value?.name }
    ] as BreadcrumbItem[]
}

async function save() {
    await store.updateClientData({ 
        heloc: { 
            ...client.value.heloc, 
            lateCharges: lateCharges.value, 
            maintenanceFees: maintenanceFees.value, 
            minimumRates: minimumRates.value,
            maximumRates: maximumRates.value,
            terminationTerms: terminationTerms.value
        }
    } as Client)
}
</script>

<style lang="scss" scoped>
.field-columns .column {
    flex: 0 1;
}
</style>