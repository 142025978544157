<template>
    <b-modal :id="id" :title="title" v-model="internalValue" size="xl" centered hide-footer v-on="$listeners" @close="clearSource" :body-class="{'modal-loading': loading}">
        <loading-overlay :show="loading" :text="loadingText">
            <embed :src="_source" type="application/pdf" width="100%" height="100%" />
        </loading-overlay>
    </b-modal>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps } from "vue"

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e: "input", value: boolean)
    (e: "src", value: string | undefined)
}>()

const props = defineProps({
    id: {type: String },
    value: {type: Boolean },
    loading: {type: Boolean },
    src: {type: String },
    title: {type: String, default: "PDF Preview"},
    loadingText: {type: String, default: "Generating document"},
})
//#endregion

//#region COMPUTED
const internalValue = computed({
    get() {
        return props.value
    },
    set(value: boolean) {
        emit("input", value)
    }
})

const _source = computed({
    get() {
        return props.src
    },
    set(value: string | undefined) {
        emit("src", value)
    }
})
//#endregion

function clearSource() {
    _source.value = ""
}
</script>