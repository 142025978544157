export interface AccessTokenRequest {
    grant_type: GrantType
    client_id: string
    redirect_uri: string
    authorization_code: string
    refresh_token: string
}

export enum GrantType {
    Password = 'password',
    AuthorizationCode = 'authorization_code',
    RefreshToken = 'refresh_token'
}

export interface AccessTokenResponse {
    access_token: string
    id_token: string
    refresh_token: string
    scope: string
    token_type: string
    expires_in: number
    state: string
}