export interface FeeSections {
    originationCharges: string[]
    servicesYouCannotShopFor: string[]
    servicesBorrowerDidNotShopFor: string[]
    servicesYouCanShopFor: string[]
    servicesBorrowerDidShopFor: string[]
    taxesAndOtherGovernmentFees: string[]
    otherCosts: string[]
}

export enum FeeDisclosureSectionType {
    OriginationCharges = 'OriginationCharges',
    ServicesYouCannotShopFor = 'ServicesYouCannotShopFor',
    ServicesBorrowerDidNotShopFor = 'ServicesBorrowerDidNotShopFor',
    ServicesYouCanShopFor = 'ServicesYouCanShopFor',
    ServicesBorrowerDidShopFor = 'ServicesBorrowerDidShopFor',
    TaxesAndOtherGovernmentFees = 'TaxesAndOtherGovernmentFees',
    OtherCosts = 'OtherCosts'
}