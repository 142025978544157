<template>
    <confirmation-modal :id="id" @ok="ok" @cancel="cancel" ok-title="Delete" ok-variant="danger" v-model="internalValue">
        <template v-slot:shortMessage
            ><slot name="shortMessage">Are you sure you want to delete {{ itemText || 'this item' }}?</slot></template
        >
        <template v-slot:longMessage><slot name="longMessage">This operation cannot be undone.</slot></template>
    </confirmation-modal>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps } from 'vue'


//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:'ok')
    (e:'cancel')
    (e:'input', value: any)
}>()

const props = defineProps({
    id: { type: String},
    value: { type: Boolean},
    itemText: { type: String },
})

//#endregion

//#region COMPUTED
const internalValue = computed({
    get() {
        return props.value
    },
    set(value: any) {
        emit('input', value)
    }
})
//#endregion

function ok() {
    emit('ok')
}

function cancel() {
    emit('cancel')
}
</script>
