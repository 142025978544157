import { defineStore } from 'pinia'
import { ref } from 'vue'
import { User } from '../models'
import { usePageActionsStore } from './page-actions'
import { userService } from '@/common/services'

export const useUserEditStore = defineStore('userEdit', () => {
    const pageActions = usePageActionsStore()
    const user = ref<User | null>(null)

    async function getUser(id: string) {
        try {
            pageActions.setBusyPage(true)
            user.value = await userService.get(id)
        } finally {
            pageActions.setBusyPage(false)
        }
    }

    return { user, getUser }
})