import { Client, LosIntegrationSetting, ClientInterfaceDetails, DataMapping, Overridable } from '@/common/models'
import { adminDataService } from '@/common/services'
import { cloneDeep } from "lodash"

export interface ClientViewModel extends Client {
    partnerIntegrationLinks: string[]
}

class ClientViewModelConverter {
    convertToViewModel(model: Client) {
        const client = cloneDeep(model) as ClientViewModel || ({} as ClientViewModel)
        client.interfaceDetails ??= ({} as ClientInterfaceDetails)
        client.interfaceDetails.losIntegrationSettings ??= ({} as Overridable<LosIntegrationSetting>)
        client.interfaceDetails.integrationLinks ??= ([] as string[])
        client.partnerIntegrationLinks ??= ([] as string[])
        client.dataMapping ??= ({} as DataMapping)

        return client
    }

    async initializeIntegrations(client: ClientViewModel) {
        const partner = (client.partnerCode) ? await adminDataService.getPartner(client.partnerCode) : null
        client.partnerIntegrationLinks = partner?.interfaceDetail?.integrationLinks || ([] as string[])

        client.interfaceDetails.integrationLinks = client.interfaceDetails.integrationLinks.filter(l => !client.partnerIntegrationLinks.includes(l))
    }

    convertToModel(client: ClientViewModel): Client {
        return  Object ({
            ...client,
            partnerIntegrationLinks: undefined,
        }) as Client
    }
}

export const clientViewModelConverter = new ClientViewModelConverter()