
export interface Payoff {
    type: PayoffType
    liabilityType: LiabilityType | null
    closingAdjustmentType: ClosingAdjustmentType | null
    description: string
    sectionType: DisclosureSectionType
    subsectionType: DisclosureSubsectionType | null
    paidTo: string
    paidBy: string
    payoffAmount: number
    prepaymentPenaltyAmount: number
    securedByProperty: boolean
    isPaidOutsideOfClosing: boolean
    deductFromSettlementWire: boolean
    isPaidByIndividual: boolean
}

export interface ProrationItem {
    sectionType: DisclosureSectionType
    sectionTypeOtherDescription: string
    subsectionType: DisclosureSubsectionType
    subsectionTypeOtherDescription: string
    amount: number | null
    description: string
    paidFromDate: Date | null
    paidThroughDate: Date | null
    prorationItemType: ProrationItemType
}

export enum PayoffType {
    Liability="Liability",
    ClosingAdjustment="ClosingAdjustment"
}

export enum DisclosureSectionType {
    DueFromBorrowerAtClosing="DueFromBorrowerAtClosing",
    DueFromSellerAtClosing="DueFromSellerAtClosing",
    DueToSellerAtClosing="DueToSellerAtClosing",
    InitialEscrowPaymentAtClosing="InitialEscrowPaymentAtClosing",
    OriginationCharges="OriginationCharges",
    Other="Other",
    OtherCosts="OtherCosts",
    PaidAlreadyByOrOnBehalfOfBorrowerAtClosing="PaidAlreadyByOrOnBehalfOfBorrowerAtClosing",
    PayoffsAndPayments="PayoffsAndPayments",
    Prepaids="Prepaids",
    ServicesBorrowerDidNotShopFor="ServicesBorrowerDidNotShopFor",
    ServicesBorrowerDidShopFor="ServicesBorrowerDidShopFor",
    ServicesYouCannotShopFor="ServicesYouCannotShopFor",
    ServicesYouCanShopFor="ServicesYouCanShopFor",
    TaxesAndOtherGovernmentFees="TaxesAndOtherGovernmentFees",
    TotalClosingCosts="TotalClosingCosts",
    TotalLoanCosts="TotalLoanCosts",
    TotalOtherCosts="TotalOtherCosts"
}

export enum DisclosureSubsectionType {
    Adjustments="Adjustments",
    AdjustmentsForItemsPaidBySellerInAdvance="AdjustmentsForItemsPaidBySellerInAdvance",
    AdjustmentsForItemsUnpaidBySeller="AdjustmentsForItemsUnpaidBySeller",
    ClosingCostsSubtotal="ClosingCostsSubtotal",
    LenderCredits="LenderCredits",
    LoanCostsSubtotal="LoanCostsSubtotal",
    Other="Other",
    OtherCostsSubtotal="OtherCostsSubtotal",
    OtherCredit="OtherCredits",
    TotalClosingCostsSellerOnly="TotalClosingCostsSellerOnly"
}

export enum LiabilityType {
    BorrowerEstimatedTotalMonthlyLiabilityPayment = "BorrowerEstimatedTotalMonthlyLiabilityPayment",
    CollectionsJudgmentsAndLiens = "CollectionsJudgmentsAndLiens",
    DeferredStudentLoan = "DeferredStudentLoan",
    DelinquentTaxes = "DelinquentTaxes",
    FirstPositionMortgageLien = "FirstPositionMortgageLien",
    Garnishments = "Garnishments",
    HELOC = "HELOC",
    HomeownersAssociationLien = "HomeownersAssociationLien",
    Installment = "Installment",
    LeasePayment = "LeasePayment",
    MonetaryJudgment = "MonetaryJudgment",
    MortgageLoan = "MortgageLoan",
    Open30DayChargeAccount = "Open30DayChargeAccount",
    Other = "Other",
    PersonalLoan = "PersonalLoan",
    Revolving = "Revolving",
    SecondPositionMortgageLien = "SecondPositionMortgageLien",
    Taxes = "Taxes",
    TaxLien = "TaxLien",
    ThirdPositionMortgageLien = "ThirdPositionMortgageLien",
    UnsecuredHomeImprovementLoanInstallment = "UnsecuredHomeImprovementLoanInstallment",
    UnsecuredHomeImprovementLoanRevolving = "UnsecuredHomeImprovementLoanRevolving"
}

export enum ClosingAdjustmentType {
    FuelCosts = "FuelCosts",
    Gift = "Gift",
    Grant = "Grant",
    LenderCredit = "LenderCredit",
    Other = "Other",
    ProceedsOfSubordinateLiens = "ProceedsOfSubordinateLiens",
    RebateCredit = "RebateCredit",
    RelocationFunds = "RelocationFunds",
    RentFromSubjectProperty = "RentFromSubjectProperty",
    RepairCompletionEscrowHoldback = "RepairCompletionEscrowHoldback",
    Repairs = "Repairs",
    SatisfactionOfSubordinateLien = "SatisfactionOfSubordinateLien",
    SellerCredit = "SellerCredit",
    SellersEscrowAssumption = "SellersEscrowAssumption",
    SellersMortgageInsuranceAssumption = "SellersMortgageInsuranceAssumption",
    SellersReserveAccountAssumption = "SellersReserveAccountAssumption",
    Services = "Services",
    SubordinateFinancingProceeds = "SubordinateFinancingProceeds",
    SweatEquity = "SweatEquity",
    TenantSecurityDeposit = "TenantSecurityDeposit",
    TitlePremiumAdjustment = "TitlePremiumAdjustment",
    TradeEquity = "TradeEquity",
    UnpaidUtilityEscrowHoldback = "UnpaidUtilityEscrowHoldback",
    PrincipalReduction= "PrincipalReduction",
    ExistingLoansAssumedTakenSubjectTo = "ExistingLoansAssumedTakenSubjectTo"
}

export enum FundType {
    BridgeLoan="BridgeLoan",
    CashOnHand="CashOnHand",
    CashOrOtherEquity="CashOrOtherEquity",
    CheckingSavings="CheckingSavings",
    Contribution="Contribution",
    CreditCard="CreditCard",
    DepositOnSalesContract="DepositOnSalesContract",
    EquityOnPendingSale="EquityOnPendingSale",
    EquityOnSoldProperty="EquityOnSoldProperty",
    EquityOnSubjectProperty="EquityOnSubjectProperty",
    ExcessDeposit="ExcessDeposit",
    ForgivableSecuredLoan="ForgivableSecuredLoan",
    GiftFunds="GiftFunds",
    Grant="Grant",
    HousingRelocation="HousingRelocation",
    LifeInsuranceCashValue="LifeInsuranceCashValue",
    LotEquity="LotEquity",
    MortgageCreditCertificates="MortgageCreditCertificates",
    MortgageRevenueBond="MortgageRevenueBond",
    Other="Other",
    OtherEquity="OtherEquity",
    PledgedCollateral="PledgedCollateral",
    PremiumFunds="PremiumFunds",
    RentWithOptionToPurchase="RentWithOptionToPurchase",
    RetirementFunds="RetirementFunds",
    SaleOfChattel="SaleOfChattel",
    SalesPriceAdjustment="SalesPriceAdjustment",
    SecondaryFinancing="SecondaryFinancing",
    SecuredLoan="SecuredLoan",
    StocksAndBonds="StocksAndBonds",
    SweatEquity="SweatEquity",
    TradeEquity="TradeEquity",
    TrustFunds="TrustFunds",
    UnsecuredBorrowedFunds="UnsecuredBorrowedFunds"
}

export enum ProrationItemType {
    BoroughPropertyTax="BoroughPropertyTax",
    CityPropertyTax="CityPropertyTax",
    CondominiumAssociationDues="CondominiumAssociationDues",
    CondominiumAssociationSpecialAssessment="CondominiumAssociationSpecialAssessment",
    CooperativeAssociationDues="CooperativeAssociationDues",
    CooperativeAssociationSpecialAssessment="CooperativeAssociationSpecialAssessment",
    CountyPropertyTax="CountyPropertyTax",
    DistrictPropertyTax="DistrictPropertyTax",
    EarthquakeInsurancePremium="EarthquakeInsurancePremium",
    FloodInsurancePremium="FloodInsurancePremium",
    GroundRent="GroundRent",
    HailInsurancePremium="HailInsurancePremium",
    HazardInsurancePremium="HazardInsurancePremium",
    HomeownersAssociationDues="HomeownersAssociationDues",
    HomeownersAssociationSpecialAssessment="HomeownersAssociationSpecialAssessment",
    HomeownersInsurancePremium="HomeownersInsurancePremium",
    InterestOnLoanAssumption="InterestOnLoanAssumption",
    MortgageInsurancePremium="MortgageInsurancePremium",
    Other="Other",
    PastDuePropertyTax="PastDuePropertyTax",
    RentFromSubjectProperty="RentFromSubjectProperty",
    SellersReserveAccountAssumption="SellersReserveAccountAssumption",
    StatePropertyTax="StatePropertyTax",
    TownPropertyTax="TownPropertyTax",
    Utilities="Utilities",
    VolcanoInsurancePremium="VolcanoInsurancePremium",
    WindAndStormInsurancePremium="WindAndStormInsurancePremium",
    OtherAssessments="OtherAssessments"
}