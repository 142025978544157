import { BasicContact, Officer, WarehouseBankContact, Overridable, SingleSignerNotary, LoanRequestOptions } from './index'

export interface Lender {
    info: LenderInfo
    officer: Officer
    mersSigner: MersSigner
    branch: LenderBranch
    funder: BasicContact
    closer: BasicContact
    closingInstructions: BasicContact
    servicer: BasicContact
    servicerPayment: Overridable<BasicContact>
    dtMortgageReturn: BasicContact
    noticeOfRightToCancel: BasicContact
    documentsReturn: BasicContact
    notePay: BasicContact
    firstPaymentLetter: FirstPaymentLetterContact
    mortgageeClause: BasicContact
    taxService: BasicContact
    beneficiary: BasicContact
    processor: BasicContact
    warehouseBank: WarehouseBankContact
    sponsor: SponsorContact
    packageNotification: BasicContact[]
}

export interface LenderInfo {
    lenderProfileId: string
    name: string
    email: string
    phone: string
    tollFreePhone: string
    fax: string
    licenseId: string
    stateLicenseId: string
    stateLicenseType: string
    stateLicenseExempt: boolean
    address: LenderAddress
    stateOfIncorporation: string
    entityType: string
    typeOtherDescription: string
    signingOfficerName: string
    signingOfficerTitle: string
    notary: SingleSignerNotary
    currentServicerAttentionTo: string
    fhaId: string
    vaId: string
    closingLoansUnderUCCC: boolean
    closingLoansUnderTxOCCC: boolean
}

export interface LenderAddress {
    street: string
    unit: string
    city: string
    county: string
    state: string
    zip: string
}

export interface MersSigner {
    name: string
    title: string
    notary: SingleSignerNotary
}

export interface LenderBranch {
    info: BasicContact
}

export interface SponsorContact {
    sponsorId: string
    info: BasicContact
}

export interface FirstPaymentLetterContact extends BasicContact {
    contactName: string
}

export interface UpdateLenderProfileRequest extends LoanRequestOptions {
    lenderProfileId: string
    defaultType: LenderProfileContactDefaultType
}

export enum LenderProfileContactDefaultType {
    None = "None",
    Initial = "Initial",
    Update = "Update"
}