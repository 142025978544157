import { LoanAuditChecks } from '@/common/models'
import { AuditCheckResult } from '@/propel/models'

class AuditCheckService {
    getIndividualAuditChecks(auditChecks: LoanAuditChecks, key: string, append: string): AuditCheckResult[] {        
        if (key) {
            const fullKey = `${key}${append}`
            return auditChecks[fullKey]
        }
        return []
    }

    getAuditCheckLevelClass(displayAuditCheckErrors: boolean, auditChecks: AuditCheckResult[]) {
        if (displayAuditCheckErrors && auditChecks?.length) {
            return auditChecks.some(x => x.level === 'Error') 
            ? 'error' 
            : auditChecks.some(x => x.level === 'Success') 
                ? 'success'
                : 'warning';
        }
        return ''
    }
}

export const auditCheckService = new AuditCheckService()