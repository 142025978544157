<template>
    <confirmation-modal
        id="idle-modal"
        v-model="showModal"
        title="Session Timeout"
        @ok="stayLoggedIn"
        ok-title="Stay Logged In"
        cancel-title="Logout"
        @cancel="logout"
        @close="logout"
        cancel-variant="danger"
    >
        
            <template v-slot:shortMessage> Your session will expire soon</template>
            <template v-slot:longMessage> You have been inactive for a while and will be logged out in<div> {{ timeLeft }} seconds.</div></template>
    </confirmation-modal>
</template>

<script setup lang="ts">
import { authService, sessionService } from '@/common/services'
import { config } from '@/config'
import { computed, ref, watch } from 'vue'
import store from '../store/store'
import { useRouter } from 'vue-router/composables'

//#region DEFINE VARIABLES
const router = useRouter()

const showModal = ref(false)
const stayLoggedInNow = ref(false)
const timeLeft = ref(config.app.timeoutWarning)
const timeLeftConstant = config.app.timeoutWarning
//#endregion

//#region computed
// TODO: figure out how to make this warning go away. Tried initializing on the store, but ran into other warnings
const isIdle = computed(() => store.state.idleVue?.isIdle || false)
//#endregion

//#region WATCH
watch(() => isIdle.value, (isIdle: boolean) => isIdleChanged(isIdle)) 
//#endregion

//#region INITIALIZE
initialize()

async function initialize(){
    timeLeft.value = timeLeftConstant
}
// #endregion
    
function isIdleChanged(isIdle: boolean) {
    if (isIdle && !showModal.value) {
        const inactiveSeconds = (sessionService.timeoutMinutes * 60) - Number(config.app.timeoutWarning)
        const lastActive = new Date().getTime() - (inactiveSeconds * 1000)
        sessionService.setLastActiveTime(new Date(lastActive))
        showModal.value = true
        timeLeft.value = timeLeftConstant
        stayLoggedInNow.value = false
        const timerId = setInterval(() => {
            if (stayLoggedInNow.value) {
                clearInterval(timerId)
                sessionService.clearLastActiveTime()
            } else if (!sessionService.hasLastActiveTime()) {
                // If timeout is cleared in any other tabs, stay alive
                stayLoggedIn()
                clearInterval(timerId)
            }
            timeLeft.value -= 1
            if (timeLeft.value === 0) {
                clearInterval(timerId)
                logout()
            }
        }, 1000)
    }
}

function stayLoggedIn() {
    stayLoggedInNow.value = true
    showModal.value = false
}

function logout() {
    authService.logout(router.currentRoute.fullPath)
}

</script>

<style lang="scss" scoped>
:deep(.modal-footer button) {
    padding: 0.5rem;
    width: auto;
}
</style>
