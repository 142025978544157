<template>
    <base-page :breadcrumbs="onGetBreadcrumbs()" :pageIsBusy="pageIsBusy" :small-header="smallHeader">
        <template v-slot:pageActions>
            <slot name="new" v-if="onNew || newButtonMenu">
                <custom-button icon="Add" label="New" @click="onNew" :disabled="pageIsReadOnly"></custom-button>
            </slot>
            <custom-button icon="Save" label="Save" @click="save" :loading="isSaveLoading" :disabled="isSaveDisabledValue" v-if="onSave" title="Save" />
            <span class="save-error-message">{{ saveErrorMessage }}</span>
        </template>
        <slot />
        <template v-slot:summarySidebar>
            <slot name="summarySidebar" />
        </template>
    </base-page>
</template>

<script lang="ts" setup>
import { computed, ref, watch, defineProps, PropType } from "vue"
import { useRoute } from "vue-router/composables"
import { BreadcrumbItem } from "@/common/components/navigation/breadcrumb-item"
import { userPermissionValidatorService } from "@/common/services"
import { usePageActionsStore } from "@/common/store"

//#region DEFINE VARIABLES
const props = defineProps({
    isSaveDisabled: { type: Boolean },
    saveErrorMessage: { type: String, default: "" },
    smallHeader: { type: Boolean },
    onSave: { type: Function as PropType<() => Promise<void>> },
    onNew: { type: Function as PropType<() => void>},
    newButtonMenu: { type: Boolean },
    onGetBreadcrumbs: { type: Function as PropType<() => BreadcrumbItem[]>, default: () => [] as BreadcrumbItem[]}
})

const route = useRoute()
const store = usePageActionsStore()

const pageIsReadOnly = ref(false)
const isSaveLoading = ref(false)
//#endregion

//#region COMPUTED
const isSaveDisabledValue = computed<boolean>(() => props.isSaveDisabled || isSaveLoading.value || pageIsReadOnly.value)
const pageIsBusy = computed<boolean>(() => store.pageLoading.isLoading)
//#endregion

//#region WATCH
watch(() => route, () => routeUpdated(), { immediate: true })

function routeUpdated() {
    const writePermission: string = route?.meta?.writePermission
    if (writePermission)
        pageIsReadOnly.value = !userPermissionValidatorService.hasPermission([writePermission])
    else
        pageIsReadOnly.value = false
}
//#endregion

async function save () {
    try {
        isSaveLoading.value = true
        if (props.onSave) await props.onSave()
    }
    finally {
        isSaveLoading.value = false
    }
}
</script>

<style scoped lang="scss">
main {
    padding-bottom: 4rem;
}

.save-error-message {
    color: $red;
    font-size: 0.875rem;
}
</style>