export interface SystemIntegrationClient {
    clientId: string
    secret: string
    tokenExpiration: number
    refreshTokenExpiration?: number
    redirectUri: string
    scopes: string[]
    integratingSystem: string
    // UI will only support one Webhook configuration for now
    webhooks: WebhookConfiguration[]
}

export interface WebhookConfiguration
{
    url: string
    authenticationType: WebhookAuthenticationType | null
    certificateName: string | null
}

export enum WebhookAuthenticationType {
    MutualTLS = "MutualTLS"
}