import { LoanRecord } from '@/common/models'
import { FileType } from '@/propel/models'

export interface PackageRequest {
    loanId: string
    clientCode: string
    cacheId: string
    packageType: string
    packageDescription: string
    packageNotes: string
    isRedraw: boolean
    type: PackageRequestType
    formIds: string[]
    originatingSystem: string
}

export interface PreviewPackageRequest{
    loanRecord: LoanRecord
    isRedraw: boolean
    formIds: string[]
    isStateSpecific: boolean
    isClientSpecific: boolean
    clientCodes: string[]
    states: string[]
}

export enum PackageRequestType {
    Preview = 'Preview',
    Save = 'Save',
    SaveDraft = 'SaveDraft',
    Test = 'Test'
}

export interface PackageResponse {
    id: string
    name: string
    orderNumber: string
    documentCount: number
    pageCount: number
    fileType: FileType
    cacheId: string
    pdfUri: string
    metadata: any
}

export interface FileDetails{
    id: string
    cacheId: string | null
    fileName: string
    md5Hash: string | null
    progress: number
    bytesSent: number
    totalBytes: number
    complete: boolean
    error: string | null
}

export interface Attachment{
    locationType: string
    fileName: string
    cacheId: string
    md5Hash: string
}

export interface AttachmentResponse {
    hash: string
    cacheId: string
    locations: string[]
    succeeded: boolean

    exception: string
    exceptionTitle: string
    exceptionMessage: string
    correlationId: string
}