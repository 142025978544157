import { render, staticRenderFns } from "./checkbox-select-list.vue?vue&type=template&id=035b1fae&scoped=true"
import script from "./checkbox-select-list.vue?vue&type=script&setup=true&lang=ts"
export * from "./checkbox-select-list.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./checkbox-select-list.vue?vue&type=style&index=0&id=035b1fae&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "035b1fae",
  null
  
)

export default component.exports