import { GridDefaults, ClientDeliveryOption, RowSelectionType, User, UserProfile, DefaultLandingPages } from '@/common/models'
import { useCurrentUserStore } from '@/common/store'

enum LoanDefaultsOption {
    PackageSaveOption = "packageSaveOption",
    TestLoanId = "testLoanId",
    Delivery = "delivery",
    Status = "status",
    DocumentTestOption = "documentTestOption",
    FieldTestLoanIds = "fieldTestLoanIds",
    DocumentModeConversionType = "documentModeConversionType"
}

enum GridDefaultsOption {
    PageSize = "pageSize",
    SortBy = "sortBy",
    Clients = "clients",

}
class UserPreferenceService {

    get currentUserProfile(): UserProfile {
        const store = useCurrentUserStore()
        return store.currentUserProfile
    }

    getUserLandingPagePreference(): DefaultLandingPages {
        return this.currentUserProfile?.preferences?.defaultLandingPage
    }

    getTestingLoanId(): string {
        return this.getLoanDefaults(LoanDefaultsOption.TestLoanId) ?? ''
    }

    async setTestingLoanId(testingLoanId: string) {
        await this.setLoanDefaults(LoanDefaultsOption.TestLoanId, testingLoanId)
    }

    
    getUserLoanDelivery(): string {
        return this.getLoanDefaults(LoanDefaultsOption.Delivery) ?? ''
    }
    
    async setDelivery(delivery: string) {
        await this.setLoanDefaults(LoanDefaultsOption.Delivery, delivery)
    }
    
    getUserLoanStatus(): string {
        return this.getLoanDefaults(LoanDefaultsOption.Status) ?? ''
    }
    
    async setStatus(status: string) {
        await this.setLoanDefaults(LoanDefaultsOption.Status, status)
    }

    getFieldTestingLoanIds(): string[] {
        //if we don't have field testing loans, get the default testing loan
        return this.getLoanDefaults(LoanDefaultsOption.FieldTestLoanIds)?.split(';') 
            ?? [this.getTestingLoanId()].filter(id => id?.length ?? 0 > 0)
        //the filter prevents a blank array item caused by no default testing loan existing either
    }

    async setFieldTestingLoanIds(testingLoanIds: string[]) {
        await this.setLoanDefaults(LoanDefaultsOption.FieldTestLoanIds, testingLoanIds.join(';'))
    }

    getGridPageSize(gridId: string): number {
        const pageSize = this.getGridSettings(gridId, GridDefaultsOption.PageSize)
        return parseInt(pageSize)
    }

    async setGridPageSize(gridId: string, value: number) {
        await this.setGridSettings(gridId, GridDefaultsOption.PageSize, value)
    }

    getGridSortBy(gridId: string): string {
        return this.getGridSettings(gridId, GridDefaultsOption.SortBy)
    }
    
    async setGridSortBy(gridId: string, value: string) {
        await this.setGridSettings(gridId, GridDefaultsOption.SortBy, value)
    }

    getGridClients(gridId: string): string {
        return this.getGridSettings(gridId, GridDefaultsOption.Clients)
    }

    async setGridClients(gridId: string, value: string) {
        await this.setGridSettings(gridId, GridDefaultsOption.Clients, value)
    }

    getGridSettings(gridId: string, property: string): string {
        const gridDefaults = Object.entries(this.currentUserProfile?.preferences?.grids?.defaults || ({} as Map<string, string[]>)).map(([key, value]) => [key, value])
        let value
        for (let i = 0; i < gridDefaults.length; i++) {
            if (gridDefaults[i][0] === gridId) {
                const defaults = gridDefaults[i][1] as GridDefaults
                value = defaults[property]
                i = gridDefaults.length
            }
        }
        return value
    }

    async setGridSettings(gridId: string, property: string, value: string | number ) {
        const gridDefaults = Object.entries(this.currentUserProfile?.preferences?.grids?.defaults || ({} as Map<string, string[]>)).map(([key, value]) => [key, value])
        let gridFound = false
        for (let i = 0; i < gridDefaults.length; i++) {
            if (gridDefaults[i][0] === gridId) {
                gridDefaults[i][1][property] = value
                gridFound = true
                i = gridDefaults.length
            }
        }
        if (!gridFound) {
            const defaults = {} as GridDefaults
            defaults[property] = value
            gridDefaults.push([gridId, defaults])
        }
        this.currentUserProfile.preferences.grids.defaults = Object.fromEntries(gridDefaults)

        const store = useCurrentUserStore()
        await store.updateCurrentUser({ profile: this.currentUserProfile } as User)
    }

    getPackageSaveOption() {
        return this.getLoanDefaults(LoanDefaultsOption.PackageSaveOption)
    }

    async setPackageSaveOption(packageSaveOption: ClientDeliveryOption) {
        await this.setLoanDefaults(LoanDefaultsOption.PackageSaveOption, packageSaveOption)
    }

    getDocumentModeConversionType() {
        return this.getLoanDefaults(LoanDefaultsOption.DocumentModeConversionType)
    }

    async setDocumentModeConversionType(conversionType: string) {
        await this.setLoanDefaults(LoanDefaultsOption.DocumentModeConversionType, conversionType)
    }

    getDisplayAuditCheckErrors(): boolean {
        const displayAuditCheckErrors = localStorage.getItem(`displayAuditCheckErrors`)
        if (displayAuditCheckErrors) {
            return !!(JSON.parse(displayAuditCheckErrors) as boolean)
        }
        else {
            return false
        }
    }

    setDisplayAuditCheckErrors(value: boolean): void {
        localStorage.setItem(`displayAuditCheckErrors`, JSON.stringify(value))
    }

    getDocumentTestOption() {
        const documentTestOpton = this.getLoanDefaults(LoanDefaultsOption.DocumentTestOption)
        return documentTestOpton ? documentTestOpton : 'download'
    }

    async setDocumentTestOption(documentTestOpton: string) {
        await this.setLoanDefaults(LoanDefaultsOption.DocumentTestOption, documentTestOpton)
    }
    
    getAlternateGridRowColors() {
        const userProfile = this.currentUserProfile
        return userProfile.preferences?.grids?.alternateRowColors
    }

    getAutoSavePreference() {
        const userProfile = this.currentUserProfile
        return userProfile.preferences?.loan?.autoSave ?? true
    }

    isGridRowSelectionTypeAnywhere() {
        const userProfile = this.currentUserProfile
        return userProfile.preferences?.grids?.rowSelectionType ? userProfile.preferences.grids.rowSelectionType === RowSelectionType.Anywhere : true
    }

    getLoanDefaults(property: string): string {
        const loanDefaults = Object.entries(this.currentUserProfile?.preferences?.loan?.defaults || ({} as Map<string, string>)).map(([key, value]) => [key, value])
        let value
        for (let i = 0; i < loanDefaults.length; i++) {
            if (loanDefaults[i][0] === property) {
                value = loanDefaults[i][1]
                i = loanDefaults.length
            }
        }
        return value
    }

    async setLoanDefaults(property: string, value: string ) {
        const loanDefaults = Object.entries(this.currentUserProfile?.preferences?.loan?.defaults || ({} as Map<string, string>)).map(([key, value]) => [key, value])
        let propertyFound = false
        for (let i = 0; i < loanDefaults.length; i++) {
            if (loanDefaults[i][0] === property) {
                loanDefaults[i][1] = value
                propertyFound = true
                i = loanDefaults.length
            }
        }
        if (!propertyFound) {
            loanDefaults.push([property, value])
        }
        this.currentUserProfile.preferences.loan.defaults = Object.fromEntries(loanDefaults)
        const store = useCurrentUserStore()
        await store.updateCurrentUser({profile: this.currentUserProfile} as User)
    }
    getLoanSummarySidebarState(): boolean | null {
        const loanSummarySidebarState = localStorage.getItem(`loanSummarySidebarState`)
        if (loanSummarySidebarState) {
            return JSON.parse(loanSummarySidebarState) as boolean
        }
        else {
            return null
        }
    }

    setLoanSummarySidebarState(value: boolean): void {
        localStorage.setItem(`loanSummarySidebarState`, JSON.stringify(value))
    }
}

export const userPreferenceService = new UserPreferenceService()