<template>
    <base-page :breadcrumbs="breadcrumbs" :pageIsBusy="pageIsBusy">
        <template v-slot:pageActions>
            <custom-button v-if="onNew" icon="Add" @click="onNew" label="New" :disabled="pageIsReadOnly"></custom-button>
            <custom-button
            icon="Save"
            @click="onSave"
            label="Save"
            :disabled="isSaveDisabledValue"
            v-if="displaySaveButton"
            title="Save"
            :loading="isSaveLoading"
            ></custom-button>
            <slot name="pageActions" />
        </template>
        <slot />
        <template v-slot:summarySidebar>
            <slot name="summarySidebar" />
        </template>
    </base-page>
</template>

<script setup lang="ts">
import { BreadcrumbItem } from "@/common/components/navigation/breadcrumb-item"
import { userPermissionValidatorService } from "@/common/services"
import { usePageActionsStore } from "@/common/store"
import { PropType, ref, watch, defineProps, computed } from "vue"
import { Route } from "vue-router"
import { useRoute } from 'vue-router/composables'

//#region DEFINE VARIABLES
const props = defineProps({
    isSaveDisabled: {type: Boolean},
    hideSaveButton: {type: Boolean},
    onSave: { type: Function as PropType<() => Promise<void>>},
    onNew: { type: Function as PropType<() => Promise<void>>},
    breadcrumbs: { type: Array as PropType<Array<BreadcrumbItem>>, required: true},
})

const route = useRoute()
const store = usePageActionsStore()
const pageIsReadOnly = ref(false)

//#region COMPUTED
const isSaveDisabledValue = computed(() => props.isSaveDisabled || isSaveLoading.value || pageIsReadOnly.value)
const isSaveLoading = computed(() => store.saveLoading.isLoading)
const pageIsBusy = computed(() => store.pageLoading.isLoading)
const displaySaveButton = computed(() => props.onSave ? !props.hideSaveButton : false)
//#endregion

//#region WATCH
watch(() => route, () => routeUpdated(route),  { immediate: true }) 
//#endregion

function routeUpdated(route: Route) {
    const writePermission: string = route?.meta?.writePermission
    if (writePermission) {
        pageIsReadOnly.value = !userPermissionValidatorService.hasPermission([writePermission])
    } else {
        pageIsReadOnly.value = false
    }
}

</script>

<style lang="scss">
.test-area {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .field {
        margin-right: 0;
    }

    .test-section {
        flex: 0 1;

        &.results {
            flex: 1 0;

            textarea {
                width: 100%;
                height: 100%;
                padding: 0.5rem;
                font-size: 0.75rem;
                line-height: 1.125rem;
                color: $near-black;
            }
        }
    }
}

.summary-content {
    .test-area {
        height: calc(100% - 1.5rem);
    }
}

// TODO: Might need a better solution for scrollbars.
// Modal scrollbar is to the left of the <aside> but should
// really be on the right for consistency
.modal.testable {
    
    @media (min-width: 900px) {
        .modal-xl {
            max-width: 850px;
        }
    }

    .modal-xl .modal-body {
        overflow: hidden;
        height: 75vh;
    }

    .testable-area-wrap {
        display:flex;
        height: 100%;

        > div:first-child {
            flex-grow: 1;
        }
    }

    .tab-content {
        overflow-y: auto;

         &::-webkit-scrollbar {
            background-color: transparent;
        }
    }
    .tab-pane {
        height: calc(75vh - 4.5rem);
        padding-right: 1.5rem;
    }
}

</style>