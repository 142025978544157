<template>
    <div :id="id" ref="rootElement">
        <div
            class="custom-select"
            :class="[{ disabled: disabled }, auditCheckLevelClass]"
            ref="multiSelect"
            :tabindex="disabled ? undefined : 0"
            :jsonpath="jsonPath"
            @click.prevent="toggle"
            @keydown.enter.prevent="onEnter"
            @keydown.tab.esc="expanded = false"
            @keydown.down.prevent="onArrowDown"
            @keydown.up.prevent="onArrowUp"
            @keydown="onKeyboardSearch"
            @blur="onBlur"
        >
            <div class="selected">
                <span class="placeholder" v-if="placeholder && selectedItems.length === 0">{{ placeholder }}</span>
                <template v-else-if="multiple">
                    <span class="badge badge-sm badge-primary" v-for="(item, i) of selectedItems" :key="i">
                        <span>
                            {{ itemDisplay(item) }}
                        </span>
                        <custom-icon icon="Remove" v-if="displayDeselect(item)" @click="deselectItem(i)" />
                    </span>
                </template>
                <template v-else>
                    {{ selectedItemsDisplay }}
                </template>
            </div>
        </div>
        <div class="dropdown" :class="{ show: expanded, 'has-custom-sticky': hasStickyItems, 'has-search': showSearchInput }">
            <div class="dropdown-menu" ref="dropdownMenu" :class="{ show: expanded }" @mouseleave="dropdownItemsMouseLeave">
                <!-- Sticky area -->
                <div class="sticky-items" ref="stickyContainer">
                    <!-- dropdown search bar -->
                    <div class="dropdown-item search" v-show="showSearchInput">
                        <search-input
                            ref="searchInput"
                            :id="`search-${uid}`"
                            :loading="loadingSearchItems"
                            v-model="searchTerm"
                            @input="searchItems"
                            @keydown.enter.prevent="onEnter"
                            @keydown.tab="expanded = false"
                            @keydown.down.prevent="onArrowDown"
                            @keydown.up.prevent="onArrowUp"
                            :warning-message="searchMessageString"
                        />
                    </div>
                    <!-- sticky items -->
                    <div v-if="hasStickyItems">
                        <div
                            class="dropdown-item"
                            ref="dropdownItems"
                            v-for="(item, i) of stickyItems"
                            :key="i"
                            @mouseenter="hoverOnItem('activeStickyItemIndex', i)"
                            @click="selectItem(item, true)"
                            :class="{ active: isSelected(item), hover: activeStickyItemIndex === i }"
                            v-html="item.text || `&nbsp;`"
                        />
                    </div>
                </div>
                <div
                    class="dropdown-item"
                    ref="dropdownItems"
                    v-for="(item, i) of items"
                    :key="i"
                    @mouseenter="hoverOnItem('activeIndex', i)"
                    @click="selectItem(item, false)"
                    :class="{ active: isSelected(item), hover: activeIndex === i }"
                >
                    <div v-if="optionDisplayType === 'SplitWithValue'" class="subtitle">{{ item.value || `&nbsp;` }}</div>
                    <div v-if="optionDisplayType === 'SplitWithSecondaryText'" class="subtitle">{{ item.secondaryText || item.text }}</div>
                    <div v-html="item.text || `&nbsp;`" />
                    <div v-if="item.notes" class="notes">{{ item.notes }}</div>
                    <div v-if="optionDisplayType === 'SplitWithSecondaryTextBelow'" class="secondary">{{ item.secondaryText || item.text }}</div>
                </div>
                <div v-if="items && items.length === 0">
                    <div class="dropdown-item" v-if="!searchMessages">No results</div>
                    <div class="dropdown-item" v-else>{{ searchMessageString }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { PropType, ref, computed, onMounted, onUnmounted, defineEmits, defineProps, watch, nextTick } from 'vue'
import { useAuditChecks } from '@/common/composables/audit-check-composable'
import { MultiSelectItem } from './multi-select-item'
import { config } from '@/config'
import { debounce, isEqual, differenceWith } from 'lodash'
import { DropdownOptionDisplayType } from './dropdown-option-display'
import SearchInput from './search-input.vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e: "input", value: string | string[] | boolean): void
    (e: "change"): void
}>()

const props = defineProps({
    value: { required: true }, // Not including a type defaults the type to any
    id: { type: String, default: "" },
    source: { type: [Array, Function] as PropType<MultiSelectItem[] | ((searchTerm?: any) => Promise<MultiSelectItem[]>)>, required: true },
    stickyItems: { type: Array as PropType<Array<MultiSelectItem>>, default: () => [] },
    searchable: { type: Boolean },
    useLocalSearch: { type: Boolean },
    searchMinimumCharacters: { type: Number, default: 1 },
    showEmptyOption: { type: Boolean, default: true },
    disabled: { type: Boolean },
    multiple: { type: Boolean },
    placeholder: { type: String },
    optionsToFilter: { type: Array as PropType<Array<string>>, default: () => [] },
    showSelectedItemsValueOnDisplay: { type: Boolean },
    showDescriptionAfterSelect: { type: Boolean },
    clearSelectedItemsOnSelection: { type: Boolean },
    clearSearchOnSelection: { type: Boolean },
    optionDisplayType: { type: String as PropType<DropdownOptionDisplayType>, default: DropdownOptionDisplayType.Text },
    searchMessages: { type: Array as PropType<string[]| null>, default: null },
    jsonPath: { type: String, default: '' }
})

const items = ref([] as MultiSelectItem[]) //the result of the source prop
const selectedItems = ref([] as MultiSelectItem[]) // the item or items selected
const allItems = ref([] as MultiSelectItem[]) //a copy of items, used for local search
const expanded = ref(false)
const searchTerm = ref("")
const activeIndex = ref(-1) //represents the active item in the dropdown
const activeStickyItemIndex = ref(-1) //represents the active item in the stick items
const valueChangedLocally = ref(false)
const loadingSearchItems = ref(false)

const keyboardSearchText = ref("")
const keyboardSearchTextResetTimeout = ref()
const changeOnBlur = ref(false)

const uid = ref(0)

// element refs
const rootElement = ref<HTMLElement | null>(null) // We need a ref on the surrounding div within the template. This ref replaces Vue 2's this.$el
const multiSelect = ref<InstanceType<typeof HTMLElement> | null>(null)
const dropdownMenu = ref<InstanceType<typeof HTMLElement> | null>(null)
const dropdownItems = ref<InstanceType<typeof HTMLElement> | null>(null)
const stickyContainer = ref<InstanceType<typeof HTMLElement> | null>(null)
const searchInput = ref<InstanceType<typeof SearchInput> | null>(null)
//#endregion

//#region COMPUTED
const showSearchInput = computed<boolean>(() => (props.searchable || props.useLocalSearch) && !(props.useLocalSearch && allItems.value.length < config.app.minSearchableCount))
const hasStickyItems = computed<boolean>(() => props.stickyItems.length > 0)
const searchMessageString = computed<string|null>(() => props.searchMessages != null ? props.searchMessages[0] : null)

const selectedItemsDisplay = computed(() => {
    if (props.showDescriptionAfterSelect) {
        return selectedItems.value
            .map((x) => {
                const description = x.text.split(' - ')
                if (description.length > 1) {
                    return description[1]
                } else {
                    //accounts for drop down selections that act as a placeholder for no value
                    return description[0]
                }
            })
            .join(', ')
    }
    return props.showSelectedItemsValueOnDisplay ? selectedItems.value.map((x) => x.value).join(', ') : selectedItems.value.map((x) => x.text).join(', ')
})
//#endregion

//#region WATCH
watch(() => props.source, async () => {    
    if (Array.isArray(props.source)) {
        items.value = [...props.source]
    } else {
        items.value = await props.source()
    }
    // filter and remove duplicates
    items.value = filterOptions(items.value).filter((item, index, self) => self.findIndex((i) => i.value === item.value) === index)
    if (props.showEmptyOption) {
        addEmptyOption()
    }

    allItems.value = [...items.value]

    await setSelectedItems()
}, { immediate: true })

watch(() => props.value, async () => {
    if (valueChangedLocally.value) {
        valueChangedLocally.value = false
    } else {
        // value prop updated in parent component
        if (props.value) {
            await searchItems()
        }
        await setSelectedItems()
    }
})
//#endregion

//#region INITIALIZE
const { auditCheckLevelClass } = useAuditChecks(props)

onMounted(() => {
    document.addEventListener('click', onClickOutside)
})

onUnmounted(() => document.removeEventListener('click', onClickOutside))

//#endregion

//ensures that only one item can be active in the dropdown
function hoverOnItem(itemIndexType: string, index: number) {
    if (itemIndexType === 'activeStickyItemIndex') {
        activeIndex.value = -1
        activeStickyItemIndex.value = index
    } else {
        activeStickyItemIndex.value = -1
        activeIndex.value = index
    }
}

function displayDeselect(item: MultiSelectItem): boolean {
    return !!item.value
}

function onBlur() {
    if (changeOnBlur.value) {
        changeOnBlur.value = false
        emit('change')
    }
}

const _debouncedSearch = debounce(async () => {
    //Search input must be empty or greater than minimum character count
    const triggerSearch = !searchTerm.value.length || searchTerm.value.length >= props.searchMinimumCharacters
    if (typeof props.source === 'function' && triggerSearch) {
        activeIndex.value = -1
        items.value = await props.source(searchTerm.value)
        if (searchTerm.value === '' && props.showEmptyOption) {
            addEmptyOption()
        }
    }
}, 200)

function filterOptions(items: MultiSelectItem[]): MultiSelectItem[] {
    return props.optionsToFilter.length > 0 ? items.filter((x) => !props.optionsToFilter.includes(x.value)) : items
}

async function setSelectedItems() {
    if (Array.isArray(props.value)) {
        if (!props.multiple) throw new Error('Multiple flag must be enabled if binding to an array')
        //filter the items where props.value (selected items) share the same value, eliminates selected items that are not in the drop down list
        selectedItems.value = items.value.filter((x) => (props.value as any[]).some((y) => compareItems(y, x.value))) 
        //When showing selected item values, find the items that are not in selected items and make dummy multiselect items
        if (props.showSelectedItemsValueOnDisplay) {
            props.value.forEach((v, index) => {
                if (!!v && !selectedItems.value.some((s) => compareItems(s.value, v))) {
                    selectedItems.value.push({
                        text: `unrepresented dropdown value ${index + 1}`,
                        value: v
                    })
                }
            })
        }
    } else {
        selectedItems.value = items.value.filter((x) => compareItems(x.value, props.value))

        //check if a sticky item has been selected
        if (selectedItems.value.length === 0) {
            selectedItems.value = props.stickyItems.filter((x) => compareItems(x.value, props.value))
        }

        if (selectedItems.value.length === 0 && props.value && !Array.isArray(props.source)) {
            const searchItems = await props.source(props.value)
            //if something is misconfigured or set to an improper enum, this will prevent resorting or adding to the list again
            if (
                compareItems(
                    searchItems,
                    items.value.filter((x) => x.value !== null)
                )
            ) {
                return
            }
            const itemsToAdd = differenceWith(searchItems, items.value, compareItems)
            items.value = items.value.concat(itemsToAdd).sort((a, b) => {
                if (a.text > b.text) {
                    return 1
                } else if (b.text > a.text) {
                    return -1
                } else {
                    return 0
                }
            })
            selectedItems.value = items.value.filter((x) => compareItems(x.value, props.value))
        }
    }
}

function compareItems(a: any, b: any): boolean {
    if (typeof a === "string" && typeof b === "string"){
        return a.toLowerCase() === b.toLowerCase()
    }
    return isEqual(a, b)
}

async function toggle() {
    if (props.disabled) return

    expanded.value = !expanded.value

    if (expanded.value) {
        // wait for dropdown menu to be mounted/visible
        await nextTick()

        // init search input
        if (props.searchable && searchInput.value) {
            searchInput.value.focus()
        }

        // init active item
        if (selectedItems.value.length > 0) {
            // set activeIndex if items are selected
            const selectedItem = selectedItems.value[0]
            const index = items.value.indexOf(selectedItem)
            activeIndex.value = index

            // set scroll position to active item
            scrollToActive(true)
        }
    } else {
        multiSelect.value?.focus()
    }
}

async function deselectItem(index: number) {
    selectedItems.value.splice(index, 1)
    emit(
        'input',
        selectedItems.value.map((x) => x.value)
    )
    emit('change')
}

async function selectItem(item: MultiSelectItem, isStickyItem: boolean) {
    valueChangedLocally.value = true
    if (props.multiple && !isStickyItem) {
        const itemIndex = selectedItems.value.findIndex((i) => compareItems(i.value, item.value))

        if (item.value === null) {
            // empty option selected, clear all selections
            selectedItems.value = []
        } else if (itemIndex !== -1) {
            selectedItems.value.splice(itemIndex, 1)
        } else {
            selectedItems.value.push(item)
        }

        emit(
            'input',
            selectedItems.value.map((x) => x.value)
        )
    } else {
        selectedItems.value = [item]
        if (!item.value) {
            await searchItems()
        }
        await toggle()

        emit('input', selectedItems.value[0].value)
    }

    if (props.clearSelectedItemsOnSelection) {
        selectedItems.value = []
        emit('input', '')
    }

    emit('change')

    if (props.clearSearchOnSelection)
        searchInput.value?.clearSearch();
}

function isSelected(item: MultiSelectItem) {
    return selectedItems.value.findIndex((x) => x.value === item.value) !== -1
}

function itemDisplay(item: MultiSelectItem) {
    const description = item.text.split(' - ')
    return props.showDescriptionAfterSelect ? description[1] : props.showSelectedItemsValueOnDisplay ? item.value : item.text
}

function moveActiveIndex(offset: 1 | -1) {
    activeIndex.value = activeIndex.value + offset

    if (activeIndex.value === items.value.length) {
        activeIndex.value = 0
    }

    if (activeIndex.value < 0) {
        activeIndex.value = items.value.length - 1
    }
}

// search functions

async function searchItems() {
    loadingSearchItems.value = true
    activeIndex.value = props.showEmptyOption ? 1 : 0
    if (props.useLocalSearch) {
        performLocalSearch()
    } else {
        await _debouncedSearch()
    }
    loadingSearchItems.value = false
}

function performLocalSearch() {
    items.value = allItems.value.filter(
        (x) => 
            x.text?.toLowerCase().includes(searchTerm.value.toLowerCase()) 
            || x.value?.toLowerCase().includes(searchTerm.value.toLowerCase())
            || x.secondaryText?.toLowerCase().includes(searchTerm.value.toLowerCase())
    )
    if (searchTerm.value === '' && props.showEmptyOption) {
        addEmptyOption()
    }
}

function addEmptyOption() {
    if (items.value.length === 0 || items.value[0].value !== null) items.value.unshift({ value: null, text: '' })
}

// DOM events
async function onEnter() {
    if (expanded.value && activeIndex.value !== -1) {
        const activeItem = items.value[activeIndex.value]
        await selectItem(activeItem, false)
    } else {
        await toggle()
    }
}

async function onArrowDown() {
    if (!expanded.value) {
        await toggle()
    }
    moveActiveIndex(1)
    scrollToActive()
}

function scrollToActive(activeTop = false) {
    if (!expanded.value || !stickyContainer.value || !dropdownMenu.value || !dropdownItems.value) return

    const stickyHeight = stickyContainer.value.offsetHeight
    const menuScrollTop = dropdownMenu.value.scrollTop
    const menuHeight = dropdownMenu.value.offsetHeight - stickyHeight
    const menuBottomBorderWidth = 1

    const maxMenuViewArea = menuScrollTop + menuHeight - menuBottomBorderWidth

    const activeIndexValue = hasStickyItems.value ? activeIndex.value + 1 : activeIndex.value //The sticky item adds one to the dropdownItems list
    const dropdownItem = dropdownItems.value[activeIndexValue]
    const itemOffsetTop = dropdownItem?.offsetTop - stickyHeight
    const itemHeight = dropdownItem?.offsetHeight

    // scroll up to item
    if (activeTop || itemOffsetTop <= menuScrollTop) {
        dropdownMenu.value?.scrollTo({ top: itemOffsetTop })
    }

    //  scroll down to item
    else if (itemOffsetTop + itemHeight >= maxMenuViewArea) {
        dropdownMenu.value?.scrollTo({ top: itemOffsetTop + itemHeight - menuHeight })
    }
}

async function onArrowUp() {
    if (!expanded.value) 
        await toggle()
    
    moveActiveIndex(-1)
    scrollToActive()
}

function clearKeyboardSearch() {
        keyboardSearchText.value = ""
}

function onKeyboardSearch(e: KeyboardEvent) {
    const validKeys = RegExp(/^[A-Za-z]$/)

    if (!props.multiple && validKeys.test(e.key)) {
        keyboardSearchText.value += e.key
        clearTimeout(keyboardSearchTextResetTimeout.value)
        keyboardSearchTextResetTimeout.value = setTimeout(clearKeyboardSearch, 1000)

        const searchText = keyboardSearchText.value.toLowerCase()
        const searchOffFirstLetter = searchText.split('').every((char) => char === searchText[0])
        let item: MultiSelectItem

        //If repeated input of same letter, cycle through the options that start with that letter
        if (searchOffFirstLetter) {
            const filteredItems = items.value.filter((x) => x.text.toLowerCase().startsWith(searchText[0]))
            let currentFilteredIndex = filteredItems.findIndex((i) => i.value === selectedItems.value[0]?.value)

            if (currentFilteredIndex == filteredItems.length - 1)
                //If at the end of list, start over
                currentFilteredIndex = -1

            item = filteredItems[currentFilteredIndex + 1]
        } else {
            item = items.value.filter((x) => x.text.toLowerCase().startsWith(searchText))[0]
        }

        if (item) {
            activeIndex.value = items.value.indexOf(item)
            selectedItems.value = [item]
            scrollToActive()
            emit('input', selectedItems.value[0].value)

            //Wait to trigger change until blur to prevent change events from firing while typing
            changeOnBlur.value = true
        }
    }
}

function onClickOutside(e: Event) {
    // close dropdown if click outside of multiselect
    if (!rootElement.value?.contains(e.target as Node))
        expanded.value = false
}

function dropdownItemsMouseLeave() {
    if (props.multiple) {
        setTimeout(() => {
            expanded.value = false
        }, 300)
    }
}
</script>

<style lang="scss" scoped>
.selected {
    cursor: default;
}

.custom-select {
    min-height: $input-height;
    height: auto;

    &.disabled {
        background-color: $custom-select-disabled-bg;
        color: $custom-select-color;
    }

    .placeholder {
        color: $input-placeholder-color;
        font-style: italic;
    }
}

.placeholder {
    color: $input-placeholder-color;
}

.badge {
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;

    margin: 0.0625rem 0.25rem 0.0625rem 0;
    font-weight: normal;
    font-size: 90%;
    padding: 0.1875rem 0.1875rem 0.1875rem 0.375rem;
    border-radius: 0.125rem;
    letter-spacing: 0.5px;
}

.custom-icon.remove {
    font-size: 1rem !important;
    color: $off-white !important;
    height: 0;

    &:hover {
        color: $red !important;
    }
}

.form-control {
    margin: 0.2rem 0;
}

.dropdown-menu {
    margin-top: 0.125rem;
    margin-bottom: calc(#{$footer-height} + 1rem);

    // ensures dropdown menus are at least 10 rem (Bootstrap default)
    // but also at least as wide as the multi-select
    min-width: max(10rem, 100%);

    .dropdown-item {
        display:block;

        &:not(.search) {
            cursor: pointer;
        }

        &.search {
            padding: 0.5rem;

            :deep(.search-input .custom-icon.remove) {
                left: calc(100% - 1.75rem); // not sure why the standard right:0 doesn't work?
            }
        }

        &:not(.active) {
            &:hover {
                // ignore browser hover and let hoverIndex property control styling
                background: $white;
            }
            &.hover {
                background: $highlight-color;
                color: $body-color;
            }
        }
    }
}

.has-custom-sticky,
.has-search {
    .dropdown-menu {
        padding-top: 0;
    }
}

.loading-indicator {
    position: absolute;
    top: 12px;
    right: 15px;
}

.sticky-items {
    position: sticky;
    top: 0;
    background: $white;
}

.has-custom-sticky .sticky-items {
    border-bottom: 2px solid transparentize($medium-gray, 0.8);
    padding-bottom: 0.25rem;
}

.has-custom-sticky:not(.has-search) .sticky-items {
    padding-top: 0.25rem;
}

.subtitle, .secondary {
    font-size: 0.6875rem;
    color: $dark-gray;
    margin-bottom: -0.25rem;
}

.notes {
    font-size: 0.6875rem;
    color: $black;
    margin-top: -0.25rem;
}
</style>