export class PageTitles {
    static Prime = 'Prime'
    static Apor = 'APOR (HPM)'
    static TreasuryDaily = 'Treasury (HCM)'
    static TreasuryWeekly = 'Treasury (Weekly)'
    static Freddie = 'Freddie Mac (PMMS)'
    static Libor = 'LIBOR'
    static Sofr = 'SOFR'
    static LoanLimits = 'Loan Limits'
    static Commercial90DayFinancialPaper = 'Commercial 90-Day Financial Paper'
    static FannieMaeHistoricalDailyYields = 'Fannie Mae Historical Daily Required Net Yields'
    static FederalReserveDiscount = 'Federal Reserve Discount Rate'
    static TreasuryBill = "Treasury Bill Rates, Discount/Coupon Equivalent"
    static TennesseeMaxRate = "Tennessee Maximum Effective Interest Rates"
    static TennesseePrimeRate = "Tennessee Prime Formula Rate"
    static MissouriMarketRate = "Missouri Market Rate"
}