import { axiosDeliveryService } from '@/common/services'
import { EmailTransactionRequest, LastEmailTransaction, LoanReviewRequest, LoanReviewResponse, TransactionRequest, TransactionResponse, TransactionSearchResult } from '@/propel/models/delivery'
import { PagedCollection } from '@/common/models'

class DeliveryService {

    async createTransaction(request: TransactionRequest): Promise<TransactionResponse[]> {
        const response = await axiosDeliveryService.post('/transactions', request)
        return response.data
    }

    async getTransactions(params?: URLSearchParams): Promise<PagedCollection<TransactionSearchResult>> {
        const response = await axiosDeliveryService.get('/transactions', { params })
        return response.data
    }

    async getLastEmailTransaction(request: EmailTransactionRequest): Promise<LastEmailTransaction> {
        const response = await axiosDeliveryService.get(`/transactions/email/latest?clientCode=${request.clientCode}&loanNumber=${request.loanNumber}`)
        return response.data
    }

    async sendLoanReviewEmail(request: LoanReviewRequest): Promise<LoanReviewResponse> {
        const response = await axiosDeliveryService.post('/loan-review', request)
        return response.data
    }
}

export const deliveryService = new DeliveryService()