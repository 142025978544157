<template>
    <div class="yes-no-container">
        <validation-provider :name="label" :rules="validationRules" :immediate="immediateValidation" v-slot="{ errors }">
            <custom-icon icon="Error" :id="`${id}-error`" v-if="errors[0]" :title="errors[0]" />
            <audit-check-icon :id="`${id}-audit-check`" :auditChecks="auditChecks" />
            <custom-icon icon="Help" :id="`${id}-help`" v-if="helpText" :title="helpText" />
            <fg-defaultable
                :currentValue="value"
                :defaultValue="defaultValue"
                :isDefaulted="isDefaulted"
                @change="onDefaultValueChange"
                @defaultChange="onDefaultChange"
                :disabled="!showDefaultFieldEdit"
            />
            <input-select
                :id="id"
                :value="value"
                :source="dataSource"
                :disabled="disabled"
                :placeholder="placeholder"
                :validationRules="validationRules"
                :showEmptyOption="showEmptyOption"
                :validationFieldName="label"
                :immediateValidation="immediateValidation"
                :jsonPath="jsonPathValue"
                @input="$emit('input', $event)"
                @change="$emit('change', $event)"
            />
        </validation-provider>
        <label>{{ label }}</label>
    </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import { useFormGroupBase, baseProps } from './form-group-base-composable'

//#region DEFINE VARIABLES
const emit = defineEmits<{ 
    (e: 'input', value: any): void
    (e: 'change', value: any): void 
    (e: 'unlocked'): void
    }>()

const props = defineProps({
    value: { type: String, required: true },
    id: { type: String, required: true },
    label: { type: String, default: '' }, 
    placeholder: { type: String, default: '' }, 
    showEmptyOption: { type: Boolean, default: true }, 
    helpText: { type: String },
    ...baseProps
})

const dataSource = [
    { text: 'YES', value: true },
    { text: 'NO', value: false }
]
//#endregion

const { showDefaultFieldEdit, jsonPathValue, defaultValue, isDefaulted, auditChecks, onDefaultChange, onDefaultValueChange } = useFormGroupBase(props, emit)
</script>


<style lang="scss">
.yes-no-container {
    display: grid;
    grid-template-columns: 70px minmax(300px, 80%);
    width: 100%;
}

.yes-no-container label {
    padding-left: 10px;
    margin-top: 5px;
    font-size: 0.875rem;
}
</style>
