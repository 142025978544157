export interface LockAgreement {
    rateLockTermsAndConditions: string
    rateLockFeeRefundConditions: string
	daysLockRequired: number
	lockPaidByDate: Date
	otherQualifyingPrograms: string
	ca: LockAgreementCa
	mn: LockAgreementMn
	nm: LockAgreementNm
	ny: LockAgreementNy
}

export interface LockAgreementCa {
    costDescription: string
    notifiedBy: string
	ruleChanged: boolean
	loanProgramDiscontinued: boolean
	borrowerFailedToQualify: boolean
	otherReason: boolean
	otherReasonDescription: boolean
	programCostsMore: boolean
}

export interface LockAgreementMn {
	borrowerCanCloseAtLowerRate: boolean
	furtherActionRequired: boolean
	lowerRateCircumstances: string
	borrowerActionRequired: string
	lenderActionRequired: string
}

export interface LockAgreementNm {
	extendLockCost: number
	extendLockDuration: number
}

export interface LockAgreementNy {
	loanMustCloseBeforeCommitmentExpiration: boolean
	brokerFeePaidBy: BrokerFeePaidByType
	brokerPaidWhen: BrokerPaidWhenType
	brokerBonusEligibility: BonusEligibilityType
	maximumPoints: number
	premiumPricingBasis: string
	bonusDescription: string
	lenderPaidPointsBasis: string
	isBorrowerNotifiedOfPaidBonus: boolean
	hasPrepaymentPenalty: boolean
	penaltyAmount: number
	penaltyTerms: string
	commitment: LockAgreementNyCommitment
	assumptionConditions: string
}

export interface LockAgreementNyCommitment {
	minimumPoints: number
	maximumPoints: number
	interestRate: LockAgreementNyCommitmentInterestRate
}

export interface LockAgreementNyCommitmentInterestRate {
	isIndexPlusMargin: boolean
	index: number
	margin: number
	indexSource: string
	maxDeviation: number
}

export enum BonusEligibilityType
{
	NotOffered = "NotOffered",
	MayBeEligible = "MayBeEligible",
	IsEligible = "IsEligible"
}

export enum BrokerFeePaidByType
{
	Lender = "Lender",
	Borrower = "Borrower"
}

export enum BrokerPaidWhenType
{
	CommitmentAcceptance = "CommitmentAcceptance",
	AtClosing = "AtClosing"
}